import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bulk-edit-container" }
const _hoisted_2 = { class: "bulk-edit-top" }
const _hoisted_3 = { class: "rtitle" }
const _hoisted_4 = { class: "bulk-edit-content" }
const _hoisted_5 = { class: "bulk-edit-bottom" }
const _hoisted_6 = { class: "buttons" }
const _hoisted_7 = { class: "rudolfbox" }
const _hoisted_8 = { class: "modal-inner has-text-centered" }
const _hoisted_9 = { class: "mt-5 mb-5" }
const _hoisted_10 = { class: "mb-2" }
const _hoisted_11 = { class: "is-flex is-justify-content-center mb-2" }
const _hoisted_12 = {
  class: "has-text-weight-bold",
  style: {"text-transform":"uppercase"}
}
const _hoisted_13 = { class: "buttons mb-0" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_autocomplete = _resolveComponent("b-autocomplete")
  const _component_b_field = _resolveComponent("b-field")
  const _component_b_taginput = _resolveComponent("b-taginput")
  const _component_b_input = _resolveComponent("b-input")
  const _component_b_button = _resolveComponent("b-button")
  const _component_b_loading = _resolveComponent("b-loading")
  const _component_b_icon = _resolveComponent("b-icon")
  const _component_b_modal = _resolveComponent("b-modal")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.l('manage-leads-bulk-edit/title')), 1 /* TEXT */)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_b_field, {
        class: "mb-4",
        label: $data.bulkEditProjectModified ? _ctx.settingsStore.label("project") + " (" + _ctx.l("manage-leads-bulk-edit/edited") + ")" : _ctx.settingsStore.label("project"),
        expanded: "",
        type: { "is-danger": $data.bulkEditProjectModified }
      }, {
        default: _withCtx(() => [
          _createVNode(_component_b_autocomplete, {
            modelValue: $data.bulkEditProject,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.bulkEditProject) = $event)),
            data: $options.belongsToOptions,
            icon: "format-list-checkbox",
            "open-on-focus": true,
            placeholder: $data.bulkEditProjectModified ? "" : _ctx.l("manage-leads-bulk-edit/not-edited"),
            onFocus: _cache[1] || (_cache[1] = $event => ($data.bulkEditProjectModified = true))
          }, null, 8 /* PROPS */, ["modelValue", "data", "placeholder"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["label", "type"]),
      _createVNode(_component_b_field, {
        class: "mb-4",
        label: $options.bulkEditAddTagsModified ? _ctx.l("manage-leads-bulk-edit/add-tags") + " (" + _ctx.l("manage-leads-bulk-edit/edited") + ")" : _ctx.l("manage-leads-bulk-edit/add-tags"),
        expanded: "",
        type: { "is-danger": $options.bulkEditAddTagsModified }
      }, {
        default: _withCtx(() => [
          _createVNode(_component_b_taginput, {
            modelValue: $data.bulkEditAddTags,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($data.bulkEditAddTags) = $event)),
            ellipsis: "",
            autocomplete: "",
            data: $data.filteredTags,
            onTyping: _cache[3] || (_cache[3] = (text) => $options.getFilteredTags(text)),
            onRemove: _cache[4] || (_cache[4] = () => $options.getFilteredTags()),
            "allow-new": true,
            "open-on-focus": true,
            icon: "label",
            placeholder: $options.bulkEditAddTagsModified ? _ctx.l("manage-leads-bulk-edit/add-tags") : _ctx.l("manage-leads-bulk-edit/not-edited")
          }, null, 8 /* PROPS */, ["modelValue", "data", "placeholder"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["label", "type"]),
      _createVNode(_component_b_field, {
        class: "mb-4",
        label: $options.bulkEditRemoveTagsModified ? _ctx.l("manage-leads-bulk-edit/remove-tags") + " (" + _ctx.l("manage-leads-bulk-edit/edited") + ")" : _ctx.l("manage-leads-bulk-edit/remove-tags"),
        expanded: "",
        type: { "is-danger": $options.bulkEditRemoveTagsModified }
      }, {
        default: _withCtx(() => [
          _createVNode(_component_b_taginput, {
            modelValue: $data.bulkEditRemoveTags,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => (($data.bulkEditRemoveTags) = $event)),
            ellipsis: "",
            autocomplete: "",
            data: $data.filteredTags,
            onTyping: _cache[6] || (_cache[6] = (text) => $options.getFilteredTags(text)),
            onRemove: _cache[7] || (_cache[7] = () => $options.getFilteredTags()),
            "allow-new": false,
            "open-on-focus": true,
            icon: "label",
            placeholder: $options.bulkEditRemoveTagsModified ? _ctx.l("manage-leads-bulk-edit/remove-tags") : _ctx.l("manage-leads-bulk-edit/not-edited")
          }, null, 8 /* PROPS */, ["modelValue", "data", "placeholder"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["label", "type"]),
      _createVNode(_component_b_field, {
        class: "mb-4",
        label: $data.bulkEditProjectTypeModified ? _ctx.settingsStore.label("projecttype") + " (" + _ctx.l("manage-leads-bulk-edit/edited") + ")" : _ctx.settingsStore.label("projecttype"),
        expanded: "",
        type: { "is-danger": $data.bulkEditProjectTypeModified }
      }, {
        default: _withCtx(() => [
          _createVNode(_component_b_autocomplete, {
            modelValue: $data.bulkEditProjecttype,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => (($data.bulkEditProjecttype) = $event)),
            data: $options.projectTypeOptions,
            clearable: "",
            "open-on-focus": true,
            placeholder: $data.bulkEditProjectTypeModified ? "" : _ctx.l("manage-leads-bulk-edit/not-edited"),
            onFocus: _cache[9] || (_cache[9] = $event => ($data.bulkEditProjectTypeModified = true))
          }, null, 8 /* PROPS */, ["modelValue", "data", "placeholder"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["label", "type"]),
      _createVNode(_component_b_field, {
        class: "mb-4",
        label: $data.bulkEditCustomerTypeModified ? _ctx.settingsStore.label("customertype") + " (" + _ctx.l("manage-leads-bulk-edit/edited") + ")" : _ctx.settingsStore.label("customertype"),
        expanded: "",
        type: { "is-danger": $data.bulkEditCustomerTypeModified }
      }, {
        default: _withCtx(() => [
          _createVNode(_component_b_autocomplete, {
            modelValue: $data.bulkEditCustomertype,
            "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => (($data.bulkEditCustomertype) = $event)),
            data: $options.customerTypeOptions,
            clearable: "",
            "open-on-focus": true,
            placeholder: $data.bulkEditCustomerTypeModified ? "" : _ctx.l("manage-leads-bulk-edit/not-edited"),
            onFocus: _cache[11] || (_cache[11] = $event => ($data.bulkEditCustomerTypeModified = true))
          }, null, 8 /* PROPS */, ["modelValue", "data", "placeholder"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["label", "type"]),
      _createVNode(_component_b_field, {
        class: "mb-4",
        label: $data.bulkEditRegionModified ? _ctx.settingsStore.label("region") + " (" + _ctx.l("manage-leads-bulk-edit/edited") + ")" : _ctx.settingsStore.label("region"),
        expanded: "",
        type: { "is-danger": $data.bulkEditRegionModified }
      }, {
        default: _withCtx(() => [
          _createVNode(_component_b_input, {
            modelValue: $data.bulkEditRegion,
            "onUpdate:modelValue": _cache[12] || (_cache[12] = $event => (($data.bulkEditRegion) = $event)),
            onFocus: _cache[13] || (_cache[13] = $event => ($data.bulkEditRegionModified = true)),
            placeholder: $data.bulkEditRegionModified ? "" : _ctx.l("manage-leads-bulk-edit/not-edited")
          }, null, 8 /* PROPS */, ["modelValue", "placeholder"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["label", "type"]),
      _createVNode(_component_b_field, {
        class: "mb-4",
        label: $data.bulkEditWorkZone1Modified ? _ctx.settingsStore.label("zone1") + " (" + _ctx.l("manage-leads-bulk-edit/edited") + ")" : _ctx.settingsStore.label("zone1"),
        expanded: "",
        type: { "is-danger": $data.bulkEditWorkZone1Modified }
      }, {
        default: _withCtx(() => [
          _createVNode(_component_b_input, {
            modelValue: $data.bulkEditWorkZone1,
            "onUpdate:modelValue": _cache[14] || (_cache[14] = $event => (($data.bulkEditWorkZone1) = $event)),
            onFocus: _cache[15] || (_cache[15] = $event => ($data.bulkEditWorkZone1Modified = true)),
            placeholder: $data.bulkEditWorkZone1Modified ? "" : _ctx.l("manage-leads-bulk-edit/not-edited")
          }, null, 8 /* PROPS */, ["modelValue", "placeholder"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["label", "type"]),
      _createVNode(_component_b_field, {
        class: "mb-4",
        label: $data.bulkEditWorkZone2Modified ? _ctx.settingsStore.label("zone2") + " (" + _ctx.l("manage-leads-bulk-edit/edited") + ")" : _ctx.settingsStore.label("zone2"),
        expanded: "",
        type: { "is-danger": $data.bulkEditWorkZone2Modified }
      }, {
        default: _withCtx(() => [
          _createVNode(_component_b_input, {
            modelValue: $data.bulkEditWorkZone2,
            "onUpdate:modelValue": _cache[16] || (_cache[16] = $event => (($data.bulkEditWorkZone2) = $event)),
            onFocus: _cache[17] || (_cache[17] = $event => ($data.bulkEditWorkZone2Modified = true)),
            placeholder: $data.bulkEditWorkZone2Modified ? "" : _ctx.l("manage-leads-bulk-edit/not-edited")
          }, null, 8 /* PROPS */, ["modelValue", "placeholder"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["label", "type"]),
      _createVNode(_component_b_field, {
        class: "mb-4",
        label: $data.bulkEditWorkZone3Modified ? _ctx.settingsStore.label("zone3") + " (" + _ctx.l("manage-leads-bulk-edit/edited") + ")" : _ctx.settingsStore.label("zone3"),
        expanded: "",
        type: { "is-danger": $data.bulkEditWorkZone3Modified }
      }, {
        default: _withCtx(() => [
          _createVNode(_component_b_input, {
            modelValue: $data.bulkEditWorkZone3,
            "onUpdate:modelValue": _cache[18] || (_cache[18] = $event => (($data.bulkEditWorkZone3) = $event)),
            onFocus: _cache[19] || (_cache[19] = $event => ($data.bulkEditWorkZone3Modified = true)),
            placeholder: $data.bulkEditWorkZone3Modified ? "" : _ctx.l("manage-leads-bulk-edit/not-edited")
          }, null, 8 /* PROPS */, ["modelValue", "placeholder"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["label", "type"]),
      _createVNode(_component_b_field, {
        class: "mb-4",
        label: $data.bulkEditWorkZone4Modified ? _ctx.settingsStore.label("zone4") + " (" + _ctx.l("manage-leads-bulk-edit/edited") + ")" : _ctx.settingsStore.label("zone4"),
        expanded: "",
        type: { "is-danger": $data.bulkEditWorkZone4Modified }
      }, {
        default: _withCtx(() => [
          _createVNode(_component_b_input, {
            modelValue: $data.bulkEditWorkZone4,
            "onUpdate:modelValue": _cache[20] || (_cache[20] = $event => (($data.bulkEditWorkZone4) = $event)),
            onFocus: _cache[21] || (_cache[21] = $event => ($data.bulkEditWorkZone4Modified = true)),
            placeholder: $data.bulkEditWorkZone4Modified ? "" : _ctx.l("manage-leads-bulk-edit/not-edited")
          }, null, 8 /* PROPS */, ["modelValue", "placeholder"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["label", "type"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_b_button, {
          type: "is-primary",
          rounded: "",
          onClick: _cache[22] || (_cache[22] = $event => ($options.openBulkEditLeadsConfirmModal())),
          disabled: $data.loading || !$options.fieldsModified
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.l('manage-leads-bulk-edit/submit-button')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["disabled"]),
        _createVNode(_component_b_button, {
          type: "is-light",
          rounded: "",
          disabled: $data.loading,
          onClick: _cache[23] || (_cache[23] = $event => ($options.close()))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.l('generic/close')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["disabled"])
      ])
    ]),
    _createVNode(_component_b_loading, {
      "is-full-page": false,
      modelValue: $data.loading,
      "onUpdate:modelValue": _cache[24] || (_cache[24] = $event => (($data.loading) = $event)),
      animation: "none"
    }, null, 8 /* PROPS */, ["modelValue"]),
    _createCommentVNode(""),
    _createCommentVNode(" ███╗   ███╗ ██████╗ ██████╗  █████╗ ██╗"),
    _createCommentVNode(" ████╗ ████║██╔═══██╗██╔══██╗██╔══██╗██║"),
    _createCommentVNode(" ██╔████╔██║██║   ██║██║  ██║███████║██║"),
    _createCommentVNode(" ██║╚██╔╝██║██║   ██║██║  ██║██╔══██║██║"),
    _createCommentVNode(" ██║ ╚═╝ ██║╚██████╔╝██████╔╝██║  ██║███████╗"),
    _createCommentVNode(" ╚═╝     ╚═╝ ╚═════╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝"),
    _createCommentVNode(""),
    _createCommentVNode(" Bulk manage modals"),
    _createCommentVNode(""),
    _createVNode(_component_b_modal, {
      class: "fit-content-height",
      modelValue: $data.bulkEditLeadsConfirmationModalActive,
      "onUpdate:modelValue": _cache[27] || (_cache[27] = $event => (($data.bulkEditLeadsConfirmationModalActive) = $event)),
      width: 640,
      scroll: "keep",
      "can-cancel": [ 'escape', 'outside' ],
      "on-cancel": $options.closeBulkEditLeadsConfirmModal
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_b_icon, {
              icon: "alert-circle-outline",
              "custom-class": "modal-alert"
            }),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.l('manage-leads-bulk-edit/confirm-dialog1')), 1 /* TEXT */),
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", null, [
                  _createVNode(_component_b_icon, {
                    icon: "alert-circle",
                    "custom-size": "mdi-18px",
                    "custom-class": "bulk-edit-alert-circle"
                  })
                ]),
                _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.l('manage-leads-bulk-edit/confirm-dialog2')), 1 /* TEXT */)
              ]),
              _createElementVNode("div", null, _toDisplayString(_ctx.l('manage-leads-bulk-edit/confirm-dialog3', $props.selectedLeads.length)), 1 /* TEXT */)
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createVNode(_component_b_button, {
                type: "is-light",
                rounded: "",
                onClick: _cache[25] || (_cache[25] = $event => ($options.closeBulkEditLeadsConfirmModal()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.l('generic/cancel')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_b_button, {
                type: "is-primary",
                rounded: "",
                onClick: _cache[26] || (_cache[26] = $event => ($options.bulkEditLeads()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.l('generic/confirm')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              })
            ])
          ])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "on-cancel"])
  ]))
}