import { createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, renderList as _renderList, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "quick-search-container"
}
const _hoisted_2 = { class: "quickfilter-dropdown-status-container" }
const _hoisted_3 = { class: "status-product" }
const _hoisted_4 = { class: "greytext status-product-name" }
const _hoisted_5 = { class: "status-status" }
const _hoisted_6 = {
  key: 0,
  class: "filter-options-area"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_input = _resolveComponent("b-input")
  const _component_b_field = _resolveComponent("b-field")
  const _component_b_taginput = _resolveComponent("b-taginput")
  const _component_b_tag = _resolveComponent("b-tag")
  const _component_b_taglist = _resolveComponent("b-taglist")
  const _component_b_icon = _resolveComponent("b-icon")
  const _component_b_tooltip = _resolveComponent("b-tooltip")

  return ($options.filter)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(["filter-input-area", { "with-filter-option": $options.filter.filterOptionEnabled }])
        }, [
          ($options.filter.type === "simpleText")
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createCommentVNode(""),
                _createCommentVNode(" Simple text filters"),
                _createCommentVNode(""),
                _createVNode(_component_b_field, { expanded: "" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_input, {
                      size: "is-small",
                      placeholder: _ctx.l("filtering/filter-by") + "...",
                      modelValue: _ctx.filtering[$options.filter.data],
                      onInput: _cache[0] || (_cache[0] = (event) => { _ctx.debounceTextInput()($options.filter.data, event.target.value) }),
                      "icon-right": $options.isFilterActive ? "close-circle" : "",
                      "icon-right-clickable": true,
                      onIconRightClick: _cache[1] || (_cache[1] = $event => (_ctx.resetFilters($options.filter.data))),
                      "custom-class": "simpletext-input",
                      loading: _ctx.debounceLoading[$options.filter.data]
                    }, null, 8 /* PROPS */, ["placeholder", "modelValue", "icon-right", "loading"])
                  ]),
                  _: 1 /* STABLE */
                })
              ], 64 /* STABLE_FRAGMENT */))
            : _createCommentVNode("v-if", true),
          ($options.filter.type === "minMax")
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createCommentVNode(""),
                _createCommentVNode(" Regular columns min/max filter"),
                _createCommentVNode(""),
                _createVNode(_component_b_field, { grouped: "" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_field, { expanded: "" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_b_input, {
                          size: "is-small",
                          placeholder: _ctx.l("generic/min"),
                          type: "number",
                          modelValue: _ctx.filtering[$options.filter.data][0],
                          onInput: _cache[2] || (_cache[2] = (event) => { _ctx.debounceMinMaxInput()($options.filter.data, "min", event.target.value) }),
                          "icon-right": !isNaN(_ctx.filtering[$options.filter.data][0]) ? "close-circle" : "",
                          "icon-right-clickable": true,
                          onIconRightClick: _cache[3] || (_cache[3] = $event => (_ctx.clearFilterMinMax($options.filter.data, "min"))),
                          "custom-class": "simpletext-input",
                          loading: _ctx.debounceLoading[$options.filter.data]
                        }, null, 8 /* PROPS */, ["placeholder", "modelValue", "icon-right", "loading"])
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_b_field, { expanded: "" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_b_input, {
                          size: "is-small",
                          placeholder: _ctx.l("generic/max"),
                          type: "number",
                          modelValue: _ctx.filtering[$options.filter.data][1],
                          onInput: _cache[4] || (_cache[4] = (event) => { _ctx.debounceMinMaxInput()($options.filter.data, "max", event.target.value) }),
                          "icon-right": !isNaN(_ctx.filtering[$options.filter.data][1]) ? "close-circle" : "",
                          "icon-right-clickable": true,
                          onIconRightClick: _cache[5] || (_cache[5] = $event => (_ctx.clearFilterMinMax($options.filter.data, "max"))),
                          "custom-class": "simpletext-input",
                          loading: _ctx.debounceLoading[$options.filter.data]
                        }, null, 8 /* PROPS */, ["placeholder", "modelValue", "icon-right", "loading"])
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _: 1 /* STABLE */
                })
              ], 64 /* STABLE_FRAGMENT */))
            : _createCommentVNode("v-if", true),
          ($options.filter.type === "questionText")
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                _createCommentVNode(""),
                _createCommentVNode(" Questions simple text filter"),
                _createCommentVNode(""),
                _createVNode(_component_b_field, { expanded: "" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_input, {
                      size: "is-small",
                      placeholder: _ctx.l("filtering/filter-by") + "...",
                      modelValue: $options.questionFilter.text,
                      onInput: _cache[6] || (_cache[6] = (event) => { _ctx.debounceQuestionTextInput()($options.filter.templateQuestion._id, event.target.value, true) }),
                      "icon-right": $options.isFilterActive ? "close-circle" : "",
                      "icon-right-clickable": true,
                      onIconRightClick: _cache[7] || (_cache[7] = $event => (_ctx.resetQuestionFilter($options.filter.templateQuestion._id))),
                      "custom-class": "simpletext-input",
                      loading: _ctx.debounceLoading[$options.filter.templateQuestion._id]
                    }, null, 8 /* PROPS */, ["placeholder", "modelValue", "icon-right", "loading"])
                  ]),
                  _: 1 /* STABLE */
                })
              ], 64 /* STABLE_FRAGMENT */))
            : _createCommentVNode("v-if", true),
          ($options.filter.type === "questionMinMax")
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                _createCommentVNode(""),
                _createCommentVNode(" Questions min/max filter"),
                _createCommentVNode(""),
                _createVNode(_component_b_field, { grouped: "" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_field, { expanded: "" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_b_input, {
                          size: "is-small",
                          placeholder: _ctx.l("generic/min"),
                          type: "number",
                          modelValue: $options.questionFilter?.range[0],
                          onInput: _cache[8] || (_cache[8] = (event) => { _ctx.debounceQuestionMinMaxInput()($options.filter.templateQuestion._id, "min", event.target.value, true) }),
                          "icon-right": !isNaN($options.questionFilter?.range[0]) ? "close-circle" : "",
                          "icon-right-clickable": true,
                          onIconRightClick: _cache[9] || (_cache[9] = $event => (_ctx.clearQuestionFilterMinMax($options.filter.templateQuestion._id, "min"))),
                          "custom-class": "simpletext-input",
                          loading: _ctx.debounceLoading[$options.filter.templateQuestion._id]
                        }, null, 8 /* PROPS */, ["placeholder", "modelValue", "icon-right", "loading"])
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_b_field, { expanded: "" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_b_input, {
                          size: "is-small",
                          placeholder: _ctx.l("generic/max"),
                          type: "number",
                          modelValue: $options.questionFilter?.range[1],
                          onInput: _cache[10] || (_cache[10] = (event) => { _ctx.debounceQuestionMinMaxInput()($options.filter.templateQuestion._id, "max", event.target.value, true) }),
                          "icon-right": !isNaN($options.questionFilter?.range[1]) ? "close-circle" : "",
                          "icon-right-clickable": true,
                          onIconRightClick: _cache[11] || (_cache[11] = $event => (_ctx.clearQuestionFilterMinMax($options.filter.templateQuestion._id, "max"))),
                          "custom-class": "simpletext-input",
                          loading: _ctx.debounceLoading[$options.filter.templateQuestion._id]
                        }, null, 8 /* PROPS */, ["placeholder", "modelValue", "icon-right", "loading"])
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _: 1 /* STABLE */
                })
              ], 64 /* STABLE_FRAGMENT */))
            : _createCommentVNode("v-if", true),
          ($options.filter.type === "multiTags")
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                _createCommentVNode(""),
                _createCommentVNode(" Multi tag filters"),
                _createCommentVNode(""),
                _createVNode(_component_b_field, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_taginput, {
                      ref: (child) => { $data.taginputRefs[$options.filter.data] = child },
                      size: "is-small",
                      modelValue: _ctx.filtering[$options.filter.data],
                      data: _ctx.filterableSearched[$options.filter.data],
                      autocomplete: "",
                      "open-on-focus": $options.filter.openOnFocus,
                      "allow-new": $options.filter.allowNew,
                      field: $options.filter.dataProp,
                      "remove-on-keys": [],
                      placeholder: _ctx.l("filtering/filter-by") + "...",
                      "append-to-body": true,
                      onFocus: _cache[12] || (_cache[12] = $event => (_ctx.refreshFilterables(null, $options.filter.data))),
                      onTyping: _cache[13] || (_cache[13] = (text) => _ctx.getFilterableItems($options.filter.data, text, $options.filter.dataProp)),
                      onAdd: _cache[14] || (_cache[14] = (item) => _ctx.addFilteredItem($options.filter.data, item)),
                      onRemove: _cache[15] || (_cache[15] = (item) => _ctx.removeFilteredItem($options.filter.data, item))
                    }, {
                      empty: _withCtx(() => _cache[21] || (_cache[21] = [
                        _createElementVNode("div", null, "No results found", -1 /* HOISTED */)
                      ])),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["modelValue", "data", "open-on-focus", "allow-new", "field", "placeholder"])
                  ]),
                  _: 1 /* STABLE */
                })
              ], 64 /* STABLE_FRAGMENT */))
            : _createCommentVNode("v-if", true),
          ($options.filter.type === "statusFilter")
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 5 }, [
                _createCommentVNode(""),
                _createCommentVNode(" Status filter is special case"),
                _createCommentVNode(""),
                _createVNode(_component_b_field, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_taginput, {
                      ref: (child) => { $data.taginputRefs[$options.filter.data] = child },
                      size: "is-small",
                      modelValue: _ctx.filtering[$options.filter.data],
                      data: _ctx.filterableSearched[$options.filter.data],
                      autocomplete: "",
                      "open-on-focus": $options.filter.openOnFocus,
                      "allow-new": false,
                      field: $options.filter.dataProp,
                      "remove-on-keys": [],
                      placeholder: _ctx.l("filtering/filter-by") + "...",
                      "append-to-body": true,
                      onFocus: _cache[16] || (_cache[16] = $event => (_ctx.refreshFilterables(null, $options.filter.data))),
                      onTyping: _cache[17] || (_cache[17] = (text) => _ctx.getFilterableItems($options.filter.data, text, $options.filter.dataProp)),
                      onAdd: _cache[18] || (_cache[18] = (item) => _ctx.addFilteredItem($options.filter.data, item)),
                      onRemove: _cache[19] || (_cache[19] = (item) => _ctx.removeFilteredItem($options.filter.data, item))
                    }, {
                      default: _withCtx((props) => [
                        _createElementVNode("div", _hoisted_2, [
                          _createElementVNode("div", {
                            class: "status-color",
                            style: _normalizeStyle({ "backgroundColor": props.option.color })
                          }, null, 4 /* STYLE */),
                          _createElementVNode("div", _hoisted_3, [
                            _createElementVNode("span", _hoisted_4, _toDisplayString(props.option.productName), 1 /* TEXT */),
                            _cache[22] || (_cache[22] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
                            _createElementVNode("span", _hoisted_5, _toDisplayString(props.option.text), 1 /* TEXT */)
                          ])
                        ])
                      ]),
                      selected: _withCtx((props) => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.tags, (tag, index) => {
                          return (_openBlock(), _createBlock(_component_b_taglist, {
                            key: index,
                            attached: ""
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_b_tag, {
                                style: _normalizeStyle({ "backgroundColor": tag.color })
                              }, null, 8 /* PROPS */, ["style"]),
                              _createVNode(_component_b_tag, {
                                rounded: "",
                                closable: "",
                                onClose: _withModifiers($event => ($data.taginputRefs["statuses"].removeTag(index, $event)), ["stop"])
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.truncateText(tag.productName)) + " / " + _toDisplayString(_ctx.truncateText(tag.text)), 1 /* TEXT */)
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClose"])
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1024 /* DYNAMIC_SLOTS */))
                        }), 128 /* KEYED_FRAGMENT */))
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["modelValue", "data", "open-on-focus", "field", "placeholder"])
                  ]),
                  _: 1 /* STABLE */
                })
              ], 64 /* STABLE_FRAGMENT */))
            : _createCommentVNode("v-if", true)
        ], 2 /* CLASS */),
        ($options.filter.filterOptionEnabled)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode(_component_b_tooltip, {
                label: $options.filterOption === "any-of" ? _ctx.l("filtering/anyof-tooltip") : _ctx.l("filtering/allof-tooltip"),
                type: "is-white",
                "append-to-body": true,
                multilined: ""
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: "filter-options-trigger is-clickable",
                    onClick: _cache[20] || (_cache[20] = (...args) => ($options.toggleFilterOption && $options.toggleFilterOption(...args)))
                  }, [
                    _createVNode(_component_b_icon, {
                      icon: $options.filterOption === "any-of" ? "ab-testing" : "alphabet-latin",
                      "custom-size": "mdi-18px"
                    }, null, 8 /* PROPS */, ["icon"])
                  ])
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["label"])
            ]))
          : _createCommentVNode("v-if", true)
      ]))
    : _createCommentVNode("v-if", true)
}