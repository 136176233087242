//
//
// RUDOLF frontend
//
// Nurminen Development Oy Ltd - https://nurminen.dev
//
// For customer: The Rudolf Oy
//
// ALL RIGHTS RESERVED BY CUSTOMER
//
//

//
// File author(s):
//   - Riku Nurminen <riku@nurminen.dev>
//


import io                           from 'socket.io-client'

import * as appConfig               from './app.config.js'

import { useBulkAssignStore }       from './store/bulkassign.js'
import { useCallListStore }         from './store/calllist.js'
import { useContactsStore }         from './store/contacts.js'
import { useExcelImportStore }      from './store/excelimport.js'
import { useNotificationsStore }    from './store/notifications.js'
import { useLeadsStore }            from './store/leads.js'
import { useLocalizationsStore }    from './store/localizations.js'
import { useProductsStore }         from './store/products.js'
import { useRootStore }             from './store/root.js'
import { useSalesStore }            from './store/sales.js'
import { useSettingsStore }         from './store/settings.js'
import { useTodosStore }            from './store/todo.js'
import { useUsersStore }            from './store/users.js'


let socket

try {
    socket = io(appConfig.API_HOST, {
        transports: [ 'websocket' ],
        upgrade: false,
        withCredentials: true,
        autoConnect: false,
        reconnection: false // Manual reconnects, see below
    })
} catch(error) {
    console.error(`SocketIO init error: ${error.message}`)
}


socket.on('connect', () => {
    if(process.env.NODE_ENV !== 'production') console.log(`SocketIO: connected to ${appConfig.API_HOST}`)
})

// Force our own reconnects on any error/disconnect
// Auto-reconnect seems to behave weirdly when middleware returns an error from backend...

socket.on('disconnect', (reason) => {
    if(process.env.NODE_ENV !== 'production') console.log(`SocketIO disconnect: ${reason}`)
})

socket.on('connect_error', (error) => {
    if(process.env.NODE_ENV !== 'production') console.error(`SocketIO error: ${error.message}`)
})


export function connectSocket() {
    if(process.env.NODE_ENV !== 'production') console.log(`SocketIO connecting to ${appConfig.API_HOST}...`)
    socket.connect()
}

connectSocket()

setInterval(() => {
    if(socket.connected) return

    connectSocket()
}, 2000)


let socketHooksBound = false


export function bindSocketHooks() {
    if(socketHooksBound) return

    socketHooksBound = true

    const stores = {
        bulkassign:     useBulkAssignStore(),
        callList:       useCallListStore(),
        contacts:       useContactsStore(),
        leads:          useLeadsStore(),
        localizations:  useLocalizationsStore(),
        notifications:  useNotificationsStore(),
        products:       useProductsStore(),
        root:           useRootStore(),
        sales:          useSalesStore(),
        settings:       useSettingsStore(),
        todos:          useTodosStore(),
        users:          useUsersStore(),

        leadsexcelimport: useExcelImportStore()
    }

    socket.onAny(async (event, data) => {
        if(!event.startsWith('apiv2')) {
            console.log(`SocketIO event: ${event}`)
            //console.log(data)
            //console.log('-----------------------------------------')
            return
        }

        if(process.env.NODE_ENV !== 'production') {
            console.time(`SocketIO event: ${event}`)
        }

        const eventParsed = event.split(':')

        if(eventParsed.length !== 2) {
            console.error(`SocketIO: invalid event: ${event}`)
            console.timeEnd(`SocketIO event: ${event}`)
            return
        }

        const storeAndAction = eventParsed[1].split('/')

        if(storeAndAction.length !== 2) {
            console.error(`SocketIO: invalid event: ${event}`)
            console.timeEnd(`SocketIO event: ${event}`)
            return
        }

        const store = storeAndAction[0]
        const action = storeAndAction[1]

        // Call store action with data
        stores[store][action](data)

        if(process.env.NODE_ENV !== 'production') {
            console.timeEnd(`SocketIO event: ${event}`)
        }
    })
}


export default socket
