import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "color-picker" }
const _hoisted_2 = { class: "colors" }
const _hoisted_3 = ["onClick"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_dropdown_item = _resolveComponent("b-dropdown-item")
  const _component_b_dropdown = _resolveComponent("b-dropdown")

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_b_dropdown, {
      ref: "colorPickerDropdown",
      position: "is-bottom-left",
      "aria-role": "menu",
      "trap-focus": "",
      "mobile-modal": false,
      onActiveChange: _cache[0] || (_cache[0] = (active) => $options.colorPickerToggled(active))
    }, {
      trigger: _withCtx(() => [
        _createElementVNode("div", {
          class: "color-selection",
          style: _normalizeStyle(`background-color: ${this.color}`)
        }, null, 4 /* STYLE */)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_b_dropdown_item, {
          "aria-role": "menu-item",
          focusable: false,
          custom: "",
          paddingless: ""
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _cache[1] || (_cache[1] = _createElementVNode("p", {
                class: "rtitle sm",
                style: {"margin-bottom":"12px"}
              }, "Pick status color", -1 /* HOISTED */)),
              _createElementVNode("div", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.availableColors, (color) => {
                  return (_openBlock(), _createElementBlock("span", {
                    class: "color is-clickable",
                    style: _normalizeStyle(`background-color: ${color}`),
                    onClick: $event => ($options.updateColor(color))
                  }, " ", 12 /* STYLE, PROPS */, _hoisted_3))
                }), 256 /* UNKEYED_FRAGMENT */))
              ])
            ])
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }, 512 /* NEED_PATCH */)
  ]))
}