import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "column-sorting" }
const _hoisted_2 = { class: "sorting-trigger" }
const _hoisted_3 = { class: "ml-4" }
const _hoisted_4 = { class: "ml-4" }
const _hoisted_5 = { class: "ml-4" }
const _hoisted_6 = { class: "ml-4" }
const _hoisted_7 = { class: "ml-4" }
const _hoisted_8 = { class: "ml-4" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_icon = _resolveComponent("b-icon")
  const _component_b_dropdown_item = _resolveComponent("b-dropdown-item")
  const _component_b_dropdown = _resolveComponent("b-dropdown")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_b_dropdown, {
      modelValue: $options.currentSortValue,
      position: $props.column.field === "leadname" && !$props.isSidebar ? "is-bottom-right" : "is-bottom-left",
      onChange: _cache[0] || (_cache[0] = (value) => $options.setSortOrder(value))
    }, {
      trigger: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_b_icon, {
            icon: $options.columnSortIcon,
            type: $options.sortingColumnActive ? "is-dark" : "",
            "custom-class": "r-icon"
          }, null, 8 /* PROPS */, ["icon", "type"])
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_b_dropdown_item, {
          custom: "",
          "aria-role": "menu-item"
        }, {
          default: _withCtx(() => [
            _createElementVNode("strong", null, _toDisplayString(_ctx.l('manage-leads-table/sort-by')) + "...", 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        ([ "leadname", "product", "belongs_to" ].includes($props.column.field))
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createVNode(_component_b_dropdown_item, {
                "aria-role": "menu-item",
                value: "asc"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_3, _toDisplayString($props.column.label) + " A-Z", 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_b_dropdown_item, {
                "aria-role": "menu-item",
                value: "desc"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_4, _toDisplayString($props.column.label) + " Z-A", 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }),
              ($props.column.field === "leadname")
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createVNode(_component_b_dropdown_item, {
                      "aria-role": "menu-item",
                      value: "creation_timestamp_desc"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.l('manage-leads-table/sort-newest-first')), 1 /* TEXT */)
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_b_dropdown_item, {
                      "aria-role": "menu-item",
                      value: "creation_timestamp_asc"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.l('manage-leads-table/sort-oldest-first')), 1 /* TEXT */)
                      ]),
                      _: 1 /* STABLE */
                    })
                  ], 64 /* STABLE_FRAGMENT */))
                : _createCommentVNode("v-if", true)
            ], 64 /* STABLE_FRAGMENT */))
          : ([ "status", "next_appts", "due_dates" ].includes($props.column.field))
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createVNode(_component_b_dropdown_item, {
                  "aria-role": "menu-item",
                  value: "asc"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.l('manage-leads-table/sort-ascending')), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_b_dropdown_item, {
                  "aria-role": "menu-item",
                  value: "desc"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.l('manage-leads-table/sort-descending')), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                })
              ], 64 /* STABLE_FRAGMENT */))
            : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "position"])
  ]))
}