//
//
// RUDOLF frontend
//
// Nurminen Development Oy Ltd - https://nurminen.dev
//
// For customer: The Rudolf Oy
//
// ALL RIGHTS RESERVED BY CUSTOMER
//
//

//
// File author(s):
//   - Riku Nurminen <riku@nurminen.dev>
//


import { apiRequest }           from '../api_request.js'

import * as appHelpers          from '../helpers.js'


//
// Factory for building a basic CRUD pinia store
//


// These should always be included whenever using anything out of this factory
export function states() {
    return {
        updateToggler: false,
        reactivityEnabled: true,
    }
}


/********************************************************************
*********************************************************************

 ██████╗ ███████╗████████╗████████╗███████╗██████╗ ███████╗
██╔════╝ ██╔════╝╚══██╔══╝╚══██╔══╝██╔════╝██╔══██╗██╔════╝
██║  ███╗█████╗     ██║      ██║   █████╗  ██████╔╝███████╗
██║   ██║██╔══╝     ██║      ██║   ██╔══╝  ██╔══██╗╚════██║
╚██████╔╝███████╗   ██║      ██║   ███████╗██║  ██║███████║
 ╚═════╝ ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚═╝  ╚═╝╚══════╝

*********************************************************************
********************************************************************/

export function getters(itemsRef, itemsMap) {

    return {
        all() {
            this.updateToggler

            // Vue 3.4 reactivity system refactor: Need to return a new array now
            // instead of directly 'return itemsRef'
            return [ ...itemsRef ]
        },


        byId: (state) => (id) => {
            state.updateToggler

            const item = itemsMap.get(id)

            // Need to return via Object.assign since Vue 3.4 reactivity changes
            return item ? Object.assign({}, item) : {}
        },


        byIdMany: (state) => (ids) => {
            if(Array.isArray(ids)) {
                if(ids.length > 1) {
                    return state.all.filter(t => ids.includes(t._id))
                } else if(ids.length === 1) {
                    const item = state.byId(ids[0])
                    return item ? [ item ] : []
                }
            }

            const item = state.byId(ids)

            return item ? [ item ] : []
        }

    }
}



/********************************************************************
*********************************************************************

 █████╗  ██████╗████████╗██╗ ██████╗ ███╗   ██╗███████╗
██╔══██╗██╔════╝╚══██╔══╝██║██╔═══██╗████╗  ██║██╔════╝
███████║██║        ██║   ██║██║   ██║██╔██╗ ██║███████╗
██╔══██║██║        ██║   ██║██║   ██║██║╚██╗██║╚════██║
██║  ██║╚██████╗   ██║   ██║╚██████╔╝██║ ╚████║███████║
╚═╝  ╚═╝ ╚═════╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝

*********************************************************************
********************************************************************/

export function actions(resourceType, apiOverride = {}, itemsRef, itemsMap) {
    let api = {
        'index':      [ 'GET',      `/${resourceType}` ],
        'create':     [ 'POST',     `/${resourceType}` ],
        'update':     [ 'PATCH',    `/${resourceType}` ],
        'destroy':    [ 'DELETE',   `/${resourceType}` ],
    }

    api = Object.assign(api, apiOverride)

    const actions = {
        async fetch() {
            const items = await apiRequest(...api.index)

            this.SET(items)
        },

        async fetchById(id) {
            const path = api.index[1] + `/${id}`

            const item = await apiRequest(api.index[0], path)
    
            this.PUT(item)
        },

        async create(payload) {
            const newItem = await apiRequest(...api.create, payload)
    
            this.ADD(newItem)

            return newItem
        },

        async patch({ id, payload }) {
            const path = api.update[1] + `/${id}`
    
            const updatedItem = await apiRequest(api.update[0], path, payload)
    
            this.UPDATE(updatedItem)

            return updatedItem
        },

        async destroy(id, skipStoreUpdate = false) {
            const path = api.destroy[1] + `/${id}`
    
            await apiRequest(api.destroy[0], path)

            if(!skipStoreUpdate) this.DESTROY_BY({ field: '_id', values: id })
        },



        //
        //
        // "Mutations", separated from the above calls so we can use these
        // directly via socket.io
        //
        //
        SET(items) {
            itemsRef.length = 0

            const itemsLength = items.length

            itemsMap.clear()

            for(let i = 0; i < itemsLength; i++) {
                itemsRef.push(items[i])

                // Update map
                itemsMap.set(items[i]._id, items[i])
            }

            if(this.reactivityEnabled) this.updateToggler++

        },
    
    
        ADD(items) {
            if(!Array.isArray(items)) items = [ items ]

            const itemsLength = items.length

            for(let i = 0; i < itemsLength; i++) {
                itemsRef.push(items[i])

                // Update map
                itemsMap.set(items[i]._id, items[i])
            }

            if(this.reactivityEnabled) this.updateToggler++

        },
    
    
        UPDATE(updatedItems) {
            // Optimized "update" for big arrays - only change if you know what you're doing!

            if(!Array.isArray(updatedItems)) updatedItems = [ updatedItems ]

            for(const updatedItem of updatedItems) {
                let itemToUpdate = itemsMap.get(updatedItem._id)

                if(!itemToUpdate) continue

                Object.assign(itemToUpdate, updatedItem)
            }

            if(this.reactivityEnabled) this.updateToggler++
        },


        PUT(newItems) {
            // Optimized "upsert" for big arrays - only change if you know what you're doing!

            if(!Array.isArray(newItems)) newItems = [ newItems ]

            const newItemsMap = appHelpers.toMap(newItems, '_id')
            const oldItems = []

            for(const existingItem of itemsRef) {
                if(!newItemsMap.get(existingItem._id)) oldItems.push(existingItem)
            }

            itemsRef.length = 0

            itemsMap.clear()

            const mergedItems = newItems.concat(oldItems)

            const itemsLength = mergedItems.length
            for(let i = 0; i < itemsLength; i++) {
                itemsRef.push(mergedItems[i])

                // Update map
                itemsMap.set(mergedItems[i]._id, mergedItems[i])
            }

            if(this.reactivityEnabled) this.updateToggler++
        },


        //
        // Destroy an item by a prop, e.g.:
        //
        // DESTROY_BY({ field: '_id', values: 'id-to-destroy' })
        //
        // Also accepts an array for 'values'
        //
        DESTROY_BY({ field, values }) {
            // Optimized "delete" for big arrays - only change if you know what you're doing!

            if(!Array.isArray(values)) values = [ values ]

            const filtered = itemsRef.filter(i => {
                return values.includes(i[field]) ? false : true
            })

            itemsRef.length = 0

            const itemsLength = filtered.length

            itemsMap.clear()

            for(let i = 0; i < itemsLength; i++) {
                itemsRef.push(filtered[i])

                // Update map
                itemsMap.set(filtered[i]._id, filtered[i])
            }

            if(this.reactivityEnabled) this.updateToggler++
        },


        TOGGLE_REACTIVITY(toggle) {
            this.reactivityEnabled = toggle

            if(toggle === true) this.updateToggler++
        },

    }

    return actions
}
