import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-content-inner rudolfbox" }
const _hoisted_2 = { class: "labels-page" }
const _hoisted_3 = { class: "columns mt-2" }
const _hoisted_4 = { class: "column is-4" }
const _hoisted_5 = { class: "column is-4" }
const _hoisted_6 = { class: "column is-4" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_input = _resolveComponent("b-input")
  const _component_b_field = _resolveComponent("b-field")
  const _component_b_button = _resolveComponent("b-button")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_b_field, {
            label: _ctx.l("setup-labels/leadname"),
            "label-position": "on-border",
            style: {"margin-bottom":"32px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_b_input, {
                modelValue: $data.editedLabelName,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.editedLabelName) = $event)),
                placeholder: _ctx.l("setup-labels/leadname"),
                expanded: "",
                loading: $data.loading,
                disabled: $data.loading,
                onFocus: _cache[1] || (_cache[1] = $event => ($options.labelInputFocused()))
              }, null, 8 /* PROPS */, ["modelValue", "placeholder", "loading", "disabled"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode(_component_b_field, {
            label: _ctx.l("setup-labels/assignment"),
            "label-position": "on-border",
            style: {"margin-bottom":"32px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_b_input, {
                modelValue: $data.editedLabelAssignment,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($data.editedLabelAssignment) = $event)),
                placeholder: _ctx.l("setup-labels/assignment"),
                expanded: "",
                loading: $data.loading,
                disabled: $data.loading,
                onFocus: _cache[3] || (_cache[3] = $event => ($options.labelInputFocused()))
              }, null, 8 /* PROPS */, ["modelValue", "placeholder", "loading", "disabled"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode(_component_b_field, {
            label: _ctx.l("setup-labels/address"),
            "label-position": "on-border",
            style: {"margin-bottom":"32px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_b_input, {
                modelValue: $data.editedLabelAddress,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($data.editedLabelAddress) = $event)),
                placeholder: _ctx.l("setup-labels/address"),
                expanded: "",
                loading: $data.loading,
                disabled: $data.loading,
                onFocus: _cache[5] || (_cache[5] = $event => ($options.labelInputFocused()))
              }, null, 8 /* PROPS */, ["modelValue", "placeholder", "loading", "disabled"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode(_component_b_field, {
            label: _ctx.l("setup-labels/country"),
            "label-position": "on-border",
            style: {"margin-bottom":"32px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_b_input, {
                modelValue: $data.editedLabelCountry,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => (($data.editedLabelCountry) = $event)),
                placeholder: _ctx.l("setup-labels/country"),
                expanded: "",
                loading: $data.loading,
                disabled: $data.loading,
                onFocus: _cache[7] || (_cache[7] = $event => ($options.labelInputFocused()))
              }, null, 8 /* PROPS */, ["modelValue", "placeholder", "loading", "disabled"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode(_component_b_field, {
            label: _ctx.l("setup-labels/website"),
            "label-position": "on-border",
            style: {"margin-bottom":"32px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_b_input, {
                modelValue: $data.editedLabelWebsite,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => (($data.editedLabelWebsite) = $event)),
                placeholder: _ctx.l("setup-labels/website"),
                expanded: "",
                loading: $data.loading,
                disabled: $data.loading,
                onFocus: _cache[9] || (_cache[9] = $event => ($options.labelInputFocused()))
              }, null, 8 /* PROPS */, ["modelValue", "placeholder", "loading", "disabled"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode(_component_b_field, {
            label: _ctx.l("setup-labels/mobilenumber"),
            "label-position": "on-border",
            style: {"margin-bottom":"32px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_b_input, {
                modelValue: $data.editedLabelMobileNumber,
                "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => (($data.editedLabelMobileNumber) = $event)),
                placeholder: _ctx.l("setup-labels/mobilenumber"),
                expanded: "",
                loading: $data.loading,
                disabled: $data.loading,
                onFocus: _cache[11] || (_cache[11] = $event => ($options.labelInputFocused()))
              }, null, 8 /* PROPS */, ["modelValue", "placeholder", "loading", "disabled"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode(_component_b_field, {
            label: _ctx.l("setup-labels/nextappointment"),
            "label-position": "on-border",
            style: {"margin-bottom":"32px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_b_input, {
                modelValue: $data.editedLabelNextAppointment,
                "onUpdate:modelValue": _cache[12] || (_cache[12] = $event => (($data.editedLabelNextAppointment) = $event)),
                placeholder: _ctx.l("setup-labels/nextappointment"),
                expanded: "",
                loading: $data.loading,
                disabled: $data.loading,
                onFocus: _cache[13] || (_cache[13] = $event => ($options.labelInputFocused()))
              }, null, 8 /* PROPS */, ["modelValue", "placeholder", "loading", "disabled"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode(_component_b_field, {
            label: _ctx.l("setup-labels/zone1"),
            "label-position": "on-border",
            style: {"margin-bottom":"32px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_b_input, {
                modelValue: $data.editedLabelZone1,
                "onUpdate:modelValue": _cache[14] || (_cache[14] = $event => (($data.editedLabelZone1) = $event)),
                placeholder: _ctx.l("setup-labels/zone1"),
                expanded: "",
                loading: $data.loading,
                disabled: $data.loading,
                onFocus: _cache[15] || (_cache[15] = $event => ($options.labelInputFocused()))
              }, null, 8 /* PROPS */, ["modelValue", "placeholder", "loading", "disabled"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode(_component_b_field, {
            label: _ctx.l("setup-labels/zone4"),
            "label-position": "on-border",
            style: {"margin-bottom":"32px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_b_input, {
                modelValue: $data.editedLabelZone4,
                "onUpdate:modelValue": _cache[16] || (_cache[16] = $event => (($data.editedLabelZone4) = $event)),
                placeholder: _ctx.l("setup-labels/zone4"),
                expanded: "",
                loading: $data.loading,
                disabled: $data.loading,
                onFocus: _cache[17] || (_cache[17] = $event => ($options.labelInputFocused()))
              }, null, 8 /* PROPS */, ["modelValue", "placeholder", "loading", "disabled"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode(_component_b_field, {
            label: _ctx.l("setup-labels/comments"),
            "label-position": "on-border",
            style: {"margin-bottom":"32px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_b_input, {
                modelValue: $data.editedLabelComments,
                "onUpdate:modelValue": _cache[18] || (_cache[18] = $event => (($data.editedLabelComments) = $event)),
                placeholder: _ctx.l("setup-labels/comments"),
                expanded: "",
                loading: $data.loading,
                disabled: $data.loading,
                onFocus: _cache[19] || (_cache[19] = $event => ($options.labelInputFocused()))
              }, null, 8 /* PROPS */, ["modelValue", "placeholder", "loading", "disabled"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode(_component_b_field, {
            label: _ctx.l("setup-labels/last_comment"),
            "label-position": "on-border",
            style: {"margin-bottom":"32px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_b_input, {
                modelValue: $data.editedLabelLastComment,
                "onUpdate:modelValue": _cache[20] || (_cache[20] = $event => (($data.editedLabelLastComment) = $event)),
                placeholder: _ctx.l("setup-labels/last_comment"),
                expanded: "",
                loading: $data.loading,
                disabled: $data.loading,
                onFocus: _cache[21] || (_cache[21] = $event => ($options.labelInputFocused()))
              }, null, 8 /* PROPS */, ["modelValue", "placeholder", "loading", "disabled"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_b_field, {
            label: _ctx.l("setup-labels/product"),
            "label-position": "on-border",
            style: {"margin-bottom":"32px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_b_input, {
                modelValue: $data.editedLabelProduct,
                "onUpdate:modelValue": _cache[22] || (_cache[22] = $event => (($data.editedLabelProduct) = $event)),
                placeholder: _ctx.l("setup-labels/product"),
                expanded: "",
                loading: $data.loading,
                disabled: $data.loading,
                onFocus: _cache[23] || (_cache[23] = $event => ($options.labelInputFocused()))
              }, null, 8 /* PROPS */, ["modelValue", "placeholder", "loading", "disabled"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode(_component_b_field, {
            label: _ctx.l("setup-labels/status"),
            "label-position": "on-border",
            style: {"margin-bottom":"32px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_b_input, {
                modelValue: $data.editedLabelStatus,
                "onUpdate:modelValue": _cache[24] || (_cache[24] = $event => (($data.editedLabelStatus) = $event)),
                placeholder: _ctx.l("setup-labels/status"),
                expanded: "",
                loading: $data.loading,
                disabled: $data.loading,
                onFocus: _cache[25] || (_cache[25] = $event => ($options.labelInputFocused()))
              }, null, 8 /* PROPS */, ["modelValue", "placeholder", "loading", "disabled"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode(_component_b_field, {
            label: _ctx.l("setup-labels/city"),
            "label-position": "on-border",
            style: {"margin-bottom":"32px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_b_input, {
                modelValue: $data.editedLabelCity,
                "onUpdate:modelValue": _cache[26] || (_cache[26] = $event => (($data.editedLabelCity) = $event)),
                placeholder: _ctx.l("setup-labels/city"),
                expanded: "",
                loading: $data.loading,
                disabled: $data.loading,
                onFocus: _cache[27] || (_cache[27] = $event => ($options.labelInputFocused()))
              }, null, 8 /* PROPS */, ["modelValue", "placeholder", "loading", "disabled"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode(_component_b_field, {
            label: _ctx.l("setup-labels/region"),
            "label-position": "on-border",
            style: {"margin-bottom":"32px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_b_input, {
                modelValue: $data.editedLabelRegion,
                "onUpdate:modelValue": _cache[28] || (_cache[28] = $event => (($data.editedLabelRegion) = $event)),
                placeholder: _ctx.l("setup-labels/region"),
                expanded: "",
                loading: $data.loading,
                disabled: $data.loading,
                onFocus: _cache[29] || (_cache[29] = $event => ($options.labelInputFocused()))
              }, null, 8 /* PROPS */, ["modelValue", "placeholder", "loading", "disabled"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode(_component_b_field, {
            label: _ctx.l("setup-labels/email"),
            "label-position": "on-border",
            style: {"margin-bottom":"32px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_b_input, {
                modelValue: $data.editedLabelEmail,
                "onUpdate:modelValue": _cache[30] || (_cache[30] = $event => (($data.editedLabelEmail) = $event)),
                placeholder: _ctx.l("setup-labels/email"),
                expanded: "",
                loading: $data.loading,
                disabled: $data.loading,
                onFocus: _cache[31] || (_cache[31] = $event => ($options.labelInputFocused()))
              }, null, 8 /* PROPS */, ["modelValue", "placeholder", "loading", "disabled"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode(_component_b_field, {
            label: _ctx.l("setup-labels/customertype"),
            "label-position": "on-border",
            style: {"margin-bottom":"32px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_b_input, {
                modelValue: $data.editedLabelCustomerType,
                "onUpdate:modelValue": _cache[32] || (_cache[32] = $event => (($data.editedLabelCustomerType) = $event)),
                placeholder: _ctx.l("setup-labels/customertype"),
                expanded: "",
                loading: $data.loading,
                disabled: $data.loading,
                onFocus: _cache[33] || (_cache[33] = $event => ($options.labelInputFocused()))
              }, null, 8 /* PROPS */, ["modelValue", "placeholder", "loading", "disabled"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode(_component_b_field, {
            label: _ctx.l("setup-labels/deadline"),
            "label-position": "on-border",
            style: {"margin-bottom":"32px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_b_input, {
                modelValue: $data.editedLabelDeadline,
                "onUpdate:modelValue": _cache[34] || (_cache[34] = $event => (($data.editedLabelDeadline) = $event)),
                placeholder: _ctx.l("setup-labels/deadline"),
                expanded: "",
                loading: $data.loading,
                disabled: $data.loading,
                onFocus: _cache[35] || (_cache[35] = $event => ($options.labelInputFocused()))
              }, null, 8 /* PROPS */, ["modelValue", "placeholder", "loading", "disabled"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode(_component_b_field, {
            label: _ctx.l("setup-labels/zone2"),
            "label-position": "on-border",
            style: {"margin-bottom":"32px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_b_input, {
                modelValue: $data.editedLabelZone2,
                "onUpdate:modelValue": _cache[36] || (_cache[36] = $event => (($data.editedLabelZone2) = $event)),
                placeholder: _ctx.l("setup-labels/zone2"),
                expanded: "",
                loading: $data.loading,
                disabled: $data.loading,
                onFocus: _cache[37] || (_cache[37] = $event => ($options.labelInputFocused()))
              }, null, 8 /* PROPS */, ["modelValue", "placeholder", "loading", "disabled"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode(_component_b_field, {
            label: _ctx.l("setup-labels/latitude"),
            "label-position": "on-border",
            style: {"margin-bottom":"32px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_b_input, {
                modelValue: $data.editedLabelLatitude,
                "onUpdate:modelValue": _cache[38] || (_cache[38] = $event => (($data.editedLabelLatitude) = $event)),
                placeholder: _ctx.l("setup-labels/latitude"),
                expanded: "",
                loading: $data.loading,
                disabled: $data.loading,
                onFocus: _cache[39] || (_cache[39] = $event => ($options.labelInputFocused()))
              }, null, 8 /* PROPS */, ["modelValue", "placeholder", "loading", "disabled"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode(_component_b_field, {
            label: _ctx.l("setup-labels/identifier"),
            "label-position": "on-border",
            style: {"margin-bottom":"32px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_b_input, {
                modelValue: $data.editedLabelIdentifier,
                "onUpdate:modelValue": _cache[40] || (_cache[40] = $event => (($data.editedLabelIdentifier) = $event)),
                placeholder: _ctx.l("setup-labels/identifier"),
                expanded: "",
                loading: $data.loading,
                disabled: $data.loading,
                onFocus: _cache[41] || (_cache[41] = $event => ($options.labelInputFocused()))
              }, null, 8 /* PROPS */, ["modelValue", "placeholder", "loading", "disabled"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode(_component_b_field, {
            label: _ctx.l("setup-labels/contacts"),
            "label-position": "on-border"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_b_input, {
                modelValue: $data.editedLabelContacts,
                "onUpdate:modelValue": _cache[42] || (_cache[42] = $event => (($data.editedLabelContacts) = $event)),
                placeholder: _ctx.l("setup-labels/contacts"),
                expanded: "",
                loading: $data.loading,
                disabled: $data.loading,
                onFocus: _cache[43] || (_cache[43] = $event => ($options.labelInputFocused()))
              }, null, 8 /* PROPS */, ["modelValue", "placeholder", "loading", "disabled"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_b_field, {
            label: _ctx.l("setup-labels/project"),
            "label-position": "on-border",
            style: {"margin-bottom":"32px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_b_input, {
                modelValue: $data.editedLabelProject,
                "onUpdate:modelValue": _cache[44] || (_cache[44] = $event => (($data.editedLabelProject) = $event)),
                placeholder: _ctx.l("setup-labels/project"),
                expanded: "",
                loading: $data.loading,
                disabled: $data.loading,
                onFocus: _cache[45] || (_cache[45] = $event => ($options.labelInputFocused()))
              }, null, 8 /* PROPS */, ["modelValue", "placeholder", "loading", "disabled"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode(_component_b_field, {
            label: _ctx.l("setup-labels/full_address"),
            "label-position": "on-border",
            style: {"margin-bottom":"32px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_b_input, {
                modelValue: $data.editedLabelFullAddress,
                "onUpdate:modelValue": _cache[46] || (_cache[46] = $event => (($data.editedLabelFullAddress) = $event)),
                placeholder: _ctx.l("setup-labels/full_address"),
                expanded: "",
                loading: $data.loading,
                disabled: $data.loading,
                onFocus: _cache[47] || (_cache[47] = $event => ($options.labelInputFocused()))
              }, null, 8 /* PROPS */, ["modelValue", "placeholder", "loading", "disabled"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode(_component_b_field, {
            label: _ctx.l("setup-labels/state"),
            "label-position": "on-border",
            style: {"margin-bottom":"32px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_b_input, {
                modelValue: $data.editedLabelState,
                "onUpdate:modelValue": _cache[48] || (_cache[48] = $event => (($data.editedLabelState) = $event)),
                placeholder: _ctx.l("setup-labels/state"),
                expanded: "",
                loading: $data.loading,
                disabled: $data.loading,
                onFocus: _cache[49] || (_cache[49] = $event => ($options.labelInputFocused()))
              }, null, 8 /* PROPS */, ["modelValue", "placeholder", "loading", "disabled"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode(_component_b_field, {
            label: _ctx.l("setup-labels/addressmisc"),
            "label-position": "on-border",
            style: {"margin-bottom":"32px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_b_input, {
                modelValue: $data.editedLabelAddressMisc,
                "onUpdate:modelValue": _cache[50] || (_cache[50] = $event => (($data.editedLabelAddressMisc) = $event)),
                placeholder: _ctx.l("setup-labels/addressmisc"),
                expanded: "",
                loading: $data.loading,
                disabled: $data.loading,
                onFocus: _cache[51] || (_cache[51] = $event => ($options.labelInputFocused()))
              }, null, 8 /* PROPS */, ["modelValue", "placeholder", "loading", "disabled"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode(_component_b_field, {
            label: _ctx.l("setup-labels/tags"),
            "label-position": "on-border",
            style: {"margin-bottom":"32px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_b_input, {
                modelValue: $data.editedLabelTags,
                "onUpdate:modelValue": _cache[52] || (_cache[52] = $event => (($data.editedLabelTags) = $event)),
                placeholder: _ctx.l("setup-labels/tags"),
                expanded: "",
                loading: $data.loading,
                disabled: $data.loading,
                onFocus: _cache[53] || (_cache[53] = $event => ($options.labelInputFocused()))
              }, null, 8 /* PROPS */, ["modelValue", "placeholder", "loading", "disabled"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode(_component_b_field, {
            label: _ctx.l("setup-labels/contactnumber"),
            "label-position": "on-border",
            style: {"margin-bottom":"32px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_b_input, {
                modelValue: $data.editedLabelContactNumber,
                "onUpdate:modelValue": _cache[54] || (_cache[54] = $event => (($data.editedLabelContactNumber) = $event)),
                placeholder: _ctx.l("setup-labels/contactnumber"),
                expanded: "",
                loading: $data.loading,
                disabled: $data.loading,
                onFocus: _cache[55] || (_cache[55] = $event => ($options.labelInputFocused()))
              }, null, 8 /* PROPS */, ["modelValue", "placeholder", "loading", "disabled"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode(_component_b_field, {
            label: _ctx.l("setup-labels/projecttype"),
            "label-position": "on-border",
            style: {"margin-bottom":"32px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_b_input, {
                modelValue: $data.editedLabelProjectType,
                "onUpdate:modelValue": _cache[56] || (_cache[56] = $event => (($data.editedLabelProjectType) = $event)),
                placeholder: _ctx.l("setup-labels/projecttype"),
                expanded: "",
                loading: $data.loading,
                disabled: $data.loading,
                onFocus: _cache[57] || (_cache[57] = $event => ($options.labelInputFocused()))
              }, null, 8 /* PROPS */, ["modelValue", "placeholder", "loading", "disabled"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode(_component_b_field, {
            label: _ctx.l("setup-labels/zone3"),
            "label-position": "on-border",
            style: {"margin-bottom":"32px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_b_input, {
                modelValue: $data.editedLabelZone3,
                "onUpdate:modelValue": _cache[58] || (_cache[58] = $event => (($data.editedLabelZone3) = $event)),
                placeholder: _ctx.l("setup-labels/zone3"),
                expanded: "",
                loading: $data.loading,
                disabled: $data.loading,
                onFocus: _cache[59] || (_cache[59] = $event => ($options.labelInputFocused()))
              }, null, 8 /* PROPS */, ["modelValue", "placeholder", "loading", "disabled"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode(_component_b_field, {
            label: _ctx.l("setup-labels/longitude"),
            "label-position": "on-border",
            style: {"margin-bottom":"32px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_b_input, {
                modelValue: $data.editedLabelLongitude,
                "onUpdate:modelValue": _cache[60] || (_cache[60] = $event => (($data.editedLabelLongitude) = $event)),
                placeholder: _ctx.l("setup-labels/longitude"),
                expanded: "",
                loading: $data.loading,
                disabled: $data.loading,
                onFocus: _cache[61] || (_cache[61] = $event => ($options.labelInputFocused()))
              }, null, 8 /* PROPS */, ["modelValue", "placeholder", "loading", "disabled"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode(_component_b_field, {
            label: _ctx.l("setup-labels/contact"),
            "label-position": "on-border",
            style: {"margin-bottom":"32px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_b_input, {
                modelValue: $data.editedLabelContact,
                "onUpdate:modelValue": _cache[62] || (_cache[62] = $event => (($data.editedLabelContact) = $event)),
                placeholder: _ctx.l("setup-labels/contact"),
                expanded: "",
                loading: $data.loading,
                disabled: $data.loading,
                onFocus: _cache[63] || (_cache[63] = $event => ($options.labelInputFocused()))
              }, null, 8 /* PROPS */, ["modelValue", "placeholder", "loading", "disabled"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"])
        ])
      ]),
      _createVNode(_component_b_button, {
        type: "is-primary",
        rounded: "",
        onClick: _cache[64] || (_cache[64] = $event => ($options.saveLabels())),
        loading: $data.loading
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.l('setup-labels/save-labels')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["loading"])
    ])
  ]))
}