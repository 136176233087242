import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, withKeys as _withKeys, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "page-content-inner rudolfbox is-relative" }
const _hoisted_2 = {
  class: "columns",
  style: {"height":"calc(100%)"}
}
const _hoisted_3 = {
  class: "column is-3",
  style: {"border-right":"1px solid #F6F6F6"}
}
const _hoisted_4 = { class: "leftcolumn" }
const _hoisted_5 = { class: "rtitle product-list has-text-centered" }
const _hoisted_6 = { class: "control" }
const _hoisted_7 = {
  key: 0,
  class: "sorting-note"
}
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "product-name" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = ["onClick"]
const _hoisted_12 = { class: "product-name" }
const _hoisted_13 = { class: "save-product-name" }
const _hoisted_14 = { class: "control" }
const _hoisted_15 = { class: "cancel-save-product-name" }
const _hoisted_16 = { class: "control" }
const _hoisted_17 = { key: 1 }
const _hoisted_18 = { class: "search-result pt-4 pb-4" }
const _hoisted_19 = { class: "column is-9" }
const _hoisted_20 = {
  class: "rightcolumn",
  id: "setup-right-column"
}
const _hoisted_21 = { class: "rtitle selected-product has-text-centered" }
const _hoisted_22 = { class: "rudolfbox" }
const _hoisted_23 = { class: "modal-inner has-text-centered" }
const _hoisted_24 = ["innerHTML"]
const _hoisted_25 = { class: "buttons mb-0" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_input = _resolveComponent("b-input")
  const _component_b_button = _resolveComponent("b-button")
  const _component_b_tooltip = _resolveComponent("b-tooltip")
  const _component_b_field = _resolveComponent("b-field")
  const _component_b_icon = _resolveComponent("b-icon")
  const _component_b_loading = _resolveComponent("b-loading")
  const _component_product_statuses = _resolveComponent("product-statuses")
  const _component_product_questions = _resolveComponent("product-questions")
  const _component_product_kpi = _resolveComponent("product-kpi")
  const _component_b_modal = _resolveComponent("b-modal")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h4", _hoisted_5, _toDisplayString(_ctx.l('setup-products/product-list')), 1 /* TEXT */),
          _createVNode(_component_b_field, {
            class: "searchfield",
            grouped: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_b_input, {
                expanded: "",
                modelValue: $data.productSearchStr,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.productSearchStr) = $event)),
                icon: "magnify",
                "custom-class": "square product-search-input",
                placeholder: "Search...",
                "icon-right": $data.productSearchStr ? "close-circle" : "",
                "icon-right-clickable": $data.productSearchStr ? true : false,
                onIconRightClick: _cache[1] || (_cache[1] = $event => ($data.productSearchStr = ""))
              }, null, 8 /* PROPS */, ["modelValue", "icon-right", "icon-right-clickable"]),
              _createElementVNode("p", _hoisted_6, [
                _createVNode(_component_b_tooltip, {
                  label: "Add new",
                  type: "is-white"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_button, {
                      class: "icon-button",
                      type: "is-light",
                      size: "is-large",
                      onClick: _cache[2] || (_cache[2] = $event => ($options.createProduct())),
                      disabled: $data.loading
                    }, {
                      default: _withCtx(() => _cache[11] || (_cache[11] = [
                        _createTextVNode("+")
                      ])),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["disabled"])
                  ]),
                  _: 1 /* STABLE */
                })
              ])
            ]),
            _: 1 /* STABLE */
          }),
          ($data.productSearchStr.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("span", null, _toDisplayString(_ctx.l('setup-products/sorting-note')), 1 /* TEXT */)
              ]))
            : _createCommentVNode("v-if", true),
          _cache[12] || (_cache[12] = _createElementVNode("div", { class: "separator" }, null, -1 /* HOISTED */)),
          _createElementVNode("div", {
            class: "product-list",
            id: "products-sort-drag-handle",
            style: _normalizeStyle({ "opacity": $data.productListDragging ? "0.7" : "1" })
          }, [
            ($options.productsFiltered.length > 0)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList($options.productsFiltered, (product) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: _normalizeClass(["product", {              "active": $options.selectedProduct._id === product._id,              "disable-dragging": $data.editProductNameId === product._id || $data.productSearchStr !== "",            }]),
                    key: product._id,
                    onClick: $event => ($options.setSelectedProduct(product)),
                    style: _normalizeStyle({ "backgroundColor": $data.productListDragging ? "#fff" : "" })
                  }, [
                    ($data.editProductNameId !== product._id)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createElementVNode("div", _hoisted_9, _toDisplayString(product.name), 1 /* TEXT */),
                          _createElementVNode("div", {
                            class: "edit-product",
                            onClick: $event => ($options.editProductName(product))
                          }, [
                            _createVNode(_component_b_icon, {
                              icon: "pencil-outline",
                              "custom-size": "mdi-18px"
                            })
                          ], 8 /* PROPS */, _hoisted_10),
                          _createElementVNode("div", {
                            class: "delete-product",
                            onClick: _withModifiers($event => ($options.deleteProductConfirmModal(product)), ["stop"])
                          }, [
                            _createVNode(_component_b_icon, {
                              icon: "delete-outline",
                              "custom-size": "mdi-18px"
                            })
                          ], 8 /* PROPS */, _hoisted_11)
                        ], 64 /* STABLE_FRAGMENT */))
                      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _createElementVNode("div", _hoisted_12, [
                            _createVNode(_component_b_field, { grouped: "" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_b_input, {
                                  expanded: "",
                                  modelValue: $data.editedProductName,
                                  "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($data.editedProductName) = $event)),
                                  size: "is-small",
                                  "custom-class": "square product-name-input",
                                  placeholder: "Product name",
                                  onKeyup: _withKeys($options.saveEditProductName, ["native","enter"])
                                }, null, 8 /* PROPS */, ["modelValue", "onKeyup"])
                              ]),
                              _: 1 /* STABLE */
                            })
                          ]),
                          _createElementVNode("div", _hoisted_13, [
                            _createElementVNode("p", _hoisted_14, [
                              _createVNode(_component_b_button, {
                                type: "is-primary",
                                size: "is-small",
                                onClick: _cache[4] || (_cache[4] = _withModifiers($event => ($options.saveEditProductName()), ["stop"])),
                                disabled: $data.loading
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.l('generic/save')), 1 /* TEXT */)
                                ]),
                                _: 1 /* STABLE */
                              }, 8 /* PROPS */, ["disabled"])
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_15, [
                            _createElementVNode("p", _hoisted_16, [
                              _createVNode(_component_b_button, {
                                type: "is-light",
                                size: "is-small",
                                onClick: _cache[5] || (_cache[5] = _withModifiers($event => ($options.closeEditProductName()), ["stop"])),
                                disabled: $data.loading
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.l('generic/cancel')), 1 /* TEXT */)
                                ]),
                                _: 1 /* STABLE */
                              }, 8 /* PROPS */, ["disabled"])
                            ])
                          ])
                        ], 64 /* STABLE_FRAGMENT */))
                  ], 14 /* CLASS, STYLE, PROPS */, _hoisted_8))
                }), 128 /* KEYED_FRAGMENT */))
              : (_openBlock(), _createElementBlock("div", _hoisted_17, [
                  _createElementVNode("p", _hoisted_18, _toDisplayString(_ctx.l('setup-products/no-products')), 1 /* TEXT */)
                ])),
            _createVNode(_component_b_loading, {
              "is-full-page": false,
              modelValue: $data.loading,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => (($data.loading) = $event))
            }, null, 8 /* PROPS */, ["modelValue"])
          ], 4 /* STYLE */)
        ])
      ]),
      _createElementVNode("div", _hoisted_19, [
        _createElementVNode("div", _hoisted_20, [
          ($options.selectedProduct.name)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createElementVNode("h4", _hoisted_21, _toDisplayString(_ctx.l('setup-products/setup-product-title', $options.selectedProduct.name)), 1 /* TEXT */),
                _createVNode(_component_product_statuses, {
                  "product-id": $options.selectedProduct._id
                }, null, 8 /* PROPS */, ["product-id"]),
                _createVNode(_component_product_questions, {
                  "product-id": $options.selectedProduct._id
                }, null, 8 /* PROPS */, ["product-id"]),
                _createVNode(_component_product_kpi, { product: $options.selectedProduct }, null, 8 /* PROPS */, ["product"])
              ], 64 /* STABLE_FRAGMENT */))
            : _createCommentVNode("v-if", true)
        ])
      ])
    ]),
    _createVNode(_component_b_loading, {
      "is-full-page": false,
      modelValue: _ctx.appLoading,
      "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => ((_ctx.appLoading) = $event))
    }, null, 8 /* PROPS */, ["modelValue"]),
    _createVNode(_component_b_modal, {
      class: "fit-content-height",
      modelValue: $data.deleteProductConfirmModalActive,
      "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => (($data.deleteProductConfirmModalActive) = $event)),
      width: 640,
      scroll: "keep",
      "can-cancel": [ 'escape', 'outside' ],
      "on-cancel": $options.closeDeleteProduct
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_22, [
          _createElementVNode("div", _hoisted_23, [
            _createVNode(_component_b_icon, {
              icon: "alert-circle-outline",
              "custom-class": "modal-alert"
            }),
            _createElementVNode("p", {
              class: "modal-text",
              innerHTML: _ctx.l_escaped("setup-products/confirm-delete-product-text", $data.productToBeDeleted.name)
            }, null, 8 /* PROPS */, _hoisted_24),
            _createElementVNode("div", _hoisted_25, [
              _createVNode(_component_b_button, {
                type: "is-light",
                rounded: "",
                onClick: _cache[8] || (_cache[8] = $event => ($options.closeDeleteProduct()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.l('generic/cancel')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_b_button, {
                type: "is-primary",
                rounded: "",
                onClick: _cache[9] || (_cache[9] = $event => ($options.deleteProduct()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.l('generic/delete')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              })
            ])
          ])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "on-cancel"])
  ]))
}