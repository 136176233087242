//
//
// RUDOLF frontend
//
// Nurminen Development Oy Ltd - https://nurminen.dev
//
// For customer: The Rudolf Oy
//
// ALL RIGHTS RESERVED BY CUSTOMER
//
//

//
// File author(s):
//   - Riku Nurminen <riku@nurminen.dev>
//


import { defineStore }              from 'pinia'

import { apiRequest }               from '../api_request.js'

import * as crudFactory             from './_crud.js'
import { useProductsStore }         from './products.js'


const api = {
    'index':      [ 'GET',    '/statuses' ],
    'create':     [ 'POST',   '/statuses'],
    'bulkSave':   [ 'PUT',    '/statuses/bulksave' ],
    'destroy':    [ 'DELETE', '/statuses' ]
}


const statuses      = []
const statusesMap   = new Map()


export const useStatusesStore = defineStore('statuses', {
    state: () => {
        return {
            ...crudFactory.states(),
        }
    },


    getters: {
        ...crudFactory.getters(statuses, statusesMap),


        byProductId: (state) => (productId) => {
            return state.all.filter(s => s.product === productId)
        },

    },


    actions: {
        ...crudFactory.actions('statuses', {}, statuses, statusesMap),


        async fetchByProductId(productId) {
            const path = api.index[1] + `?productId=${productId}`

            const statusesByProductId = await apiRequest(api.index[0], path)

            this.PUT(statusesByProductId)
        },


        async create(payload) {
            const productsStore = useProductsStore()

            const { newStatus, updatedProduct }    = await apiRequest(...api.create, payload)

            this.ADD(newStatus)
            productsStore.UPDATE(updatedProduct)

            return newStatus
        },


        async destroy(id) {
            const productsStore = useProductsStore()

            const path = api.destroy[1] + `/${id}`

            const updatedProduct = await apiRequest(api.destroy[0], path)

            productsStore.UPDATE(updatedProduct)
            this.DESTROY_BY('_id', id)
        },


        async bulkSave(editedStatuses) {
            const productsStore = useProductsStore()

            const { updatedStatuses, updatedProducts } = await apiRequest(...api.bulkSave, editedStatuses)

            this.UPDATE(updatedStatuses)
            productsStore.UPDATE(updatedProducts)
        },

    }

})
