import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from 'FRONTEND/images/the-rudolf-text-logo.png'


const _hoisted_1 = { class: "login-content is-relative" }
const _hoisted_2 = { class: "login-left" }
const _hoisted_3 = { class: "login-right" }
const _hoisted_4 = {
  class: "login-form",
  style: {"position":"relative"}
}
const _hoisted_5 = { class: "rudolf-logo has-text-centered" }
const _hoisted_6 = { class: "sign-in-text mb-5" }
const _hoisted_7 = {
  class: "has-text-right",
  style: {"clear":"right","margin-top":"20px"}
}
const _hoisted_8 = { class: "rudolf-logo has-text-centered" }
const _hoisted_9 = { class: "pwd-reset-title" }
const _hoisted_10 = { class: "has-text-centered mb-4" }
const _hoisted_11 = { class: "buttons is-justify-content-center" }
const _hoisted_12 = { class: "has-text-centered mb-4 has-text-weight-medium" }
const _hoisted_13 = {
  key: 0,
  class: "has-text-centered mb-4"
}
const _hoisted_14 = {
  key: 1,
  class: "has-text-centered mb-6 has-text-danger"
}
const _hoisted_15 = { class: "number-code" }
const _hoisted_16 = {
  class: "has-text-right",
  style: {"margin-bottom":"70px"}
}
const _hoisted_17 = { class: "buttons is-justify-content-center" }
const _hoisted_18 = {
  key: 0,
  class: "pwd-reset-title"
}
const _hoisted_19 = {
  key: 1,
  class: "pwd-reset-title"
}
const _hoisted_20 = { class: "create-new-password-paragraph" }
const _hoisted_21 = {
  class: "has-text-centered mb-5",
  style: {"font-size":"14px"}
}
const _hoisted_22 = { class: "has-text-centered" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_input = _resolveComponent("b-input")
  const _component_b_field = _resolveComponent("b-field")
  const _component_b_button = _resolveComponent("b-button")
  const _component_b_loading = _resolveComponent("b-loading")

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _withDirectives(_createElementVNode("div", _hoisted_2, null, 512 /* NEED_PATCH */), [
      [_vShow, !$data.newUserPwdLoading]
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createCommentVNode(""),
        _createCommentVNode(""),
        _createCommentVNode(" LOGIN PAGE"),
        _createCommentVNode(""),
        _createCommentVNode(""),
        _withDirectives(_createElementVNode("div", _hoisted_5, [
          _cache[14] || (_cache[14] = _createElementVNode("img", { src: _imports_0 }, null, -1 /* HOISTED */)),
          _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.l('login/signin-welcome')), 1 /* TEXT */)
        ], 512 /* NEED_PATCH */), [
          [_vShow, $data.forgotPasswordStep === 0]
        ]),
        _withDirectives(_createElementVNode("form", {
          onSubmit: _cache[3] || (_cache[3] = _withModifiers($event => ($options.submitLogin()), ["prevent"]))
        }, [
          _createVNode(_component_b_field, {
            label: _ctx.l("generic/email"),
            "custom-class": "login-field-label",
            "label-position": "on-border",
            type: { "is-danger": $data.invalidCredentials },
            style: {"margin-bottom":"32px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_b_input, {
                modelValue: $data.email,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.email) = $event)),
                placeholder: _ctx.l("generic/email"),
                required: ""
              }, null, 8 /* PROPS */, ["modelValue", "placeholder"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label", "type"]),
          _createVNode(_component_b_field, {
            label: _ctx.l("generic/password"),
            "custom-class": "login-field-label",
            "label-position": "on-border",
            type: { "is-danger": $data.invalidCredentials },
            message: $data.invalidCredentials ? _ctx.l("login/user-pass-mismatch") : ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_b_input, {
                modelValue: $data.password,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($data.password) = $event)),
                type: "password",
                "password-reveal": ""
              }, null, 8 /* PROPS */, ["modelValue"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label", "type", "message"]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("span", {
              class: "forgot-password",
              onClick: _cache[2] || (_cache[2] = $event => ($options.goToForgotPasswordStep1()))
            }, _toDisplayString(_ctx.l('login/forgot-password')), 1 /* TEXT */)
          ]),
          _createVNode(_component_b_button, {
            class: "login-page-button sign-in-button",
            type: "is-primary",
            expanded: "",
            "native-type": "submit",
            loading: $data.loginLoading
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(_ctx.l('login/signin-button')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["loading"])
        ], 544 /* NEED_HYDRATION, NEED_PATCH */), [
          [_vShow, $data.forgotPasswordStep === 0]
        ]),
        _createCommentVNode(""),
        _createCommentVNode(""),
        _createCommentVNode(" PASSWORD RESET STEPS"),
        _createCommentVNode(""),
        _createCommentVNode(""),
        _withDirectives(_createElementVNode("div", _hoisted_8, _cache[15] || (_cache[15] = [
          _createElementVNode("img", { src: _imports_0 }, null, -1 /* HOISTED */)
        ]), 512 /* NEED_PATCH */), [
          [_vShow, $data.forgotPasswordStep > 0]
        ]),
        _createCommentVNode(""),
        _createCommentVNode(" Step 1: Enter email"),
        _createCommentVNode(""),
        _withDirectives(_createElementVNode("form", {
          onSubmit: _cache[6] || (_cache[6] = _withModifiers($event => ($options.sendResetPasswordEmail()), ["prevent"]))
        }, [
          _createElementVNode("h3", _hoisted_9, _toDisplayString(_ctx.l('login/password-reset-step1-title')), 1 /* TEXT */),
          _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.l('login/password-reset-step1-text')), 1 /* TEXT */),
          _createVNode(_component_b_field, { class: "mb-6 reset-pwd-email-field" }, {
            default: _withCtx(() => [
              _createVNode(_component_b_input, {
                modelValue: $data.passwordResetEmail,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($data.passwordResetEmail) = $event)),
                placeholder: _ctx.l("generic/email"),
                required: "",
                disabled: $data.sendResetPasswordEmailLoading
              }, null, 8 /* PROPS */, ["modelValue", "placeholder", "disabled"])
            ]),
            _: 1 /* STABLE */
          }),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_b_button, {
              class: "login-page-button mr-4",
              type: "is-light",
              onClick: _cache[5] || (_cache[5] = $event => ($options.goBackToLoginStep())),
              disabled: $data.sendResetPasswordEmailLoading,
              loading: $data.sendResetPasswordEmailLoading
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.l('generic/cancel')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["disabled", "loading"]),
            _createVNode(_component_b_button, {
              class: "login-page-button",
              type: "is-primary",
              "native-type": "submit",
              disabled: $data.sendResetPasswordEmailLoading,
              loading: $data.sendResetPasswordEmailLoading,
              style: {"width":"150px"}
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.l('generic/next')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["disabled", "loading"])
          ])
        ], 544 /* NEED_HYDRATION, NEED_PATCH */), [
          [_vShow, $data.forgotPasswordStep === 1]
        ]),
        _createCommentVNode(""),
        _createCommentVNode(" Step 2: Enter confirmation code"),
        _createCommentVNode(""),
        _withDirectives(_createElementVNode("form", {
          onSubmit: _cache[9] || (_cache[9] = _withModifiers($event => ($options.checkPasswordResetCode()), ["prevent"])),
          autocomplete: "off"
        }, [
          _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.l('login/password-reset-step2-1')), 1 /* TEXT */),
          (!$data.invalidVerificationCode)
            ? (_openBlock(), _createElementBlock("p", _hoisted_13, _toDisplayString(_ctx.l('login/password-reset-step2-2')), 1 /* TEXT */))
            : _createCommentVNode("v-if", true),
          ($data.invalidVerificationCode)
            ? (_openBlock(), _createElementBlock("p", _hoisted_14, [
                _createTextVNode(_toDisplayString(_ctx.l('login/password-reset-step2-incorrect-code')), 1 /* TEXT */),
                _cache[16] || (_cache[16] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
                _createTextVNode(_toDisplayString(_ctx.l('login/password-reset-step2-please-retry')), 1 /* TEXT */)
              ]))
            : _createCommentVNode("v-if", true),
          _createElementVNode("fieldset", _hoisted_15, [
            _createElementVNode("div", null, [
              _createElementVNode("input", {
                class: _normalizeClass(["code-input", { "invalid": this.invalidVerificationCode }]),
                name: "code",
                required: ""
              }, null, 2 /* CLASS */),
              _createElementVNode("input", {
                class: _normalizeClass(["code-input", { "invalid": this.invalidVerificationCode }]),
                name: "code",
                required: ""
              }, null, 2 /* CLASS */),
              _createElementVNode("input", {
                class: _normalizeClass(["code-input", { "invalid": this.invalidVerificationCode }]),
                name: "code",
                required: ""
              }, null, 2 /* CLASS */),
              _createElementVNode("input", {
                class: _normalizeClass(["code-input", { "invalid": this.invalidVerificationCode }]),
                name: "code",
                required: ""
              }, null, 2 /* CLASS */),
              _createElementVNode("input", {
                class: _normalizeClass(["code-input", { "invalid": this.invalidVerificationCode }]),
                name: "code",
                required: ""
              }, null, 2 /* CLASS */),
              _createElementVNode("input", {
                class: _normalizeClass(["code-input", { "invalid": this.invalidVerificationCode }]),
                name: "code",
                required: ""
              }, null, 2 /* CLASS */)
            ])
          ]),
          _createElementVNode("div", _hoisted_16, [
            _createVNode(_component_b_button, {
              class: "login-page-button resend-code",
              onClick: _cache[7] || (_cache[7] = $event => ($options.sendResetPasswordEmail(true))),
              loading: $data.sendResetPasswordEmailLoading
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.l('login/password-reset-step2-resend-code')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["loading"])
          ]),
          _createElementVNode("div", _hoisted_17, [
            _createVNode(_component_b_button, {
              class: "login-page-button mr-4",
              type: "is-light",
              onClick: _cache[8] || (_cache[8] = $event => ($data.forgotPasswordStep = 1)),
              disabled: $data.checkPasswordResetCodeLoading,
              loading: $data.checkPasswordResetCodeLoading
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.l('generic/go-back')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["disabled", "loading"]),
            _createVNode(_component_b_button, {
              class: "login-page-button",
              type: "is-primary",
              "native-type": "submit",
              disabled: $data.checkPasswordResetCodeLoading,
              loading: $data.checkPasswordResetCodeLoading,
              style: {"width":"150px"}
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.l('generic/next')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["disabled", "loading"])
          ])
        ], 544 /* NEED_HYDRATION, NEED_PATCH */), [
          [_vShow, $data.forgotPasswordStep === 2]
        ]),
        _createCommentVNode(""),
        _createCommentVNode(" Step 3: Set new password"),
        _createCommentVNode(""),
        _withDirectives(_createElementVNode("form", {
          onSubmit: _cache[12] || (_cache[12] = _withModifiers($event => ($options.setNewPassword()), ["prevent"])),
          autocomplete: "off"
        }, [
          (!$data.userFirstPassword)
            ? (_openBlock(), _createElementBlock("h3", _hoisted_18, _toDisplayString(_ctx.l('login/password-reset-step3-title')), 1 /* TEXT */))
            : (_openBlock(), _createElementBlock("h3", _hoisted_19, _toDisplayString(_ctx.l('login/password-reset-step3-new-user-pwd-title')), 1 /* TEXT */)),
          _createElementVNode("p", _hoisted_20, _toDisplayString(_ctx.l('login/password-reset-step3-create-new')), 1 /* TEXT */),
          _createVNode(_component_b_field, {
            label: _ctx.l("login/password-reset-step3-new-pwd"),
            "custom-class": "login-field-label",
            "label-position": "on-border",
            type: { "is-danger": $data.invalidNewPassword | $data.passwordMismatch }
          }, {
            default: _withCtx(() => [
              _createVNode(_component_b_input, {
                modelValue: $data.newPassword,
                "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => (($data.newPassword) = $event)),
                type: "password",
                "password-reveal": ""
              }, null, 8 /* PROPS */, ["modelValue"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label", "type"]),
          _createElementVNode("p", _hoisted_21, _toDisplayString(_ctx.l('login/password-reset-step3-pwd-rules')), 1 /* TEXT */),
          _createVNode(_component_b_field, {
            class: "new-password-again-field",
            label: _ctx.l("login/password-reset-step3-new-pwd-again"),
            "custom-class": "login-field-label",
            "label-position": "on-border",
            type: { "is-danger": $data.invalidNewPassword | $data.passwordMismatch },
            message: $options.resetPasswordMessage
          }, {
            default: _withCtx(() => [
              _createVNode(_component_b_input, {
                modelValue: $data.newPasswordAgain,
                "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => (($data.newPasswordAgain) = $event)),
                type: "password",
                "password-reveal": ""
              }, null, 8 /* PROPS */, ["modelValue"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label", "type", "message"]),
          _createElementVNode("div", _hoisted_22, [
            _createVNode(_component_b_button, {
              class: "login-page-button",
              type: "is-primary",
              "native-type": "submit",
              disabled: $data.setNewPasswordLoading,
              loading: $data.setNewPasswordLoading,
              style: {"width":"150px"}
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.l('generic/save')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["disabled", "loading"])
          ])
        ], 544 /* NEED_HYDRATION, NEED_PATCH */), [
          [_vShow, $data.forgotPasswordStep === 3]
        ]),
        _createVNode(_component_b_loading, {
          "is-full-page": false,
          active: $data.sendResetPasswordEmailLoading || $data.checkPasswordResetCodeLoading || $data.setNewPasswordLoading
        }, null, 8 /* PROPS */, ["active"])
      ])
    ], 512 /* NEED_PATCH */), [
      [_vShow, !$data.newUserPwdLoading]
    ]),
    _createVNode(_component_b_loading, {
      "is-full-page": true,
      modelValue: $data.newUserPwdLoading,
      "onUpdate:modelValue": _cache[13] || (_cache[13] = $event => (($data.newUserPwdLoading) = $event))
    }, null, 8 /* PROPS */, ["modelValue"])
  ]))
}