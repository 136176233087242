//
//
// RUDOLF frontend
//
// Nurminen Development Oy Ltd - https://nurminen.dev
//
// For customer: The Rudolf Oy
//
// ALL RIGHTS RESERVED BY CUSTOMER
//
//

//
// File author(s):
//   - Riku Nurminen <riku@nurminen.dev>
//


import { defineStore }                  from 'pinia'
import { useToast }                     from 'vue-toastification'

import { apiRequest }                   from '../api_request.js'

import * as crudFactory                 from './_crud.js'

import { useAuthStore }                 from './auth.js'
import { useLeadsStore }                from './leads.js'
import { useLocalizationsStore }        from './localizations.js'
import { useRootStore }                 from './root.js'


const api = {
    'me':                   [ 'GET',        '/users/me' ],
    'update':               [ 'PATCH',      '/users' ],
    'updateMe':             [ 'PATCH',      '/users' ],
    'destroy':              [ 'DELETE',     '/users' ],
}


const users     = []
const usersMap  = new Map()


export const useUsersStore = defineStore('users', {
    state: () => {
        return {
            ...crudFactory.states(),
        }
    },


    getters: {
        ...crudFactory.getters(users, usersMap),
    },


    actions: {
        ...crudFactory.actions('users', api, users, usersMap),

        async updateMe(updatedUserInfo, skipLeadsSync = false) {
            const updatedUser = await apiRequest(...api.updateMe, updatedUserInfo)

            this.PUT(updatedUser)

            const leadsStore = useLeadsStore()

            if(!skipLeadsSync) leadsStore.syncUserChanges(updatedUser._id)
        },


        async patch({ id, payload }) {
            const path = api.update[1] + `/${id}`
    
            const updatedUser = await apiRequest(api.update[0], path, payload)
    
            this.PUT(updatedUser)

            const leadsStore = useLeadsStore()

            leadsStore.syncUserChanges(updatedUser._id)

            return updatedUser
        },


        async destroy(id) {
            const path = api.destroy[1] + `/${id}`

            await apiRequest(api.destroy[0], path)

            this.syncDestroy(id)
        },

        async syncDestroy(id) {
            const authStore = useAuthStore()

            const userId = authStore.user._id

            this.DESTROY_BY({ field: '_id', values: id })

            if(userId === id) {
                // Currently logged-in user has been deleted
                this.loggedInUserDeleted()
            }
        },


        async syncUserVisibilitiesChange() {
            const localizationsStore = useLocalizationsStore()
            const rootStore = useRootStore()

            const toast = useToast()

            try {
                rootStore.appLoading = true

                rootStore.resetStore(false)

                // Re-fetch all app data
                await rootStore.loadAllData()

                toast.info(localizationsStore.l('generic/access-level-changed'), { timeout: false })
            } catch(error) {
                console.error('Failed to reload app data to sync user visibilities changes:')
                console.error(error)
            } finally {
                rootStore.appLoading = false
            }
        },


        async loggedInUserDeleted() {
            const authStore = useAuthStore()
            const localizationsStore = useLocalizationsStore()

            const toast = useToast()

            toast.warning(localizationsStore.l('generic/logout-user-deleted'), { timeout: false })

            authStore.logout()
        },
    }

})
