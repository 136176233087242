import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "is-relative",
  style: {"height":"100%"}
}
const _hoisted_2 = { class: "map-settings-container" }
const _hoisted_3 = { class: "map-settings-dropdown" }
const _hoisted_4 = { style: {"padding-left":"23px"} }
const _hoisted_5 = ["id"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_icon = _resolveComponent("b-icon")
  const _component_b_checkbox = _resolveComponent("b-checkbox")
  const _component_b_field = _resolveComponent("b-field")
  const _component_b_button = _resolveComponent("b-button")
  const _component_b_dropdown_item = _resolveComponent("b-dropdown-item")
  const _component_b_dropdown = _resolveComponent("b-dropdown")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_b_dropdown, {
        position: "is-bottom-right",
        "aria-role": "menu",
        "trap-focus": "",
        "mobile-modal": false,
        onActiveChange: _cache[3] || (_cache[3] = (active) => $options.mapSettingsDropdownToggled(active))
      }, {
        trigger: _withCtx(() => [
          _createVNode(_component_b_icon, {
            icon: "cog",
            "custom-size": "mdi-18px"
          })
        ]),
        default: _withCtx(() => [
          _createVNode(_component_b_dropdown_item, {
            "aria-role": "menu-item",
            focusable: false,
            custom: "",
            paddingless: ""
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _cache[6] || (_cache[6] = _createElementVNode("div", { class: "rtitle ssm mb-4" }, "Map settings:", -1 /* HOISTED */)),
                _createElementVNode("div", null, [
                  _createVNode(_component_b_field, { class: "mb-3" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_b_checkbox, {
                        modelValue: $data.useClustering,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.useClustering) = $event))
                      }, {
                        default: _withCtx(() => _cache[4] || (_cache[4] = [
                          _createTextVNode("Use clustering for lead markers")
                        ])),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["modelValue"])
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _createElementVNode("div", null, [
                  _createVNode(_component_b_field, { class: "mb-3 ml-3" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_b_checkbox, {
                        modelValue: $data.useDonutClusterIcons,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($data.useDonutClusterIcons) = $event)),
                        disabled: $data.useClustering ? false : true
                      }, {
                        default: _withCtx(() => _cache[5] || (_cache[5] = [
                          _createTextVNode("Use \"donut\" chart icons for clusters when filtering by one product")
                        ])),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["modelValue", "disabled"])
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _createElementVNode("div", {
                  style: {"padding-left":"23px"},
                  class: _normalizeClass({ "greytext": $data.useClustering !== true })
                }, "Maximum zoom level at which clustering should happen:", 2 /* CLASS */),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_b_dropdown, {
                    modelValue: $data.clusteringMaxZoom,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($data.clusteringMaxZoom) = $event)),
                    "aria-role": "list",
                    disabled: !$data.useClustering
                  }, {
                    trigger: _withCtx(({ active }) => [
                      _createVNode(_component_b_button, {
                        label: String($data.clusteringMaxZoom),
                        type: "is-select",
                        size: "is-small",
                        "icon-right": active ? "chevron-up" : "chevron-down"
                      }, null, 8 /* PROPS */, ["label", "icon-right"])
                    ]),
                    default: _withCtx(() => [
                      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(18, (zoomLevel) => {
                        return (_openBlock(), _createElementBlock(_Fragment, {
                          key: "map_clustering_max_zoom_option_" + zoomLevel
                        }, [
                          (zoomLevel > 1)
                            ? (_openBlock(), _createBlock(_component_b_dropdown_item, {
                                key: 0,
                                "aria-role": "listitem",
                                value: zoomLevel
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(zoomLevel), 1 /* TEXT */)
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"]))
                            : _createCommentVNode("v-if", true)
                        ], 64 /* STABLE_FRAGMENT */))
                      }), 64 /* STABLE_FRAGMENT */))
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["modelValue", "disabled"])
                ])
              ])
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _createElementVNode("div", {
      class: "google-map",
      id: $props.mapId
    }, null, 8 /* PROPS */, _hoisted_5)
  ]))
}