//
//
// RUDOLF frontend
//
// Nurminen Development Oy Ltd - https://nurminen.dev
//
// For customer: The Rudolf Oy
//
// ALL RIGHTS RESERVED BY CUSTOMER
//
//

//
// File author(s):
//   - Riku Nurminen <riku@nurminen.dev>
//


import { defineStore }              from 'pinia'

import { apiRequest }               from '../api_request.js'

import * as crudFactory             from './_crud.js'
import { useCallListStore }         from './calllist.js'
import { useLeadsStore }            from './leads.js'


const api = {
    'update':     [ 'PATCH',    '/contacts' ],
    'destroy':    [ 'DELETE',   '/contacts' ],
}


const contacts      = []
const contactsMap   = new Map()


export const useContactsStore = defineStore('contacts', {
    state: () => {
        return {
            ...crudFactory.states(),
        }
    },


    getters: {
        ...crudFactory.getters(contacts, contactsMap),

        contactsWithLeadsMap() {
            const _contactsWithLeadsMap = new Map()

            const leadsStore = useLeadsStore()
    
            for(const lead of leadsStore.all) {
              if(!Array.isArray(lead.contacts) || lead.contacts.length === 0) continue
    
              for(const contact of lead.contacts) {
                if(_contactsWithLeadsMap.has(contact._id)) {
                  const leadContacts = _contactsWithLeadsMap.get(contact._id)
    
                  leadContacts.push({ _id: lead._id, name: lead.name })
                } else {
                  _contactsWithLeadsMap.set(contact._id, [ { _id: lead._id, name: lead.name } ])
                }
              }
            }
    
            return _contactsWithLeadsMap
        }
    },


    actions: {
        ...crudFactory.actions('contacts', {}, contacts, contactsMap),

        async patch({ id, payload }) {
            const path = api.update[1] + `/${id}`

            const updatedContact = await apiRequest(api.update[0], path, payload)

            this.PUT(updatedContact)

            const leadsStore = useLeadsStore()

            leadsStore.syncContactChanges(updatedContact._id)

            return updatedContact
        },

        async destroy(contact) {
            const path = api.destroy[1] + `/${contact._id}`

            await apiRequest(api.destroy[0], path)

            this.syncDestroy(contact)
        },


        // Sync an already destroyed contact on backend with store
        syncDestroy(contact) {
            const leadsStore = useLeadsStore()
            const callListStore = useCallListStore()

            this.DESTROY_BY({ field: '_id', values: contact._id })

            leadsStore.PULL_CONTACT(contact._id)
            callListStore.DESTROY_BY({ field: 'contact', values: contact._id })
        }

    }

})
