//
//
// RUDOLF frontend
//
// Nurminen Development Oy Ltd - https://nurminen.dev
//
// For customer: The Rudolf Oy
//
// ALL RIGHTS RESERVED BY CUSTOMER
//
//

//
// File author(s):
//   - Riku Nurminen <riku@nurminen.dev>
//


import { defineStore }              from 'pinia'

import * as crudFactory             from './_crud.js'


const sales     = []
const salesMap  = new Map()


export const useSalesStore = defineStore('sales', {
    state: () => {
        return {
            ...crudFactory.states(),

            selectedMonth: null,
        }
    },


    getters: {
        ...crudFactory.getters(sales, salesMap),
    },


    actions: {
        ...crudFactory.actions('sales', {}, sales, salesMap),
    }
})
