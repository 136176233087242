//
//
// RUDOLF frontend
//
// Nurminen Development Oy Ltd - https://nurminen.dev
//
// For customer: The Rudolf Oy
//
// ALL RIGHTS RESERVED BY CUSTOMER
//
//

//
// File author(s):
//   - Riku Nurminen <riku@nurminen.dev>
//


import { defineStore }              from 'pinia'

import { apiRequest }               from '../api_request.js'

import * as crudFactory             from './_crud.js'
import { useReportsStore }          from './reports.js'
import { useTasksStore }            from './tasks.js'


const api = {
    // Override these to factory
    'index':            [ 'GET',        '/questions' ],
    'update':           [ 'POST',       '/questions/edit' ],
    'destroy':          [ 'DELETE',     '/questions' ],

    'saveAnswers':      [ 'POST',       '/questions/answers' ],
}


let questions                   = []
const questionsMap              = new Map()


export const useQuestionsStore = defineStore('questions', {
    state: () => {
        return {
            ...crudFactory.states(),
        }
    },


    getters: {
        ...crudFactory.getters(questions, questionsMap),


        templatesByFormId: (state) => (formId) => {
            return state.all.filter(i => {
                return (i.formid === formId || i.formid._id === formId) && i.template === true
            })
        },

        find: (state) => (taskId, formId = null) => {
            if(formId) {
                // Remember to return empty array if not found
                const questions = state.all.filter(q => q.formid === formId && q.taskid === taskId)
                return questions
            } else {
                return state.all.filter(i => i.taskid === taskId)
            }
        }
    },


    actions: {
        ...crudFactory.actions('questions', api, questions, questionsMap),


        async fetchTemplates() {
            const path = api.index[1] + `?template=true`

            const templates = await apiRequest(api.index[0], path)
        
            this.PUT(templates)
        },


        async fetchByTaskId(taskId) {
            const path = api.index[1] + `?taskId=${taskId}`

            const questionsByTaskId = await apiRequest(api.index[0], path)

            this.PUT(questionsByTaskId)
        },


        async saveAnswers(payload) {
            const tasksStore = useTasksStore()
            const reportsStore = useReportsStore()

            const { updatedQuestions, touchedTask, touchedReport } = await apiRequest(...api.saveAnswers, payload)

            if(updatedQuestions.length > 0) {
                this.PUT(updatedQuestions)
                tasksStore.UPDATE(touchedTask)
                reportsStore.UPDATE(touchedReport)
            }
        },

        async editQuestion({ id, payload }) {
            const path = api.update[1] + `/${id}`

            const updatedQuestions = await apiRequest(api.update[0], path, payload)

            this.UPDATE(updatedQuestions)
        },


        async destroy(template) {
            const path = api.destroy[1] + `/${template._id}`

            await apiRequest(api.destroy[0], path)

            this.DESTROY_QUESTION(template)
        },


        // Destroy template question + task question(s)
        DESTROY_QUESTION(template) {
            const filtered = questions.filter(i => {
                return (i.formid === template.formid &&
                        i.description === template.description &&
                        i.type === template.type) ? false : true
            })

            questions.length = 0
            questionsMap.clear()

            const questionsLength = filtered.length
            for(let i = 0; i < questionsLength; i++) {
                questions.push(filtered[i])
                questionsMap.set(filtered[i]._id, filtered[i])
    
            }

            state.updateToggler = !state.updateToggler
        },


        DESTROY_BY_TASK_FORM({ taskId, formId }) {
            // Optimized "delete" for big arrays - only change if you know what you're doing!
            const filtered = questions.filter(i => {
                if(i.taskid === taskId && i.formid === formId) return false

                return true
            })

            questions.length = 0
            questionsMap.clear()

            const questionsLength = filtered.length
            for(let i = 0; i < questionsLength; i++) {
                questions.push(filtered[i])
                questionsMap.set(filtered[i]._id, filtered[i])
            }

            state.updateToggler = !state.updateToggler
        }

    }

})
