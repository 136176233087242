import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "personal-sales-chart-container" }
const _hoisted_2 = { class: "personal-sales-reach" }
const _hoisted_3 = { class: "reach-percentage" }
const _hoisted_4 = {
  class: "personal-sales-chart-wrapper",
  style: {"height":"65px"}
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Bar = _resolveComponent("Bar")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, _toDisplayString(_ctx.l('kpi/sales-reached-in')) + " " + _toDisplayString(_ctx.$dayjs().format('YYYY')), 1 /* TEXT */),
      _createElementVNode("div", _hoisted_3, _toDisplayString($options.personalSalesVsTargetPercentage) + "%", 1 /* TEXT */)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_Bar, {
        options: $data.options,
        data: $data.chartData,
        id: $props.chartId,
        "dataset-id-key": $props.datasetIdKey
      }, null, 8 /* PROPS */, ["options", "data", "id", "dataset-id-key"])
    ])
  ]))
}