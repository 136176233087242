import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { style: {"position":"relative","margin-bottom":"50px"} }
const _hoisted_2 = { class: "statuses-header" }
const _hoisted_3 = {
  class: "statuses-title rtitle",
  style: {"margin-bottom":"24px"}
}
const _hoisted_4 = { class: "new-status-container" }
const _hoisted_5 = { class: "status-text new" }
const _hoisted_6 = { class: "control" }
const _hoisted_7 = {
  key: 0,
  class: "statuses"
}
const _hoisted_8 = { class: "status" }
const _hoisted_9 = { class: "status-text" }
const _hoisted_10 = { class: "rudolfbox" }
const _hoisted_11 = { class: "modal-inner has-text-centered" }
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = { class: "buttons mb-0" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_button = _resolveComponent("b-button")
  const _component_b_input = _resolveComponent("b-input")
  const _component_b_field = _resolveComponent("b-field")
  const _component_color_picker = _resolveComponent("color-picker")
  const _component_b_tooltip = _resolveComponent("b-tooltip")
  const _component_b_loading = _resolveComponent("b-loading")
  const _component_b_icon = _resolveComponent("b-icon")
  const _component_b_modal = _resolveComponent("b-modal")
  const _component_change_sort_order_modal = _resolveComponent("change-sort-order-modal")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.l('setup-statuses/statuses-title')), 1 /* TEXT */),
      _createElementVNode("div", null, [
        _createVNode(_component_b_button, {
          type: "is-light",
          rounded: "",
          onClick: _cache[0] || (_cache[0] = $event => ($options.openChangeSortOrderModal()))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.l('setup-ordering/change-order')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.l('setup-statuses/status')) + " " + _toDisplayString(this.editedStatuses.length + 1), 1 /* TEXT */),
      _createVNode(_component_b_field, {
        label: _ctx.l("generic/name"),
        "label-position": "on-border",
        style: {"flex-basis":"250px"},
        type: { "is-danger": $data.newStatusNameError }
      }, {
        default: _withCtx(() => [
          _createVNode(_component_b_input, {
            modelValue: $data.newStatusText,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($data.newStatusText) = $event)),
            disabled: $data.loading,
            placeholder: "New status..."
          }, null, 8 /* PROPS */, ["modelValue", "disabled"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["label", "type"]),
      _createVNode(_component_color_picker, {
        color: $data.newStatusColor,
        "onUpdate:color": _cache[2] || (_cache[2] = $event => (($data.newStatusColor) = $event)),
        style: {"margin-left":"24px","margin-right":"8px"}
      }, null, 8 /* PROPS */, ["color"]),
      _createElementVNode("p", _hoisted_6, [
        _createVNode(_component_b_tooltip, {
          label: _ctx.l("setup-statuses/add-new-status"),
          type: "is-white"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_b_button, {
              class: "icon-button",
              type: "is-light",
              size: "is-large",
              onClick: _cache[3] || (_cache[3] = $event => ($options.createStatus())),
              disabled: $data.loading,
              style: {"margin-left":"12px"}
            }, {
              default: _withCtx(() => _cache[10] || (_cache[10] = [
                _createTextVNode("+")
              ])),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["disabled"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"])
      ])
    ]),
    ($data.editedStatuses.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.editedStatuses, (status, idx) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.l('setup-statuses/status')) + " " + _toDisplayString(idx + 1), 1 /* TEXT */),
              _createVNode(_component_b_field, {
                label: _ctx.l("generic/name"),
                "label-position": "on-border"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_input, {
                    modelValue: status.text,
                    "onUpdate:modelValue": $event => ((status.text) = $event),
                    disabled: $data.loading,
                    onFocus: $event => (status.focused = true)
                  }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "disabled", "onFocus"])
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label"]),
              _createVNode(_component_color_picker, {
                color: status.color,
                "onUpdate:color": $event => ((status.color) = $event),
                onToggled: $event => (status.focused = true)
              }, null, 8 /* PROPS */, ["color", "onUpdate:color", "onToggled"]),
              _createVNode(_component_b_button, {
                class: "icon-button",
                type: "is-light",
                size: "is-small",
                "icon-right": "delete-outline",
                onClick: $event => ($options.deleteStatusConfirmModal(status))
              }, null, 8 /* PROPS */, ["onClick"])
            ]))
          }), 256 /* UNKEYED_FRAGMENT */))
        ]))
      : _createCommentVNode("v-if", true),
    _createVNode(_component_b_button, {
      class: "mt-5",
      type: "is-primary",
      rounded: "",
      onClick: _cache[4] || (_cache[4] = $event => ($options.saveStatuses())),
      disabled: $data.loading,
      loading: $data.loading
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.l('setup-statuses/save-statuses')), 1 /* TEXT */)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["disabled", "loading"]),
    _createVNode(_component_b_loading, {
      "is-full-page": false,
      modelValue: $data.loading,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => (($data.loading) = $event))
    }, null, 8 /* PROPS */, ["modelValue"]),
    _createVNode(_component_b_modal, {
      class: "fit-content-height",
      modelValue: $data.deleteStatusConfirmModalActive,
      "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => (($data.deleteStatusConfirmModalActive) = $event)),
      width: 640,
      scroll: "keep",
      "can-cancel": [ 'escape', 'outside' ],
      "on-cancel": $options.closeDeleteStatus
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_b_icon, {
              icon: "alert-circle-outline",
              "custom-class": "modal-alert"
            }),
            _createElementVNode("p", {
              class: "modal-text",
              innerHTML: _ctx.l_escaped("setup-statuses/confirm-delete-status-text", $data.statusToBeDeleted.text)
            }, null, 8 /* PROPS */, _hoisted_12),
            _createElementVNode("div", _hoisted_13, [
              _createVNode(_component_b_button, {
                type: "is-light",
                rounded: "",
                onClick: _cache[6] || (_cache[6] = $event => ($options.closeDeleteStatus()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.l('generic/cancel')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_b_button, {
                type: "is-primary",
                rounded: "",
                onClick: _cache[7] || (_cache[7] = $event => ($options.deleteStatus()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.l('generic/delete')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              })
            ])
          ])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "on-cancel"]),
    _createVNode(_component_b_modal, {
      class: "fit-content-height",
      modelValue: $data.changeSortOrderModalActive,
      "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => (($data.changeSortOrderModalActive) = $event)),
      "has-modal-card": false,
      "trap-focus": true,
      "can-cancel": ["escape", "outside"]
    }, {
      default: _withCtx((props) => [
        _createVNode(_component_change_sort_order_modal, {
          type: "statuses",
          items: this.product.statuses,
          onClose: props.close
        }, null, 8 /* PROPS */, ["items", "onClose"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue"])
  ]))
}