import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "task-pipeline-container is-relative" }
const _hoisted_2 = { style: {"display":"contents"} }
const _hoisted_3 = { class: "task-pipeline-scroll-container noselect" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "item-inner" }
const _hoisted_6 = { class: "task-pipeline-status-text" }
const _hoisted_7 = {
  class: "task-pipeline-product-selector",
  style: {"display":"contents"}
}
const _hoisted_8 = { class: "rtitle sm has-text-centered mt-3 mb-5 semibold" }
const _hoisted_9 = { style: {"width":"200px","margin":"0 auto"} }
const _hoisted_10 = { class: "dropdown-product-name" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_icon = _resolveComponent("b-icon")
  const _component_task_pipeline_doughnut = _resolveComponent("task-pipeline-doughnut")
  const _component_b_button = _resolveComponent("b-button")
  const _component_b_field = _resolveComponent("b-field")
  const _component_b_dropdown_item = _resolveComponent("b-dropdown-item")
  const _component_b_dropdown = _resolveComponent("b-dropdown")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "task-pipeline-scroll-left-container",
        onClick: _cache[0] || (_cache[0] = $event => ($options.scrollContainerScroll(-350)))
      }, [
        _createVNode(_component_b_icon, {
          icon: "chevron-left",
          "custom-class": "left-scroll-arrow"
        })
      ]),
      _createElementVNode("div", {
        class: "task-pipeline-scroll-right-container",
        onClick: _cache[1] || (_cache[1] = $event => ($options.scrollContainerScroll(350)))
      }, [
        _createVNode(_component_b_icon, {
          icon: "chevron-right",
          "custom-class": "right-scroll-arrow"
        })
      ]),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.taskPipeline, (taskPipelineItem) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass(["task-pipeline-item", { "selected": $data.selectedStatusId === taskPipelineItem.statusId }]),
            key: "task_pipeline_item_" + taskPipelineItem.statusId,
            onClick: $event => ($options.selectStatus(taskPipelineItem.statusId))
          }, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_task_pipeline_doughnut, {
                chartId: "doughnut_" + taskPipelineItem.statusId,
                text: taskPipelineItem.statusText,
                color: taskPipelineItem.statusColor,
                count: taskPipelineItem.numStatuses,
                total: _ctx.leadsStore.filteredStage0.length - taskPipelineItem.numStatuses
              }, null, 8 /* PROPS */, ["chartId", "text", "color", "count", "total"]),
              _createElementVNode("div", _hoisted_6, _toDisplayString(taskPipelineItem.statusText), 1 /* TEXT */)
            ])
          ], 10 /* CLASS, PROPS */, _hoisted_4))
        }), 128 /* KEYED_FRAGMENT */))
      ])
    ], 512 /* NEED_PATCH */), [
      [_vShow, $options.oneProductFilterActive]
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.l('overview/taskpipeline-select-product')), 1 /* TEXT */),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_b_dropdown, {
          modelValue: $options.filteredProductId,
          "aria-role": "list",
          scrollable: true,
          "max-height": "300px",
          position: "is-top-right",
          onChange: _cache[2] || (_cache[2] = (productId) => $options.filterLeadsByProduct(productId))
        }, {
          trigger: _withCtx(({ active }) => [
            _createVNode(_component_b_field, {
              label: _ctx.l("generic/product"),
              "label-position": "on-border"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_b_button, {
                  label: $options.filteredProductLabel,
                  type: "is-select",
                  expanded: "",
                  size: "is-small",
                  "icon-right": active ? "chevron-up" : "chevron-down"
                }, null, 8 /* PROPS */, ["label", "icon-right"])
              ]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label"])
          ]),
          default: _withCtx(() => [
            _createVNode(_component_b_dropdown_item, {
              value: null,
              "aria-role": "menuitem"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.l('generic/allproducts')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.productsSorted, (product) => {
              return (_openBlock(), _createBlock(_component_b_dropdown_item, {
                key: product._id,
                value: product._id,
                "aria-role": "menuitem"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(product.name), 1 /* TEXT */)
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"]))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue"])
      ])
    ], 512 /* NEED_PATCH */), [
      [_vShow, !$options.oneProductFilterActive]
    ])
  ]))
}