import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "mb-6 is-relative" }
const _hoisted_2 = {
  class: "rtitle",
  style: {"margin":"24px 0 16px"}
}
const _hoisted_3 = {
  class: "rtitle sm",
  style: {"margin":"24px 0 16px 0"}
}
const _hoisted_4 = { class: "kpi-data-upload mb-4" }
const _hoisted_5 = { class: "upload-for" }
const _hoisted_6 = { class: "attach-file" }
const _hoisted_7 = { class: "file-cta" }
const _hoisted_8 = { class: "file-label rtitle sm" }
const _hoisted_9 = {
  key: 0,
  class: "is-size-7 has-text-danger"
}
const _hoisted_10 = {
  key: 0,
  class: "attached-file"
}
const _hoisted_11 = { class: "attachment-icon" }
const _hoisted_12 = { class: "attachment-name semibold" }
const _hoisted_13 = {
  class: "rtitle sm",
  style: {"margin":"24px 0 16px 0"}
}
const _hoisted_14 = { class: "kpi-weight-months" }
const _hoisted_15 = { class: "month-container" }
const _hoisted_16 = { class: "month-container" }
const _hoisted_17 = { class: "month-container" }
const _hoisted_18 = { class: "month-container" }
const _hoisted_19 = { class: "month-container" }
const _hoisted_20 = { class: "month-container" }
const _hoisted_21 = { class: "month-container" }
const _hoisted_22 = { class: "month-container" }
const _hoisted_23 = { class: "month-container" }
const _hoisted_24 = { class: "month-container" }
const _hoisted_25 = { class: "month-container" }
const _hoisted_26 = { class: "month-container" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_button = _resolveComponent("b-button")
  const _component_b_field = _resolveComponent("b-field")
  const _component_b_dropdown_item = _resolveComponent("b-dropdown-item")
  const _component_b_dropdown = _resolveComponent("b-dropdown")
  const _component_b_icon = _resolveComponent("b-icon")
  const _component_b_upload = _resolveComponent("b-upload")
  const _component_b_loading = _resolveComponent("b-loading")
  const _component_b_numberinput = _resolveComponent("b-numberinput")

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h5", _hoisted_2, _toDisplayString(_ctx.l('setup-kpi/section-title')), 1 /* TEXT */),
      _createElementVNode("h6", _hoisted_3, _toDisplayString(_ctx.l('setup-kpi/upload-kpi-data')), 1 /* TEXT */),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_b_dropdown, {
            modelValue: $data.selectedMonthYear,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.selectedMonthYear) = $event)),
            "aria-role": "list",
            scrollable: true,
            "max-height": "200"
          }, {
            trigger: _withCtx(({ active }) => [
              _createVNode(_component_b_field, {
                label: _ctx.l("setup-kpi/upload-for"),
                "label-position": "on-border",
                type: { "is-danger" : $data.selectedMonthYearError },
                message: $data.selectedMonthYearError ? _ctx.l("setup-kpi/select-month-error") : ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_button, {
                    class: "upload-for-dropdown",
                    label: $data.selectedMonthYear === "none" ? _ctx.l("generic/select") + "..." : _ctx.$dayjs($data.selectedMonthYear).format("MMMM YYYY"),
                    type: "is-select",
                    "icon-right": active ? "chevron-up" : "chevron-down"
                  }, null, 8 /* PROPS */, ["label", "icon-right"])
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label", "type", "message"])
            ]),
            default: _withCtx(() => [
              _createVNode(_component_b_dropdown_item, {
                "aria-role": "listitem",
                value: "none"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.l('generic/select')) + "...", 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.uploadMonthYears, (option) => {
                return (_openBlock(), _createBlock(_component_b_dropdown_item, {
                  key: option.month_year,
                  "aria-role": "listitem",
                  value: option.iso
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(option.display), 1 /* TEXT */)
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"]))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_b_field, {
            class: _normalizeClass(["file is-primary", {'has-name': !!$data.rawSalesFile}]),
            "custom-class": "smallfield"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_b_upload, {
                class: "file-label",
                modelValue: $data.rawSalesFile,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($data.rawSalesFile) = $event)),
                accept: ".xlsx",
                disabled: $data.loading
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_7, [
                    _createVNode(_component_b_icon, {
                      class: "file-icon",
                      icon: "paperclip"
                    }),
                    _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.l('setup-kpi/attach-file')), 1 /* TEXT */)
                  ])
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["modelValue", "disabled"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["class"]),
          ($data.rawSalesFileError)
            ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(_ctx.l('setup-kpi/select-file-error')), 1 /* TEXT */))
            : _createCommentVNode("v-if", true)
        ]),
        ($data.rawSalesFile)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createVNode(_component_b_icon, { icon: "file-document-outline" })
              ]),
              _createElementVNode("div", _hoisted_12, _toDisplayString($data.rawSalesFile.name), 1 /* TEXT */),
              _createElementVNode("div", {
                class: "remove-attachment",
                onClick: _cache[2] || (_cache[2] = $event => ($data.rawSalesFile = null))
              }, [
                _createVNode(_component_b_icon, { icon: "close" })
              ])
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      _createVNode(_component_b_button, {
        type: "is-primary",
        rounded: "",
        "icon-left": "upload",
        onClick: _cache[3] || (_cache[3] = $event => ($options.uploadKpiData())),
        loading: $data.kpiUploadLoading,
        disabled: !$data.rawSalesFile || (!$data.selectedMonthYear || $data.selectedMonthYear === "none")
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.l('setup-kpi/upload-kpi-data')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["loading", "disabled"]),
      _createVNode(_component_b_loading, {
        "is-full-page": false,
        modelValue: $data.kpiUploadLoading,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($data.kpiUploadLoading) = $event))
      }, null, 8 /* PROPS */, ["modelValue"])
    ]),
    _createElementVNode("h6", _hoisted_13, _toDisplayString(_ctx.l('setup-kpi/monthly-emphasis')), 1 /* TEXT */),
    _createElementVNode("div", _hoisted_14, [
      _createElementVNode("div", _hoisted_15, [
        _createVNode(_component_b_field, {
          horizontal: "",
          label: _ctx.$dayjs.months()[0],
          "custom-class": "month-name"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_b_numberinput, {
              "controls-position": "compact",
              step: "any",
              modelValue: $data.kpiWeights.jan,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => (($data.kpiWeights.jan) = $event)),
              min: "1",
              max: "100",
              required: ""
            }, null, 8 /* PROPS */, ["modelValue"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"])
      ]),
      _createElementVNode("div", _hoisted_16, [
        _createVNode(_component_b_field, {
          horizontal: "",
          label: _ctx.$dayjs.months()[1],
          "custom-class": "month-name"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_b_numberinput, {
              "controls-position": "compact",
              step: "any",
              modelValue: $data.kpiWeights.feb,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => (($data.kpiWeights.feb) = $event)),
              min: "1",
              max: "100",
              required: ""
            }, null, 8 /* PROPS */, ["modelValue"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"])
      ]),
      _createElementVNode("div", _hoisted_17, [
        _createVNode(_component_b_field, {
          horizontal: "",
          label: _ctx.$dayjs.months()[2],
          "custom-class": "month-name"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_b_numberinput, {
              "controls-position": "compact",
              step: "any",
              modelValue: $data.kpiWeights.mar,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => (($data.kpiWeights.mar) = $event)),
              min: "1",
              max: "100",
              required: ""
            }, null, 8 /* PROPS */, ["modelValue"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"])
      ]),
      _createElementVNode("div", _hoisted_18, [
        _createVNode(_component_b_field, {
          horizontal: "",
          label: _ctx.$dayjs.months()[3],
          "custom-class": "month-name"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_b_numberinput, {
              "controls-position": "compact",
              step: "any",
              modelValue: $data.kpiWeights.apr,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => (($data.kpiWeights.apr) = $event)),
              min: "1",
              max: "100",
              required: ""
            }, null, 8 /* PROPS */, ["modelValue"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"])
      ]),
      _createElementVNode("div", _hoisted_19, [
        _createVNode(_component_b_field, {
          horizontal: "",
          label: _ctx.$dayjs.months()[4],
          "custom-class": "month-name"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_b_numberinput, {
              "controls-position": "compact",
              step: "any",
              modelValue: $data.kpiWeights.may,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => (($data.kpiWeights.may) = $event)),
              min: "1",
              max: "100",
              required: ""
            }, null, 8 /* PROPS */, ["modelValue"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"])
      ]),
      _createElementVNode("div", _hoisted_20, [
        _createVNode(_component_b_field, {
          horizontal: "",
          label: _ctx.$dayjs.months()[5],
          "custom-class": "month-name"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_b_numberinput, {
              "controls-position": "compact",
              step: "any",
              modelValue: $data.kpiWeights.jun,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => (($data.kpiWeights.jun) = $event)),
              min: "1",
              max: "100",
              required: ""
            }, null, 8 /* PROPS */, ["modelValue"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"])
      ]),
      _createElementVNode("div", _hoisted_21, [
        _createVNode(_component_b_field, {
          horizontal: "",
          label: _ctx.$dayjs.months()[6],
          "custom-class": "month-name"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_b_numberinput, {
              "controls-position": "compact",
              step: "any",
              modelValue: $data.kpiWeights.jul,
              "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => (($data.kpiWeights.jul) = $event)),
              min: "1",
              max: "100",
              required: ""
            }, null, 8 /* PROPS */, ["modelValue"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"])
      ]),
      _createElementVNode("div", _hoisted_22, [
        _createVNode(_component_b_field, {
          horizontal: "",
          label: _ctx.$dayjs.months()[7],
          "custom-class": "month-name"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_b_numberinput, {
              "controls-position": "compact",
              step: "any",
              modelValue: $data.kpiWeights.aug,
              "onUpdate:modelValue": _cache[12] || (_cache[12] = $event => (($data.kpiWeights.aug) = $event)),
              min: "1",
              max: "100",
              required: ""
            }, null, 8 /* PROPS */, ["modelValue"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"])
      ]),
      _createElementVNode("div", _hoisted_23, [
        _createVNode(_component_b_field, {
          horizontal: "",
          label: _ctx.$dayjs.months()[8],
          "custom-class": "month-name"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_b_numberinput, {
              "controls-position": "compact",
              step: "any",
              modelValue: $data.kpiWeights.sep,
              "onUpdate:modelValue": _cache[13] || (_cache[13] = $event => (($data.kpiWeights.sep) = $event)),
              min: "1",
              max: "100",
              required: ""
            }, null, 8 /* PROPS */, ["modelValue"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"])
      ]),
      _createElementVNode("div", _hoisted_24, [
        _createVNode(_component_b_field, {
          horizontal: "",
          label: _ctx.$dayjs.months()[9],
          "custom-class": "month-name"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_b_numberinput, {
              "controls-position": "compact",
              step: "any",
              modelValue: $data.kpiWeights.oct,
              "onUpdate:modelValue": _cache[14] || (_cache[14] = $event => (($data.kpiWeights.oct) = $event)),
              min: "1",
              max: "100",
              required: ""
            }, null, 8 /* PROPS */, ["modelValue"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"])
      ]),
      _createElementVNode("div", _hoisted_25, [
        _createVNode(_component_b_field, {
          horizontal: "",
          label: _ctx.$dayjs.months()[10],
          "custom-class": "month-name"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_b_numberinput, {
              "controls-position": "compact",
              step: "any",
              modelValue: $data.kpiWeights.nov,
              "onUpdate:modelValue": _cache[15] || (_cache[15] = $event => (($data.kpiWeights.nov) = $event)),
              min: "1",
              max: "100",
              required: ""
            }, null, 8 /* PROPS */, ["modelValue"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"])
      ]),
      _createElementVNode("div", _hoisted_26, [
        _createVNode(_component_b_field, {
          horizontal: "",
          label: _ctx.$dayjs.months()[11],
          "custom-class": "month-name"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_b_numberinput, {
              "controls-position": "compact",
              step: "any",
              modelValue: $data.kpiWeights.dec,
              "onUpdate:modelValue": _cache[16] || (_cache[16] = $event => (($data.kpiWeights.dec) = $event)),
              min: "1",
              max: "100",
              required: ""
            }, null, 8 /* PROPS */, ["modelValue"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"])
      ]),
      _cache[19] || (_cache[19] = _createElementVNode("div", { class: "month-placeholder" }, null, -1 /* HOISTED */)),
      _cache[20] || (_cache[20] = _createElementVNode("div", { class: "month-placeholder" }, null, -1 /* HOISTED */)),
      _cache[21] || (_cache[21] = _createElementVNode("div", { class: "month-placeholder" }, null, -1 /* HOISTED */)),
      _createVNode(_component_b_loading, {
        "is-full-page": false,
        modelValue: $data.loading,
        "onUpdate:modelValue": _cache[17] || (_cache[17] = $event => (($data.loading) = $event))
      }, null, 8 /* PROPS */, ["modelValue"])
    ]),
    _createVNode(_component_b_button, {
      class: "ok-button",
      type: "is-primary",
      onClick: _cache[18] || (_cache[18] = $event => ($options.saveKpiSettings())),
      disabled: $data.loading,
      loading: $data.loading
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.l('generic/save')), 1 /* TEXT */)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["disabled", "loading"])
  ]))
}