//
//
// RUDOLF frontend
//
// Nurminen Development Oy Ltd - https://nurminen.dev
//
// For customer: The Rudolf Oy
//
// ALL RIGHTS RESERVED BY CUSTOMER
//
//

//
// File author(s):
//   - Riku Nurminen <riku@nurminen.dev>
//


import { apiRequest }                   from '../api_request.js'

import { useLocalizationsStore }        from '../store/localizations.js'


class LocalizationsService {

    constructor() {
    
    }


    _buildJsonAsXlsxData(localization, instance) {
        const localizationsStore = useLocalizationsStore()

        const fields = {
            'field':        'FIELD',
            'translation':  'TRANSLATION',
            'details':      'DETAILS / NOTES',
        }

        const columns = []

        for(const [value, label] of Object.entries(fields)) {
          columns.push({ label, value })
        }

        const content = []

        const defaultFields = localizationsStore.fields

        for(const [field, value] of Object.entries(defaultFields)) {
            let translation = value.default

            if(field in localization.fields) translation = localization.fields[field]

            let details = value?.details || ''

            content.push({ field, translation, details })
        }

        let sheet = `Rudolf ${instance} ${localization.code.toUpperCase()}`

        // Sheet name max 31 chars
        if(sheet.length > 31) sheet = `Rudolf ${localization.code.toUpperCase()}`

        return [ { sheet, columns, content, } ]
      }

}


export default new LocalizationsService()
