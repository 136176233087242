import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "localization-edit is-relative" }
const _hoisted_2 = {
  key: 0,
  class: "rtitle"
}
const _hoisted_3 = { class: "localization-edit-top" }
const _hoisted_4 = { class: "localization-edit-top-inner" }
const _hoisted_5 = { class: "is-clickable" }
const _hoisted_6 = {
  key: 0,
  class: "bold"
}
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "rtitle lg" }
const _hoisted_9 = { class: "localization-edit-content" }
const _hoisted_10 = ["id"]
const _hoisted_11 = {
  key: 0,
  class: "rtitle category-header mb-3 has-missing-translations"
}
const _hoisted_12 = {
  key: 1,
  class: "rtitle category-header mb-3"
}
const _hoisted_13 = {
  key: 2,
  class: "semibold mb-4"
}
const _hoisted_14 = { class: "localization-fields" }
const _hoisted_15 = { class: "localization-edit-bottom" }
const _hoisted_16 = { class: "buttons" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_icon = _resolveComponent("b-icon")
  const _component_b_dropdown_item = _resolveComponent("b-dropdown-item")
  const _component_b_dropdown = _resolveComponent("b-dropdown")
  const _component_b_input = _resolveComponent("b-input")
  const _component_b_field = _resolveComponent("b-field")
  const _component_b_button = _resolveComponent("b-button")
  const _component_b_loading = _resolveComponent("b-loading")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!$props.localization || !$props.localization?._id)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, "Select a localization to edit translations"))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", null, [
                _createVNode(_component_b_dropdown, {
                  position: "is-bottom-right",
                  triggers: [ "click", "hover" ],
                  scrollable: true,
                  "max-height": "300px",
                  onChange: _cache[0] || (_cache[0] = (category) => $options.navigateToCategory(category))
                }, {
                  trigger: _withCtx(({ active }) => [
                    _createElementVNode("div", _hoisted_5, [
                      _createVNode(_component_b_icon, { icon: "menu" })
                    ])
                  ]),
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.groupedFields, (category, categoryName) => {
                      return (_openBlock(), _createBlock(_component_b_dropdown_item, { value: categoryName }, {
                        default: _withCtx(() => [
                          ($props.localization.code !== "en" && $options.hasMissingTranslations(category))
                            ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(categoryName.toUpperCase()) + " (!)", 1 /* TEXT */))
                            : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(categoryName.toUpperCase()), 1 /* TEXT */))
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"]))
                    }), 256 /* UNKEYED_FRAGMENT */))
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _createElementVNode("div", _hoisted_8, _toDisplayString($props.localization.fullName), 1 /* TEXT */)
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.groupedFields, (category, categoryName) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "localization-category mb-6",
                id: categoryName
              }, [
                ($props.localization.code !== "en" && $options.hasMissingTranslations(category))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(categoryName) + " (!)", 1 /* TEXT */))
                  : (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(categoryName), 1 /* TEXT */)),
                (_ctx.localizationsStore.categoryDetails[categoryName])
                  ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(_ctx.localizationsStore.categoryDetails[categoryName]), 1 /* TEXT */))
                  : _createCommentVNode("v-if", true),
                _createElementVNode("div", _hoisted_14, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(category, (field) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: _normalizeClass(["localization-field", { "is-default-translation": $props.localization.code !== "en" && !field.isModified }])
                    }, [
                      _createVNode(_component_b_field, {
                        label: field.default,
                        message: field.details
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_b_input, {
                            modelValue: $data.editedFields[field.key],
                            "onUpdate:modelValue": $event => (($data.editedFields[field.key]) = $event),
                            type: field.type ? field.type : "input"
                          }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "type"])
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label", "message"])
                    ], 2 /* CLASS */))
                  }), 256 /* UNKEYED_FRAGMENT */))
                ])
              ], 8 /* PROPS */, _hoisted_10))
            }), 256 /* UNKEYED_FRAGMENT */))
          ]),
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("div", _hoisted_16, [
              _createVNode(_component_b_button, {
                type: "is-primary",
                rounded: "",
                onClick: _cache[1] || (_cache[1] = $event => ($options.saveLocalization())),
                disabled: $data.loading
              }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode("Save localization")
                ])),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["disabled"])
            ])
          ])
        ], 64 /* STABLE_FRAGMENT */)),
    _createVNode(_component_b_loading, {
      "is-full-page": false,
      modelValue: $data.loading,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($data.loading) = $event)),
      animation: "none"
    }, null, 8 /* PROPS */, ["modelValue"])
  ]))
}