import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "bulk-manage" }
const _hoisted_2 = { class: "bulk-manage-tabs" }
const _hoisted_3 = { class: "bulk-products-container" }
const _hoisted_4 = { class: "bulk-products-top" }
const _hoisted_5 = { class: "products-columns" }
const _hoisted_6 = { class: "products-column" }
const _hoisted_7 = { class: "semibold greytext" }
const _hoisted_8 = { class: "product-value-column" }
const _hoisted_9 = { class: "semibold greytext" }
const _hoisted_10 = { class: "bulk-products-content" }
const _hoisted_11 = {
  class: "products-column pt-1",
  style: {"overflow":"hidden","padding-right":"10px"}
}
const _hoisted_12 = { class: "product-value-column" }
const _hoisted_13 = {
  key: 0,
  class: "has-text-centered is-italic mt-5"
}
const _hoisted_14 = {
  key: 1,
  class: "bulk-products-container"
}
const _hoisted_15 = { class: "bulk-products-top" }
const _hoisted_16 = { class: "products-columns" }
const _hoisted_17 = { class: "products-column" }
const _hoisted_18 = { class: "semibold greytext" }
const _hoisted_19 = { class: "product-value-column" }
const _hoisted_20 = { class: "semibold greytext" }
const _hoisted_21 = { class: "bulk-products-content" }
const _hoisted_22 = {
  class: "products-column pt-1",
  style: {"overflow":"hidden","padding-right":"10px"}
}
const _hoisted_23 = { class: "product-value-column" }
const _hoisted_24 = { key: 0 }
const _hoisted_25 = {
  key: 1,
  class: "status-selection"
}
const _hoisted_26 = { class: "status-text" }
const _hoisted_27 = { class: "status-selection" }
const _hoisted_28 = { class: "status-text" }
const _hoisted_29 = {
  key: 0,
  class: "has-text-centered is-italic mt-5"
}
const _hoisted_30 = ["innerHTML"]
const _hoisted_31 = { class: "bulk-manage-bottom" }
const _hoisted_32 = { class: "buttons is-justify-content-space-between" }
const _hoisted_33 = { class: "rudolfbox" }
const _hoisted_34 = { class: "modal-inner has-text-centered" }
const _hoisted_35 = { class: "modal-text" }
const _hoisted_36 = { class: "modal-text" }
const _hoisted_37 = { class: "buttons mb-0" }
const _hoisted_38 = { class: "rudolfbox bulk-action-modal" }
const _hoisted_39 = { class: "modal-inner has-text-centered" }
const _hoisted_40 = { class: "rudolfbox" }
const _hoisted_41 = { class: "modal-inner has-text-centered" }
const _hoisted_42 = ["innerHTML"]
const _hoisted_43 = { class: "buttons mb-0" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_icon = _resolveComponent("b-icon")
  const _component_b_checkbox = _resolveComponent("b-checkbox")
  const _component_b_button = _resolveComponent("b-button")
  const _component_b_field = _resolveComponent("b-field")
  const _component_b_dropdown_item = _resolveComponent("b-dropdown-item")
  const _component_b_dropdown = _resolveComponent("b-dropdown")
  const _component_b_tab_item = _resolveComponent("b-tab-item")
  const _component_b_tabs = _resolveComponent("b-tabs")
  const _component_b_loading = _resolveComponent("b-loading")
  const _component_b_modal = _resolveComponent("b-modal")
  const _component_b_progress = _resolveComponent("b-progress")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "close-button",
      onClick: _cache[0] || (_cache[0] = $event => ($options.close()))
    }, [
      _createVNode(_component_b_icon, { icon: "window-close" })
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_b_tabs, {
        modelValue: $data.activeTab,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($data.activeTab) = $event))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_b_tab_item, { value: "assignments" }, {
            header: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(_ctx.l('manage-leads-bulk-manage/assignments')), 1 /* TEXT */)
            ]),
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("h6", _hoisted_7, _toDisplayString(_ctx.l('manage-leads-bulk-manage/assigned-products')), 1 /* TEXT */)
                    ]),
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("h6", _hoisted_9, _toDisplayString(_ctx.l('manage-leads-bulk-manage/assignee-of-products')), 1 /* TEXT */)
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_10, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.bulkAssignments, (bulkAssignment, idx) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "products-columns mb-4",
                      key: bulkAssignment.productId
                    }, [
                      _createElementVNode("div", _hoisted_11, [
                        _createVNode(_component_b_checkbox, {
                          type: "is-dark",
                          "true-value": "all",
                          "false-value": "none",
                          modelValue: bulkAssignment.productCheckbox,
                          "onUpdate:modelValue": $event => ((bulkAssignment.productCheckbox) = $event),
                          indeterminate: bulkAssignment.productCheckbox === "partial",
                          onInput: (value) => $options.bulkAssignProductCheckboxChanged(value, bulkAssignment)
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(bulkAssignment.productName), 1 /* TEXT */)
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onUpdate:modelValue", "indeterminate", "onInput"])
                      ]),
                      _createElementVNode("div", _hoisted_12, [
                        _createVNode(_component_b_dropdown, {
                          position: "is-bottom-right",
                          "aria-role": "list",
                          scrollable: "",
                          modelValue: bulkAssignment.productAssignee,
                          "onUpdate:modelValue": $event => ((bulkAssignment.productAssignee) = $event),
                          onChange: _cache[1] || (_cache[1] = (value) => $options.bulkAssignUserAssignmentChanged()),
                          disabled: bulkAssignment.productCheckbox === "none" || bulkAssignment.productCheckbox === "partial"
                        }, {
                          trigger: _withCtx(({ active }) => [
                            _createVNode(_component_b_field, {
                              label: _ctx.l("generic/assigned-to"),
                              "label-position": "on-border"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_b_button, {
                                  label: $options.bulkAssignAssigneeLabel(bulkAssignment.productAssignee),
                                  type: "is-select",
                                  size: "is-small",
                                  expanded: "",
                                  rounded: "",
                                  "icon-right": active ? "menu-up" : "menu-down",
                                  class: _normalizeClass({ "is-italic": bulkAssignment.productAssignee === "multiple" })
                                }, null, 8 /* PROPS */, ["label", "icon-right", "class"])
                              ]),
                              _: 2 /* DYNAMIC */
                            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label"])
                          ]),
                          default: _withCtx(() => [
                            _createVNode(_component_b_dropdown_item, {
                              "aria-role": "listitem",
                              value: "unassigned"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.l('generic/unassigned')), 1 /* TEXT */)
                              ]),
                              _: 1 /* STABLE */
                            }),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.users, (user) => {
                              return (_openBlock(), _createBlock(_component_b_dropdown_item, {
                                key: user._id,
                                "aria-role": "listitem",
                                value: user._id
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(user.fullName), 1 /* TEXT */)
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"]))
                            }), 128 /* KEYED_FRAGMENT */))
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onUpdate:modelValue", "disabled"])
                      ])
                    ]))
                  }), 128 /* KEYED_FRAGMENT */))
                ])
              ])
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_b_tab_item, { value: "statuses" }, {
            header: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(_ctx.l('manage-leads-bulk-manage/statuses')), 1 /* TEXT */)
            ]),
            default: _withCtx(() => [
              ($options.bulkStatusesProducts.length === 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(_ctx.l('manage-leads-bulk-manage/select-leads-statuses')), 1 /* TEXT */))
                : (_openBlock(), _createElementBlock("div", _hoisted_14, [
                    _createElementVNode("div", _hoisted_15, [
                      _createElementVNode("div", _hoisted_16, [
                        _createElementVNode("div", _hoisted_17, [
                          _createElementVNode("h6", _hoisted_18, _toDisplayString(_ctx.l('manage-leads-bulk-manage/product')), 1 /* TEXT */)
                        ]),
                        _createElementVNode("div", _hoisted_19, [
                          _createElementVNode("h6", _hoisted_20, _toDisplayString(_ctx.l('manage-leads-bulk-manage/status')), 1 /* TEXT */)
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_21, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.bulkStatusesProducts, (product, idx) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: "products-columns mb-4",
                          key: "bulk_statuses_product_" + product._id
                        }, [
                          _createElementVNode("div", _hoisted_22, [
                            _createVNode(_component_b_checkbox, {
                              type: "is-dark",
                              modelValue: product._id in $data.bulkStatuses,
                              onInput: (value) => $options.bulkStatusSelectProduct(value, product)
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(product.name), 1 /* TEXT */)
                              ]),
                              _: 2 /* DYNAMIC */
                            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onInput"])
                          ]),
                          _createElementVNode("div", _hoisted_23, [
                            _createVNode(_component_b_dropdown, {
                              position: "is-bottom-right",
                              "aria-role": "list",
                              scrollable: "",
                              modelValue: $data.bulkStatuses[product._id],
                              onChange: (statusId) => $options.bulkStatusSelectProductStatus(product, statusId)
                            }, {
                              trigger: _withCtx(({ active }) => [
                                _createVNode(_component_b_field, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_b_button, {
                                      type: "is-select",
                                      size: "is-small",
                                      expanded: "",
                                      "icon-right": active ? "chevron-up" : "chevron-down"
                                    }, {
                                      default: _withCtx(() => [
                                        (!$options.productStatus(product))
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_24, "Select"))
                                          : (_openBlock(), _createElementBlock("div", _hoisted_25, [
                                              _createElementVNode("div", {
                                                class: "status-color",
                                                style: _normalizeStyle(`background-color: ${$options.productStatus(product).color}`)
                                              }, null, 4 /* STYLE */),
                                              _createElementVNode("div", _hoisted_26, _toDisplayString($options.productStatus(product).text), 1 /* TEXT */)
                                            ]))
                                      ]),
                                      _: 2 /* DYNAMIC */
                                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["icon-right"])
                                  ]),
                                  _: 2 /* DYNAMIC */
                                }, 1024 /* DYNAMIC_SLOTS */)
                              ]),
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(product.statuses, (status) => {
                                  return (_openBlock(), _createBlock(_component_b_dropdown_item, {
                                    key: "bulk_status_id_" + status._id,
                                    "aria-role": "listitem",
                                    value: status._id
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("div", _hoisted_27, [
                                        _createElementVNode("div", {
                                          class: "status-color",
                                          style: _normalizeStyle(`background-color: ${status.color}`)
                                        }, null, 4 /* STYLE */),
                                        _createElementVNode("div", _hoisted_28, _toDisplayString(status.text), 1 /* TEXT */)
                                      ])
                                    ]),
                                    _: 2 /* DYNAMIC */
                                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"]))
                                }), 128 /* KEYED_FRAGMENT */))
                              ]),
                              _: 2 /* DYNAMIC */
                            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onChange"])
                          ])
                        ]))
                      }), 128 /* KEYED_FRAGMENT */))
                    ])
                  ]))
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_b_tab_item, { value: "delete" }, {
            header: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(_ctx.l('manage-leads-bulk-manage/delete')), 1 /* TEXT */)
            ]),
            default: _withCtx(() => [
              ($props.selectedLeads.length === 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_29, _toDisplayString(_ctx.l('manage-leads-bulk-manage/select-leads-delete')), 1 /* TEXT */))
                : (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: "has-text-centered mt-5",
                    innerHTML: _ctx.l_escaped("manage-leads-bulk-manage/bulk-delete-deleting", $props.selectedLeads.length)
                  }, null, 8 /* PROPS */, _hoisted_30))
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_31, [
      _createElementVNode("div", _hoisted_32, [
        ($data.activeTab === "assignments")
          ? (_openBlock(), _createBlock(_component_b_button, {
              key: 0,
              type: "is-primary",
              rounded: "",
              onClick: _cache[3] || (_cache[3] = $event => ($options.bulkAssignSelectedLeads())),
              disabled: $data.loading
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.l('generic/assign')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["disabled"]))
          : _createCommentVNode("v-if", true),
        ($data.activeTab === "statuses")
          ? (_openBlock(), _createBlock(_component_b_button, {
              key: 1,
              type: "is-primary",
              rounded: "",
              onClick: _cache[4] || (_cache[4] = $event => ($options.bulkSetStatuses())),
              disabled: !$options.bulkStatusHasPayload,
              loading: $data.loading
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.l('manage-leads-bulk-manage/set-statuses')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["disabled", "loading"]))
          : _createCommentVNode("v-if", true),
        ($data.activeTab === "delete")
          ? (_openBlock(), _createBlock(_component_b_button, {
              key: 2,
              type: "is-primary",
              rounded: "",
              onClick: _cache[5] || (_cache[5] = $event => ($options.openBulkDeleteLeadsConfirmModal())),
              disabled: $data.loading
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.l('manage-leads-bulk-manage/bulk-delete-button')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["disabled"]))
          : _createCommentVNode("v-if", true),
        _createVNode(_component_b_button, {
          type: "is-light",
          rounded: "",
          disabled: $data.loading,
          onClick: _cache[6] || (_cache[6] = $event => ($options.close()))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.l('generic/close')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["disabled"])
      ]),
      _createCommentVNode("div.bulk-delete-leads-button.is-flex-grow-1.has-text-right.mr-1(\n  @click='openBulkDeleteLeadsConfirmModal()'\n)\n  b-tooltip(:label='l(\"manage-leads-bulk-manage/bulk-delete-tooltip\")' type='is-white' position='is-left')\n    b-icon(icon='delete-outline' custom-size='mdi-24px')\n")
    ]),
    _createVNode(_component_b_loading, {
      "is-full-page": false,
      modelValue: $data.loading,
      "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => (($data.loading) = $event)),
      animation: "none"
    }, null, 8 /* PROPS */, ["modelValue"]),
    _createCommentVNode(""),
    _createCommentVNode(" ███╗   ███╗ ██████╗ ██████╗  █████╗ ██╗"),
    _createCommentVNode(" ████╗ ████║██╔═══██╗██╔══██╗██╔══██╗██║"),
    _createCommentVNode(" ██╔████╔██║██║   ██║██║  ██║███████║██║"),
    _createCommentVNode(" ██║╚██╔╝██║██║   ██║██║  ██║██╔══██║██║"),
    _createCommentVNode(" ██║ ╚═╝ ██║╚██████╔╝██████╔╝██║  ██║███████╗"),
    _createCommentVNode(" ╚═╝     ╚═╝ ╚═════╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝"),
    _createCommentVNode(""),
    _createCommentVNode(" Bulk manage modals"),
    _createCommentVNode(""),
    _createVNode(_component_b_modal, {
      class: "fit-content-height",
      modelValue: $data.bulkDeleteLeadsConfirmationModalActive,
      "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => (($data.bulkDeleteLeadsConfirmationModalActive) = $event)),
      width: 640,
      scroll: "keep",
      "can-cancel": [ 'escape', 'outside' ],
      "on-cancel": $options.closeBulkDeleteLeadsConfirmModal
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_33, [
          _createElementVNode("div", _hoisted_34, [
            _createVNode(_component_b_icon, {
              icon: "alert-circle-outline",
              "custom-class": "modal-alert"
            }),
            _createElementVNode("p", _hoisted_35, [
              _createTextVNode(_toDisplayString(_ctx.l('manage-leads-bulk-manage/delete-leads-confirm-title')) + " ", 1 /* TEXT */),
              _createElementVNode("strong", null, "(" + _toDisplayString($props.selectedLeads.length) + ")", 1 /* TEXT */)
            ]),
            _createElementVNode("p", _hoisted_36, _toDisplayString(_ctx.l('manage-leads-bulk-manage/delete-leads-confirm-text')), 1 /* TEXT */),
            _createElementVNode("div", _hoisted_37, [
              _createVNode(_component_b_button, {
                type: "is-light",
                rounded: "",
                onClick: _cache[8] || (_cache[8] = $event => ($options.closeBulkDeleteLeadsConfirmModal()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.l('generic/cancel')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_b_button, {
                type: "is-primary",
                rounded: "",
                onClick: _cache[9] || (_cache[9] = $event => ($options.bulkDeleteLeads()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.l('generic/delete')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              })
            ])
          ])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "on-cancel"]),
    _createVNode(_component_b_modal, {
      class: "fit-content-height",
      modelValue: $data.bulkAssignModalActive,
      "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => (($data.bulkAssignModalActive) = $event)),
      width: 640,
      scroll: "keep",
      "can-cancel": [ ]
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_38, [
          _createElementVNode("div", _hoisted_39, [
            _createElementVNode("div", null, [
              _createVNode(_component_b_progress, {
                value: $options.bulkAssignCurrentValue > 0 ? $options.bulkAssignCurrentValue : undefined,
                max: $options.bulkAssignMaxValue,
                "show-value": $options.bulkAssignCurrentValue > 0 ? true : false,
                format: "percent",
                precision: 0
              }, null, 8 /* PROPS */, ["value", "max", "show-value"])
            ])
          ])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue"]),
    _createVNode(_component_b_modal, {
      class: "fit-content-height",
      modelValue: $data.bulkUnassignConfirmModalActive,
      "onUpdate:modelValue": _cache[14] || (_cache[14] = $event => (($data.bulkUnassignConfirmModalActive) = $event)),
      width: 640,
      scroll: "keep",
      "can-cancel": [ 'escape', 'outside' ],
      "on-cancel": $options.closeBulkUnassignConfirmModal
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_40, [
          _createElementVNode("div", _hoisted_41, [
            _createVNode(_component_b_icon, {
              icon: "alert-circle-outline",
              "custom-class": "modal-alert"
            }),
            _createElementVNode("p", {
              class: "modal-text",
              innerHTML: _ctx.l_escaped("manage-leads-bulk-manage/confirm-bulk-unassign-text", $data.bulkUnassignConfirmRef.productName)
            }, null, 8 /* PROPS */, _hoisted_42),
            _createElementVNode("div", _hoisted_43, [
              _createVNode(_component_b_button, {
                type: "is-light",
                rounded: "",
                onClick: _cache[12] || (_cache[12] = $event => ($options.closeBulkUnassignConfirmModal()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.l('generic/cancel')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_b_button, {
                type: "is-primary",
                rounded: "",
                onClick: _cache[13] || (_cache[13] = $event => ($options.confirmBulkUnassignment()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.l('generic/confirm')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              })
            ])
          ])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "on-cancel"])
  ]))
}