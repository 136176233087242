import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "rudolfbox" }
const _hoisted_2 = { class: "modal-inner" }
const _hoisted_3 = { class: "modal-header" }
const _hoisted_4 = { class: "rtitle modal-title" }
const _hoisted_5 = {
  class: "item-list",
  id: "sort-order-modal-item-list"
}
const _hoisted_6 = { class: "item-name" }
const _hoisted_7 = { class: "buttons mb-0" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_button = _resolveComponent("b-button")
  const _component_b_loading = _resolveComponent("b-loading")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.l('setup-ordering/change-type-order', $props.type)), 1 /* TEXT */),
        _createElementVNode("div", null, [
          _createElementVNode("button", {
            class: "delete",
            type: "button",
            onClick: _cache[0] || (_cache[0] = $event => (_ctx.$emit("close")))
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        ($props.items.length > 0)
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList($data.itemsCopy, (item) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "item",
                key: item._id,
                style: _normalizeStyle({ "backgroundColor": $data.itemListDragging ? "#fff" : "" })
              }, [
                ($props.type === "statuses")
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: "color-selection",
                      style: _normalizeStyle(`background-color: ${item.color}`)
                    }, null, 4 /* STYLE */))
                  : _createCommentVNode("v-if", true),
                _createElementVNode("div", _hoisted_6, [
                  ($props.type === "statuses")
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createTextVNode(_toDisplayString(item.text), 1 /* TEXT */)
                      ], 64 /* STABLE_FRAGMENT */))
                    : ($props.type === "questions")
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _createTextVNode(_toDisplayString(item.description), 1 /* TEXT */)
                        ], 64 /* STABLE_FRAGMENT */))
                      : _createCommentVNode("v-if", true)
                ])
              ], 4 /* STYLE */))
            }), 128 /* KEYED_FRAGMENT */))
          : _createCommentVNode("v-if", true)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_b_button, {
          type: "is-primary",
          rounded: "",
          onClick: _cache[1] || (_cache[1] = $event => ($options.save())),
          disabled: $data.loading
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.l('generic/save')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["disabled"]),
        _createVNode(_component_b_button, {
          type: "is-light",
          rounded: "",
          onClick: _cache[2] || (_cache[2] = $event => (_ctx.$emit("close"))),
          disabled: $data.loading
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.l('generic/cancel')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["disabled"])
      ]),
      _createVNode(_component_b_loading, {
        "is-full-page": false,
        modelValue: $data.loading,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($data.loading) = $event)),
        animation: "none"
      }, null, 8 /* PROPS */, ["modelValue"])
    ])
  ]))
}