//
//
// RUDOLF frontend
//
// Nurminen Development Oy Ltd - https://nurminen.dev
//
// For customer: The Rudolf Oy
//
// ALL RIGHTS RESERVED BY CUSTOMER
//
//

//
// File author(s):
//   - Riku Nurminen <riku@nurminen.dev>
//


import { defineStore }          from 'pinia'

import * as crudFactory         from './_crud.js'


const callList     = []
const callListMap  = new Map()


export const useCallListStore = defineStore('callList', {
    state: () => {
        return {
            ...crudFactory.states(),
        }
    },


    getters: {
        ...crudFactory.getters(callList, callListMap),
    },


    actions: {
        ...crudFactory.actions('call-lists', {}, callList, callListMap),
    }

})
