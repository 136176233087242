//
//
// RUDOLF frontend
//
// Nurminen Development Oy Ltd - https://nurminen.dev
//
// For customer: The Rudolf Oy
//
// ALL RIGHTS RESERVED BY CUSTOMER
//
//

//
// File author(s):
//   - Riku Nurminen <riku@nurminen.dev>
//


import { defineStore }  from 'pinia'


const initialState = () => {
    return {
        currentStage: null,
        totalStages: 0,
        currentValue: 0,
        maxValue: 100,
        result: null,
    }
}


export const useExcelImportStore = defineStore('excelImport', {
    state: () => {
        return {
            ...initialState()
        }
    },


    actions: {
        startExcelImport(numStages) {
            this.reset()
            this.totalStages = numStages
        },

        startBulkAssign() {},

        startProgress(numItems) {
            this.currentValue = 0
            this.maxValue = numItems

            if(this.currentStage === null) {
                this.currentStage = 0
            } else {
                this.currentStage = this.currentStage + 1
            }
        },

        progress(processedItems) {
            this.currentValue = processedItems
        },

        finishedProgress() {
        },

        finishedBulkAssign() {},

        finishedExcelImport(result) {
            this.result = result
        },


        reset() {
            Object.assign(this, initialState())
        }

    }
})
