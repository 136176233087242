//
//
// RUDOLF frontend
//
// Nurminen Development Oy Ltd - https://nurminen.dev
//
// For customer: The Rudolf Oy
//
// ALL RIGHTS RESERVED BY CUSTOMER
//
//

//
// File author(s):
//   - Riku Nurminen <riku@nurminen.dev>
//


import { defineStore } from 'pinia'


const initialState = () => {
    return {
        currentValue: 0,
        maxValue: 100,
        finished: false,
    }
}


export const useBulkAssignStore = defineStore('bulkAssign', {
    state: () => {
        return {
            ...initialState()
        }
    },


    actions: {
        startBulkAssign() {
            this.reset()
        },


        startProgress(numItems) {
            this.currentValue = 0
            this.maxValue = numItems
        },

        progress(processedItems) {
            this.currentValue = processedItems
        },

        finishedProgress() {
        },


        finishedBulkAssign(result) {
            this.finished = true
        },


        reset() {
            Object.assign(this, initialState())
        }

    }
})
