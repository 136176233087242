//
//
// RUDOLF frontend
//
// Nurminen Development Oy Ltd - https://nurminen.dev
//
// For customer: The Rudolf Oy
//
// ALL RIGHTS RESERVED BY CUSTOMER
//
//

//
// File author(s):
//   - Riku Nurminen <riku@nurminen.dev>
//


import { defineStore }              from 'pinia'

import { apiRequest }               from '../api_request.js'

import * as crudFactory             from './_crud.js'

import { useAssignmentsStore }      from './assignments.js'
import { useProductsStore }         from './products.js'
import { useQuestionsStore }        from './questions.js'
import { useReportsStore }          from './reports.js'
import { useSalesStore }            from './sales.js'


const api = {
    'create':           [ 'POST',       '/tasks' ],
    'update':           [ 'PATCH',      '/tasks' ],
    'destroy':          [ 'DELETE',     '/tasks' ],
    'bulkDelete':       [ 'DELETE',     '/tasks/bulkdelete' ],
    'assign':           [ 'POST',       '/tasks/assignments' ],
    'createComment':    [ 'POST',       '/tasks/{taskId}/comments' ],
    'uploadTasks':      [ 'POST',       '/tasks/uploadtasks' ],

    // TODO: PHASE OUT WITH OLD UI / APIV1
    'byIds':            [ 'POST',       '/tasks/byIds' ],

    'leadsPopulated':   [ 'GET',        '/leads/populated' ],
}


const tasks     = []
const tasksMap  = new Map()


export const useTasksStore = defineStore('tasks', {
    state: () => {
        return {
            ...crudFactory.states(),

            tasksPerPage: '25',

            leadsPopulated: [],
        }
    },


    getters: {
        ...crudFactory.getters(tasks, tasksMap),


        assignments: (state) => (leadId) => {
            const assignmentsStore = useAssignmentsStore()

            const leadAssignments = []

            const lead = state.byId(leadId)

            if(!lead._id) return leadAssignments

            if(!Array.isArray(lead.products) || lead.products.length === 0) return leadAssignments

            lead.products.forEach(leadProductId => {
                const productAssignment = {
                    productId: leadProductId,
                    assigneeId: 'unassigned'
                }

                const assignment = assignmentsStore.find(lead._id, leadProductId)

                if(assignment._id) {
                    productAssignment.assigneeId = assignment.assignee                
                }

                leadAssignments.push(productAssignment)
            })

            return leadAssignments
        },


        getTasksPerPage() {
            return this.tasksPerPage
        },
    },


    actions: {
        ...crudFactory.actions('tasks', {}, tasks, tasksMap),


        async fetchTest() {
            this.leadsPopulated = await apiRequest(...api.leadsPopulated)
        },


        async create(payload) {
            const { newTask, assignmentsResult } = await apiRequest(...api.create, payload)

            this.syncAddTasks(newTask)
            
            if(assignmentsResult) {
                this.syncAssignments(assignmentsResult)
            }

            return newTask
        },


        syncAddTasks(newTasks) {
            this.PUT(newTasks)

            this.syncProjectHQs(newTasks)
        },


        async update({ id, payload }) {
            const path = api.update[1] + `/${id}`

            const updatedTask = await apiRequest(api.update[0], path, payload)

            this.syncUpdate(updatedTask)

            return updatedTask
        },

        syncUpdate(udatedTask) {
            this.UPDATE(updatedTask)

            this.syncProjectHQs(updatedTask)
        },


        syncProjectHQs(syncTasks) {
            if(!Array.isArray(syncTasks)) syncTasks = [ syncTasks ]

            for(const hqTask of syncTasks) {
                if(hqTask.project_isparent === true) {
                    // If task is "HQ" task, make sure all other tasks in the same
                    // project have HQ flag set to false
                    tasks.forEach(task => {
                        if(task._id === hqTask._id) return
                        if(task.project !== hqTask.project) return
        
                        this.UPDATE({ _id: task._id, project_isparent: false })
                    })
                }
            }
        },


        async assign({ payload, bulkAssign }) {
            if(!bulkAssign) {
                const assignmentResult = await apiRequest(...api.assign, payload)

                this.syncAssignments(assignmentResult)
                this.syncUnAssignments(assignmentResult)
            } else {
                const path = api.assign[1] + '?bulkAssign=1'

                await apiRequest(api.assign[0], path, payload)
            }
        },


        syncAssignments(assignmentResult) {
            const assignmentsStore  = useAssignmentsStore()
            const productsStore     = useProductsStore()
            const questionsStore    = useQuestionsStore()
            const reportsStore      = useReportsStore()

            this.PUT(assignmentResult.updatedTasks)

            assignmentsStore.PUT(assignmentResult.updatedAssignments)
            productsStore.PUT(assignmentResult.updatedProducts)
            questionsStore.PUT(assignmentResult.updatedQuestions)
            reportsStore.PUT(assignmentResult.updatedReports)
        },


        syncUnAssignments(unassignments) {
            const assignmentsStore  = useAssignmentsStore()
            const questionsStore    = useQuestionsStore()
            const reportsStore      = useReportsStore()
            const salesStore        = useSalesStore()

            const { removedProductAssignments, removedUserAssignmentIds } = unassignments

            if(Array.isArray(removedProductAssignments) && removedProductAssignments.length > 0) {
                removedProductAssignments.forEach(removedProductAssignment => {
                    const taskId    = removedProductAssignment.taskId
                    const productId = removedProductAssignment.productId
                    const formId    = removedProductAssignment.formId

                    const taskProduct = { taskId, productId }

                    assignmentsStore.DESTROY_BY_TASK_PRODUCT(taskProduct)
                    reportsStore.DESTROY_BY_TASK_PRODUCT(taskProduct)
                    salesStore.DESTROY_BY_TASK_PRODUCT(taskProduct)
                    questionsStore.DESTROY_BY_TASK_FORM({ taskId, formId })
                })
            }

            if(Array.isArray(removedUserAssignmentIds) && removedUserAssignmentIds.length > 0) {
                assignmentsStore.DESTROY_BY('_id', removedUserAssignmentIds)
            }

        },


        async destroy(leadId) {
            const url = api.destroy[1] + `/${leadId}`

            await apiRequest(api.destroy[0], url)

            this.syncDelete(leadId)
        },


        async bulkDelete(leadIds) {
            const payload = {
                bulkDeleteLeadIds: leadIds
            }

            await apiRequest(...api.bulkDelete, payload)

            this.syncDelete(leadIds)
        },


        syncDelete(deletedTaskIds) {
            const assignmentsStore  = useAssignmentsStore()
            const questionsStore    = useQuestionsStore()
            const reportsStore      = useReportsStore()
            const salesStore        = useSalesStore()

            if(!Array.isArray(deletedTaskIds)) deletedTaskIds = [ deletedTaskIds ]

            this.DESTROY_BY('_id', deletedTaskIds)

            assignmentsStore.DESTROY_BY('task', deletedTaskIds)
            reportsStore.DESTROY_BY('task', deletedTaskIds)
            questionsStore.DESTROY_BY('taskid', deletedTaskIds)
            salesStore.DESTROY_BY('task', deletedTaskIds)

            productsStore.PULL_TASKS(deletedTaskIds)
        },


        async addComment({ taskId, newComment }) {
            const url = api.createComment[1].replace('{taskId}', taskId)

            const payload = {
                newComment
            }

            const updatedTask = await apiRequest(api.createComment[0], url, payload)

            this.UPDATE(updatedTask)
        },


        async uploadTasksExcel(payload) {
            await apiRequest(...api.uploadTasks, payload)
        },


        PULL_PRODUCT(productId) {
            for(const task of tasks) {
                if(!Array.isArray(task.products) || task.products.length === 0) continue
    
                const idx = task.products.findIndex(p => p === productId)
    
                if(idx === -1) continue
    
                task.products.splice(idx, 1)
            }
        },
    
    
        PULL_CONTACT(contactId) {
            for(const task of tasks) {
                if(!Array.isArray(task.contacts) || task.contacts.length === 0) continue
    
                const idx = task.contacts.findIndex(c => c === contactId)
    
                if(idx === -1) continue
    
                task.contacts.splice(idx, 1)
            }
        }

    }

})
