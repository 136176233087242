import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "page-content-inner rudolfbox" }
const _hoisted_2 = { class: "data-page" }
const _hoisted_3 = { class: "rtitle sm" }
const _hoisted_4 = { class: "rtitle sm" }
const _hoisted_5 = { class: "mt-4" }
const _hoisted_6 = { class: "rtitle sm" }
const _hoisted_7 = { class: "is-relative" }
const _hoisted_8 = { class: "upload-assignments-top" }
const _hoisted_9 = { class: "upload-assignments-title" }
const _hoisted_10 = { class: "file-cta" }
const _hoisted_11 = { class: "upload-assignments-product-list" }
const _hoisted_12 = { class: "upload-assignments-content mb-4" }
const _hoisted_13 = { class: "upload-assignments-products-column" }
const _hoisted_14 = { class: "upload-assignments-products-assignee-title" }
const _hoisted_15 = { class: "upload-assignments-assignee-column" }
const _hoisted_16 = { class: "upload-assignments-products-assignee-title" }
const _hoisted_17 = {
  class: "upload-assignments-products-column pt-1 pl-1",
  style: {"overflow":"hidden","padding-right":"10px"}
}
const _hoisted_18 = { class: "upload-assignments-assignee-column" }
const _hoisted_19 = { class: "rudolfbox" }
const _hoisted_20 = { class: "modal-inner has-text-centered" }
const _hoisted_21 = ["innerHTML"]
const _hoisted_22 = { class: "buttons mb-0" }
const _hoisted_23 = { class: "rudolfbox bulk-action-modal" }
const _hoisted_24 = { class: "mb-2" }
const _hoisted_25 = { class: "rtitle lg normalcase has-text-centered mt-3" }
const _hoisted_26 = { class: "rtitle lg normalcase has-text-centered mt-3" }
const _hoisted_27 = {
  key: 0,
  class: "rtitle lg normalcase has-text-centered mt-3"
}
const _hoisted_28 = {
  key: 1,
  class: "rtitle lg normalcase has-text-centered mt-3"
}
const _hoisted_29 = { class: "rtitle lg normalcase has-text-centered mt-3" }
const _hoisted_30 = { key: 0 }
const _hoisted_31 = { key: 1 }
const _hoisted_32 = { class: "results-container" }
const _hoisted_33 = { class: "results-row-number" }
const _hoisted_34 = { class: "results-row-content" }
const _hoisted_35 = { class: "results-container" }
const _hoisted_36 = { class: "results-row-content" }
const _hoisted_37 = { class: "results-row-subcontent has-text-danger" }
const _hoisted_38 = { class: "results-row-content" }
const _hoisted_39 = { class: "results-row-subcontent has-text-danger" }
const _hoisted_40 = {
  key: 2,
  class: "buttons mt-4 mb-0"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_button = _resolveComponent("b-button")
  const _component_b_icon = _resolveComponent("b-icon")
  const _component_b_upload = _resolveComponent("b-upload")
  const _component_b_field = _resolveComponent("b-field")
  const _component_b_checkbox = _resolveComponent("b-checkbox")
  const _component_b_dropdown_item = _resolveComponent("b-dropdown-item")
  const _component_b_dropdown = _resolveComponent("b-dropdown")
  const _component_b_tag = _resolveComponent("b-tag")
  const _component_b_loading = _resolveComponent("b-loading")
  const _component_b_input = _resolveComponent("b-input")
  const _component_b_modal = _resolveComponent("b-modal")
  const _component_b_step_item = _resolveComponent("b-step-item")
  const _component_b_steps = _resolveComponent("b-steps")
  const _component_b_progress = _resolveComponent("b-progress")
  const _component_b_tab_item = _resolveComponent("b-tab-item")
  const _component_b_tabs = _resolveComponent("b-tabs")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "data-content",
        onClick: _cache[0] || (_cache[0] = $event => ($data.deleteAllDataConfirmModal = true))
      }, [
        _createElementVNode("div", null, [
          _createElementVNode("h4", _hoisted_3, _toDisplayString(_ctx.l('setup-data/clean-database')), 1 /* TEXT */)
        ]),
        _createElementVNode("div", null, [
          _createVNode(_component_b_button, {
            class: "icon-button data-button",
            type: "is-light",
            size: "is-large",
            "icon-right": "trash-can-outline",
            disabled: $data.loading
          }, null, 8 /* PROPS */, ["disabled"])
        ])
      ]),
      _createElementVNode("div", {
        class: "data-content",
        onClick: _cache[1] || (_cache[1] = $event => ($options.exportContactsXlsx()))
      }, [
        _createElementVNode("div", null, [
          _createElementVNode("h4", _hoisted_4, _toDisplayString(_ctx.l('setup-data/export-contacts')), 1 /* TEXT */)
        ]),
        _createElementVNode("div", null, [
          _createVNode(_component_b_button, {
            class: "icon-button data-button",
            type: "is-light",
            size: "is-large",
            "icon-right": "tray-arrow-up",
            disabled: $data.loading
          }, null, 8 /* PROPS */, ["disabled"])
        ])
      ]),
      _createElementVNode("div", {
        class: "data-content last is-align-items-flex-start",
        onClick: _cache[7] || (_cache[7] = () => {})
      }, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("h4", _hoisted_6, _toDisplayString(_ctx.l('setup-data/import-leads')), 1 /* TEXT */)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", null, [
              _createElementVNode("h5", _hoisted_9, _toDisplayString(_ctx.l('setup-data/upload-assignments')), 1 /* TEXT */)
            ]),
            _createElementVNode("div", null, [
              _createVNode(_component_b_field, {
                class: _normalizeClass(["file is-primary", {'has-name': !!$data.rawLeadsFile}]),
                "custom-class": "smallfield"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_upload, {
                    class: "file-label",
                    modelValue: $data.rawLeadsFile,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($data.rawLeadsFile) = $event)),
                    accept: ".xlsx",
                    disabled: $data.loading
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_10, [
                        _createVNode(_component_b_icon, {
                          class: "file-icon",
                          icon: "paperclip",
                          "custom-size": "mdi-36px"
                        })
                      ])
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["modelValue", "disabled"])
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["class"])
            ])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("h6", _hoisted_14, _toDisplayString(_ctx.l('setup-data/assigned-products')), 1 /* TEXT */)
              ]),
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("h6", _hoisted_16, _toDisplayString(_ctx.l('setup-data/assignee-of-products')), 1 /* TEXT */)
              ])
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productsStore.all, (product) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "upload-assignments-content mb-4",
                key: product._id
              }, [
                _createElementVNode("div", _hoisted_17, [
                  _createVNode(_component_b_checkbox, {
                    type: "is-dark",
                    modelValue: $data.uploadProductAssignments,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($data.uploadProductAssignments) = $event)),
                    "native-value": product._id,
                    onClick: $event => ($options.productAssignmentClicked(product._id)),
                    disabled: !$data.rawLeadsFile
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(product.name), 1 /* TEXT */)
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "native-value", "onClick", "disabled"])
                ]),
                _createElementVNode("div", _hoisted_18, [
                  _createVNode(_component_b_dropdown, {
                    ref_for: true,
                    ref: "user_assignment_dropdown_" + product._id,
                    onChange: (userId) => $options.uploadLeadsSelectUserAssignment(userId, product._id),
                    position: "is-bottom-right",
                    disabled: !$data.rawLeadsFile,
                    "aria-role": "list"
                  }, {
                    trigger: _withCtx(({ active }) => [
                      _createVNode(_component_b_field, {
                        label: _ctx.l("generic/assigned-to"),
                        "label-position": "on-border"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_b_button, {
                            label: $options.uploadAssignmentsProductAssignee(product._id),
                            type: "is-select",
                            size: "is-small",
                            expanded: "",
                            rounded: "",
                            "icon-right": active ? "menu-up" : "menu-down"
                          }, null, 8 /* PROPS */, ["label", "icon-right"])
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label"])
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_b_dropdown_item, {
                        "aria-role": "listitem",
                        value: "unassigned"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.l('generic/unassigned')), 1 /* TEXT */)
                        ]),
                        _: 1 /* STABLE */
                      }),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.usersStore.all, (user) => {
                        return (_openBlock(), _createBlock(_component_b_dropdown_item, {
                          key: user._id,
                          "aria-role": "listitem",
                          value: user._id
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(user.fullName), 1 /* TEXT */)
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"]))
                      }), 128 /* KEYED_FRAGMENT */))
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onChange", "disabled"])
                ])
              ]))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _createVNode(_component_b_field, { style: {"font-weight":"500","font-size":"0.929rem"} }, {
            default: _withCtx(() => [
              _createVNode(_component_b_checkbox, {
                modelValue: $data.createDuplicates,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($data.createDuplicates) = $event))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.l('setup-data/create-duplicates')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["modelValue"])
            ]),
            _: 1 /* STABLE */
          }),
          ($data.rawLeadsFile)
            ? (_openBlock(), _createBlock(_component_b_field, {
                key: 0,
                class: "mt-3"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_tag, {
                    attached: "",
                    closable: "",
                    onClose: _cache[5] || (_cache[5] = $event => ($data.rawLeadsFile = null))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($data.rawLeadsFile.name), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }))
            : _createCommentVNode("v-if", true),
          _createVNode(_component_b_button, {
            class: "mt-5",
            type: "is-primary",
            rounded: "",
            onClick: _cache[6] || (_cache[6] = $event => ($options.uploadLeads())),
            disabled: !$data.rawLeadsFile,
            loading: $data.loading
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.l('setup-data/upload-leads')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["disabled", "loading"])
        ])
      ]),
      _createVNode(_component_b_loading, {
        "is-full-page": false,
        modelValue: $data.loading,
        "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => (($data.loading) = $event))
      }, null, 8 /* PROPS */, ["modelValue"])
    ]),
    _createVNode(_component_b_modal, {
      class: "fit-content-height",
      modelValue: $data.deleteAllDataConfirmModal,
      "onUpdate:modelValue": _cache[12] || (_cache[12] = $event => (($data.deleteAllDataConfirmModal) = $event)),
      width: 640,
      scroll: "keep",
      "can-cancel": [ 'escape', 'outside' ]
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_19, [
          _createElementVNode("div", _hoisted_20, [
            _createVNode(_component_b_icon, {
              icon: "alert-circle-outline",
              "custom-class": "modal-alert"
            }),
            _createElementVNode("p", {
              class: "modal-text",
              innerHTML: _ctx.l_escaped("setup-data/clean-db-confirm-text")
            }, null, 8 /* PROPS */, _hoisted_21),
            _createVNode(_component_b_field, { class: "mb-6" }, {
              default: _withCtx(() => [
                _createVNode(_component_b_input, {
                  modelValue: $data.deleteAllDataConfirmText,
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => (($data.deleteAllDataConfirmText) = $event)),
                  placeholder: "",
                  required: ""
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            }),
            _createElementVNode("div", _hoisted_22, [
              _createVNode(_component_b_button, {
                type: "is-light",
                rounded: "",
                onClick: _cache[10] || (_cache[10] = $event => ($data.deleteAllDataConfirmModal = false))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.l('generic/cancel')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_b_button, {
                type: "is-primary",
                rounded: "",
                onClick: _cache[11] || (_cache[11] = $event => ($options.eraseAllData())),
                disabled: $data.deleteAllDataConfirmText !== "erase all data"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.l('setup-data/erase')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["disabled"])
            ])
          ])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue"]),
    _createVNode(_component_b_modal, {
      modelValue: $data.excelImportLeadsModal,
      "onUpdate:modelValue": _cache[15] || (_cache[15] = $event => (($data.excelImportLeadsModal) = $event)),
      width: 640,
      scroll: "keep",
      "can-cancel": [ ]
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_23, [
          _createElementVNode("div", {
            style: _normalizeStyle([{"width":"100%"}, { "height": $options.excelImportResult ? "100%" : "auto" }])
          }, [
            (!$options.excelImportResult)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createElementVNode("section", _hoisted_24, [
                    _createVNode(_component_b_steps, {
                      modelValue: $options.excelImportCurrentStage,
                      "onUpdate:modelValue": _cache[13] || (_cache[13] = $event => (($options.excelImportCurrentStage) = $event)),
                      "has-navigation": false
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_b_step_item, {
                          step: "1",
                          type: "is-success"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("h5", _hoisted_25, _toDisplayString(_ctx.l('setup-data/parsing-excel-file')) + "...", 1 /* TEXT */)
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_b_step_item, {
                          step: "2",
                          type: "is-success"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("h5", _hoisted_26, _toDisplayString(_ctx.l('setup-data/importing-leads')) + "..", 1 /* TEXT */)
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_b_step_item, {
                          step: "3",
                          type: "is-success"
                        }, {
                          default: _withCtx(() => [
                            ($options.excelImportTotalStages === 3)
                              ? (_openBlock(), _createElementBlock("h5", _hoisted_27, _toDisplayString(_ctx.l('setup-data/geocoding-leads')) + "...", 1 /* TEXT */))
                              : _createCommentVNode("v-if", true),
                            ($options.excelImportTotalStages === 4)
                              ? (_openBlock(), _createElementBlock("h5", _hoisted_28, _toDisplayString(_ctx.l('setup-data/setting-assignments')) + "...", 1 /* TEXT */))
                              : _createCommentVNode("v-if", true)
                          ]),
                          _: 1 /* STABLE */
                        }),
                        ($options.excelImportTotalStages === 4)
                          ? (_openBlock(), _createBlock(_component_b_step_item, {
                              key: 0,
                              step: "4",
                              type: "is-success"
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("h5", _hoisted_29, _toDisplayString(_ctx.l('setup-data/geocoding-leads')) + "...", 1 /* TEXT */)
                              ]),
                              _: 1 /* STABLE */
                            }))
                          : _createCommentVNode("v-if", true)
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["modelValue"])
                  ]),
                  ($options.excelImportCurrentStage === null || $options.excelImportCurrentStage === 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                        _createVNode(_component_b_progress)
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_31, [
                        _createVNode(_component_b_progress, {
                          value: $options.excelImportCurrentValue > 0 ? $options.excelImportCurrentValue : undefined,
                          max: $options.excelImportMaxValue,
                          "show-value": $options.excelImportCurrentValue > 0 ? true : false,
                          format: "raw",
                          precision: 0
                        }, {
                          default: _withCtx(() => [
                            ($options.excelImportCurrentStage > 0 && $options.excelImportCurrentValue > 0)
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                  _createTextVNode(_toDisplayString(_ctx.l('generic/lead').toLowerCase()) + " " + _toDisplayString($options.excelImportCurrentValue) + " / " + _toDisplayString($options.excelImportMaxValue), 1 /* TEXT */)
                                ], 64 /* STABLE_FRAGMENT */))
                              : _createCommentVNode("v-if", true)
                          ]),
                          _: 1 /* STABLE */
                        }, 8 /* PROPS */, ["value", "max", "show-value"])
                      ]))
                ], 64 /* STABLE_FRAGMENT */))
              : ($options.excelImportResult)
                ? (_openBlock(), _createBlock(_component_b_tabs, {
                    key: 1,
                    position: "is-centered"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_b_tab_item, {
                        label: "Successful (" + $options.excelImportSuccessCount + ")"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_32, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.excelImportResult.succeededLeads, (lead, idx) => {
                              return (_openBlock(), _createElementBlock("div", {
                                class: "results-row",
                                key: lead._id
                              }, [
                                _createElementVNode("div", _hoisted_33, _toDisplayString(idx + 1) + ".", 1 /* TEXT */),
                                _createElementVNode("div", _hoisted_34, [
                                  _createVNode(_component_b_icon, {
                                    size: "is-small",
                                    icon: "check-circle",
                                    type: "is-success"
                                  }),
                                  _createTextVNode(" " + _toDisplayString(lead.name), 1 /* TEXT */)
                                ])
                              ]))
                            }), 128 /* KEYED_FRAGMENT */))
                          ])
                        ]),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["label"]),
                      _createVNode(_component_b_tab_item, {
                        label: "Errors (" + $options.excelImportErrorsCount + ")"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_35, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.excelImportResult.erroredContacts, (erroredContact, idx) => {
                              return (_openBlock(), _createElementBlock("div", {
                                class: "results-row",
                                key: "errored_contact_" + idx
                              }, [
                                _createElementVNode("div", _hoisted_36, [
                                  _createVNode(_component_b_icon, {
                                    size: "is-small",
                                    icon: "alert-circle",
                                    type: "is-danger"
                                  }),
                                  _createTextVNode(" " + _toDisplayString(_ctx.l('generic/contact').toUpperCase()) + ": " + _toDisplayString(erroredContact.contact.firstname) + " " + _toDisplayString(erroredContact.contact.lastname) + " " + _toDisplayString(erroredContact.contact.email), 1 /* TEXT */),
                                  _createElementVNode("div", _hoisted_37, _toDisplayString(erroredContact.error), 1 /* TEXT */)
                                ])
                              ]))
                            }), 128 /* KEYED_FRAGMENT */)),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.excelImportResult.erroredLeads, (erroredLead, idx) => {
                              return (_openBlock(), _createElementBlock("div", {
                                class: "results-row",
                                key: erroredLead.lead._id
                              }, [
                                _createElementVNode("div", _hoisted_38, [
                                  _createVNode(_component_b_icon, {
                                    size: "is-small",
                                    icon: "alert-circle",
                                    type: "is-danger"
                                  }),
                                  _createTextVNode(" " + _toDisplayString(_ctx.l('generic/lead').toUpperCase()) + ": " + _toDisplayString(erroredLead.lead.name), 1 /* TEXT */),
                                  _createElementVNode("div", _hoisted_39, _toDisplayString(erroredLead.error), 1 /* TEXT */)
                                ])
                              ]))
                            }), 128 /* KEYED_FRAGMENT */))
                          ])
                        ]),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["label"])
                    ]),
                    _: 1 /* STABLE */
                  }))
                : _createCommentVNode("v-if", true),
            ($options.excelImportResult)
              ? (_openBlock(), _createElementBlock("div", _hoisted_40, [
                  _createVNode(_component_b_button, {
                    type: "is-light",
                    rounded: "",
                    onClick: _cache[14] || (_cache[14] = $event => ($options.closeBulkImportLeadsModal()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.l('generic/close')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  })
                ]))
              : _createCommentVNode("v-if", true)
          ], 4 /* STYLE */)
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue"])
  ]))
}