//
//
// RUDOLF frontend
//
// Nurminen Development Oy Ltd - https://nurminen.dev
//
// For customer: The Rudolf Oy
//
// ALL RIGHTS RESERVED BY CUSTOMER
//
//

//
// File author(s):
//   - Riku Nurminen <riku@nurminen.dev>
//


import { mapStores, mapState }          from 'pinia'
import { mapWritableState }             from 'pinia'

import * as appHelpers                  from '../../js/helpers.js'

import { useLeadsStore }                from '../../js/store/leads.js'
import { useLocalizationsStore }        from '../../js/store/localizations.js'
import { useProductsStore }             from '../../js/store/products.js'
import { useRootStore }                 from '../store/root.js'
import { useSettingsStore }             from '../../js/store/settings.js'
import { useStatusesStore }             from '../../js/store/statuses.js'
import { useUsersStore }                from '../../js/store/users.js'


import countryList                      from '../country_list.js'


//
// Shared filtering base component for manage lead table column quick filters and
// advanced filtering tool.
//

export default {
  data() {
    return {
      filterable: {
        products:           [],
        projects:           [],
        assignments:        [],
        statuses:           [],
        addressCountries:   [],
        tags:               [],
        types:              [],
        customerTypes:      [],
      },

      filterableSearched: {
        products:           [],
        projects:           [],
        assignments:        [],
        statuses:           [],
        addressCountries:   [],
        tags:               [],
        types:              [],
        customerTypes:      [],
      },

      //
      // "type" = simple text filter, multi filter with autocomplete dropdown, min/max filter
      //
      // "field" and "data" = mapping of table columns from RTable component to filtering props
      // in this component
      //
      // "dataProp" = the property to use as display text for the tags, for "multiTags" type entries
      //
      // "filterOptionEnabled" - any-of / all-of switch
      //
      // "desc" = description text to use in various places in column quick filters and advanced filter tool
      //
      filters: [
        { type: 'simpleText',    field: 'leadname',        data: 'leadName',             dataProp: 'name',         desc: 'leads' },
        { type: 'multiTags',     field: 'product',         data: 'products',             dataProp: 'name',         desc: 'products',             filterOptionEnabled: true,     openOnFocus: true  },
        { type: 'multiTags',     field: 'belongs_to',      data: 'projects',             dataProp: '',             desc: 'project/belongs to',   filterOptionEnabled: false,    openOnFocus: true  },
        { type: 'multiTags',     field: 'assignment',      data: 'assignments',          dataProp: 'fullName',     desc: 'assignments',          filterOptionEnabled: true,     openOnFocus: true },
        { type: 'statusFilter',  field: 'status',          data: 'statuses',             dataProp: 'text',         desc: 'statuses',             filterOptionEnabled: true,     openOnFocus: true  },
        { type: 'minMax',        field: 'sales_ytd',       data: 'salesYtd',             dataProp: '',             desc: 'Sales YTD' },
        { type: 'minMax',        field: 'sales_ytdvs',     data: 'salesYtdVs',           dataProp: '',             desc: 'Sales YTD vs Target' },
        { type: 'simpleText',    field: 'full_address',    data: 'addressFullAddress',   dataProp: '',             desc: 'full addresses' },
        { type: 'simpleText',    field: 'address',         data: 'addressStreet',        dataProp: '',             desc: 'street addresses' },
        { type: 'simpleText',    field: 'city',            data: 'addressCity',          dataProp: '',             desc: 'cities' },
        { type: 'simpleText',    field: 'state',           data: 'addressState',         dataProp: '',             desc: 'states' },
        { type: 'multiTags',     field: 'country',         data: 'addressCountries',     dataProp: 'displayName',  desc: 'countries',            filterOptionEnabled: false,    openOnFocus: true  },
        { type: 'simpleText',    field: 'address_misc',    data: 'addressMisc',          dataProp: '',             desc: 'additional addresses' },
        { type: 'simpleText',    field: 'phone_numbers',   data: 'phoneNumber',          dataProp: '',             desc: 'phone numbers' },
        { type: 'simpleText',    field: 'website',         data: 'website',              dataProp: '',             desc: 'websites' },
        { type: 'simpleText',    field: 'email',           data: 'email',                dataProp: '',             desc: 'emails' },
        { type: 'simpleText',    field: 'contacts',        data: 'contact',              dataProp: '',             desc: 'contacts' },
        { type: 'simpleText',    field: 'workzone1',       data: 'workzone1',            dataProp: '',             desc: 'work zones 1' },
        { type: 'simpleText',    field: 'workzone2',       data: 'workzone2',            dataProp: '',             desc: 'work zones 2' },
        { type: 'simpleText',    field: 'workzone3',       data: 'workzone3',            dataProp: '',             desc: 'work zones 3' },
        { type: 'simpleText',    field: 'workzone4',       data: 'workzone4',            dataProp: '',             desc: 'work zones 4' },
        { type: 'simpleText',    field: 'region1',         data: 'region1',              dataProp: '',             desc: 'regions' },
        { type: 'multiTags',     field: 'tags',            data: 'tags',                 dataProp: '',             desc: 'tags',                 filterOptionEnabled: true,     openOnFocus: true },
        { type: 'multiTags',     field: 'type',            data: 'types',                dataProp: '',             desc: 'types',                filterOptionEnabled: false,    openOnFocus: true },
        { type: 'multiTags',     field: 'customer_type',   data: 'customerTypes',        dataProp: '',             desc: 'customer types',       filterOptionEnabled: false,    openOnFocus: true },
        { type: 'simpleText',    field: 'last_comment',    data: 'lastComment',          dataProp: '',             desc: 'last comments' }
      ],

      // Simple text filters input debounce
      textInputDebounce:  250, // ms
      textInputTimer:     null,

      debounceLoading: {}
    }
  },


  mounted() {
    const initLoop = setInterval(() => {
      if(this.appLoading) return

      clearInterval(initLoop)

      this.setFilterLabels()

    }, 100)
  },


  methods: {
    setFilterLabels() {
      for(const filter of this.filters) {
        const label = this.getFilterLabel(filter)

        if(typeof label === 'string') filter.desc = label
      }

    },

    getFilterLabel(filter) {
      let field = filter.field

      switch(field) {
        case 'belongs_to':    field = 'project'; break
        case 'address_misc':  field = 'addressmisc'; break
        case 'workzone1':     field = 'zone1'; break
        case 'workzone2':     field = 'zone2'; break
        case 'workzone3':     field = 'zone3'; break
        case 'workzone4':     field = 'zone4'; break
        case 'region1':       field = 'region'; break
        case 'type':          field = 'projecttype'; break
        case 'customer_type': field = 'customertype'; break
      }

      // Search user configurable labels
      let label = this.settingsStore.label(field)

      if(label) return label

      // If no user configure label found, search from localized fields
      if(`filtering/field-${field}` in this.localizationsStore.fields) {
        return this.l(`filtering/field-${field}`)
      }

      // No user label nor localized field found, return default filter description
      return filter.desc
    },


    getFilterableItems(itemType, text, field = 'name') {
      this.filterableSearched[itemType] = this.filterable[itemType].filter(item => {
          if(field) {
            return item[field]
              .toString()
              .toLowerCase()
              .indexOf(text.toLowerCase()) >= 0
          } else {
            return item
              .toString()
              .toLowerCase()
              .indexOf(text.toLowerCase()) >= 0
          }
      })
    },


    addFilteredItem(itemType, value) {
      this.filtering[itemType].push(value)
      this.refreshFilterables(null, itemType)
    },

    removeFilteredItem(itemType, value) {
      this.leadsStore.removeFilter({ filter: itemType, value })
      this.refreshFilterables(null, itemType)
    },


    trimEmptyArrayItems(element) {
      return (
        element !== null &&
        element !== undefined &&
        element !== ''
      )
    },


    resetQuestionFilter(templateId) {
      const idx = this.filtering.questionFilters.findIndex(f => f.templateId === templateId)

      if(idx === -1) return

      this.filtering.questionFilters.splice(idx, 1)
    },


    clearFilterMinMax(filter, minMax, resetIfEmpty = true) {
      if(minMax === 'min') this.filtering[filter][0] = undefined
      if(minMax === 'max') this.filtering[filter][1] = undefined

      if(resetIfEmpty && this.filtering[filter][0] === undefined && this.filtering[filter][1] === undefined) {
        this.resetFilters(filter)
      }
    },


    clearQuestionFilterMinMax(templateId, minMax, resetIfEmpty = true) {
      const questionFilter = this.filtering.questionFilters.find(f => f.templateId === templateId)

      if(!questionFilter) return

      if(minMax === 'min') questionFilter.range[0] = undefined
      if(minMax === 'max') questionFilter.range[1] = undefined

      if(resetIfEmpty && questionFilter.range[0] === undefined && questionFilter.range[1] === undefined) {
        this.resetQuestionFilter(templateId)
      }
    },


    getFilterOptionText(filter) {
      return this.filtering.filterOption[filter] === 'any-of' ? this.l('filtering/anyof-above') : this.l('filtering/allof-above')
    },


    products() {
      return this.productsAll.slice().sort(appHelpers.resourceSortFunction)
    },


    projects() {
      const projectsList = []

      for(const lead of this.leads) {
        // Limit projects by product filter
        if(this.filtering.products.length > 0 && lead.products.length === 0) continue

        if(this.filtering.products.length > 0) {
          let found = false

          for(let productFilter of this.filtering.products) {
            if(typeof productFilter !== 'object' && !productFilter?._id) continue

            if(lead.products.includes(productFilter._id)) {
              found = true
              break
            }
          }

          if(!found) continue
        }

        if(typeof lead.project === 'string') {
          projectsList.push(lead.project.trim())
        }
      }

      return [...new Set(projectsList)].filter(this.trimEmptyArrayItems).sort(appHelpers.stringSortCaseInsensitive)
    },


    assignments() {
      const userList = this.users.map(u => { return { '_id': u._id, 'fullName': u.fullName } } )

      userList.sort(appHelpers.sortAlphabetical('fullName'))

      userList.unshift({
        _id: 'unassigned',
        fullName: 'Unassigned'
      })

      return userList
    },


    statuses() {
      const statusesRes = []

      const filteredProductIds = this.filtering.products.map(p => p._id)

      for(const product of this.productsAll) {
        if(!Array.isArray(product.statuses)) continue

        if(filteredProductIds.length > 0 && !filteredProductIds.includes(product._id)) continue

        for(const status of product.statuses) {
          if(!status?._id) continue

          statusesRes.push({
            _id:          status._id,
            text:         status.text,
            color:        status.color,
            productName:  product.name,
            productOrder: product?.sort_order_id,
            statusOrder:  status?.sort_order_id
          })
        }
      }

      statusesRes.sort((a, b) => {
        const productOrderA = Number.isInteger(a.productOrder) ? a.productOrder : 999999
        const productOrderB = Number.isInteger(b.productOrder) ? b.productOrder : 999999

        const statusOrderA = Number.isInteger(a.statusOrder) ? a.statusOrder : 999999
        const statusOrderB = Number.isInteger(b.statusOrder) ? b.statusOrder : 999999

        return productOrderA - productOrderB || statusOrderA - statusOrderB
      })

      return statusesRes
    },


    addressCountries() {
      return countryList.map(country => {
        return {
          displayName: `${country.finName} / ${country.engName} / ${country.gerName} (${country.code})`,
          code: country.code
        }
      })
    },

    tags() {
      const tagsList = []

      for(const lead of this.leads) {
        // Limit tags by product filter
        if(this.filtering.products.length > 0 && lead.products.length === 0) continue

        if(this.filtering.products.length > 0) {
          let found = false

          for(let productFilter of this.filtering.products) {
            if(typeof productFilter !== 'object' && !productFilter?._id) continue

            if(lead.products.includes(productFilter._id)) {
              found = true
              break
            }
          }

          if(!found) continue
        }

        if(Array.isArray(lead.tags) && lead.tags.length > 0) {
          tagsList.push(...lead.tags.map(t => t.trim()).filter(this.trimEmptyArrayItems))
        }

      }

      return [...new Set(tagsList)].sort(appHelpers.stringSortCaseInsensitive)
    },

    types() {
      const projectTypesList = []

      for(const lead of this.leads) {
        // Limit projecttypes by product filter
        if(this.filtering.products.length > 0 && lead.products.length === 0) continue

        if(this.filtering.products.length > 0) {
          let found = false

          for(let productFilter of this.filtering.products) {
            if(typeof productFilter !== 'object' && !productFilter?._id) continue

            if(lead.products.includes(productFilter._id)) {
              found = true
              break
            }
          }

          if(!found) continue
        }

        if(typeof lead.projecttype === 'string') {
          projectTypesList.push(lead.projecttype.trim())
        }
      }

      return [...new Set(projectTypesList)].filter(this.trimEmptyArrayItems).sort(appHelpers.stringSortCaseInsensitive)
    },

    customerTypes() {
      const customerTypesList = []

      for(const lead of this.leads) {
        // Limit customertypes by product filter
        if(this.filtering.products.length > 0 && lead.products.length === 0) continue

        if(this.filtering.products.length > 0) {
          let found = false

          for(let productFilter of this.filtering.products) {
            if(typeof productFilter !== 'object' && !productFilter?._id) continue

            if(lead.products.includes(productFilter._id)) {
              found = true
              break
            }
          }

          if(!found) continue
        }

        if(typeof lead.customertype === 'string') {
          customerTypesList.push(lead.customertype.trim())
        }
      }

      return [...new Set(customerTypesList)].filter(this.trimEmptyArrayItems).sort(appHelpers.stringSortCaseInsensitive)
    },



    refreshFilterables(field, filter = null) {
      if(!filter) filter = this.filters.find(df => df.field === field)?.filter

      this.filterable[filter] = this[filter]().filter(item => {
        const simpleFilters = [ 'products', 'projects', 'tags', 'types', 'customerTypes' ]

        const filtered = this.filtering[filter]

        if(simpleFilters.includes(filter)) {
          // Exclude already filtered items
          return !filtered.includes(item)
        } else if(['statuses', 'assignments'].includes(filter)) {
          // Exclude by already filtered item by id
          let filteredIds = filtered.map(i => i?._id)

          filteredIds = filteredIds.filter(i => i !== undefined)
  
          if(filteredIds.length > 0) {
            return !filteredIds.includes(item._id)
          }

          return true
        } else if(['addressCountries'].includes(filter)) {
          // Exclude by already filtered countries
          let filteredCountries = filtered.map(i => i.code)
  
          if(filteredCountries.length > 0) {
            return !filteredCountries.includes(item.code)
          }

          return true
        } else {
          return true
        }
      })

      this.filterableSearched[filter] = this.filterable[filter]
    },

    truncateText(text) {
      return appHelpers.textEllipsis(text, 15)
    },

    debounceTextInput() {
      // Delayed text input event send
      // Otherwise the simple text filtering can get too laggy
      return (filter, value) => {
        this.debounceLoading[filter] = true
        clearTimeout(this.textInputTimer)
        this.textInputTimer = setTimeout(() => {
          this.filtering[filter] = value
          this.debounceLoading[filter] = false
        }, this.textInputDebounce)
      }
    },


    addQuestionFilter(templateId) {
      const newQuestionFilter = {
        templateId,
        range: [],
        text: '',
        selectedOptions: [],
        selectedType: 'any-of'
      }

      this.filtering.questionFilters.push(newQuestionFilter)

      return newQuestionFilter
    },

    debounceQuestionTextInput() {
      // Delayed text input event send
      // Otherwise the questions simple text filtering can get too laggy
      return (templateId, value, removeFilterIfEmpty = false) => {
        this.debounceLoading[templateId] = true
        clearTimeout(this.textInputTimer)
        this.textInputTimer = setTimeout(() => {
          let questionFilter = this.filtering.questionFilters.find(qf => qf.templateId === templateId)

          if(!questionFilter) {
            questionFilter = this.addQuestionFilter(templateId)
          } else {
            // Reset advanced filter if inputting text in column quick filter
            questionFilter.selectedOptions = []
            questionFilter.selectedType = 'any-of'
          }

          questionFilter.text = value

          this.debounceLoading[templateId] = false

          if(removeFilterIfEmpty && value === '') {
            this.resetQuestionFilter(templateId)
          }
        }, this.textInputDebounce)
      }
    },

    debounceMinMaxInput() {
      // Delayed range input event send
      // Otherwise the questions min/max filtering can get too laggy
      return (filter, minMax, value) => {
        this.debounceLoading[filter] = true
        clearTimeout(this.textInputTimer)
        this.textInputTimer = setTimeout(() => {
          if(minMax === 'min') {
            this.filtering[filter][0] = value
          } else if(minMax === 'max') {
            this.filtering[filter][1] = value
          }

          if(value === '') this.clearFilterMinMax(filter, minMax, true)

          this.debounceLoading[filter] = false
        }, this.textInputDebounce)
      }
    },
  
    debounceQuestionMinMaxInput() {
      // Delayed range input event send
      // Otherwise the questions min/max filtering can get too laggy
      return (templateId, minMax, value, removeFilterIfEmpty = false) => {
        this.debounceLoading[templateId] = true
        clearTimeout(this.textInputTimer)
        this.textInputTimer = setTimeout(() => {
          let questionFilter = this.filtering.questionFilters.find(qf => qf.templateId === templateId)

          if(!questionFilter) {
            questionFilter = this.addQuestionFilter(templateId)
          }

          if(minMax === 'min') {
            questionFilter.range[0] = value
          } else if(minMax === 'max') {
            questionFilter.range[1] = value
          }

          this.debounceLoading[templateId] = false

          if(removeFilterIfEmpty && value === '') {
            this.clearQuestionFilterMinMax(templateId, minMax, true)
          }
        }, this.textInputDebounce)
      }
    },
  },


  computed: {
    ...mapStores(useLeadsStore, useUsersStore, useSettingsStore, useProductsStore, useLocalizationsStore),

    ...mapState(useProductsStore,  { productsAll: 'all' }),
    ...mapState(useStatusesStore,  { statusesAll: 'all' }),
    ...mapState(useLeadsStore,     { leads: 'all' }),
    ...mapState(useLeadsStore,     { leadsFiltered: 'filtered' }),
    ...mapState(useUsersStore,     { users: 'all' }),
    ...mapState(useLeadsStore,     [ 'filtering', 'resetFilters' ]),
    ...mapState(useLocalizationsStore, [ 'l', 'activeLocalizationCode', 'activeDateTimeLocalizationCode' ]),
    ...mapState(useLocalizationsStore, { localizationUpdated: 'updateToggler' }),
    ...mapState(useSettingsStore, { settingsUpdated: 'updateToggler' }),
    ...mapState(useRootStore,      [ 'appLoading' ]),

    ...mapWritableState(useLeadsStore, ['filtering' ])

  },


  watch: {
    settingsUpdated() { this.setFilterLabels() },

    localizationUpdated() { this.setFilterLabels() }

  },

}
