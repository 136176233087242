//
//
// RUDOLF frontend
//
// Nurminen Development Oy Ltd - https://nurminen.dev
//
// For customer: The Rudolf Oy
//
// ALL RIGHTS RESERVED BY CUSTOMER
//
//

//
// File author(s):
//   - Riku Nurminen <riku@nurminen.dev>
//


import { defineStore }          from 'pinia'

import * as crudFactory         from './_crud.js'


const todos     = []
const todosMap  = new Map()


export const useTodosStore = defineStore('todos', {
    state: () => {
        return {
            ...crudFactory.states(),
        }
    },


    getters: {
        ...crudFactory.getters(todos, todosMap),
    },


    actions: {
        ...crudFactory.actions('todos', {}, todos, todosMap),

        PULL_LEADS(deletedLeadIds) {
            let changed = false

            for(const todo of todos) {
                for(const deletedLeadId of deletedLeadIds) {
                    if(!Array.isArray(todo.leads) || todo.leads.length === 0) continue
    
                    const idx = todo.leads.findIndex(todoLeadId => todoLeadId === deletedLeadId)
        
                    if(idx === -1) continue
        
                    todo.leads.splice(idx, 1)
                    changed = true
                }
            }

            if(changed && this.reactivityEnabled) this.updateToggler++
        },
    }

})
