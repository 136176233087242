import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "page-content-inner is-relative" }
const _hoisted_2 = { class: "overview-columns" }
const _hoisted_3 = { class: "overview-left" }
const _hoisted_4 = { class: "rudolfbox personal-target" }
const _hoisted_5 = { class: "personal-target-top" }
const _hoisted_6 = { class: "personal-target-title rtitle sm" }
const _hoisted_7 = {
  key: 0,
  class: "personal-target-user-select"
}
const _hoisted_8 = { class: "personal-target-settings-dropdown" }
const _hoisted_9 = { class: "personal-target-settings" }
const _hoisted_10 = {
  key: 0,
  class: "is-italic"
}
const _hoisted_11 = { class: "mb-4" }
const _hoisted_12 = { class: "buttons mr-4" }
const _hoisted_13 = { key: 1 }
const _hoisted_14 = { class: "rtitle ssm mb-4" }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { key: 1 }
const _hoisted_17 = { class: "buttons mr-4" }
const _hoisted_18 = { class: "rudolfbox googlemap" }
const _hoisted_19 = { class: "rudolfbox task-pipeline" }
const _hoisted_20 = {
  class: "rtitle sm has-text-centered",
  style: {"height":"1.7rem"}
}
const _hoisted_21 = { class: "overview-right" }
const _hoisted_22 = { class: "rudolfbox kpi-sales" }
const _hoisted_23 = { class: "is-flex is-align-items-center mb-4" }
const _hoisted_24 = { class: "rtitle sm" }
const _hoisted_25 = { class: "month-select" }
const _hoisted_26 = { class: "mb-4" }
const _hoisted_27 = { class: "smalltext semibold greytext ml-6" }
const _hoisted_28 = { class: "normaltext normalcolor" }
const _hoisted_29 = { class: "smalltext semibold greytext ml-6" }
const _hoisted_30 = { class: "normaltext normalcolor" }
const _hoisted_31 = { class: "rudolfbox upcoming-tasks" }
const _hoisted_32 = { class: "rtitle sm" }
const _hoisted_33 = { class: "contacts-tab-header-number" }
const _hoisted_34 = { class: "contacts-tab-header-subtitle" }
const _hoisted_35 = { class: "meetings-list" }
const _hoisted_36 = { class: "meetings-date greytext smallertext semibold" }
const _hoisted_37 = { class: "meetings-daily-list" }
const _hoisted_38 = { class: "meeting-text is-relative" }
const _hoisted_39 = { class: "semibold" }
const _hoisted_40 = { class: "bold" }
const _hoisted_41 = { class: "contacts-tab-header-number" }
const _hoisted_42 = { class: "contacts-tab-header-subtitle" }
const _hoisted_43 = { class: "call-list" }
const _hoisted_44 = { class: "call-list-dot" }
const _hoisted_45 = {
  key: 0,
  class: "call-list-info"
}
const _hoisted_46 = { class: "call-list-name" }
const _hoisted_47 = { key: 0 }
const _hoisted_48 = {
  key: 0,
  class: "smallertext semibold ml-2"
}
const _hoisted_49 = {
  key: 1,
  class: "smallertext semibold ml-2"
}
const _hoisted_50 = { class: "smalltext greytext ml-2" }
const _hoisted_51 = {
  key: 1,
  class: "call-list-info"
}
const _hoisted_52 = { class: "call-list-name" }
const _hoisted_53 = {
  key: 0,
  class: "smalltext greytext ml-2"
}
const _hoisted_54 = { class: "contacts-tab-header-number" }
const _hoisted_55 = { class: "contacts-tab-header-subtitle" }
const _hoisted_56 = { class: "todo-list" }
const _hoisted_57 = { class: "todo-date greytext smallertext semibold" }
const _hoisted_58 = { class: "todo-daily-list" }
const _hoisted_59 = { class: "todo-dot" }
const _hoisted_60 = { class: "greytext smallertext semibold" }
const _hoisted_61 = { class: "go-to-activities-footer" }
const _hoisted_62 = { class: "rtitle me mr-2" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_button = _resolveComponent("b-button")
  const _component_b_field = _resolveComponent("b-field")
  const _component_b_dropdown_item = _resolveComponent("b-dropdown-item")
  const _component_b_dropdown = _resolveComponent("b-dropdown")
  const _component_b_icon = _resolveComponent("b-icon")
  const _component_b_input = _resolveComponent("b-input")
  const _component_chart_personal_sales = _resolveComponent("chart-personal-sales")
  const _component_google_map = _resolveComponent("google-map")
  const _component_task_pipeline = _resolveComponent("task-pipeline")
  const _component_chart_target_actual_last_yr_sales = _resolveComponent("chart-target-actual-last-yr-sales")
  const _component_b_tab_item = _resolveComponent("b-tab-item")
  const _component_b_tabs = _resolveComponent("b-tabs")
  const _component_b_loading = _resolveComponent("b-loading")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.l('overview/personal-target')), 1 /* TEXT */),
            (_ctx.authStore.isAdmin)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createVNode(_component_b_dropdown, {
                    modelValue: $data.personalSalesUserId,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.personalSalesUserId) = $event)),
                    "aria-role": "list"
                  }, {
                    trigger: _withCtx(({ active }) => [
                      _createVNode(_component_b_field, {
                        label: _ctx.l("generic/user"),
                        "label-position": "on-border"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_b_button, {
                            label: $options.personalSalesSelectedUserLabel,
                            type: "is-select",
                            expanded: "",
                            size: "is-small",
                            "icon-right": active ? "chevron-up" : "chevron-down"
                          }, null, 8 /* PROPS */, ["label", "icon-right"])
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label"])
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_b_dropdown_item, {
                        "aria-role": "listitem",
                        value: "all"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.l('overview/allusers')), 1 /* TEXT */)
                        ]),
                        _: 1 /* STABLE */
                      }),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.users, (user) => {
                        return (_openBlock(), _createBlock(_component_b_dropdown_item, {
                          key: user._id,
                          "aria-role": "listitem",
                          value: user._id
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(user.fullName), 1 /* TEXT */)
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"]))
                      }), 128 /* KEYED_FRAGMENT */))
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["modelValue"])
                ]))
              : _createCommentVNode("v-if", true),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_b_dropdown, {
                ref: "personalSalesTargetDropdown",
                position: "is-bottom-left",
                "aria-role": "menu",
                "trap-focus": "",
                "can-close": ["escape"],
                "mobile-modal": false,
                onActiveChange: _cache[5] || (_cache[5] = (active) => $options.personalSalesTargetDropdownToggled(active))
              }, {
                trigger: _withCtx(() => [
                  _createVNode(_component_b_icon, {
                    icon: "cog",
                    "custom-size": "mdi-18px"
                  })
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_b_dropdown_item, {
                    "aria-role": "menu-item",
                    focusable: false,
                    custom: "",
                    paddingless: ""
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_9, [
                        ($data.personalSalesUserId === "all")
                          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                              _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.l('overview/select-user-text')), 1 /* TEXT */),
                              _createElementVNode("div", _hoisted_12, [
                                _createVNode(_component_b_button, {
                                  type: "is-light",
                                  size: "is-small",
                                  onClick: _cache[1] || (_cache[1] = $event => (_ctx.$refs.personalSalesTargetDropdown.toggle()))
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.l('generic/close')), 1 /* TEXT */)
                                  ]),
                                  _: 1 /* STABLE */
                                })
                              ])
                            ]))
                          : (_openBlock(), _createElementBlock("div", _hoisted_13, [
                              _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.l('overview/personal-sales-target')), 1 /* TEXT */),
                              ($data.personalSalesUserId === _ctx.authStore.userId)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_15, _toDisplayString(_ctx.l('overview/set-self-sales-target')) + ":", 1 /* TEXT */))
                                : (_openBlock(), _createElementBlock("div", _hoisted_16, [
                                    _createTextVNode(_toDisplayString(_ctx.l('overview/set-other-sales-target')) + " ", 1 /* TEXT */),
                                    _createElementVNode("strong", null, _toDisplayString(_ctx.usersStore.byId($data.personalSalesUserId).fullName), 1 /* TEXT */),
                                    _cache[9] || (_cache[9] = _createTextVNode(":"))
                                  ])),
                              _createElementVNode("div", null, [
                                _createVNode(_component_b_field, { class: "mb-3" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_b_input, {
                                      modelValue: $data.personalSalesTarget,
                                      "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($data.personalSalesTarget) = $event)),
                                      type: "number"
                                    }, null, 8 /* PROPS */, ["modelValue"])
                                  ]),
                                  _: 1 /* STABLE */
                                })
                              ]),
                              _createElementVNode("div", _hoisted_17, [
                                _createVNode(_component_b_button, {
                                  type: "is-primary",
                                  size: "is-small",
                                  onClick: _cache[3] || (_cache[3] = $event => ($options.savePersonalSalesTarget())),
                                  loading: $data.personalSalesTargetLoading
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.l('generic/save')), 1 /* TEXT */)
                                  ]),
                                  _: 1 /* STABLE */
                                }, 8 /* PROPS */, ["loading"]),
                                _createVNode(_component_b_button, {
                                  type: "is-light",
                                  size: "is-small",
                                  onClick: _cache[4] || (_cache[4] = $event => (_ctx.$refs.personalSalesTargetDropdown.toggle())),
                                  disabled: $data.personalSalesTargetLoading
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.l('generic/cancel')), 1 /* TEXT */)
                                  ]),
                                  _: 1 /* STABLE */
                                }, 8 /* PROPS */, ["disabled"])
                              ])
                            ]))
                      ])
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }, 512 /* NEED_PATCH */)
            ])
          ]),
          _createVNode(_component_chart_personal_sales, { userId: $data.personalSalesUserId }, null, 8 /* PROPS */, ["userId"])
        ]),
        _createElementVNode("div", _hoisted_18, [
          ($data.googleMapsLoaded)
            ? (_openBlock(), _createBlock(_component_google_map, {
                key: 0,
                ref: "googleMapDefault",
                "map-id": "overview-page-map",
                "min-zoom": 3
              }, null, 512 /* NEED_PATCH */))
            : _createCommentVNode("v-if", true)
        ]),
        _createElementVNode("div", _hoisted_19, [
          _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.l('overview/taskpipeline')), 1 /* TEXT */),
          _createVNode(_component_task_pipeline)
        ])
      ]),
      _createElementVNode("div", _hoisted_21, [
        _createElementVNode("div", _hoisted_22, [
          _createElementVNode("div", _hoisted_23, [
            _createElementVNode("div", _hoisted_24, _toDisplayString(_ctx.l('kpi/salestarget')), 1 /* TEXT */),
            _createElementVNode("div", _hoisted_25, [
              _createVNode(_component_b_dropdown, {
                modelValue: _ctx.salesSelectedMonth,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => ((_ctx.salesSelectedMonth) = $event)),
                "aria-role": "list"
              }, {
                trigger: _withCtx(({ active }) => [
                  _createVNode(_component_b_field, {
                    label: _ctx.l("generic/month"),
                    "label-position": "on-border"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_b_button, {
                        label: _ctx.$dayjs().month(_ctx.salesSelectedMonth).format("MMMM"),
                        type: "is-select",
                        expanded: "",
                        size: "is-small",
                        "icon-right": active ? "chevron-up" : "chevron-down"
                      }, null, 8 /* PROPS */, ["label", "icon-right"])
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label"])
                ]),
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.monthsYtd(), (month) => {
                    return (_openBlock(), _createBlock(_component_b_dropdown_item, {
                      key: month.number,
                      "aria-role": "listitem",
                      value: month.number
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(month.display), 1 /* TEXT */)
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"]))
                  }), 128 /* KEYED_FRAGMENT */))
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["modelValue"])
            ])
          ]),
          _createElementVNode("div", _hoisted_26, [
            _createElementVNode("div", _hoisted_27, [
              _createTextVNode(_toDisplayString(_ctx.l('kpi/sales-reached-in')) + " " + _toDisplayString(_ctx.$dayjs().format('YYYY')) + ": ", 1 /* TEXT */),
              _createElementVNode("span", _hoisted_28, _toDisplayString($options.salesVsTarget) + "%", 1 /* TEXT */)
            ]),
            _createElementVNode("div", _hoisted_29, [
              _createTextVNode(_toDisplayString(_ctx.l('kpi/sales-reached-in')) + " " + _toDisplayString(_ctx.$dayjs().subtract(1, 'year').format('YYYY')) + ": ", 1 /* TEXT */),
              _createElementVNode("span", _hoisted_30, _toDisplayString($options.salesVsTargetLastYear) + "%", 1 /* TEXT */)
            ])
          ]),
          _createVNode(_component_chart_target_actual_last_yr_sales, {
            autoWidth: true,
            subtitle: false,
            thickerBars: true
          })
        ]),
        _createElementVNode("div", _hoisted_31, [
          _createElementVNode("div", _hoisted_32, _toDisplayString(_ctx.l('activities/upcoming-tasks')), 1 /* TEXT */),
          _createVNode(_component_b_tabs, { position: "is-centered" }, {
            default: _withCtx(() => [
              _createVNode(_component_b_tab_item, null, {
                header: _withCtx(() => [
                  _createElementVNode("div", null, [
                    _createElementVNode("div", _hoisted_33, _toDisplayString($options.numMeetings), 1 /* TEXT */),
                    _createElementVNode("div", _hoisted_34, _toDisplayString(_ctx.l('activities/meetings')), 1 /* TEXT */)
                  ])
                ]),
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_35, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.computedMeetings, (dailyMeetings, idx) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "meetings-daily",
                        key: "meetings_daily_list_" + idx
                      }, [
                        _createElementVNode("div", _hoisted_36, _toDisplayString(dailyMeetings.dateStr), 1 /* TEXT */),
                        _createElementVNode("div", _hoisted_37, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dailyMeetings.items, (meeting) => {
                            return (_openBlock(), _createElementBlock("div", {
                              class: "meeting-daily-item is-clickable",
                              key: "meeting_item_" + meeting.leadProduct
                            }, [
                              _createElementVNode("div", _hoisted_38, [
                                _createElementVNode("span", _hoisted_39, _toDisplayString(meeting.timeStr), 1 /* TEXT */),
                                _cache[10] || (_cache[10] = _createTextVNode()),
                                _createElementVNode("span", _hoisted_40, _toDisplayString(meeting.leadName), 1 /* TEXT */),
                                _createTextVNode(" / " + _toDisplayString(meeting.productName), 1 /* TEXT */)
                              ])
                            ]))
                          }), 128 /* KEYED_FRAGMENT */))
                        ])
                      ]))
                    }), 128 /* KEYED_FRAGMENT */))
                  ])
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_b_tab_item, null, {
                header: _withCtx(() => [
                  _createElementVNode("div", null, [
                    _createElementVNode("div", _hoisted_41, _toDisplayString(_ctx.callList.length), 1 /* TEXT */),
                    _createElementVNode("div", _hoisted_42, _toDisplayString(_ctx.l('activities/to-call')), 1 /* TEXT */)
                  ])
                ]),
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_43, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.computedCallList, (callListItem) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "call-list-item is-relative is-clickable",
                        key: "contact_list_contact_" + callListItem._id
                      }, [
                        _createElementVNode("div", _hoisted_44, [
                          _createVNode(_component_b_icon, { icon: "circle-small" })
                        ]),
                        (callListItem.contact)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_45, [
                              _createElementVNode("div", _hoisted_46, [
                                _createTextVNode(_toDisplayString(_ctx.contactsStore.byId(callListItem.contact).fullName), 1 /* TEXT */),
                                (_ctx.contactsStore.byId(callListItem.contact).title)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_47, " (" + _toDisplayString(_ctx.contactsStore.byId(callListItem.contact).title) + ")", 1 /* TEXT */))
                                  : _createCommentVNode("v-if", true)
                              ]),
                              (_ctx.contactsStore.byId(callListItem.contact).telephone)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_48, _toDisplayString(_ctx.contactsStore.byId(callListItem.contact).telephone), 1 /* TEXT */))
                                : _createCommentVNode("v-if", true),
                              (_ctx.contactsStore.byId(callListItem.contact).telephone2)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_49, _toDisplayString(_ctx.contactsStore.byId(callListItem.contact).telephone2), 1 /* TEXT */))
                                : _createCommentVNode("v-if", true),
                              (_ctx.contactsStore.contactsWithLeadsMap.has(callListItem.contact))
                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [], 64 /* STABLE_FRAGMENT */))
                                : _createCommentVNode("v-if", true),
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contactsStore.contactsWithLeadsMap.get(callListItem.contact), (lead) => {
                                return (_openBlock(), _createElementBlock("div", _hoisted_50, _toDisplayString(lead.name), 1 /* TEXT */))
                              }), 256 /* UNKEYED_FRAGMENT */))
                            ]))
                          : (_openBlock(), _createElementBlock("div", _hoisted_51, [
                              _createElementVNode("div", _hoisted_52, _toDisplayString(callListItem.firstName) + " " + _toDisplayString(callListItem.lastName), 1 /* TEXT */),
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(callListItem.phoneNumbers, (phoneNumber, idx) => {
                                return (_openBlock(), _createElementBlock("div", {
                                  class: "smallertext semibold ml-2",
                                  key: "call_list_item_" + callListItem._id + "_phone_" + idx
                                }, _toDisplayString(phoneNumber), 1 /* TEXT */))
                              }), 128 /* KEYED_FRAGMENT */)),
                              (callListItem.company)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_53, _toDisplayString(callListItem.company), 1 /* TEXT */))
                                : _createCommentVNode("v-if", true)
                            ]))
                      ]))
                    }), 128 /* KEYED_FRAGMENT */))
                  ])
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_b_tab_item, null, {
                header: _withCtx(() => [
                  _createElementVNode("div", null, [
                    _createElementVNode("div", _hoisted_54, _toDisplayString($options.todosFiltered.length), 1 /* TEXT */),
                    _createElementVNode("div", _hoisted_55, _toDisplayString(_ctx.l('activities/to-do')), 1 /* TEXT */)
                  ])
                ]),
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_56, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.computedTodos, (dailyTodos, idx) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "todo-daily",
                        key: "todo_list_" + idx
                      }, [
                        _createElementVNode("div", _hoisted_57, _toDisplayString(dailyTodos.dateStr), 1 /* TEXT */),
                        _createElementVNode("div", _hoisted_58, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dailyTodos.items, (todo) => {
                            return (_openBlock(), _createElementBlock("div", {
                              class: "todo-daily-item",
                              key: "todo_item_" + todo._id
                            }, [
                              _createElementVNode("div", _hoisted_59, [
                                _createVNode(_component_b_icon, { icon: "circle-small" })
                              ]),
                              _createElementVNode("div", {
                                class: _normalizeClass(["todo-text is-relative", { "greytext": todo.done }])
                              }, [
                                _createTextVNode(_toDisplayString(todo.text), 1 /* TEXT */),
                                (Array.isArray(todo?.leads) && todo.leads.length > 0 && _ctx.leadsStore.byId(todo.leads[0])?._id)
                                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                      _cache[11] || (_cache[11] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
                                      _createElementVNode("span", _hoisted_60, _toDisplayString(_ctx.l('activities/related-lead')) + ": " + _toDisplayString(this.leadsStore.byId(todo.leads[0]).name), 1 /* TEXT */)
                                    ], 64 /* STABLE_FRAGMENT */))
                                  : _createCommentVNode("v-if", true)
                              ], 2 /* CLASS */)
                            ]))
                          }), 128 /* KEYED_FRAGMENT */))
                        ])
                      ]))
                    }), 128 /* KEYED_FRAGMENT */))
                  ])
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          }),
          _createElementVNode("div", _hoisted_61, [
            _cache[12] || (_cache[12] = _createElementVNode("div", { class: "is-flex-grow-1" }, null, -1 /* HOISTED */)),
            _createElementVNode("div", {
              class: "activities-link",
              onClick: _cache[7] || (_cache[7] = $event => (_ctx.$router.push({ name: "activities" })))
            }, [
              _createElementVNode("div", _hoisted_62, _toDisplayString(_ctx.l('sidebar/activities')), 1 /* TEXT */),
              _createElementVNode("div", null, [
                _createVNode(_component_b_icon, { icon: "arrow-right" })
              ])
            ])
          ])
        ])
      ])
    ]),
    _createVNode(_component_b_loading, {
      "is-full-page": false,
      modelValue: _ctx.appLoading,
      "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => ((_ctx.appLoading) = $event)),
      animation: "none"
    }, null, 8 /* PROPS */, ["modelValue"])
  ]))
}