import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-content-inner rudolfbox" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("div", { class: "outlook-page" }, [
      _createElementVNode("div", null, [
        _createElementVNode("p", { class: "mb-6" }, "Outlook features coming soon!")
      ])
    ], -1 /* HOISTED */)
  ])))
}