//
//
// RUDOLF frontend
//
// Nurminen Development Oy Ltd - https://nurminen.dev
//
// For customer: The Rudolf Oy
//
// ALL RIGHTS RESERVED BY CUSTOMER
//
//

//
// File author(s):
//   - Riku Nurminen <riku@nurminen.dev>
//


import * as appConfig       from './app.config.js'

import { useAuthStore }     from './store/auth.js'
import socket               from './socket.js'


export async function apiRequest(method, url, payload, logoutIf401 = true) {
    const authStore = useAuthStore()

    const options = {
        method:         method,
        headers:        {},
        credentials:    'include'
    }

    if (payload) {
        options['headers']['Content-Type'] = 'application/json'
        options['body'] = JSON.stringify(payload)
    }

    if(socket.id) {
        options['headers']['X-Socket-ID'] = socket.id
    }

    const apiHost = appConfig.API_HOST
    const apiPath = appConfig.API_PATH

    const fullUrl = apiHost + apiPath + url

    const response = await fetch(fullUrl, options)

    if(!response.ok) {
        const error = new Error(`${apiPath}${url}: ${response.status} ${response.statusText}`)
        error.status = response.status
        error.body = await response.text()

        try {
            error.bodyObj = JSON.parse(error.body)
        } catch (e) {
            error.bodyObj = null
        }

        if(response.status === 401 && logoutIf401) {
            await authStore.logout(true)
        }

        throw error
    } else {
        const body = await response.text()

        try {
            return JSON.parse(body)
        } catch(error) {
            return body
        }
    }

}
