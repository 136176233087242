import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, normalizeClass as _normalizeClass, TransitionGroup as _TransitionGroup } from "vue"

const _hoisted_1 = { class: "rnotifications-button" }
const _hoisted_2 = {
  key: 0,
  class: "rnotifications-counter"
}
const _hoisted_3 = { class: "rnotifications-container" }
const _hoisted_4 = { class: "rnotifications-top" }
const _hoisted_5 = { class: "rnotifications-title rtitle sm" }
const _hoisted_6 = { class: "rnotifications-top-buttons" }
const _hoisted_7 = {
  key: 1,
  style: {"padding":"3px"}
}
const _hoisted_8 = {
  key: 3,
  style: {"padding":"3px"}
}
const _hoisted_9 = {
  key: 0,
  class: "rnotifications-list"
}
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "rnotification-text" }
const _hoisted_12 = { class: "rnotification-time" }
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = { class: "rnotification-buttons" }
const _hoisted_15 = {
  key: 0,
  class: "rnotification-mark-as-read"
}
const _hoisted_16 = ["onClick"]
const _hoisted_17 = {
  key: 1,
  class: "rnotification-delete"
}
const _hoisted_18 = ["onClick"]
const _hoisted_19 = {
  key: 1,
  class: "has-text-centered mt-4 is-italic"
}
const _hoisted_20 = { class: "rnotifications-footer" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_icon = _resolveComponent("b-icon")
  const _component_b_tooltip = _resolveComponent("b-tooltip")
  const _component_router_link = _resolveComponent("router-link")
  const _component_b_dropdown_item = _resolveComponent("b-dropdown-item")
  const _component_b_dropdown = _resolveComponent("b-dropdown")

  return (_openBlock(), _createBlock(_component_b_dropdown, {
    ref: "notifications",
    position: "is-bottom-left",
    "aria-role": "menu",
    "trap-focus": "",
    "append-to-body": false,
    "mobile-modal": false,
    onActiveChange: _cache[3] || (_cache[3] = () => $options.notificationsToggled())
  }, {
    trigger: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.notificationsStore.numUnread > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.notificationsStore.numUnread), 1 /* TEXT */))
          : _createCommentVNode("v-if", true),
        _createVNode(_component_b_icon, { icon: "bell-outline" })
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_b_dropdown_item, {
        "aria-role": "menu-item",
        custom: "",
        paddingless: ""
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", {
              class: "rnotifications-close is-clickable",
              onClick: _cache[0] || (_cache[0] = $event => (_ctx.$refs.notifications.toggle()))
            }, [
              _createVNode(_component_b_icon, {
                icon: "close",
                "custom-size": "mdi-18px"
              })
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createTextVNode(_toDisplayString(_ctx.l('notifications/notifications')) + " ", 1 /* TEXT */),
                (_ctx.notificationsStore.numUnread > 0)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createTextVNode("(" + _toDisplayString(_ctx.notificationsStore.numUnread) + ")", 1 /* TEXT */)
                    ], 64 /* STABLE_FRAGMENT */))
                  : _createCommentVNode("v-if", true)
              ]),
              _createElementVNode("div", _hoisted_6, [
                (_ctx.notificationsStore.numUnread > 0)
                  ? (_openBlock(), _createBlock(_component_b_tooltip, {
                      key: 0,
                      label: _ctx.l("notifications/mark-all-as-read"),
                      type: "is-white",
                      position: "is-top"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", {
                          class: "is-clickable",
                          style: {"padding":"3px"},
                          onClick: _cache[1] || (_cache[1] = _withModifiers($event => (_ctx.notificationsStore.markAllAsRead()), ["stop"]))
                        }, [
                          _createVNode(_component_b_icon, {
                            icon: "playlist-check",
                            type: "is-success"
                          })
                        ])
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["label"]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _createVNode(_component_b_icon, {
                        icon: "playlist-check",
                        "custom-class": "disabled-icon"
                      })
                    ])),
                (_ctx.notificationsStore.numRead > 0)
                  ? (_openBlock(), _createBlock(_component_b_tooltip, {
                      key: 2,
                      label: _ctx.l("notifications/remove-all-read"),
                      type: "is-white",
                      position: "is-left"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", {
                          class: "is-clickable",
                          style: {"padding":"3px"},
                          onClick: _cache[2] || (_cache[2] = _withModifiers($event => (_ctx.notificationsStore.deleteAllRead()), ["stop"]))
                        }, [
                          _createVNode(_component_b_icon, { icon: "trash-can-outline" })
                        ])
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["label"]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createVNode(_component_b_icon, {
                        icon: "trash-can-outline",
                        "custom-class": "disabled-icon"
                      })
                    ]))
              ])
            ]),
            (_ctx.notifications.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createVNode(_TransitionGroup, { name: "rnotification-list" }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notifications, (notification) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: _normalizeClass(["rnotification", { "read": notification.read }]),
                          key: "topnav_notification_" + notification._id,
                          onClick: $event => {_ctx.notificationsStore.notificationClick(notification); _ctx.$refs.notifications.toggle();}
                        }, [
                          _createElementVNode("div", _hoisted_11, [
                            _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$dayjs(notification.created_at).format('LLL')), 1 /* TEXT */),
                            _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
                            _createElementVNode("span", {
                              innerHTML: $options.formatNotificationMessage(notification)
                            }, null, 8 /* PROPS */, _hoisted_13)
                          ]),
                          _createElementVNode("div", _hoisted_14, [
                            (notification.read !== true)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                                  _createVNode(_component_b_tooltip, {
                                    label: _ctx.l("notifications/mark-as-read"),
                                    type: "is-white",
                                    position: "is-left"
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("div", {
                                        class: "is-clickable",
                                        style: {"padding":"3px"},
                                        onClick: _withModifiers($event => (_ctx.notificationsStore.markAsRead(notification)), ["stop"])
                                      }, [
                                        _createVNode(_component_b_icon, {
                                          icon: "check",
                                          type: "is-success"
                                        })
                                      ], 8 /* PROPS */, _hoisted_16)
                                    ]),
                                    _: 2 /* DYNAMIC */
                                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label"])
                                ]))
                              : (_openBlock(), _createElementBlock("div", _hoisted_17, [
                                  _createVNode(_component_b_tooltip, {
                                    label: _ctx.l("notifications/remove-notification"),
                                    type: "is-white",
                                    position: "is-left"
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("div", {
                                        class: "is-clickable",
                                        style: {"padding":"3px"},
                                        onClick: _withModifiers($event => (_ctx.notificationsStore.deleteNotification(notification)), ["stop"])
                                      }, [
                                        _createVNode(_component_b_icon, { icon: "close" })
                                      ], 8 /* PROPS */, _hoisted_18)
                                    ]),
                                    _: 2 /* DYNAMIC */
                                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label"])
                                ]))
                          ])
                        ], 10 /* CLASS, PROPS */, _hoisted_10))
                      }), 128 /* KEYED_FRAGMENT */))
                    ]),
                    _: 1 /* STABLE */
                  })
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_19, _toDisplayString(_ctx.l('notifications/no-new-notifications')), 1 /* TEXT */)),
            _createElementVNode("div", _hoisted_20, [
              _createVNode(_component_router_link, {
                class: "rlink",
                to: { name: "activities" },
                title: _ctx.l("activities/pagetitle")
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.l('activities/pagetitle')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["title"])
            ])
          ])
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }, 512 /* NEED_PATCH */))
}