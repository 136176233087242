import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { style: {"display":"contents"} }
const _hoisted_2 = {
  class: "page-content-inner",
  id: "manage-leads-top"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "is-relative" }
const _hoisted_5 = {
  key: 0,
  class: "toggle-map-maximize-container"
}
const _hoisted_6 = { class: "rudolfbox" }
const _hoisted_7 = { class: "modal-inner has-text-centered" }
const _hoisted_8 = { class: "rtitle mt-4" }
const _hoisted_9 = { class: "modal-text" }
const _hoisted_10 = { class: "buttons mb-0" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_add_edit_lead = _resolveComponent("add-edit-lead")
  const _component_r_table = _resolveComponent("r-table")
  const _component_b_icon = _resolveComponent("b-icon")
  const _component_google_map = _resolveComponent("google-map")
  const _component_manage_leads_sidebar = _resolveComponent("manage-leads-sidebar")
  const _component_b_button = _resolveComponent("b-button")
  const _component_b_modal = _resolveComponent("b-modal")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.addNewLeadToggled)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_add_edit_lead, {
              "google-maps-loaded": $data.googleMapsLoaded,
              onClosed: _cache[0] || (_cache[0] = $event => (_ctx.addNewLeadToggled = !_ctx.addNewLeadToggled))
            }, null, 8 /* PROPS */, ["google-maps-loaded"])
          ]))
        : _createCommentVNode("v-if", true),
      _createElementVNode("div", {
        class: "manage-leads-container",
        style: _normalizeStyle($options.manageLeadsGridCSS)
      }, [
        _createCommentVNode(" Left column, table or maximized map"),
        _withDirectives(_createVNode(_component_r_table, null, null, 512 /* NEED_PATCH */), [
          [_vShow, $data.view === "table"]
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_4, [
          ($data.googleMapsLoaded)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_b_icon, {
                  icon: "arrow-collapse-all",
                  onClick: _cache[1] || (_cache[1] = $event => ($options.toggleView("table"))),
                  "custom-size": "mdi-24px",
                  "custom-class": "is-clickable"
                })
              ]))
            : _createCommentVNode("v-if", true),
          ($data.googleMapsLoaded)
            ? (_openBlock(), _createBlock(_component_google_map, {
                ref: "googleMapMaximized",
                key: "google_map_maximized",
                "map-id": "manage-leads-maximized-map",
                "min-zoom": 2,
                "use-clustering": $data.userSettingMapUseClustering,
                "clustering-max-zoom": $data.userSettingMapClusteringMaxZoom
              }, null, 8 /* PROPS */, ["use-clustering", "clustering-max-zoom"]))
            : _createCommentVNode("v-if", true)
        ], 512 /* NEED_PATCH */), [
          [_vShow, $data.view === "map"]
        ]),
        _createCommentVNode(" Grid right column"),
        _createVNode(_component_manage_leads_sidebar, {
          class: "is-hidden-mobile",
          view: $data.view,
          googleMapsLoaded: $data.googleMapsLoaded,
          userSettingMapUseClustering: $data.userSettingMapUseClustering,
          userSettingMapClusteringMaxZoom: $data.userSettingMapClusteringMaxZoom,
          onToggled: _cache[2] || (_cache[2] = (toggled) => $options.sidebarToggled(toggled)),
          onResizeStart: _cache[3] || (_cache[3] = $event => ($options.sidebarResizeStart())),
          onResize: _cache[4] || (_cache[4] = (newWidth) => $options.sidebarResize(newWidth)),
          onResizeEnd: _cache[5] || (_cache[5] = $event => ($options.sidebarResizeEnd())),
          onViewToggled: _cache[6] || (_cache[6] = (view) => $options.toggleView(view))
        }, null, 8 /* PROPS */, ["view", "googleMapsLoaded", "userSettingMapUseClustering", "userSettingMapClusteringMaxZoom"])
      ], 4 /* STYLE */)
    ]),
    _createCommentVNode(""),
    _createCommentVNode(""),
    _createCommentVNode(" ███╗   ███╗ ██████╗ ██████╗  █████╗ ██╗     ███████╗"),
    _createCommentVNode(" ████╗ ████║██╔═══██╗██╔══██╗██╔══██╗██║     ██╔════╝"),
    _createCommentVNode(" ██╔████╔██║██║   ██║██║  ██║███████║██║     ███████╗"),
    _createCommentVNode(" ██║╚██╔╝██║██║   ██║██║  ██║██╔══██║██║     ╚════██║"),
    _createCommentVNode(" ██║ ╚═╝ ██║╚██████╔╝██████╔╝██║  ██║███████╗███████║"),
    _createCommentVNode(" ╚═╝     ╚═╝ ╚═════╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝╚══════╝"),
    _createCommentVNode(""),
    _createCommentVNode(""),
    _createVNode(_component_b_modal, {
      class: "fit-content-height",
      modelValue: $data.addNewLeadNotificationModalActive,
      "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => (($data.addNewLeadNotificationModalActive) = $event)),
      width: 640,
      scroll: "keep",
      "can-cancel": [ 'escape', 'outside' ],
      "on-cancel": $options.closeAddNewLeadNotificationModal
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_b_icon, {
              icon: "alert-circle-outline",
              "custom-class": "modal-alert"
            }),
            _createElementVNode("h2", _hoisted_8, _toDisplayString(_ctx.l('manage-leads/add-lead-open')), 1 /* TEXT */),
            _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.l('manage-leads/add-lead-open-text')), 1 /* TEXT */),
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_b_button, {
                type: "is-primary",
                rounded: "",
                onClick: _cache[7] || (_cache[7] = $event => ($options.closeAddNewLeadNotificationModal()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.l('generic/stay')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_b_button, {
                type: "is-light",
                rounded: "",
                onClick: _cache[8] || (_cache[8] = $event => ($options.continueNavigation()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.l('generic/continue')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              })
            ])
          ])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "on-cancel"])
  ]))
}