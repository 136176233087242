//
//
// RUDOLF frontend
//
// Nurminen Development Oy Ltd - https://nurminen.dev
//
// For customer: The Rudolf Oy
//
// ALL RIGHTS RESERVED BY CUSTOMER
//
//

//
// File author(s):
//   - Riku Nurminen <riku@nurminen.dev>
//


import { defineStore }              from 'pinia'

import { apiRequest }               from '../api_request.js'
import * as appHelpers              from '../helpers.js'

import * as crudFactory             from './_crud.js'
import { useLeadsStore }            from './leads.js'


const api = {
    'create':               [ 'POST',     '/products' ],
    'update':               [ 'PATCH',    '/products' ],
    'destroy':              [ 'DELETE',   '/products' ],

    'createStatus':         [ 'POST',     '/statuses' ],
    'bulkSaveStatuses':     [ 'PUT',      '/statuses/bulksave' ],
    'destroyStatus':        [ 'DELETE',   '/statuses' ],

    'updateForm':           [ 'PATCH',    '/products/{productId}/form' ],

    'createQuestion':       [ 'POST',     '/questions' ],
    'editQuestion':         [ 'POST',     '/questions/edit' ],
    'deleteQuestion':       [ 'DELETE',   '/questions' ],
}


const products      = []
const productsMap   = new Map()


export const useProductsStore = defineStore('products', {
    state: () => {
        return {
            ...crudFactory.states(),
        }
    },


    getters: {
        ...crudFactory.getters(products, productsMap),


        byName: (state) => (search) => {
            return state.all.filter(p => {
                if(!p.name || !search) return true

                const productName = appHelpers.stringNormalize(p.name)
                const searchAgainst = appHelpers.stringNormalize(search)

                return productName.includes(searchAgainst)
            })
        },


        findTemplateQuestion: (state) => (templateId) => {
            let templateQuestion = null

            for(const product of state.all) {
                if(!Array.isArray(product.templateQuestions) || product.templateQuestions.length === 0) continue

                templateQuestion = product.templateQuestions.find(q => q._id === templateId)

                if(templateQuestion) break
            }

            return templateQuestion
        },


        activeKpiWeights() {
            this.updateToggler

            return (productId) => {
                const weights = {
                    'jan': 8.333,
                    'feb': 8.333,
                    'mar': 8.333,
                    'apr': 8.333,
                    'may': 8.333,
                    'jun': 8.333,
                    'jul': 8.333,
                    'aug': 8.333,
                    'sep': 8.333,
                    'oct': 8.333,
                    'nov': 8.333,
                    'dec': 8.333,
                }
    
                const product = this.byId(productId)
    
                if(!product._id) return weights
    
                if(Array.isArray(product.kpi_weight_sets) && product.kpi_weight_sets.length > 0) {
                    const activeSet = product.kpi_weight_sets.find(s => s.active === true)
    
                    if(!activeSet) return weights
    
                    weights.jan = activeSet.jan
                    weights.feb = activeSet.feb
                    weights.mar = activeSet.mar
                    weights.apr = activeSet.apr
                    weights.may = activeSet.may
                    weights.jun = activeSet.jun
                    weights.jul = activeSet.jul
                    weights.aug = activeSet.aug
                    weights.sep = activeSet.sep
                    weights.oct = activeSet.oct
                    weights.nov = activeSet.nov
                    weights.dec = activeSet.dec
                }
    
                return weights
            }
        }
    },


    actions: {
        ...crudFactory.actions('products', {}, products, productsMap),


        async create(payload) {
            const newProduct = await apiRequest(...api.create, payload)

            this.ADD(newProduct)

            return newProduct
        },


        async patch({ id, payload }) {
            const path = api.update[1] + `/${id}`
    
            const updatedProduct = await apiRequest(api.update[0], path, payload)
    
            this.UPDATE(updatedProduct)

            // Update product info in lead assignments
            const leadsStore = useLeadsStore()
            leadsStore.syncProductChanges([ updatedProduct._id ])

            return updatedProduct
        },


        async destroy(product) {
            const path = api.destroy[1] + `/${product._id}`

            await apiRequest(api.destroy[0], path)

            this.syncDestroy(product)
        },


        // Sync an already destroyed product on backend with store
        syncDestroy(product) {
            const leadsStore        = useLeadsStore()

            const productId = product._id

            // Destroy product
            this.DESTROY_BY({ field: '_id', values: productId })

            // Pull product from leads and lead assignments
            leadsStore.PULL_PRODUCT(product._id)
        },


        PULL_LEADS(leadIds) {
            for(const product of products) {
                for(const leadId of leadIds) {
                    if(!Array.isArray(product.tasks) || product.tasks.length === 0) continue
    
                    const idx = product.tasks.findIndex(p => p === leadId)
    
                    if(idx === -1) continue
    
                    product.tasks.splice(idx, 1)
                }
            }

            if(this.reactivityEnabled) this.updateToggler++
        },


        // Updates sorting for products, statuses and questions
        async updateSortOrder(resourceType, itemsSorted) {
            const apiPath = `/${resourceType}/updatesortorder`

            const { updatedProducts } = await apiRequest('PUT', apiPath, { itemsSorted })
    
            // We always get fully updated products back, since statuses and questions are contained
            // within products
            this.PUT(updatedProducts)

            // Sync sort order to lead assignments
            const leadsStore = useLeadsStore()

            const updatedProductIds = updatedProducts.map(p => p._id)

            leadsStore.syncProductChanges(updatedProductIds)
        },


        // A "soft" products sort order update; used in Products setup page so
        // user sees immediate feedback on products draggable sort while backend
        // is processing the actual sort
        softUpdateSortOrder(itemsSorted) {
            for(const sortedItem of itemsSorted) {
                const itemToUpdate = productsMap.get(sortedItem._id)

                if(itemToUpdate) itemToUpdate.sort_order_id = sortedItem.sort_order_id
            }

            this.updateToggler++
        },



        // 
        // 
        // ███████╗████████╗ █████╗ ████████╗██╗   ██╗███████╗███████╗███████╗
        // ██╔════╝╚══██╔══╝██╔══██╗╚══██╔══╝██║   ██║██╔════╝██╔════╝██╔════╝
        // ███████╗   ██║   ███████║   ██║   ██║   ██║███████╗█████╗  ███████╗
        // ╚════██║   ██║   ██╔══██║   ██║   ██║   ██║╚════██║██╔══╝  ╚════██║
        // ███████║   ██║   ██║  ██║   ██║   ╚██████╔╝███████║███████╗███████║
        // ╚══════╝   ╚═╝   ╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚══════╝╚══════╝╚══════╝
        //
        //
        async createStatus(payload) {
            const { newStatus, updatedProduct } = await apiRequest(...api.createStatus, payload)

            this.PUT(updatedProduct)

            return newStatus
        },


        async destroyStatus(id) {
            const path = api.destroyStatus[1] + `/${id}`

            const updatedProduct = await apiRequest(api.destroy[0], path)

            this.PUT(updatedProduct)
        },


        async bulkSaveStatuses(editedStatuses) {
            const updatedProducts = await apiRequest(...api.bulkSaveStatuses, editedStatuses)

            this.PUT(updatedProducts)

            // Sync sort order to lead assignments
            const leadsStore = useLeadsStore()

            const productIds = updatedProducts.map(p => p._id)

            leadsStore.syncProductChanges(productIds)
        },



        // 
        // 
        // ███████╗ ██████╗ ██████╗ ███╗   ███╗        ██╗     ██████╗ ███████╗████████╗███╗   ██╗███████╗
        // ██╔════╝██╔═══██╗██╔══██╗████╗ ████║       ██╔╝    ██╔═══██╗██╔════╝╚══██╔══╝████╗  ██║██╔════╝
        // █████╗  ██║   ██║██████╔╝██╔████╔██║      ██╔╝     ██║   ██║███████╗   ██║   ██╔██╗ ██║███████╗
        // ██╔══╝  ██║   ██║██╔══██╗██║╚██╔╝██║     ██╔╝      ██║▄▄ ██║╚════██║   ██║   ██║╚██╗██║╚════██║
        // ██║     ╚██████╔╝██║  ██║██║ ╚═╝ ██║    ██╔╝       ╚██████╔╝███████║   ██║   ██║ ╚████║███████║
        // ╚═╝      ╚═════╝ ╚═╝  ╚═╝╚═╝     ╚═╝    ╚═╝         ╚══▀▀═╝ ╚══════╝   ╚═╝   ╚═╝  ╚═══╝╚══════╝
        //
        //
        async updateForm(productId, payload) {
            const method = api.updateForm[0]
            const path = api.updateForm[1].replace('{productId}', productId)

            const { updatedProduct } = await apiRequest(method, path, payload)

            this.PUT(updatedProduct)
        },


        async createQuestion(payload) {
            const { updatedProduct } = await apiRequest(...api.createQuestion, payload)

            this.PUT(updatedProduct)
        },

        async editQuestion(templateId, payload) {
            const path = api.editQuestion[1] + `/${templateId}`

            const { updatedProduct } = await apiRequest(api.editQuestion[0], path, payload)

            this.UPDATE(updatedProduct)

            const leadsStore = useLeadsStore()

            leadsStore.syncProductChanges([ updatedProduct._id ])
        },

        async deleteQuestion(templateQuestionId) {
            const path = api.deleteQuestion[1] + `/${templateQuestionId}`

            const { updatedProduct } = await apiRequest(api.deleteQuestion[0], path)

            this.PUT(updatedProduct)
        }

    }

})
