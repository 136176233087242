//
//
// RUDOLF frontend
//
// Nurminen Development Oy Ltd - https://nurminen.dev
//
// For customer: The Rudolf Oy
//
// ALL RIGHTS RESERVED BY CUSTOMER
//
//

//
// File author(s):
//   - Riku Nurminen <riku@nurminen.dev>
//


import { createRouter, createWebHistory } from 'vue-router'

import { useRootStore }     from '../store/root.js'
import { useAuthStore }     from '../store/auth.js'

import Overview             from '../../pages/Overview.vue'

import ManageLeads          from '../../pages/ManageLeads.vue'
import LeadDetails          from '../../pages/leads/LeadDetails.vue'

import Activities           from '../../pages/Activities.vue'

import SetupProducts        from '../../pages/setup/Products.vue'
import SetupUsers           from '../../pages/setup/Users.vue'
import SetupData            from '../../pages/setup/Data.vue'
import SetupLabels          from '../../pages/setup/Labels.vue'
import SetupLocalization    from '../../pages/setup/Localization.vue'

import Outlook              from '../../pages/Outlook.vue'

import Account              from '../../pages/Account.vue'

import Login                from '../../pages/Login.vue'


const routes = [
    { path: '/', name: 'overview', component: Overview, meta: { requiresAdmin: false } },

    { path: '/manage-leads', name: 'manage-leads', component: ManageLeads, meta: { requiresAdmin: false } },
    { path: '/lead/:leadId', name: 'lead-details', component: LeadDetails, meta: { requiresAdmin: false }, props: true },

    { path: '/activities', name: 'activities', component: Activities, meta: { requiresAdmin: false } },

    { path: '/setup/products', name: 'setup-products', component: SetupProducts, meta: { requiresAdmin: true } },
    { path: '/setup/users', name: 'setup-users', component: SetupUsers, meta: { requiresAdmin: true } },
    { path: '/setup/data', name: 'setup-data', component: SetupData, meta: { requiresAdmin: true } },
    { path: '/setup/labels', name: 'setup-labels', component: SetupLabels, meta: { requiresAdmin: true } },
    { path: '/setup/localization', name: 'setup-localization', component: SetupLocalization, meta: { requiresSuperAdmin: true } },

    { path: '/outlook', name: 'outlook', component: Outlook, meta: { requiresAdmin: false } },
    { path: '/account', name: 'account', component: Account, meta: { requiresAdmin: false } },

    { path: '/login', name: 'login', component: Login }
]


const router = createRouter({
    history: createWebHistory(),
    linkExactActiveClass: 'is-active',
    routes
})


router.beforeEach(async (to, from) => {
    const rootStore = useRootStore()
    const authStore = useAuthStore()

    // Wait for authentication on 1st app load before navigating to route
    await rootStore.authentication()

    if(!authStore.isLoggedIn && to.name !== 'login') return { name: 'login' }

    let vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)

    const isMobile = vw < 768

    const frontpageAfterLogin = isMobile ? 'manage-leads' : 'overview'

    if(authStore.isLoggedIn && to.name === 'login') return { name: frontpageAfterLogin }

    if(to.matched.some((record) => record.meta.requiresAdmin) && !authStore.isAdmin) {
        return { name: frontpageAfterLogin }
    } else if(to.matched.some((record) => record.meta.requiresSuperAdmin) && !authStore.isSuperAdmin) {
        return { name: frontpageAfterLogin }
    }

    // Pages allowed on mobile
    if(authStore.isLoggedIn && isMobile && !['manage-leads', 'account', 'lead-details'].includes(to.name)) {
        return { name: frontpageAfterLogin }
    }

    // Navigation OK
    rootStore.currentPage = to.name || ''

    return true
})


export default router
