import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  class: "manage-leads-sidebar",
  id: "manage-leads-sidebar-handle"
}
const _hoisted_2 = { class: "sidebar-resize-handle" }
const _hoisted_3 = { class: "manage-leads-sidebar-content expanded" }
const _hoisted_4 = { class: "is-flex is-align-items-center mr-4" }
const _hoisted_5 = { class: "is-flex-grow-1" }
const _hoisted_6 = {
  key: 0,
  class: "toggle-map-maximize-container"
}
const _hoisted_7 = { class: "is-flex is-align-items-center mr-4" }
const _hoisted_8 = { class: "is-flex-grow-1" }
const _hoisted_9 = { class: "mb-4" }
const _hoisted_10 = { class: "mb-4" }
const _hoisted_11 = { class: "sales-values mb-4" }
const _hoisted_12 = { class: "pr-4 textcut" }
const _hoisted_13 = { class: "pr-4 textcut" }
const _hoisted_14 = { class: "manage-leads-sidebar-content" }
const _hoisted_15 = { class: "manage-leads-sidebar-content expanded" }
const _hoisted_16 = { class: "sidebar-rgrid-header" }
const _hoisted_17 = {
  key: 0,
  class: "rtitle",
  style: {"text-wrap":"nowrap"}
}
const _hoisted_18 = {
  key: 1,
  class: "rtitle",
  style: {"text-wrap":"nowrap"}
}
const _hoisted_19 = { class: "sidebar-rgrid-scroller" }
const _hoisted_20 = { class: "sidebar-rgrid" }
const _hoisted_21 = { class: "sidebar-rgrid-header" }
const _hoisted_22 = { class: "column-header" }
const _hoisted_23 = { class: "column-name" }
const _hoisted_24 = { class: "column-sort" }
const _hoisted_25 = { class: "sidebar-rgrid-main-row" }
const _hoisted_26 = { class: "sidebar-rgrid-entry row-collapse" }
const _hoisted_27 = ["onClick"]
const _hoisted_28 = ["onClick"]
const _hoisted_29 = { class: "sidebar-rgrid-entry rtitle sm lead-name" }
const _hoisted_30 = {
  key: 0,
  class: "lead-number"
}
const _hoisted_31 = ["onClick"]
const _hoisted_32 = { class: "sidebar-rgrid-entry rtitle sm lead-name" }
const _hoisted_33 = { class: "is-flex" }
const _hoisted_34 = { style: {"margin-right":"5px"} }
const _hoisted_35 = {
  key: 0,
  class: "lead-number"
}
const _hoisted_36 = { class: "sidebar-rgrid-footer" }
const _hoisted_37 = { class: "pagination-per-page" }
const _hoisted_38 = { class: "leads-per-page" }
const _hoisted_39 = { class: "leads-per-page-dropdown" }
const _hoisted_40 = { class: "bold" }
const _hoisted_41 = { class: "pagination-pages" }
const _hoisted_42 = { class: "pagination-position" }
const _hoisted_43 = { class: "manage-leads-sidebar-content" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_icon = _resolveComponent("b-icon")
  const _component_google_map = _resolveComponent("google-map")
  const _component_Collapse = _resolveComponent("Collapse")
  const _component_b_button = _resolveComponent("b-button")
  const _component_b_field = _resolveComponent("b-field")
  const _component_b_dropdown_item = _resolveComponent("b-dropdown-item")
  const _component_b_dropdown = _resolveComponent("b-dropdown")
  const _component_chart_target_actual_last_yr_sales = _resolveComponent("chart-target-actual-last-yr-sales")
  const _component_chart_monthly_sales = _resolveComponent("chart-monthly-sales")
  const _component_columns_sorting = _resolveComponent("columns-sorting")
  const _component_columns_quicksearch = _resolveComponent("columns-quicksearch")
  const _component_b_input = _resolveComponent("b-input")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "hide-show-sidebar",
      onClick: _cache[0] || (_cache[0] = $event => ($options.toggleSidebar()))
    }, [
      ($data.toggled)
        ? (_openBlock(), _createBlock(_component_b_icon, {
            key: 0,
            icon: "chevron-double-right"
          }))
        : _createCommentVNode("v-if", true),
      (!$data.toggled)
        ? (_openBlock(), _createBlock(_component_b_icon, {
            key: 1,
            icon: "chevron-double-left"
          }))
        : _createCommentVNode("v-if", true)
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_2, null, 512 /* NEED_PATCH */), [
      [_vShow, $data.toggled]
    ]),
    _createCommentVNode(""),
    _createCommentVNode(""),
    _createCommentVNode(" ████████╗ █████╗ ██████╗ ██╗     ███████╗"),
    _createCommentVNode(" ╚══██╔══╝██╔══██╗██╔══██╗██║     ██╔════╝"),
    _createCommentVNode("    ██║   ███████║██████╔╝██║     █████╗  "),
    _createCommentVNode("    ██║   ██╔══██║██╔══██╗██║     ██╔══╝  "),
    _createCommentVNode("    ██║   ██║  ██║██████╔╝███████╗███████╗"),
    _createCommentVNode("    ╚═╝   ╚═╝  ╚═╝╚═════╝ ╚══════╝╚══════╝"),
    _createCommentVNode(""),
    _createCommentVNode(" \"table\" view = manage leads table on left, regular sidebar on right"),
    _createCommentVNode(""),
    _createCommentVNode(""),
    _withDirectives(_createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        class: "map-collapse-trigger is-clickable",
        onClick: _cache[1] || (_cache[1] = $event => ($data.mapCollapsed = !$data.mapCollapsed))
      }, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_b_icon, { icon: "web" })
        ]),
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.l('manage-leads-sidebar/lead-map')), 1 /* TEXT */),
        _createElementVNode("div", null, [
          _createVNode(_component_b_icon, {
            icon: $data.mapCollapsed ? "chevron-down" : "chevron-up"
          }, null, 8 /* PROPS */, ["icon"])
        ])
      ]),
      _createVNode(_component_Collapse, {
        class: "map-container",
        when: $data.mapCollapsed
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: "is-relative",
            style: _normalizeStyle({ "height": $options.computedMapHeight + "px" })
          }, [
            ($props.googleMapsLoaded)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(_component_b_icon, {
                    icon: "arrow-expand-all",
                    onClick: _cache[2] || (_cache[2] = $event => ($options.toggleView("map"))),
                    "custom-size": "mdi-24px",
                    "custom-class": "is-clickable"
                  })
                ]))
              : _createCommentVNode("v-if", true),
            ($props.googleMapsLoaded)
              ? (_openBlock(), _createBlock(_component_google_map, {
                  key: "google_map_sidebar",
                  ref: "googleMapSidebar",
                  "map-id": "manage-leads-sidebar-map",
                  "min-zoom": 2,
                  "use-clustering": $props.userSettingMapUseClustering,
                  "clustering-max-zoom": $props.userSettingMapClusteringMaxZoom
                }, null, 8 /* PROPS */, ["use-clustering", "clustering-max-zoom"]))
              : _createCommentVNode("v-if", true)
          ], 4 /* STYLE */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["when"]),
      _cache[17] || (_cache[17] = _createElementVNode("div", { class: "separator" }, null, -1 /* HOISTED */)),
      _createElementVNode("div", {
        class: "kpi-collapse-trigger is-clickable",
        onClick: _cache[3] || (_cache[3] = $event => ($data.kpiCollapsed = !$data.kpiCollapsed))
      }, [
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_b_icon, { icon: "chart-bar" })
        ]),
        _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.l('manage-leads-sidebar/kpi')), 1 /* TEXT */),
        _createElementVNode("div", null, [
          _createVNode(_component_b_icon, {
            icon: $data.kpiCollapsed ? "chevron-down" : "chevron-up"
          }, null, 8 /* PROPS */, ["icon"])
        ])
      ]),
      _createVNode(_component_Collapse, {
        class: "kpi-container",
        when: $data.kpiCollapsed
      }, {
        default: _withCtx(() => [
          (_openBlock(), _createElementBlock("div", {
            class: "kpi-month-select",
            key: "sidebar_kpi_month_select_" + _ctx.activeLocalizationCode
          }, [
            _createVNode(_component_b_dropdown, {
              modelValue: _ctx.salesSelectedMonth,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.salesSelectedMonth) = $event)),
              "aria-role": "list"
            }, {
              trigger: _withCtx(({ active }) => [
                _createVNode(_component_b_field, {
                  label: _ctx.l("generic/month"),
                  "label-position": "on-border"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_button, {
                      label: _ctx.$dayjs().month(_ctx.salesSelectedMonth).format("MMMM"),
                      type: "is-select",
                      expanded: "",
                      size: "is-small",
                      "icon-right": active ? "chevron-up" : "chevron-down"
                    }, null, 8 /* PROPS */, ["label", "icon-right"])
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label"])
              ]),
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.monthsYtd(), (month) => {
                  return (_openBlock(), _createBlock(_component_b_dropdown_item, {
                    key: month.number,
                    "aria-role": "listitem",
                    value: month.number
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(month.display), 1 /* TEXT */)
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"]))
                }), 128 /* KEYED_FRAGMENT */))
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["modelValue"])
          ])),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_chart_target_actual_last_yr_sales, { autoWidth: true })
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_chart_monthly_sales, { autoWidth: true })
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.l('kpi/actual-ytd')), 1 /* TEXT */),
            _createElementVNode("div", null, _toDisplayString(_ctx.$filters.toCurrency($options.salesYtd)), 1 /* TEXT */),
            _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.l('kpi/actual-ytd-vs-target')), 1 /* TEXT */),
            _createElementVNode("div", null, _toDisplayString($options.salesVsTarget) + "%", 1 /* TEXT */)
          ])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["when"]),
      _cache[18] || (_cache[18] = _createElementVNode("div", { class: "separator" }, null, -1 /* HOISTED */))
    ], 512 /* NEED_PATCH */), [
      [_vShow, $data.toggled && $props.view === "table"]
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_14, [
      _createElementVNode("div", {
        class: "map-collapse-trigger is-justify-content-center mb-5 is-clickable",
        onClick: _cache[5] || (_cache[5] = $event => {$options.toggleSidebar(); $data.mapCollapsed = true})
      }, [
        _createVNode(_component_b_icon, { icon: "web" })
      ]),
      _createElementVNode("div", {
        class: "map-collapse-trigger is-justify-content-center is-clickable",
        onClick: _cache[6] || (_cache[6] = $event => {$options.toggleSidebar(); $data.kpiCollapsed = true})
      }, [
        _createVNode(_component_b_icon, { icon: "chart-bar" })
      ])
    ], 512 /* NEED_PATCH */), [
      [_vShow, !$data.toggled && $props.view === "table"]
    ]),
    _createCommentVNode(""),
    _createCommentVNode(""),
    _createCommentVNode(" ███╗   ███╗ █████╗ ██████╗"),
    _createCommentVNode(" ████╗ ████║██╔══██╗██╔══██╗"),
    _createCommentVNode(" ██╔████╔██║███████║██████╔╝"),
    _createCommentVNode(" ██║╚██╔╝██║██╔══██║██╔═══╝"),
    _createCommentVNode(" ██║ ╚═╝ ██║██║  ██║██║"),
    _createCommentVNode(" ╚═╝     ╚═╝╚═╝  ╚═╝╚═╝"),
    _createCommentVNode(""),
    _createCommentVNode(" \"map\" view = big google map on left, leads listing (name column) on sidebar"),
    _createCommentVNode(""),
    _createCommentVNode(""),
    _withDirectives(_createElementVNode("div", _hoisted_15, [
      _createElementVNode("div", _hoisted_16, [
        (_ctx.numFiltersActive === 0)
          ? (_openBlock(), _createElementBlock("h4", _hoisted_17, _toDisplayString(_ctx.leads.length) + " " + _toDisplayString(_ctx.l('manage-leads-table/num-leads')), 1 /* TEXT */))
          : (_ctx.numFiltersActive > 0)
            ? (_openBlock(), _createElementBlock("h4", _hoisted_18, [
                _createTextVNode(_toDisplayString(_ctx.leads.length) + " " + _toDisplayString(_ctx.l('manage-leads-table/num-leads')) + " - ", 1 /* TEXT */),
                _createElementVNode("span", {
                  class: "linktext normaltext normalcase",
                  onClick: _cache[7] || (_cache[7] = $event => (_ctx.leadsStore.resetFilters()))
                }, _toDisplayString(_ctx.l('manage-leads-table/clear-filters').toLowerCase()) + " (" + _toDisplayString(_ctx.numFiltersActive) + ")", 1 /* TEXT */)
              ]))
            : _createCommentVNode("v-if", true)
      ]),
      _createElementVNode("div", _hoisted_19, [
        _createElementVNode("div", _hoisted_20, [
          _createCommentVNode(" Name column header"),
          _createElementVNode("div", _hoisted_21, [
            _createElementVNode("div", _hoisted_22, [
              _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.settingsStore.label("leadname")), 1 /* TEXT */),
              _createElementVNode("div", _hoisted_24, [
                _createVNode(_component_columns_sorting, {
                  column: { "field": "leadname", "label": _ctx.settingsStore.label("leadname") },
                  "is-sidebar": true,
                  onSortingChanged: _cache[8] || (_cache[8] = $event => ($options.scrollTableToTop()))
                }, null, 8 /* PROPS */, ["column"])
              ])
            ]),
            _createElementVNode("div", null, [
              _createVNode(_component_columns_quicksearch, {
                column: { "field": "leadname", "label": _ctx.settingsStore.label("leadname") }
              }, null, 8 /* PROPS */, ["column"])
            ])
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.paginatedLeads, (lead) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "sidebar-rgrid-row",
              key: lead._id
            }, [
              _createElementVNode("div", _hoisted_25, [
                _createElementVNode("div", _hoisted_26, [
                  ($options.leadHasSubleads(lead))
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: "sidebar-row-collapse-button",
                        onClick: $event => ($options.toggleSubLeadsCollapse(lead))
                      }, [
                        _createVNode(_component_b_icon, {
                          icon: this.collapsedMainLeads.has(lead._id) ? "chevron-up" : "chevron-right",
                          "custom-size": "mdi-18px",
                          "custom-class": "gray-icon"
                        }, null, 8 /* PROPS */, ["icon"])
                      ], 8 /* PROPS */, _hoisted_27))
                    : _createCommentVNode("v-if", true)
                ]),
                _createElementVNode("div", {
                  style: {"display":"contents"},
                  onClick: $event => ($options.leadClicked(lead))
                }, [
                  _createElementVNode("div", _hoisted_29, [
                    _createTextVNode(_toDisplayString(lead.name), 1 /* TEXT */),
                    (lead.index && !$options.useMapClustering && _ctx.leadsPerPage <= 50)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_30, "#" + _toDisplayString(lead.index), 1 /* TEXT */))
                      : _createCommentVNode("v-if", true)
                  ])
                ], 8 /* PROPS */, _hoisted_28)
              ]),
              (_ctx.collapsedMainLeads.has(lead._id))
                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(lead.subleads, (sublead) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "sidebar-rgrid-subrow",
                      key: sublead._id
                    }, [
                      _cache[19] || (_cache[19] = _createElementVNode("div", { class: "sidebar-rgrid-entry row-collapse" }, null, -1 /* HOISTED */)),
                      _createElementVNode("div", {
                        style: {"display":"contents"},
                        onClick: $event => ($options.leadClicked(sublead))
                      }, [
                        _createElementVNode("div", _hoisted_32, [
                          _createElementVNode("div", _hoisted_33, [
                            _createElementVNode("div", _hoisted_34, [
                              _createVNode(_component_b_icon, { icon: "arrow-right-thin" })
                            ]),
                            _createElementVNode("div", null, [
                              _createTextVNode(_toDisplayString(sublead.name), 1 /* TEXT */),
                              (sublead.index && !$options.useMapClustering && _ctx.leadsPerPage <= 50)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_35, "#" + _toDisplayString(sublead.index), 1 /* TEXT */))
                                : _createCommentVNode("v-if", true)
                            ])
                          ])
                        ])
                      ], 8 /* PROPS */, _hoisted_31)
                    ]))
                  }), 128 /* KEYED_FRAGMENT */))
                : _createCommentVNode("v-if", true)
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ])
      ]),
      _createElementVNode("div", _hoisted_36, [
        _createElementVNode("div", _hoisted_37, [
          _createElementVNode("div", _hoisted_38, [
            _createElementVNode("div", _hoisted_39, [
              _createVNode(_component_b_dropdown, {
                modelValue: _ctx.leadsPerPage,
                "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => ((_ctx.leadsPerPage) = $event)),
                "aria-role": "list",
                position: "is-top-right",
                onChange: _cache[10] || (_cache[10] = (value) => $options.leadsPerPageChanged(value))
              }, {
                trigger: _withCtx(({ active }) => [
                  _createVNode(_component_b_field, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_b_button, {
                        label: _ctx.leadsPerPage,
                        type: "is-select",
                        expanded: "",
                        "icon-right": active ? "menu-up" : "menu-down"
                      }, null, 8 /* PROPS */, ["label", "icon-right"])
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1024 /* DYNAMIC_SLOTS */)
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_b_dropdown_item, { custom: "" }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_40, _toDisplayString(_ctx.l('manage-leads-table/leads-per-page')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_b_dropdown_item, {
                    "aria-role": "listitem",
                    value: "10"
                  }, {
                    default: _withCtx(() => _cache[20] || (_cache[20] = [
                      _createTextVNode("10")
                    ])),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_b_dropdown_item, {
                    "aria-role": "listitem",
                    value: "25"
                  }, {
                    default: _withCtx(() => _cache[21] || (_cache[21] = [
                      _createTextVNode("25")
                    ])),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_b_dropdown_item, {
                    "aria-role": "listitem",
                    value: "50"
                  }, {
                    default: _withCtx(() => _cache[22] || (_cache[22] = [
                      _createTextVNode("50")
                    ])),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_b_dropdown_item, {
                    "aria-role": "listitem",
                    value: "100"
                  }, {
                    default: _withCtx(() => _cache[23] || (_cache[23] = [
                      _createTextVNode("100")
                    ])),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_b_dropdown_item, {
                    "aria-role": "listitem",
                    value: "250"
                  }, {
                    default: _withCtx(() => _cache[24] || (_cache[24] = [
                      _createTextVNode("250")
                    ])),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_b_dropdown_item, {
                    "aria-role": "listitem",
                    value: "500"
                  }, {
                    default: _withCtx(() => _cache[25] || (_cache[25] = [
                      _createTextVNode("500")
                    ])),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_b_dropdown_item, {
                    "aria-role": "listitem",
                    value: "1000"
                  }, {
                    default: _withCtx(() => _cache[26] || (_cache[26] = [
                      _createTextVNode("1000")
                    ])),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["modelValue"])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_41, [
          _createElementVNode("div", {
            class: "pagination-button noselect",
            onClick: _cache[11] || (_cache[11] = $event => ($options.paginationFirstPage()))
          }, [
            _createVNode(_component_b_icon, {
              icon: "page-first",
              "custom-size": "mdi-24px"
            })
          ]),
          _createElementVNode("div", {
            class: "pagination-button noselect",
            onClick: _cache[12] || (_cache[12] = $event => ($options.paginationPrevPage()))
          }, [
            _createVNode(_component_b_icon, {
              icon: "chevron-left",
              "custom-size": "mdi-24px"
            })
          ]),
          _createElementVNode("div", _hoisted_42, [
            _createVNode(_component_b_input, {
              id: "leadsPerPageInputSidebar",
              type: "number",
              size: "is-small",
              min: "1",
              modelValue: _ctx.currentPage,
              onInput: _cache[13] || (_cache[13] = (event) => { $options.setPage(event.target.value) })
            }, null, 8 /* PROPS */, ["modelValue"]),
            _cache[27] || (_cache[27] = _createElementVNode("div", null, "/", -1 /* HOISTED */)),
            _createElementVNode("div", null, _toDisplayString(_ctx.pagination.lastPage), 1 /* TEXT */)
          ]),
          _createElementVNode("div", {
            class: "pagination-button noselect",
            onClick: _cache[14] || (_cache[14] = $event => ($options.paginationNextPage()))
          }, [
            _createVNode(_component_b_icon, {
              icon: "chevron-right",
              "custom-size": "mdi-24px"
            })
          ]),
          _createElementVNode("div", {
            class: "pagination-button noselect",
            onClick: _cache[15] || (_cache[15] = $event => ($options.paginationLastPage()))
          }, [
            _createVNode(_component_b_icon, {
              icon: "page-last",
              "custom-size": "mdi-24px"
            })
          ])
        ])
      ])
    ], 512 /* NEED_PATCH */), [
      [_vShow, $data.toggled && $props.view === "map"]
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_43, [
      _createElementVNode("div", {
        class: "is-flex is-align-items-center is-justify-content-center is-clickable",
        onClick: _cache[16] || (_cache[16] = $event => ($options.toggleSidebar()))
      }, [
        _createVNode(_component_b_icon, { icon: "format-list-bulleted" })
      ])
    ], 512 /* NEED_PATCH */), [
      [_vShow, !$data.toggled && $props.view === "map"]
    ])
  ]))
}