//
//
// RUDOLF frontend
//
// Nurminen Development Oy Ltd - https://nurminen.dev
//
// For customer: The Rudolf Oy
//
// ALL RIGHTS RESERVED BY CUSTOMER
//
//

//
// File author(s):
//   - Riku Nurminen <riku@nurminen.dev>
//


import { defineStore }              from 'pinia'

import { apiRequest }               from '../api_request.js'

import * as crudFactory             from './_crud.js'
import { useQuestionsStore }        from './questions.js'
import { useTasksStore }            from './tasks.js'


const api = {
    'index':                    [ 'GET',        '/reports' ],
    'update':                   [ 'PATCH',      '/reports' ],
}


const reports       = []
const reportsMap    = new Map()


export const useReportsStore = defineStore('reports', {
    state: () => {
        return {
            ...crudFactory.states(),
        }
    },


    getters: {
        ...crudFactory.getters(reports, reportsMap),

        byAssignment: (state) => (taskId, productId) => {
            state.updateToggler

            const item = reports.find(r => r.task === taskId && r.product === productId)

            return item ? item : {}
        },

        byTaskId: (state) => (taskId) => {
            return state.all.filter(r => r.task === taskId)
        }
    },


    actions: {
        ...crudFactory.actions('reports', {}, reports, reportsMap),


        async update({ id, payload }) {
            const questionsStore = useQuestionsStore()
            const tasksStore = useTasksStore()

            let path = api.update[1] + `/${id}`

            const { updatedReport, updatedQuestions, updatedTask } = await apiRequest(api.update[0], path, payload)

            this.PUT(updatedReport)
            questionsStore.PUT(updatedQuestions)
            tasksStore.UPDATE(updatedTask)
        }
    }

})
