import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "has-text-centered overview-button sidebar-button" }
const _hoisted_2 = { class: "has-text-centered manage-leads-button sidebar-button" }
const _hoisted_3 = { class: "has-text-centered activities-button sidebar-button" }
const _hoisted_4 = { class: "has-text-centered setup-button sidebar-button" }
const _hoisted_5 = { class: "is-flex is-justify-content-center is-align-items-center" }
const _hoisted_6 = { class: "usermgmtlink-wide" }
const _hoisted_7 = { class: "usermgmtlink-narrow" }
const _hoisted_8 = { class: "sidebar-bottom" }
const _hoisted_9 = { class: "has-text-centered account-button sidebar-button" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_link = _resolveComponent("router-link")
  const _component_b_dropdown_item = _resolveComponent("b-dropdown-item")
  const _component_b_dropdown = _resolveComponent("b-dropdown")
  const _component_b_sidebar = _resolveComponent("b-sidebar")

  return (_openBlock(), _createBlock(_component_b_sidebar, {
    modelValue: $data.sidebarOpen,
    "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => (($data.sidebarOpen) = $event)),
    position: "static",
    fullheight: true,
    mobile: "hide"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_router_link, {
          to: { name: "overview" },
          title: "Overview",
          onClick: _cache[0] || (_cache[0] = $event => ($options.toggleSetupDropdown(false)))
        }, {
          default: _withCtx(() => [
            _createElementVNode("figure", _hoisted_1, [
              _createElementVNode("figcaption", null, _toDisplayString(_ctx.l('sidebar/overview')), 1 /* TEXT */)
            ])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_router_link, {
          to: { name: "manage-leads" },
          title: "Manage leads",
          onClick: _cache[1] || (_cache[1] = $event => ($options.toggleSetupDropdown(false)))
        }, {
          default: _withCtx(() => [
            _createElementVNode("figure", _hoisted_2, [
              _createElementVNode("figcaption", null, _toDisplayString(_ctx.l('sidebar/manageleads')), 1 /* TEXT */)
            ])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_router_link, {
          to: { name: "activities" },
          title: "My activities",
          onClick: _cache[2] || (_cache[2] = $event => ($options.toggleSetupDropdown(false)))
        }, {
          default: _withCtx(() => [
            _createElementVNode("figure", _hoisted_3, [
              _createElementVNode("figcaption", null, _toDisplayString(_ctx.l('sidebar/activities')), 1 /* TEXT */)
            ])
          ]),
          _: 1 /* STABLE */
        }),
        _withDirectives(_createVNode(_component_b_dropdown, {
          "close-on-click": false,
          "can-close": false,
          ref: "setupMenuDropdown",
          triggers: [],
          "aria-role": "list",
          "mobile-modal": false
        }, {
          trigger: _withCtx(({ active }) => [
            _createVNode(_component_router_link, {
              to: { name: "setup-products" },
              title: "Setup",
              onClick: _cache[3] || (_cache[3] = $event => ($options.toggleSetupDropdown(true))),
              class: _normalizeClass({ "is-active-dropdown": $options.isSetupSubPage })
            }, {
              default: _withCtx(() => [
                _createElementVNode("figure", _hoisted_4, [
                  _createElementVNode("figcaption", _hoisted_5, _toDisplayString(_ctx.l('sidebar/setup')), 1 /* TEXT */)
                ])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["class"])
          ]),
          default: _withCtx(() => [
            _createVNode(_component_b_dropdown_item, {
              "aria-role": "listitem",
              "has-link": true
            }, {
              default: _withCtx(() => [
                _createVNode(_component_router_link, {
                  to: { name: "setup-products" },
                  title: "Products"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.l('sidebar/setup-products')), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_b_dropdown_item, {
              "aria-role": "listitem",
              "has-link": true
            }, {
              default: _withCtx(() => [
                _createVNode(_component_router_link, {
                  to: { name: "setup-users" },
                  title: "Subscription & User Role"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.l('sidebar/setup-users')), 1 /* TEXT */),
                    _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.l('sidebar/setup-users-short')), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_b_dropdown_item, {
              "aria-role": "listitem",
              "has-link": true
            }, {
              default: _withCtx(() => [
                _createVNode(_component_router_link, {
                  to: { name: "setup-data" },
                  title: "Data"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.l('sidebar/setup-data')), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_b_dropdown_item, {
              "aria-role": "listitem",
              "has-link": true
            }, {
              default: _withCtx(() => [
                _createVNode(_component_router_link, {
                  to: { name: "setup-labels" },
                  title: "Labels"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.l('sidebar/setup-labels')), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }),
            (_ctx.authStore.isSuperAdmin)
              ? (_openBlock(), _createBlock(_component_b_dropdown_item, {
                  key: 0,
                  "aria-role": "listitem",
                  "has-link": true
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_link, {
                      to: { name: "setup-localization" },
                      title: "Labels"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.l('sidebar/setup-localization')), 1 /* TEXT */)
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        }, 512 /* NEED_PATCH */), [
          [_vShow, _ctx.authStore.isAdmin]
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createCommentVNode("router-link(:to='{ name: \"outlook\" }' title='Outlook' @click='toggleSetupDropdown(false)')\n  figure.has-text-centered.outlook-button.sidebar-button\n    figcaption OUTLOOK\nb-field.has-text-centered.outlook-switch\n  b-switch(size='is-small')"),
        _createVNode(_component_router_link, {
          to: { name: "account" },
          title: "My Account",
          onClick: _cache[4] || (_cache[4] = $event => ($options.toggleSetupDropdown(false)))
        }, {
          default: _withCtx(() => [
            _createElementVNode("figure", _hoisted_9, [
              _createElementVNode("figcaption", null, _toDisplayString(_ctx.l('sidebar/account')), 1 /* TEXT */)
            ])
          ]),
          _: 1 /* STABLE */
        })
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue"]))
}