import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from 'FRONTEND/images/account-user-profile-placeholder.png'
import _imports_1 from 'FRONTEND/images/topbar-rudolf-icon.svg'


const _hoisted_1 = { class: "page-content-inner is-relative" }
const _hoisted_2 = { class: "lead-content rudolfbox" }
const _hoisted_3 = { class: "mt-6 mb-4 has-text-centered is-italic" }
const _hoisted_4 = { class: "mb-6 has-text-centered is-italic" }
const _hoisted_5 = {
  key: 0,
  class: "lead-details rudolfbox mb-5 is-relative"
}
const _hoisted_6 = { class: "is-flex" }
const _hoisted_7 = {
  class: "rtitle",
  id: "company-and-contact-details-header"
}
const _hoisted_8 = {
  class: "ml-2",
  style: {"color":"#000"}
}
const _hoisted_9 = {
  key: 0,
  class: "is-flex-grow-1 is-flex is-justify-content-flex-end lead-buttons-area"
}
const _hoisted_10 = {
  key: 0,
  class: "ml-2 mt-4"
}
const _hoisted_11 = { class: "mt-4" }
const _hoisted_12 = { class: "lead-content rudolfbox" }
const _hoisted_13 = { class: "is-relative" }
const _hoisted_14 = { class: "products-header" }
const _hoisted_15 = { class: "rtitle sm" }
const _hoisted_16 = {
  key: 0,
  class: "assign-product-dropdown"
}
const _hoisted_17 = { class: "is-relative" }
const _hoisted_18 = { class: "lead-product-scroll-container mb-5 noselect" }
const _hoisted_19 = ["onClick"]
const _hoisted_20 = { class: "product-title" }
const _hoisted_21 = { class: "product-dropdown-content" }
const _hoisted_22 = ["onClick"]
const _hoisted_23 = { class: "mr-2" }
const _hoisted_24 = { class: "noselect" }
const _hoisted_25 = {
  key: 1,
  class: "status-header"
}
const _hoisted_26 = { class: "status-name rtitle sm" }
const _hoisted_27 = {
  key: 2,
  class: "assignment-info"
}
const _hoisted_28 = { class: "assignment-info-left" }
const _hoisted_29 = {
  key: 0,
  class: "assignment-info-right rtitle sm normalcase"
}
const _hoisted_30 = {
  key: 1,
  class: "assignment-info-right rtitle sm normalcase"
}
const _hoisted_31 = {
  key: 3,
  class: "assignment-info"
}
const _hoisted_32 = { class: "assignment-info-left" }
const _hoisted_33 = {
  key: 0,
  class: "assignment-info-right rtitle sm normalcase"
}
const _hoisted_34 = {
  key: 1,
  class: "assignment-info-right rtitle sm normalcase"
}
const _hoisted_35 = {
  key: 4,
  class: "assignment-info mt-4 mb-4"
}
const _hoisted_36 = { class: "greytext" }
const _hoisted_37 = {
  key: 5,
  class: "assignment-info edit"
}
const _hoisted_38 = { class: "assignment-info-left" }
const _hoisted_39 = {
  key: 6,
  class: "assignment-info"
}
const _hoisted_40 = { class: "assignment-info-left" }
const _hoisted_41 = { class: "assignment-info-right rtitle sm normalcase" }
const _hoisted_42 = {
  key: 0,
  class: "lead-content-separator mb-5"
}
const _hoisted_43 = {
  key: 0,
  class: "is-flex is-justify-content-space-between mb-2"
}
const _hoisted_44 = { class: "is-flex-grow-1" }
const _hoisted_45 = { class: "rtitle sm mb-4" }
const _hoisted_46 = { class: "is-flex-grow-1 has-text-right" }
const _hoisted_47 = { class: "rtitle sm mb-4 noselect" }
const _hoisted_48 = {
  key: 1,
  class: "is-flex is-justify-content-space-between mb-2"
}
const _hoisted_49 = { class: "is-flex-grow-1" }
const _hoisted_50 = { class: "rtitle sm mb-4" }
const _hoisted_51 = { class: "is-flex-grow-1 has-text-right" }
const _hoisted_52 = { class: "rtitle sm mb-4 noselect" }
const _hoisted_53 = { key: 2 }
const _hoisted_54 = {
  key: 0,
  class: "report is-relative"
}
const _hoisted_55 = {
  key: 0,
  class: "columns"
}
const _hoisted_56 = { class: "column is-4" }
const _hoisted_57 = { class: "reportbox-grey is-relative" }
const _hoisted_58 = { class: "rtitle sm semibold" }
const _hoisted_59 = { class: "column is-4" }
const _hoisted_60 = { class: "reportbox-grey is-relative" }
const _hoisted_61 = { class: "rtitle sm semibold" }
const _hoisted_62 = { class: "column is-4" }
const _hoisted_63 = { class: "reportbox-grey is-relative" }
const _hoisted_64 = { class: "rtitle sm semibold" }
const _hoisted_65 = {
  key: 0,
  class: "status-selection"
}
const _hoisted_66 = { class: "status-text" }
const _hoisted_67 = { class: "status-selection" }
const _hoisted_68 = { class: "status-text" }
const _hoisted_69 = { class: "columns mt-2" }
const _hoisted_70 = { class: "column is-9" }
const _hoisted_71 = {
  key: 0,
  class: "mt-6 mb-6 has-text-centered is-italic"
}
const _hoisted_72 = {
  key: 1,
  class: "mt-6 mb-6 has-text-centered is-italic"
}
const _hoisted_73 = {
  key: 2,
  class: "mt-6 mb-6 has-text-centered is-italic"
}
const _hoisted_74 = {
  key: 3,
  class: "mt-6 mb-6 has-text-centered is-italic"
}
const _hoisted_75 = {
  key: 4,
  class: "mt-6 mb-6 has-text-centered is-italic"
}
const _hoisted_76 = {
  key: 5,
  class: "questions-list"
}
const _hoisted_77 = {
  key: 0,
  class: "has-text-danger mt-2"
}
const _hoisted_78 = {
  key: 1,
  class: "slider-question",
  style: {"width":"100%"}
}
const _hoisted_79 = { class: "columns m-0" }
const _hoisted_80 = { class: "column is-4 p-0" }
const _hoisted_81 = { style: {"font-size":"0.857rem"} }
const _hoisted_82 = { class: "column is-4 p-0 has-text-centered" }
const _hoisted_83 = { style: {"font-size":"0.857rem"} }
const _hoisted_84 = { class: "bold" }
const _hoisted_85 = { class: "column is-4 p-0 has-text-right" }
const _hoisted_86 = { style: {"font-size":"0.857rem"} }
const _hoisted_87 = {
  key: 0,
  class: "has-text-danger mt-2"
}
const _hoisted_88 = {
  key: 0,
  class: "has-text-danger mt-2",
  style: {"font-weight":"500","padding-left":"10px"}
}
const _hoisted_89 = {
  key: 0,
  class: "has-text-danger mt-2",
  style: {"font-weight":"500","padding-left":"10px"}
}
const _hoisted_90 = {
  key: 0,
  class: "has-text-danger mt-2",
  style: {"font-weight":"500","padding-left":"10px"}
}
const _hoisted_91 = { class: "column is-3" }
const _hoisted_92 = {
  key: 0,
  class: "save-report"
}
const _hoisted_93 = {
  key: 1,
  class: "has-text-centered mt-3 mb-6"
}
const _hoisted_94 = { class: "rtitle normalcase" }
const _hoisted_95 = { class: "comments" }
const _hoisted_96 = { class: "comments-tabs" }
const _hoisted_97 = {
  key: 0,
  class: "new-comment-area is-relative"
}
const _hoisted_98 = {
  key: 1,
  class: "comments-separator"
}
const _hoisted_99 = {
  key: 2,
  class: "has-text-centered is-italic mt-4 mb-1"
}
const _hoisted_100 = {
  key: 3,
  class: "has-text-centered is-italic mt-4 mb-1"
}
const _hoisted_101 = { class: "user-avatar" }
const _hoisted_102 = {
  key: 0,
  src: _imports_1,
  width: "35",
  style: {"margin-top":"5px"}
}
const _hoisted_103 = {
  key: 1,
  src: _imports_0,
  width: "40"
}
const _hoisted_104 = { class: "comment-content" }
const _hoisted_105 = { class: "comment-posted-by-at" }
const _hoisted_106 = { class: "semibold" }
const _hoisted_107 = {
  key: 0,
  class: "comment-text"
}
const _hoisted_108 = {
  key: 1,
  class: "comment-text"
}
const _hoisted_109 = ["innerHTML"]
const _hoisted_110 = { class: "kpi is-relative" }
const _hoisted_111 = { class: "is-flex mb-4" }
const _hoisted_112 = { class: "rtitle sm" }
const _hoisted_113 = { class: "chart-month-select" }
const _hoisted_114 = { class: "columns is-align-items-center mb-6" }
const _hoisted_115 = { class: "column pl-6 pr-6 is-flex is-justify-content-center" }
const _hoisted_116 = { style: {"flex-basis":"400px"} }
const _hoisted_117 = { class: "column pr-6 is-flex is-justify-content-center" }
const _hoisted_118 = { style: {"flex-basis":"400px"} }
const _hoisted_119 = {
  class: "column is-3",
  style: {"margin-top":"20px"}
}
const _hoisted_120 = { class: "sales-ytd-totals" }
const _hoisted_121 = { class: "sales-ytd-container bold" }
const _hoisted_122 = { class: "sales-ytd-value" }
const _hoisted_123 = { class: "greytext" }
const _hoisted_124 = { class: "sales-ytd-vs-target" }
const _hoisted_125 = { class: "greytext" }
const _hoisted_126 = { class: "rtitle sm normalcase mt-5 mb-5" }
const _hoisted_127 = { class: "columns" }
const _hoisted_128 = { class: "column is-6" }
const _hoisted_129 = { class: "kpi-category-breakdown-table" }
const _hoisted_130 = { class: "category-product-name" }
const _hoisted_131 = { class: "semibold" }
const _hoisted_132 = { class: "semibold" }
const _hoisted_133 = { class: "semibold" }
const _hoisted_134 = { class: "is-relative" }
const _hoisted_135 = { class: "kpi-sales-goal-input-container" }
const _hoisted_136 = { class: "mr-6" }
const _hoisted_137 = { class: "rtitle sm normalcase" }
const _hoisted_138 = { style: {"width":"300px"} }
const _hoisted_139 = { class: "columns monthly-actuals" }
const _hoisted_140 = { class: "column is-8" }
const _hoisted_141 = { class: "column is-6" }
const _hoisted_142 = { class: "control" }
const _hoisted_143 = { class: "button is-static" }
const _hoisted_144 = { class: "monthly-reach" }
const _hoisted_145 = { class: "mr-2" }
const _hoisted_146 = { class: "column is-6" }
const _hoisted_147 = { class: "control" }
const _hoisted_148 = { class: "button is-static" }
const _hoisted_149 = { class: "monthly-reach" }
const _hoisted_150 = { class: "mr-2" }
const _hoisted_151 = { class: "rudolfbox" }
const _hoisted_152 = { class: "modal-inner has-text-centered" }
const _hoisted_153 = ["innerHTML"]
const _hoisted_154 = { class: "buttons mb-0" }
const _hoisted_155 = { class: "rudolfbox" }
const _hoisted_156 = { class: "modal-inner has-text-centered" }
const _hoisted_157 = { class: "rtitle mt-4 mb-4" }
const _hoisted_158 = { class: "modal-text mb-5" }
const _hoisted_159 = { class: "buttons mb-0" }
const _hoisted_160 = { class: "rudolfbox" }
const _hoisted_161 = { class: "modal-inner has-text-centered" }
const _hoisted_162 = ["innerHTML"]
const _hoisted_163 = { class: "buttons mb-0" }
const _hoisted_164 = { class: "rudolfbox" }
const _hoisted_165 = { class: "modal-inner has-text-centered" }
const _hoisted_166 = { class: "rtitle mt-4 mb-4" }
const _hoisted_167 = { class: "lead-unsaved-changes-modal-list" }
const _hoisted_168 = { key: 0 }
const _hoisted_169 = { key: 1 }
const _hoisted_170 = { key: 2 }
const _hoisted_171 = { class: "mb-4 semibold" }
const _hoisted_172 = { class: "buttons mb-0" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_link = _resolveComponent("router-link")
  const _component_b_icon = _resolveComponent("b-icon")
  const _component_b_tooltip = _resolveComponent("b-tooltip")
  const _component_lead_details_active_fields = _resolveComponent("lead-details-active-fields")
  const _component_add_edit_lead = _resolveComponent("add-edit-lead")
  const _component_b_loading = _resolveComponent("b-loading")
  const _component_b_autocomplete = _resolveComponent("b-autocomplete")
  const _component_b_dropdown_item = _resolveComponent("b-dropdown-item")
  const _component_b_dropdown = _resolveComponent("b-dropdown")
  const _component_b_button = _resolveComponent("b-button")
  const _component_b_datetimepicker = _resolveComponent("b-datetimepicker")
  const _component_b_field = _resolveComponent("b-field")
  const _component_b_datepicker = _resolveComponent("b-datepicker")
  const _component_b_checkbox = _resolveComponent("b-checkbox")
  const _component_b_radio = _resolveComponent("b-radio")
  const _component_b_slider = _resolveComponent("b-slider")
  const _component_b_input = _resolveComponent("b-input")
  const _component_chart_target_actual_last_yr_sales = _resolveComponent("chart-target-actual-last-yr-sales")
  const _component_chart_monthly_sales = _resolveComponent("chart-monthly-sales")
  const _component_b_table_column = _resolveComponent("b-table-column")
  const _component_b_table = _resolveComponent("b-table")
  const _component_b_modal = _resolveComponent("b-modal")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.l('lead-page/no-access-or-deleted')), 1 /* TEXT */),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_router_link, {
          to: { name: "manage-leads" },
          title: _ctx.l("manage-leads/pagetitle")
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.l('lead-page/go-back-manage-leads')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["title"])
      ])
    ], 512 /* NEED_PATCH */), [
      [_vShow, !_ctx.appLoading && !$options.lead._id]
    ]),
    _createCommentVNode(""),
    _createCommentVNode(""),
    _createCommentVNode(" Lead details / edit lead collapse"),
    _createCommentVNode(""),
    _createCommentVNode(""),
    (!_ctx.appLoading && $options.lead._id)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", {
              class: "is-flex is-clickable noselect",
              onClick: _cache[0] || (_cache[0] = $event => ($data.leadInfoOpen = !$data.leadInfoOpen))
            }, [
              _createElementVNode("div", null, [
                _createElementVNode("h5", _hoisted_7, _toDisplayString(_ctx.l('lead-page/company-and-contact-details')), 1 /* TEXT */)
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_b_icon, {
                  icon: $data.leadInfoOpen ? "chevron-up" : "chevron-down",
                  "custom-size": "mdi-18px"
                }, null, 8 /* PROPS */, ["icon"])
              ])
            ]),
            ($data.leadInfoOpen && _ctx.authStore.user?.role !== "basic")
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  (_ctx.$refs.editLeadComponent.editMode === false)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: "is-clickable mr-4",
                        onClick: _cache[1] || (_cache[1] = _withModifiers($event => (_ctx.$refs.editLeadComponent.editClicked()), ["stop"]))
                      }, [
                        _createVNode(_component_b_tooltip, {
                          type: "is-white",
                          position: "is-left",
                          delay: 1000,
                          label: _ctx.l("lead-page/edit-lead-button-tooltip")
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_b_icon, {
                              icon: "pencil-outline",
                              "custom-size": "mdi-24px"
                            })
                          ]),
                          _: 1 /* STABLE */
                        }, 8 /* PROPS */, ["label"])
                      ]))
                    : _createCommentVNode("v-if", true),
                  _createElementVNode("div", {
                    class: "is-clickable mr-3",
                    onClick: _cache[2] || (_cache[2] = _withModifiers($event => ($options.openCopyLeadModal()), ["stop"]))
                  }, [
                    _createVNode(_component_b_tooltip, {
                      type: "is-white",
                      position: "is-left",
                      delay: 1000,
                      label: _ctx.l("lead-page/copy-lead-button-tooltip")
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_b_icon, {
                          icon: "content-copy",
                          "custom-size": "mdi-24px"
                        })
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["label"])
                  ]),
                  _createElementVNode("div", {
                    class: "is-clickable mr-3",
                    onClick: _cache[3] || (_cache[3] = _withModifiers($event => ($options.deleteLeadConfirmModal()), ["stop"]))
                  }, [
                    _createVNode(_component_b_tooltip, {
                      type: "is-white",
                      position: "is-left",
                      delay: 1000,
                      label: _ctx.l("lead-page/delete-lead-button-tooltip")
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_b_icon, {
                          icon: "delete-outline",
                          "custom-size": "mdi-24px"
                        })
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["label"])
                  ])
                ]))
              : _createCommentVNode("v-if", true)
          ]),
          (!$data.leadInfoOpen && $options.activeDetailsFields.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createVNode(_component_lead_details_active_fields, {
                  lead: $options.lead,
                  activeFields: $options.activeDetailsFields
                }, null, 8 /* PROPS */, ["lead", "activeFields"])
              ]))
            : _createCommentVNode("v-if", true),
          _withDirectives(_createElementVNode("div", _hoisted_11, [
            (!_ctx.appLoading)
              ? (_openBlock(), _createBlock(_component_add_edit_lead, {
                  key: 0,
                  ref: "editLeadComponent",
                  leadId: $options.leadId,
                  "google-maps-loaded": $data.googleMapsLoaded,
                  onMainDetailsEdited: _cache[4] || (_cache[4] = $event => ($options.afterMainDetailsEdit()))
                }, null, 8 /* PROPS */, ["leadId", "google-maps-loaded"]))
              : _createCommentVNode("v-if", true)
          ], 512 /* NEED_PATCH */), [
            [_vShow, $data.leadInfoOpen]
          ]),
          _createVNode(_component_b_loading, {
            "is-full-page": false,
            modelValue: $data.loading,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => (($data.loading) = $event))
          }, null, 8 /* PROPS */, ["modelValue"])
        ]))
      : _createCommentVNode("v-if", true),
    _createCommentVNode(""),
    _createCommentVNode(""),
    _createCommentVNode(" ██████╗ ██████╗  ██████╗ ██████╗ ██╗   ██╗ ██████╗████████╗███████╗"),
    _createCommentVNode(" ██╔══██╗██╔══██╗██╔═══██╗██╔══██╗██║   ██║██╔════╝╚══██╔══╝██╔════╝"),
    _createCommentVNode(" ██████╔╝██████╔╝██║   ██║██║  ██║██║   ██║██║        ██║   ███████╗"),
    _createCommentVNode(" ██╔═══╝ ██╔══██╗██║   ██║██║  ██║██║   ██║██║        ██║   ╚════██║"),
    _createCommentVNode(" ██║     ██║  ██║╚██████╔╝██████╔╝╚██████╔╝╚██████╗   ██║   ███████║"),
    _createCommentVNode(" ╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═════╝  ╚═════╝  ╚═════╝   ╚═╝   ╚══════╝"),
    _createCommentVNode(""),
    _createCommentVNode(" Assignments product selector"),
    _createCommentVNode(""),
    _withDirectives(_createElementVNode("div", _hoisted_12, [
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.l('lead-page/products-header')), 1 /* TEXT */),
          (_ctx.authStore.user?.role !== "basic")
            ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                _createVNode(_component_b_autocomplete, {
                  modelValue: $data.assignProductSearchStr,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => (($data.assignProductSearchStr) = $event)),
                  placeholder: _ctx.l("lead-page/assign-product") + "...",
                  icon: "plus",
                  size: "is-small",
                  "open-on-focus": true,
                  "append-to-body": true,
                  "clear-on-select": true,
                  data: $options.filteredProducts,
                  field: "name",
                  onSelect: _cache[7] || (_cache[7] = product => $options.assignProduct(product)),
                  disabled: $data.loading
                }, null, 8 /* PROPS */, ["modelValue", "placeholder", "data", "disabled"])
              ]))
            : _createCommentVNode("v-if", true)
        ]),
        _withDirectives(_createElementVNode("div", { class: "mt-5 mb-5 is-italic" }, _toDisplayString(_ctx.l('lead-page/no-assignments-text')), 513 /* TEXT, NEED_PATCH */), [
          [_vShow, $options.leadAssignments.length === 0]
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_17, [
          _createElementVNode("div", {
            class: "product-scroll-left-container",
            onClick: _cache[8] || (_cache[8] = $event => ($options.productListScroll(-350)))
          }, [
            _createVNode(_component_b_icon, {
              icon: "chevron-left",
              "custom-class": "left-scroll-arrow"
            })
          ]),
          _createElementVNode("div", {
            class: "product-scroll-right-container",
            onClick: _cache[9] || (_cache[9] = $event => ($options.productListScroll(350)))
          }, [
            _createVNode(_component_b_icon, {
              icon: "chevron-right",
              "custom-class": "right-scroll-arrow"
            })
          ]),
          _createElementVNode("div", _hoisted_18, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.leadAssignments, (assignment, idx) => {
              return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["lead-product is-relative", { "selected": $options.selectedAssignment?.productId === assignment.productId }]),
                key: assignment.productId,
                onClick: $event => ($options.selectAssignment(assignment.productId, true)),
                style: _normalizeStyle({              "borderColor": assignment.statusColor || "#ccc",            })
              }, [
                _createElementVNode("div", _hoisted_20, _toDisplayString(assignment.productName), 1 /* TEXT */),
                (_ctx.authStore.isAdmin)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: "product-dots",
                      onClick: _cache[10] || (_cache[10] = _withModifiers(() => {}, ["stop"]))
                    }, [
                      _createVNode(_component_b_dropdown, {
                        position: idx === $options.leadAssignments.length - 1 ? "is-bottom-left" : "is-bottom-right",
                        "mobile-modal": false
                      }, {
                        trigger: _withCtx(() => [
                          _createVNode(_component_b_icon, {
                            class: "is-clickable",
                            icon: "dots-vertical"
                          })
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_b_dropdown_item, {
                            focusable: false,
                            custom: "",
                            paddingless: ""
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_21, [
                                _createElementVNode("div", {
                                  class: "product-dropdown-item is-clickable",
                                  onClick: $event => ($options.unassignProductConfirmModal(assignment.productId))
                                }, [
                                  _createElementVNode("div", _hoisted_23, [
                                    _createVNode(_component_b_icon, { icon: "delete-outline" })
                                  ]),
                                  _createElementVNode("div", _hoisted_24, _toDisplayString(_ctx.l('lead-page/remove-product-assignment')), 1 /* TEXT */)
                                ], 8 /* PROPS */, _hoisted_22)
                              ])
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1024 /* DYNAMIC_SLOTS */)
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["position"])
                    ]))
                  : _createCommentVNode("v-if", true),
                (assignment.hasReport)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                      (assignment.statusColor)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: "status-indicator",
                            style: _normalizeStyle({ "backgroundColor": assignment.statusColor })
                          }, null, 4 /* STYLE */))
                        : _createCommentVNode("v-if", true),
                      _createElementVNode("span", _hoisted_26, _toDisplayString(assignment.statusText), 1 /* TEXT */)
                    ]))
                  : _createCommentVNode("v-if", true),
                (assignment.hasReport)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                      _createElementVNode("div", _hoisted_28, _toDisplayString(_ctx.settingsStore.label("nextappointment")), 1 /* TEXT */),
                      (assignment.nextAppt)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                            _createTextVNode(_toDisplayString(_ctx.$dayjs(assignment.nextAppt).format('ll')), 1 /* TEXT */),
                            _cache[53] || (_cache[53] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
                            _createTextVNode(_toDisplayString(_ctx.$dayjs(assignment.nextAppt).format('LT')), 1 /* TEXT */),
                            (assignment.nextApptEnd)
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                  _createTextVNode("-" + _toDisplayString(_ctx.$dayjs(assignment.nextApptEnd).format('LT')), 1 /* TEXT */)
                                ], 64 /* STABLE_FRAGMENT */))
                              : _createCommentVNode("v-if", true)
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_30, "-"))
                    ]))
                  : _createCommentVNode("v-if", true),
                (assignment.hasReport)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                      _createElementVNode("div", _hoisted_32, _toDisplayString(_ctx.settingsStore.label("deadline")), 1 /* TEXT */),
                      (assignment.dueDate)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_33, _toDisplayString(_ctx.$dayjs(assignment.dueDate).format('ll')), 1 /* TEXT */))
                        : (_openBlock(), _createElementBlock("div", _hoisted_34, "-"))
                    ]))
                  : _createCommentVNode("v-if", true),
                (assignment.accessLevel === "assign_right")
                  ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
                      _createElementVNode("div", _hoisted_36, [
                        _createElementVNode("i", null, _toDisplayString(_ctx.l('lead-page/assign-right-only')), 1 /* TEXT */)
                      ])
                    ]))
                  : _createCommentVNode("v-if", true),
                ($options.isUserAssignmentEditable(assignment))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_37, [
                      _createElementVNode("div", _hoisted_38, _toDisplayString(_ctx.l('generic/assigned-to')) + ":", 1 /* TEXT */),
                      _createElementVNode("div", {
                        class: "assignment-change-dropdown",
                        onClick: _cache[11] || (_cache[11] = _withModifiers(() => {}, ["stop"]))
                      }, [
                        _createVNode(_component_b_dropdown, {
                          position: idx === $options.leadAssignments.length - 1 ? "is-bottom-left" : "is-bottom-right",
                          "aria-role": "list",
                          scrollable: "",
                          "append-to-body": true,
                          modelValue: assignment.assigneeId || "unassigned",
                          onChange: (assigneeId) => $options.changeUserAssignment(assignment, assigneeId),
                          disabled: $data.loading
                        }, {
                          trigger: _withCtx(({ active }) => [
                            _createVNode(_component_b_button, {
                              label: assignment.assigneeFullName,
                              type: "is-select",
                              size: "is-small",
                              expanded: "",
                              "icon-right": active ? "menu-up" : "menu-down",
                              class: _normalizeClass({ "is-italic": !assignment.assigneeId || assignment.assigneeId === "unassigned" })
                            }, null, 8 /* PROPS */, ["label", "icon-right", "class"])
                          ]),
                          default: _withCtx(() => [
                            (assignment.assigneeId && assignment.assigneeId !== "unassigned")
                              ? (_openBlock(), _createBlock(_component_b_dropdown_item, {
                                  key: 0,
                                  "aria-role": "listitem",
                                  value: "unassigned"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.l('generic/unassigned')), 1 /* TEXT */)
                                  ]),
                                  _: 1 /* STABLE */
                                }))
                              : _createCommentVNode("v-if", true),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.users, (user) => {
                              return (_openBlock(), _createElementBlock(_Fragment, {
                                key: user._id
                              }, [
                                (assignment.assigneeId !== user._id)
                                  ? (_openBlock(), _createBlock(_component_b_dropdown_item, {
                                      key: 0,
                                      "aria-role": "listitem",
                                      value: user._id
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(user.fullName), 1 /* TEXT */)
                                      ]),
                                      _: 2 /* DYNAMIC */
                                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"]))
                                  : _createCommentVNode("v-if", true)
                              ], 64 /* STABLE_FRAGMENT */))
                            }), 128 /* KEYED_FRAGMENT */))
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["position", "modelValue", "onChange", "disabled"])
                      ])
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_39, [
                      _createElementVNode("div", _hoisted_40, _toDisplayString(_ctx.l('generic/assigned-to')) + ":", 1 /* TEXT */),
                      _createElementVNode("div", _hoisted_41, _toDisplayString(assignment.assigneeFullName), 1 /* TEXT */)
                    ]))
              ], 14 /* CLASS, STYLE, PROPS */, _hoisted_19))
            }), 128 /* KEYED_FRAGMENT */))
          ])
        ], 512 /* NEED_PATCH */), [
          [_vShow, $options.leadAssignments.length > 0]
        ]),
        _createVNode(_component_b_loading, {
          "is-full-page": false,
          modelValue: $data.loading,
          "onUpdate:modelValue": _cache[12] || (_cache[12] = $event => (($data.loading) = $event))
        }, null, 8 /* PROPS */, ["modelValue"])
      ]),
      ($options.leadAssignments.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_42))
        : _createCommentVNode("v-if", true),
      ($options.leadAssignments.length > 0)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            ($data.view === "report")
              ? (_openBlock(), _createElementBlock("div", _hoisted_43, [
                  _createElementVNode("div", _hoisted_44, [
                    _createElementVNode("h3", _hoisted_45, _toDisplayString(_ctx.l('lead-page/report-view')), 1 /* TEXT */)
                  ]),
                  _createElementVNode("div", _hoisted_46, [
                    _createElementVNode("h3", _hoisted_47, [
                      _createElementVNode("a", {
                        class: "rlink",
                        onClick: _cache[13] || (_cache[13] = $event => ($data.view = "kpi"))
                      }, _toDisplayString(_ctx.l('lead-page/go-to-kpi-view')), 1 /* TEXT */)
                    ])
                  ])
                ]))
              : ($data.view === "kpi")
                ? (_openBlock(), _createElementBlock("div", _hoisted_48, [
                    _createElementVNode("div", _hoisted_49, [
                      _createElementVNode("h3", _hoisted_50, _toDisplayString(_ctx.l('lead-page/kpi-view')), 1 /* TEXT */)
                    ]),
                    _createElementVNode("div", _hoisted_51, [
                      _createElementVNode("h3", _hoisted_52, [
                        _createElementVNode("a", {
                          class: "rlink",
                          onClick: _cache[14] || (_cache[14] = $event => ($data.view = "report"))
                        }, _toDisplayString(_ctx.l('lead-page/go-to-report-view')), 1 /* TEXT */)
                      ])
                    ])
                  ]))
                : _createCommentVNode("v-if", true)
          ], 64 /* STABLE_FRAGMENT */))
        : _createCommentVNode("v-if", true),
      ($options.leadAssignments.length > 0 && !$options.selectedAssignment)
        ? (_openBlock(), _createElementBlock("div", _hoisted_53, _toDisplayString(_ctx.l('lead-page/select-product')), 1 /* TEXT */))
        : ($options.leadAssignments.length > 0)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
              _createCommentVNode(""),
              _createCommentVNode(""),
              _createCommentVNode(" ██████╗ ███████╗██████╗  ██████╗ ██████╗ ████████╗"),
              _createCommentVNode(" ██╔══██╗██╔════╝██╔══██╗██╔═══██╗██╔══██╗╚══██╔══╝"),
              _createCommentVNode(" ██████╔╝█████╗  ██████╔╝██║   ██║██████╔╝   ██║"),
              _createCommentVNode(" ██╔══██╗██╔══╝  ██╔═══╝ ██║   ██║██╔══██╗   ██║"),
              _createCommentVNode(" ██║  ██║███████╗██║     ╚██████╔╝██║  ██║   ██║"),
              _createCommentVNode(" ╚═╝  ╚═╝╚══════╝╚═╝      ╚═════╝ ╚═╝  ╚═╝   ╚═╝"),
              _createCommentVNode(""),
              _createCommentVNode(" Report view"),
              _createCommentVNode(""),
              _createCommentVNode(" Could separate this into its own component..."),
              _createCommentVNode(""),
              _createCommentVNode(""),
              ($data.view === "report")
                ? (_openBlock(), _createElementBlock("div", _hoisted_54, [
                    ($options.selectedAssignment.assigneeId && !["limited_view", "limited_view_with_assign_right", "assign_right"].includes($options.selectedAssignment.accessLevel))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_55, [
                          _createElementVNode("div", _hoisted_56, [
                            _createElementVNode("div", _hoisted_57, [
                              _createElementVNode("span", _hoisted_58, _toDisplayString(_ctx.settingsStore.label("nextappointment")), 1 /* TEXT */),
                              _createVNode(_component_b_field, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_b_datetimepicker, {
                                    ref: "nextAppointmentSelect",
                                    modelValue: $data.reportNextAppointment,
                                    "onUpdate:modelValue": _cache[15] || (_cache[15] = $event => (($data.reportNextAppointment) = $event)),
                                    locale: [ _ctx.activeDateTimeLocalizationCode, "en-gb" ],
                                    "show-week-number": true,
                                    placeholder: _ctx.l("lead-page/click-to-select") + "...",
                                    icon: "calendar",
                                    "icon-right": ($data.reportNextAppointment && $options.hasReportEditAccess) ? "close-circle" : "",
                                    "icon-right-clickable": "",
                                    onIconRightClick: _cache[16] || (_cache[16] = (e) => { $data.reportNextAppointment = null }),
                                    "horizontal-time-picker": "",
                                    "first-day-of-week": 1,
                                    "datetime-formatter": (date) => $options.formatDate(date, "nextAppointment"),
                                    disabled: !$options.hasReportEditAccess,
                                    onFocus: _cache[17] || (_cache[17] = () => { $data.reportNextApptModified = true })
                                  }, null, 8 /* PROPS */, ["modelValue", "locale", "placeholder", "icon-right", "datetime-formatter", "disabled"])
                                ]),
                                _: 1 /* STABLE */
                              }),
                              _createVNode(_component_b_loading, {
                                "is-full-page": false,
                                modelValue: $data.reportLoading,
                                "onUpdate:modelValue": _cache[18] || (_cache[18] = $event => (($data.reportLoading) = $event))
                              }, null, 8 /* PROPS */, ["modelValue"])
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_59, [
                            _createElementVNode("div", _hoisted_60, [
                              _createElementVNode("span", _hoisted_61, _toDisplayString(_ctx.settingsStore.label("deadline")), 1 /* TEXT */),
                              _createVNode(_component_b_field, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_b_datepicker, {
                                    modelValue: $data.reportDueDate,
                                    "onUpdate:modelValue": _cache[19] || (_cache[19] = $event => (($data.reportDueDate) = $event)),
                                    locale: [ _ctx.activeDateTimeLocalizationCode, "en-gb" ],
                                    "show-week-number": true,
                                    placeholder: _ctx.l("lead-page/click-to-select") + "...",
                                    icon: "calendar",
                                    "icon-right": ($data.reportDueDate && $options.hasReportEditAccess) ? "close-circle" : "",
                                    "icon-right-clickable": "",
                                    onIconRightClick: _cache[20] || (_cache[20] = (e) => { $data.reportDueDate = null }),
                                    "first-day-of-week": 1,
                                    "date-formatter": (date) => $options.formatDate(date, "dueDate"),
                                    "trap-focus": "",
                                    disabled: !$options.hasReportEditAccess,
                                    onFocus: _cache[21] || (_cache[21] = () => { $data.reportDueDateModified = true })
                                  }, null, 8 /* PROPS */, ["modelValue", "locale", "placeholder", "icon-right", "date-formatter", "disabled"])
                                ]),
                                _: 1 /* STABLE */
                              }),
                              _createVNode(_component_b_loading, {
                                "is-full-page": false,
                                modelValue: $data.reportLoading,
                                "onUpdate:modelValue": _cache[22] || (_cache[22] = $event => (($data.reportLoading) = $event))
                              }, null, 8 /* PROPS */, ["modelValue"])
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_62, [
                            _createElementVNode("div", _hoisted_63, [
                              _createElementVNode("span", _hoisted_64, _toDisplayString(_ctx.settingsStore.label("status")), 1 /* TEXT */),
                              _createVNode(_component_b_dropdown, {
                                modelValue: $data.reportStatusIdx,
                                "onUpdate:modelValue": _cache[23] || (_cache[23] = $event => (($data.reportStatusIdx) = $event)),
                                "aria-role": "list",
                                disabled: !$options.hasReportEditAccess,
                                onChange: _cache[24] || (_cache[24] = (value) => { $data.reportStatusModified = true })
                              }, {
                                trigger: _withCtx(({ active }) => [
                                  _createVNode(_component_b_field, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_b_button, {
                                        type: "is-select",
                                        expanded: "",
                                        "icon-right": active ? "chevron-up" : "chevron-down"
                                      }, {
                                        default: _withCtx(() => [
                                          ($options.selectedReportStatus.text)
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_65, [
                                                _createElementVNode("div", {
                                                  class: "status-color",
                                                  style: _normalizeStyle(`background-color: ${$options.selectedReportStatus.color}`)
                                                }, null, 4 /* STYLE */),
                                                _createElementVNode("div", _hoisted_66, _toDisplayString($options.selectedReportStatus.text), 1 /* TEXT */)
                                              ]))
                                            : _createCommentVNode("v-if", true)
                                        ]),
                                        _: 2 /* DYNAMIC */
                                      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["icon-right"])
                                    ]),
                                    _: 2 /* DYNAMIC */
                                  }, 1024 /* DYNAMIC_SLOTS */)
                                ]),
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.selectedAssignmentProductStatuses, (status, statusIdx) => {
                                    return (_openBlock(), _createBlock(_component_b_dropdown_item, {
                                      key: "assignment_status_" + statusIdx,
                                      "aria-role": "listitem",
                                      value: status.idx
                                    }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("div", _hoisted_67, [
                                          _createElementVNode("div", {
                                            class: "status-color",
                                            style: _normalizeStyle(`background-color: ${status.color}`)
                                          }, null, 4 /* STYLE */),
                                          _createElementVNode("div", _hoisted_68, _toDisplayString(status.text), 1 /* TEXT */)
                                        ])
                                      ]),
                                      _: 2 /* DYNAMIC */
                                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"]))
                                  }), 128 /* KEYED_FRAGMENT */))
                                ]),
                                _: 1 /* STABLE */
                              }, 8 /* PROPS */, ["modelValue", "disabled"]),
                              _createVNode(_component_b_loading, {
                                "is-full-page": false,
                                modelValue: $data.reportLoading,
                                "onUpdate:modelValue": _cache[25] || (_cache[25] = $event => (($data.reportLoading) = $event))
                              }, null, 8 /* PROPS */, ["modelValue"])
                            ])
                          ])
                        ]))
                      : _createCommentVNode("v-if", true),
                    _createElementVNode("div", _hoisted_69, [
                      _createElementVNode("div", _hoisted_70, [
                        ($options.selectedAssignment.accessLevel === "limited_view")
                          ? (_openBlock(), _createElementBlock("div", _hoisted_71, _toDisplayString(_ctx.l('lead-page/limited-view-report-not-accessible')), 1 /* TEXT */))
                          : ($options.selectedAssignment.accessLevel === "limited_view_with_assign_right")
                            ? (_openBlock(), _createElementBlock("div", _hoisted_72, [
                                _createTextVNode(_toDisplayString(_ctx.l('lead-page/limited-view-report-not-accessible')), 1 /* TEXT */),
                                _cache[54] || (_cache[54] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
                                _cache[55] || (_cache[55] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
                                _createTextVNode(_toDisplayString(_ctx.l('lead-page/assign-right-report-not-accessible')), 1 /* TEXT */)
                              ]))
                            : ($options.selectedAssignment.accessLevel === "assign_right")
                              ? (_openBlock(), _createElementBlock("div", _hoisted_73, _toDisplayString(_ctx.l('lead-page/assign-right-report-not-accessible')), 1 /* TEXT */))
                              : (!$options.selectedAssignment.assigneeId)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_74, _toDisplayString(_ctx.l('lead-page/report-user-assignment-needed')), 1 /* TEXT */))
                                : ($data.reportQuestions.length === 0)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_75, _toDisplayString(_ctx.l('lead-page/no-questions')), 1 /* TEXT */))
                                  : (_openBlock(), _createElementBlock("div", _hoisted_76, [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.reportQuestions, (question) => {
                                        return (_openBlock(), _createElementBlock("div", {
                                          class: _normalizeClass(["question", { "is-flex-direction-column": question.type === "value" }]),
                                          key: question.templateId
                                        }, [
                                          ($options.questionType(question) === "multiple" || $options.questionType(question) === "multiple_many")
                                            ? (_openBlock(), _createBlock(_component_b_field, {
                                                key: 0,
                                                label: question.description,
                                                "label-position": "on-border",
                                                style: {"width":"100%"}
                                              }, {
                                                default: _withCtx(() => [
                                                  _createElementVNode("div", null, [
                                                    _createElementVNode("div", {
                                                      class: _normalizeClass(["question-container", { "question-required-error": question.requiredError }])
                                                    }, [
                                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(question.multiple_selection_strings, (option, idx) => {
                                                        return (_openBlock(), _createBlock(_component_b_field, {
                                                          class: "is-flex-grow-1",
                                                          key: idx
                                                        }, {
                                                          default: _withCtx(() => [
                                                            (question.type === "multiple_many")
                                                              ? (_openBlock(), _createBlock(_component_b_checkbox, {
                                                                  key: 0,
                                                                  modelValue: question.answer.choices,
                                                                  "onUpdate:modelValue": $event => ((question.answer.choices) = $event),
                                                                  "native-value": option,
                                                                  disabled: !$options.hasReportEditAccess,
                                                                  onInput: () => { question.answer.modified = true }
                                                                }, {
                                                                  default: _withCtx(() => [
                                                                    _createTextVNode(_toDisplayString(option), 1 /* TEXT */)
                                                                  ]),
                                                                  _: 2 /* DYNAMIC */
                                                                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onUpdate:modelValue", "native-value", "disabled", "onInput"]))
                                                              : (question.type === "multiple")
                                                                ? (_openBlock(), _createBlock(_component_b_radio, {
                                                                    key: 1,
                                                                    modelValue: question.answer.choices[0],
                                                                    onInput: (e) => { question.answer.choices = [ e.target.value ]; question.answer.modified = true },
                                                                    name: `question_${question.templateId}`,
                                                                    "native-value": option,
                                                                    disabled: !$options.hasReportEditAccess
                                                                  }, {
                                                                    default: _withCtx(() => [
                                                                      _createTextVNode(_toDisplayString(option), 1 /* TEXT */)
                                                                    ]),
                                                                    _: 2 /* DYNAMIC */
                                                                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onInput", "name", "native-value", "disabled"]))
                                                                : _createCommentVNode("v-if", true)
                                                          ]),
                                                          _: 2 /* DYNAMIC */
                                                        }, 1024 /* DYNAMIC_SLOTS */))
                                                      }), 128 /* KEYED_FRAGMENT */)),
                                                      (question.required)
                                                        ? (_openBlock(), _createElementBlock("p", _hoisted_77, "* " + _toDisplayString(_ctx.l('lead-page/required-question')), 1 /* TEXT */))
                                                        : _createCommentVNode("v-if", true)
                                                    ], 2 /* CLASS */)
                                                  ])
                                                ]),
                                                _: 2 /* DYNAMIC */
                                              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label"]))
                                            : ($options.questionType(question) === "slider")
                                              ? (_openBlock(), _createElementBlock("div", _hoisted_78, [
                                                  _createVNode(_component_b_field, {
                                                    label: question.description,
                                                    "label-position": "on-border",
                                                    style: {"width":"100%"}
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createElementVNode("div", null, [
                                                        _createElementVNode("div", {
                                                          class: _normalizeClass(["question-container", { "question-required-error": question.requiredError }])
                                                        }, [
                                                          _createVNode(_component_b_field, null, {
                                                            default: _withCtx(() => [
                                                              _createVNode(_component_b_slider, {
                                                                class: "mt-1",
                                                                modelValue: !isNaN(question.answer.value) ? Number(question.answer.value) : Math.floor((Number(question.multiple_selection_strings[0]) + Number(question.multiple_selection_strings[1])) / 2),
                                                                onChange: (value) => { question.answer.value = Number(value); question.answer.modified = true },
                                                                min: Number(question.multiple_selection_strings[0]),
                                                                max: Number(question.multiple_selection_strings[1]),
                                                                disabled: !$options.hasReportEditAccess
                                                              }, null, 8 /* PROPS */, ["modelValue", "onChange", "min", "max", "disabled"])
                                                            ]),
                                                            _: 2 /* DYNAMIC */
                                                          }, 1024 /* DYNAMIC_SLOTS */),
                                                          _createElementVNode("div", _hoisted_79, [
                                                            _createElementVNode("div", _hoisted_80, [
                                                              _createElementVNode("span", _hoisted_81, _toDisplayString(_ctx.l('generic/min')) + ": " + _toDisplayString(question.multiple_selection_strings[0]), 1 /* TEXT */)
                                                            ]),
                                                            _createElementVNode("div", _hoisted_82, [
                                                              _createElementVNode("span", _hoisted_83, [
                                                                _createTextVNode(_toDisplayString(_ctx.l('generic/answer')) + ": ", 1 /* TEXT */),
                                                                _createElementVNode("span", _hoisted_84, _toDisplayString(question.answer.value), 1 /* TEXT */)
                                                              ])
                                                            ]),
                                                            _createElementVNode("div", _hoisted_85, [
                                                              _createElementVNode("span", _hoisted_86, _toDisplayString(_ctx.l('generic/max')) + ": " + _toDisplayString(question.multiple_selection_strings[1]), 1 /* TEXT */)
                                                            ])
                                                          ]),
                                                          (question.required)
                                                            ? (_openBlock(), _createElementBlock("p", _hoisted_87, "* " + _toDisplayString(_ctx.l('lead-page/required-question')), 1 /* TEXT */))
                                                            : _createCommentVNode("v-if", true)
                                                        ], 2 /* CLASS */)
                                                      ])
                                                    ]),
                                                    _: 2 /* DYNAMIC */
                                                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label"])
                                                ]))
                                              : ($options.questionType(question) === "free_text")
                                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                                                    _createVNode(_component_b_field, {
                                                      label: question.description,
                                                      "label-position": "on-border",
                                                      type: { "is-danger": question.requiredError },
                                                      style: {"width":"100%"}
                                                    }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_b_input, {
                                                          modelValue: question.answer.value,
                                                          "onUpdate:modelValue": $event => ((question.answer.value) = $event),
                                                          type: "textarea",
                                                          placeholder: _ctx.l("generic/answer"),
                                                          expanded: "",
                                                          disabled: !$options.hasReportEditAccess,
                                                          "custom-class": "has-fixed-size textarea-question-input",
                                                          onFocus: () => { question.answer.modified = true },
                                                          onInput: _cache[26] || (_cache[26] = (e) => { e.target.style.height = "";e.target.style.height = e.target.scrollHeight + "px" })
                                                        }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "placeholder", "disabled", "onFocus"])
                                                      ]),
                                                      _: 2 /* DYNAMIC */
                                                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label", "type"]),
                                                    (question.required)
                                                      ? (_openBlock(), _createElementBlock("div", _hoisted_88, "* " + _toDisplayString(_ctx.l('lead-page/required-question')), 1 /* TEXT */))
                                                      : _createCommentVNode("v-if", true)
                                                  ], 64 /* STABLE_FRAGMENT */))
                                                : ($options.questionType(question) === "number")
                                                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                                                      _createVNode(_component_b_field, {
                                                        label: question.description,
                                                        "label-position": "on-border",
                                                        type: { "is-danger": question.requiredError },
                                                        message: _ctx.l("lead-page/answer-must-be-a-number"),
                                                        style: {"width":"100%"}
                                                      }, {
                                                        default: _withCtx(() => [
                                                          _createVNode(_component_b_input, {
                                                            modelValue: question.answer.num,
                                                            "onUpdate:modelValue": $event => ((question.answer.num) = $event),
                                                            type: "number",
                                                            placeholder: _ctx.l("generic/answer"),
                                                            expanded: "",
                                                            disabled: !$options.hasReportEditAccess,
                                                            onFocus: () => { question.answer.modified = true }
                                                          }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "placeholder", "disabled", "onFocus"])
                                                        ]),
                                                        _: 2 /* DYNAMIC */
                                                      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label", "type", "message"]),
                                                      (question.required)
                                                        ? (_openBlock(), _createElementBlock("div", _hoisted_89, "* " + _toDisplayString(_ctx.l('lead-page/required-question')), 1 /* TEXT */))
                                                        : _createCommentVNode("v-if", true)
                                                    ], 64 /* STABLE_FRAGMENT */))
                                                  : ($options.questionType(question) === "personal_sales")
                                                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                                                        _createVNode(_component_b_field, {
                                                          label: question.description,
                                                          "label-position": "on-border",
                                                          type: { "is-danger": question.requiredError },
                                                          message: _ctx.l("lead-page/answer-must-be-a-number"),
                                                          style: {"width":"100%"}
                                                        }, {
                                                          default: _withCtx(() => [
                                                            _createVNode(_component_b_input, {
                                                              modelValue: question.answer.quantity,
                                                              "onUpdate:modelValue": $event => ((question.answer.quantity) = $event),
                                                              type: "number",
                                                              placeholder: _ctx.l("generic/answer"),
                                                              expanded: "",
                                                              disabled: !$options.hasReportEditAccess,
                                                              onFocus: () => { question.answer.modified = true }
                                                            }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "placeholder", "disabled", "onFocus"])
                                                          ]),
                                                          _: 2 /* DYNAMIC */
                                                        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label", "type", "message"]),
                                                        (question.required)
                                                          ? (_openBlock(), _createElementBlock("div", _hoisted_90, "* " + _toDisplayString(_ctx.l('lead-page/required-question')), 1 /* TEXT */))
                                                          : _createCommentVNode("v-if", true)
                                                      ], 64 /* STABLE_FRAGMENT */))
                                                    : _createCommentVNode("v-if", true)
                                        ], 2 /* CLASS */))
                                      }), 128 /* KEYED_FRAGMENT */)),
                                      _createVNode(_component_b_loading, {
                                        "is-full-page": false,
                                        modelValue: $data.reportLoading,
                                        "onUpdate:modelValue": _cache[27] || (_cache[27] = $event => (($data.reportLoading) = $event))
                                      }, null, 8 /* PROPS */, ["modelValue"])
                                    ]))
                      ]),
                      _createElementVNode("div", _hoisted_91, [
                        ($options.selectedAssignment.assigneeId && $options.hasReportEditAccess)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_92, [
                              _createVNode(_component_b_button, {
                                type: "is-primary",
                                rounded: "",
                                expanded: "",
                                onClick: _cache[28] || (_cache[28] = $event => ($options.saveReport())),
                                loading: $data.reportLoading
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.l('lead-page/save-report')), 1 /* TEXT */)
                                ]),
                                _: 1 /* STABLE */
                              }, 8 /* PROPS */, ["loading"])
                            ]))
                          : _createCommentVNode("v-if", true),
                        ($options.selectedProduct.hasAssignment && !$options.hasReportEditAccess)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_93, [
                              _createElementVNode("span", _hoisted_94, _toDisplayString(_ctx.l('lead-page/report-view-access-only')), 1 /* TEXT */)
                            ]))
                          : _createCommentVNode("v-if", true),
                        _createElementVNode("div", _hoisted_95, [
                          _createElementVNode("div", _hoisted_96, [
                            _createElementVNode("div", {
                              class: "comments-view rtitle ssm semibold",
                              onClick: _cache[29] || (_cache[29] = $event => ($data.commentsView = "comments"))
                            }, _toDisplayString(_ctx.settingsStore.label("comments")) + " (" + _toDisplayString($options.leadCommentsCount) + ")", 1 /* TEXT */),
                            _createElementVNode("div", {
                              class: "report-history-view rtitle ssm semibold",
                              onClick: _cache[30] || (_cache[30] = $event => ($data.commentsView = "reportHistory"))
                            }, _toDisplayString(_ctx.l('lead-page-comments/report-history')), 1 /* TEXT */)
                          ]),
                          ($data.commentsView === "comments")
                            ? (_openBlock(), _createElementBlock("div", _hoisted_97, [
                                _cache[56] || (_cache[56] = _createElementVNode("div", { class: "user-avatar" }, [
                                  _createElementVNode("img", {
                                    src: _imports_0,
                                    width: "40"
                                  })
                                ], -1 /* HOISTED */)),
                                _createElementVNode("div", {
                                  class: _normalizeClass(["new-comment-textbox", { "active": this.leadNewComment.length > 0 }])
                                }, [
                                  _createVNode(_component_b_field, {
                                    label: _ctx.l("lead-page-comments/add-new-comment"),
                                    "label-position": "on-border"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_b_input, {
                                        modelValue: $data.leadNewComment,
                                        "onUpdate:modelValue": _cache[31] || (_cache[31] = $event => (($data.leadNewComment) = $event)),
                                        type: "textarea",
                                        "custom-class": "has-fixed-size textarea-newcomment-input"
                                      }, null, 8 /* PROPS */, ["modelValue"])
                                    ]),
                                    _: 1 /* STABLE */
                                  }, 8 /* PROPS */, ["label"])
                                ], 2 /* CLASS */),
                                _createVNode(_component_b_loading, {
                                  "is-full-page": false,
                                  modelValue: $data.reportLoading,
                                  "onUpdate:modelValue": _cache[32] || (_cache[32] = $event => (($data.reportLoading) = $event))
                                }, null, 8 /* PROPS */, ["modelValue"])
                              ]))
                            : _createCommentVNode("v-if", true),
                          ($data.commentsView === "comments")
                            ? (_openBlock(), _createElementBlock("div", _hoisted_98))
                            : _createCommentVNode("v-if", true),
                          ($data.commentsView === "comments" && $options.leadCommentsCount === 0)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_99, _toDisplayString(_ctx.l('lead-page-comments/no-comments')), 1 /* TEXT */))
                            : ($data.commentsView === "reportHistory" && $options.leadHistoryCount === 0)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_100, _toDisplayString(_ctx.l('lead-page-comments/no-report-history')), 1 /* TEXT */))
                              : (_openBlock(), _createElementBlock("div", {
                                  key: 4,
                                  class: _normalizeClass(["comments-list", { "report-view": $data.commentsView === "reportHistory" }])
                                }, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.leadComments, (comment) => {
                                    return (_openBlock(), _createElementBlock("div", {
                                      class: "comment",
                                      key: comment._id
                                    }, [
                                      _createElementVNode("div", _hoisted_101, [
                                        (comment.commentor === "system" || comment?.systemComment)
                                          ? (_openBlock(), _createElementBlock("img", _hoisted_102))
                                          : (_openBlock(), _createElementBlock("img", _hoisted_103))
                                      ]),
                                      _createElementVNode("div", _hoisted_104, [
                                        _createElementVNode("div", _hoisted_105, [
                                          _createElementVNode("span", _hoisted_106, _toDisplayString($options.getCommentor(comment)), 1 /* TEXT */),
                                          _createTextVNode(", " + _toDisplayString(_ctx.$dayjs(comment.postedAt).format('llll')), 1 /* TEXT */)
                                        ]),
                                        _createCommentVNode(" Normal comments and legacy system comments"),
                                        (!comment.systemComment)
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_107, _toDisplayString(comment.comment), 1 /* TEXT */))
                                          : _createCommentVNode("v-if", true),
                                        _createCommentVNode(" New localized system comment"),
                                        (comment?.systemComment)
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_108, [
                                              _createElementVNode("span", {
                                                innerHTML: $options.getSystemCommentHtml(comment)
                                              }, null, 8 /* PROPS */, _hoisted_109)
                                            ]))
                                          : _createCommentVNode("v-if", true)
                                      ])
                                    ]))
                                  }), 128 /* KEYED_FRAGMENT */))
                                ], 2 /* CLASS */))
                        ])
                      ])
                    ]),
                    _createVNode(_component_b_loading, {
                      "is-full-page": false,
                      modelValue: $data.loading,
                      "onUpdate:modelValue": _cache[33] || (_cache[33] = $event => (($data.loading) = $event))
                    }, null, 8 /* PROPS */, ["modelValue"])
                  ]))
                : ($data.view === "kpi")
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createCommentVNode(""),
                      _createCommentVNode(""),
                      _createCommentVNode(" ██╗  ██╗██████╗ ██╗"),
                      _createCommentVNode(" ██║ ██╔╝██╔══██╗██║"),
                      _createCommentVNode(" █████╔╝ ██████╔╝██║"),
                      _createCommentVNode(" ██╔═██╗ ██╔═══╝ ██║"),
                      _createCommentVNode(" ██║  ██╗██║     ██║"),
                      _createCommentVNode(" ╚═╝  ╚═╝╚═╝     ╚═╝"),
                      _createCommentVNode(""),
                      _createCommentVNode(" KPI view"),
                      _createCommentVNode(""),
                      _createCommentVNode(" Could separate this into its own component..."),
                      _createCommentVNode(""),
                      _createCommentVNode(""),
                      _createElementVNode("div", _hoisted_110, [
                        _createElementVNode("div", _hoisted_111, [
                          _createElementVNode("div", null, [
                            _createElementVNode("h4", _hoisted_112, _toDisplayString($options.selectedProduct.name), 1 /* TEXT */)
                          ]),
                          _createElementVNode("div", _hoisted_113, [
                            _createVNode(_component_b_dropdown, {
                              modelValue: _ctx.salesSelectedMonth,
                              "onUpdate:modelValue": _cache[34] || (_cache[34] = $event => ((_ctx.salesSelectedMonth) = $event)),
                              "aria-role": "list"
                            }, {
                              trigger: _withCtx(({ active }) => [
                                _createVNode(_component_b_field, {
                                  label: _ctx.l("generic/month"),
                                  "label-position": "on-border"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_b_button, {
                                      label: _ctx.$dayjs().month(_ctx.salesSelectedMonth).format("MMMM"),
                                      type: "is-select",
                                      expanded: "",
                                      size: "is-small",
                                      "icon-right": active ? "chevron-up" : "chevron-down"
                                    }, null, 8 /* PROPS */, ["label", "icon-right"])
                                  ]),
                                  _: 2 /* DYNAMIC */
                                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label"])
                              ]),
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.monthsYtd(), (month) => {
                                  return (_openBlock(), _createBlock(_component_b_dropdown_item, {
                                    key: month.number,
                                    "aria-role": "listitem",
                                    value: month.number
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(month.display), 1 /* TEXT */)
                                    ]),
                                    _: 2 /* DYNAMIC */
                                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"]))
                                }), 128 /* KEYED_FRAGMENT */))
                              ]),
                              _: 1 /* STABLE */
                            }, 8 /* PROPS */, ["modelValue"])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_114, [
                          _createElementVNode("div", _hoisted_115, [
                            _createElementVNode("div", _hoisted_116, [
                              _createVNode(_component_chart_target_actual_last_yr_sales, {
                                kpiView: true,
                                leadId: $options.lead._id,
                                productId: $options.selectedProduct._id,
                                autoWidth: true
                              }, null, 8 /* PROPS */, ["leadId", "productId"])
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_117, [
                            _createElementVNode("div", _hoisted_118, [
                              _createVNode(_component_chart_monthly_sales, {
                                kpiView: true,
                                leadId: $options.lead._id,
                                productId: $options.selectedProduct._id,
                                autoWidth: true
                              }, null, 8 /* PROPS */, ["leadId", "productId"])
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_119, [
                            _createElementVNode("div", _hoisted_120, [
                              _createElementVNode("div", _hoisted_121, [
                                _createElementVNode("div", _hoisted_122, [
                                  _createElementVNode("div", _hoisted_123, _toDisplayString(_ctx.l('kpi/actual-ytd')), 1 /* TEXT */),
                                  _createElementVNode("div", null, _toDisplayString(_ctx.$filters.toCurrency($options.selectedAssignmentSales.total)), 1 /* TEXT */)
                                ]),
                                _createElementVNode("div", _hoisted_124, [
                                  _createElementVNode("div", _hoisted_125, _toDisplayString(_ctx.l('kpi/actual-ytd-vs-target')), 1 /* TEXT */),
                                  _createElementVNode("div", null, _toDisplayString($options.selectedAssignmentSalesVsTarget) + "%", 1 /* TEXT */)
                                ])
                              ])
                            ])
                          ])
                        ]),
                        _cache[58] || (_cache[58] = _createElementVNode("div", { class: "separator nomargins grey" }, null, -1 /* HOISTED */)),
                        _createElementVNode("h3", _hoisted_126, _toDisplayString(_ctx.l('lead-page-kpi/breakdown-by-product')), 1 /* TEXT */),
                        _createElementVNode("div", _hoisted_127, [
                          _createElementVNode("div", _hoisted_128, [
                            _createElementVNode("div", _hoisted_129, [
                              _createVNode(_component_b_table, {
                                data: $data.salesCategories,
                                striped: false,
                                hoverable: true,
                                "mobile-cards": false,
                                loading: $data.loading,
                                paginated: false,
                                "sticky-header": true,
                                "default-sort": [ "category", "asc" ],
                                "row-class": () => { return "category-product-row" }
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_b_table_column, {
                                    field: "category",
                                    label: _ctx.l("lead-page-kpi/breakdown-products-header"),
                                    sortable: "",
                                    width: "25%",
                                    "header-class": "category-header",
                                    "cell-class": "category-cell"
                                  }, {
                                    default: _withCtx((props) => [
                                      _createElementVNode("div", _hoisted_130, _toDisplayString(props.row.category), 1 /* TEXT */)
                                    ]),
                                    _: 1 /* STABLE */
                                  }, 8 /* PROPS */, ["label"]),
                                  _createVNode(_component_b_table_column, {
                                    field: "ytd",
                                    width: "25%",
                                    sortable: "",
                                    label: _ctx.l("lead-page-kpi/breakdown-ytd-header", _ctx.$dayjs().month(_ctx.salesSelectedMonth).format("MMMM")),
                                    "header-class": "category-header",
                                    "cell-class": "category-cell"
                                  }, {
                                    default: _withCtx((props) => [
                                      _createElementVNode("span", _hoisted_131, _toDisplayString(_ctx.$filters.toCurrency(props.row.ytd)), 1 /* TEXT */)
                                    ]),
                                    _: 1 /* STABLE */
                                  }, 8 /* PROPS */, ["label"]),
                                  _createVNode(_component_b_table_column, {
                                    field: "ytd_lastyear",
                                    width: "25%",
                                    sortable: "",
                                    label: _ctx.l("lead-page-kpi/breakdown-ytd-last-year-header", _ctx.$dayjs().month(_ctx.salesSelectedMonth).format("MMMM")),
                                    "header-class": "category-header",
                                    "cell-class": "category-cell"
                                  }, {
                                    default: _withCtx((props) => [
                                      _createElementVNode("span", _hoisted_132, _toDisplayString(_ctx.$filters.toCurrency(props.row.ytd_lastyear)), 1 /* TEXT */)
                                    ]),
                                    _: 1 /* STABLE */
                                  }, 8 /* PROPS */, ["label"]),
                                  _createVNode(_component_b_table_column, {
                                    field: "diff",
                                    label: _ctx.l("lead-page-kpi/breakdown-difference-header"),
                                    width: "25%",
                                    sortable: "",
                                    "header-class": "category-header",
                                    "cell-class": "category-cell"
                                  }, {
                                    default: _withCtx((props) => [
                                      _createElementVNode("span", _hoisted_133, _toDisplayString(_ctx.$filters.toCurrency(props.row.diff)), 1 /* TEXT */)
                                    ]),
                                    _: 1 /* STABLE */
                                  }, 8 /* PROPS */, ["label"])
                                ]),
                                _: 1 /* STABLE */
                              }, 8 /* PROPS */, ["data", "loading"])
                            ])
                          ]),
                          _cache[57] || (_cache[57] = _createElementVNode("div", { class: "column is-6" }, [
                            _createElementVNode("div", { class: "kpi-product-map-view" }, [
                              _createElementVNode("div", { id: "kpi-product-map-component" })
                            ])
                          ], -1 /* HOISTED */))
                        ]),
                        _cache[59] || (_cache[59] = _createElementVNode("div", { class: "separator nomargins grey" }, null, -1 /* HOISTED */)),
                        _createElementVNode("div", _hoisted_134, [
                          _createElementVNode("div", _hoisted_135, [
                            _createElementVNode("div", _hoisted_136, [
                              _createElementVNode("h3", _hoisted_137, _toDisplayString(_ctx.l('lead-page-kpi/edit-target-and-numbers')), 1 /* TEXT */)
                            ]),
                            _createElementVNode("div", _hoisted_138, [
                              _createVNode(_component_b_field, {
                                label: _ctx.l("lead-page-kpi/enter-yearly-sales-target"),
                                "label-position": "on-border"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_b_input, {
                                    modelValue: $data.kpiSalesGoal,
                                    "onUpdate:modelValue": _cache[35] || (_cache[35] = $event => (($data.kpiSalesGoal) = $event))
                                  }, null, 8 /* PROPS */, ["modelValue"])
                                ]),
                                _: 1 /* STABLE */
                              }, 8 /* PROPS */, ["label"])
                            ]),
                            _createElementVNode("div", null, [
                              _createVNode(_component_b_button, {
                                class: "ml-4 button is-primary",
                                onClick: _cache[36] || (_cache[36] = $event => ($options.saveKpiTargetAndNumbers()))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.l('lead-page-kpi/save-target-and-numbers')), 1 /* TEXT */)
                                ]),
                                _: 1 /* STABLE */
                              })
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_139, [
                            _createElementVNode("div", _hoisted_140, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.monthSets(), (monthSet, idx) => {
                                return (_openBlock(), _createElementBlock("div", {
                                  class: "columns",
                                  key: idx
                                }, [
                                  _createElementVNode("div", _hoisted_141, [
                                    _createVNode(_component_b_field, {
                                      label: _ctx.l("lead-page-kpi/actual-in-month", _ctx.$dayjs().month(monthSet.a).format("MMMM")),
                                      "label-position": "on-border",
                                      "custom-class": "actual-label"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_b_input, {
                                          modelValue: $data.actualSales[monthSet.a],
                                          "onUpdate:modelValue": $event => (($data.actualSales[monthSet.a]) = $event),
                                          expanded: "",
                                          type: "number",
                                          min: "0",
                                          step: "0.01",
                                          disabled: monthSet.a > $data.currentMonth || $data.kpiTargetAndNumbersLoading
                                        }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "disabled"]),
                                        _createElementVNode("p", _hoisted_142, [
                                          _createElementVNode("span", _hoisted_143, "/ " + _toDisplayString($options.salesTargetPerMonth(monthSet.a)), 1 /* TEXT */)
                                        ])
                                      ]),
                                      _: 2 /* DYNAMIC */
                                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label"]),
                                    _createElementVNode("div", _hoisted_144, [
                                      _createElementVNode("span", _hoisted_145, _toDisplayString(_ctx.l('lead-page-kpi/reach', $options.monthlyReach(monthSet.a))), 1 /* TEXT */)
                                    ])
                                  ]),
                                  _createElementVNode("div", _hoisted_146, [
                                    _createVNode(_component_b_field, {
                                      label: _ctx.l("lead-page-kpi/actual-in-month", _ctx.$dayjs().month(monthSet.b).format("MMMM")),
                                      "label-position": "on-border",
                                      "custom-class": "actual-label"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_b_input, {
                                          modelValue: $data.actualSales[monthSet.b],
                                          "onUpdate:modelValue": $event => (($data.actualSales[monthSet.b]) = $event),
                                          expanded: "",
                                          type: "number",
                                          min: "0",
                                          step: "0.01",
                                          disabled: monthSet.b > $data.currentMonth || $data.kpiTargetAndNumbersLoading
                                        }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "disabled"]),
                                        _createElementVNode("p", _hoisted_147, [
                                          _createElementVNode("span", _hoisted_148, "/ " + _toDisplayString($options.salesTargetPerMonth(monthSet.b)), 1 /* TEXT */)
                                        ])
                                      ]),
                                      _: 2 /* DYNAMIC */
                                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label"]),
                                    _createElementVNode("div", _hoisted_149, [
                                      _createElementVNode("span", _hoisted_150, _toDisplayString(_ctx.l('lead-page-kpi/reach', $options.monthlyReach(monthSet.b))), 1 /* TEXT */)
                                    ])
                                  ])
                                ]))
                              }), 128 /* KEYED_FRAGMENT */))
                            ])
                          ]),
                          _createVNode(_component_b_loading, {
                            "is-full-page": false,
                            modelValue: $data.kpiTargetAndNumbersLoading,
                            "onUpdate:modelValue": _cache[37] || (_cache[37] = $event => (($data.kpiTargetAndNumbersLoading) = $event))
                          }, null, 8 /* PROPS */, ["modelValue"])
                        ]),
                        _createVNode(_component_b_loading, {
                          "is-full-page": false,
                          modelValue: $data.loading,
                          "onUpdate:modelValue": _cache[38] || (_cache[38] = $event => (($data.loading) = $event))
                        }, null, 8 /* PROPS */, ["modelValue"])
                      ])
                    ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
                  : _createCommentVNode("v-if", true)
            ], 64 /* STABLE_FRAGMENT */))
          : _createCommentVNode("v-if", true)
    ], 512 /* NEED_PATCH */), [
      [_vShow, !_ctx.appLoading && $options.lead._id]
    ]),
    _createVNode(_component_b_loading, {
      "is-full-page": false,
      modelValue: _ctx.appLoading,
      "onUpdate:modelValue": _cache[39] || (_cache[39] = $event => ((_ctx.appLoading) = $event))
    }, null, 8 /* PROPS */, ["modelValue"]),
    _createCommentVNode(""),
    _createCommentVNode(""),
    _createCommentVNode(" ███╗   ███╗ ██████╗ ██████╗  █████╗ ██╗     ███████╗"),
    _createCommentVNode(" ████╗ ████║██╔═══██╗██╔══██╗██╔══██╗██║     ██╔════╝"),
    _createCommentVNode(" ██╔████╔██║██║   ██║██║  ██║███████║██║     ███████╗"),
    _createCommentVNode(" ██║╚██╔╝██║██║   ██║██║  ██║██╔══██║██║     ╚════██║"),
    _createCommentVNode(" ██║ ╚═╝ ██║╚██████╔╝██████╔╝██║  ██║███████╗███████║"),
    _createCommentVNode(" ╚═╝     ╚═╝ ╚═════╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝╚══════╝"),
    _createCommentVNode(""),
    _createCommentVNode(""),
    _createVNode(_component_b_modal, {
      class: "fit-content-height",
      modelValue: $data.deleteLeadConfirmationModalActive,
      "onUpdate:modelValue": _cache[42] || (_cache[42] = $event => (($data.deleteLeadConfirmationModalActive) = $event)),
      width: 640,
      scroll: "keep",
      "can-cancel": [ 'escape', 'outside' ],
      "on-cancel": $options.closeDeleteLead
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_151, [
          _createElementVNode("div", _hoisted_152, [
            _createVNode(_component_b_icon, {
              icon: "alert-circle-outline",
              "custom-class": "modal-alert"
            }),
            _createElementVNode("p", {
              class: "modal-text",
              innerHTML: _ctx.l_escaped("lead-page/delete-lead-confirm-text", $options.lead.name)
            }, null, 8 /* PROPS */, _hoisted_153),
            _createElementVNode("div", _hoisted_154, [
              _createVNode(_component_b_button, {
                type: "is-light",
                rounded: "",
                onClick: _cache[40] || (_cache[40] = $event => ($options.closeDeleteLead()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.l('generic/cancel')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_b_button, {
                type: "is-primary",
                rounded: "",
                onClick: _cache[41] || (_cache[41] = $event => ($options.deleteLead()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.l('generic/delete')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              })
            ])
          ])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "on-cancel"]),
    _createVNode(_component_b_modal, {
      class: "fit-content-height",
      modelValue: $data.copyLeadModalActive,
      "onUpdate:modelValue": _cache[46] || (_cache[46] = $event => (($data.copyLeadModalActive) = $event)),
      width: 640,
      scroll: "keep",
      "can-cancel": [ 'escape', 'outside' ],
      "on-cancel": $options.closeCopyLeadModal
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_155, [
          _createElementVNode("div", _hoisted_156, [
            _createElementVNode("h2", _hoisted_157, _toDisplayString(_ctx.l('lead-page/copy-lead-title')), 1 /* TEXT */),
            _createElementVNode("p", _hoisted_158, _toDisplayString(_ctx.l('lead-page/copy-lead-new-lead-name-text')), 1 /* TEXT */),
            _createVNode(_component_b_field, {
              class: "mb-5",
              label: _ctx.l("lead-page/copy-lead-new-lead-name"),
              "label-position": "on-border"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_b_input, {
                  modelValue: $data.copyLeadName,
                  "onUpdate:modelValue": _cache[43] || (_cache[43] = $event => (($data.copyLeadName) = $event)),
                  expanded: ""
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"]),
            _createElementVNode("div", _hoisted_159, [
              _createVNode(_component_b_button, {
                type: "is-light",
                rounded: "",
                onClick: _cache[44] || (_cache[44] = $event => ($options.closeCopyLeadModal()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.l('generic/cancel')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_b_button, {
                type: "is-primary",
                rounded: "",
                onClick: _cache[45] || (_cache[45] = $event => ($options.copyLead()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.l('lead-page/copy-lead-and-go')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              })
            ])
          ])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "on-cancel"]),
    _createVNode(_component_b_modal, {
      class: "fit-content-height",
      modelValue: $data.unassignProductConfirmModalActive,
      "onUpdate:modelValue": _cache[49] || (_cache[49] = $event => (($data.unassignProductConfirmModalActive) = $event)),
      width: 640,
      scroll: "keep",
      "can-cancel": [ 'escape', 'outside' ],
      "on-cancel": $options.closeUnassignProductConfirmModal
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_160, [
          _createElementVNode("div", _hoisted_161, [
            _createVNode(_component_b_icon, {
              icon: "alert-circle-outline",
              "custom-class": "modal-alert"
            }),
            _createElementVNode("p", {
              class: "modal-text",
              innerHTML: _ctx.l_escaped("lead-page/confirm-remove-product-assignment")
            }, null, 8 /* PROPS */, _hoisted_162),
            _createElementVNode("div", _hoisted_163, [
              _createVNode(_component_b_button, {
                type: "is-light",
                rounded: "",
                onClick: _cache[47] || (_cache[47] = $event => ($options.closeUnassignProductConfirmModal()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.l('generic/cancel')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_b_button, {
                type: "is-primary",
                rounded: "",
                onClick: _cache[48] || (_cache[48] = $event => ($options.unassignProduct()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.l('lead-page/confirm-remove-product-assignment-button')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              })
            ])
          ])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "on-cancel"]),
    _createVNode(_component_b_modal, {
      class: "fit-content-height",
      modelValue: $data.leadNotSavedModalActive,
      "onUpdate:modelValue": _cache[52] || (_cache[52] = $event => (($data.leadNotSavedModalActive) = $event)),
      width: 640,
      scroll: "keep",
      "can-cancel": [ 'escape', 'outside' ],
      "on-cancel": $options.closeLeadNotSavedModal
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_164, [
          _createElementVNode("div", _hoisted_165, [
            _createVNode(_component_b_icon, {
              icon: "alert-circle-outline",
              "custom-class": "modal-alert"
            }),
            _createElementVNode("h2", _hoisted_166, _toDisplayString(_ctx.l('lead-page/unsaved-changes-title')), 1 /* TEXT */),
            _createElementVNode("div", null, [
              _createElementVNode("ul", _hoisted_167, [
                (this.reportUnsavedChanges())
                  ? (_openBlock(), _createElementBlock("li", _hoisted_168, _toDisplayString(_ctx.l('lead-page/unsaved-reporting')), 1 /* TEXT */))
                  : _createCommentVNode("v-if", true),
                (this.wasNavigatingToRoute !== null && this.$refs.editLeadComponent.mainDetailsModified)
                  ? (_openBlock(), _createElementBlock("li", _hoisted_169, _toDisplayString(_ctx.l('lead-page/unsaved-lead-details')), 1 /* TEXT */))
                  : _createCommentVNode("v-if", true),
                (this.wasNavigatingToRoute !== null && this.$refs.editLeadComponent.contactsUnsavedChanges())
                  ? (_openBlock(), _createElementBlock("li", _hoisted_170, _toDisplayString(_ctx.l('add-edit-lead/contacts-header')), 1 /* TEXT */))
                  : _createCommentVNode("v-if", true)
              ])
            ]),
            _createElementVNode("p", _hoisted_171, _toDisplayString(_ctx.l('lead-page/unsaved-continue-and-discard')), 1 /* TEXT */),
            _createElementVNode("div", _hoisted_172, [
              _createVNode(_component_b_button, {
                type: "is-primary",
                rounded: "",
                onClick: _cache[50] || (_cache[50] = $event => ($options.closeLeadNotSavedModal()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.l('generic/stay')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_b_button, {
                type: "is-light",
                rounded: "",
                onClick: _cache[51] || (_cache[51] = $event => ($options.continueNavigation()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.l('generic/continue')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              })
            ])
          ])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "on-cancel"])
  ]))
}