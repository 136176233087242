//
//
// RUDOLF frontend
//
// Nurminen Development Oy Ltd - https://nurminen.dev
//
// For customer: The Rudolf Oy
//
// ALL RIGHTS RESERVED BY CUSTOMER
//
//

//
// File author(s):
//   - Riku Nurminen <riku@nurminen.dev>
//


import { Loader }                       from '@googlemaps/js-api-loader'

import * as appConfig                   from '../app.config.js'

import { useLocalizationsStore }        from '../store/localizations.js'


class GoogleMapsService {
    google          = null

    mapsLoader      = null


    constructor() {
    }


    async init() {
        if(this.google === null) {
            const apiKeys = appConfig.GOOGLE_MAPS_API_KEYS

            const apiKey = apiKeys[Math.floor(Math.random() * apiKeys.length)]
    
            const localizationsStore = useLocalizationsStore()
    
            const activeLocalization = localizationsStore.activeLocalization
    
            this.mapsLoader = new Loader({
                apiKey: apiKey,
                version: 'weekly',
                libraries: [ 'places' ],
                language: activeLocalization?.code || 'en'
            })

            this.google = await this.mapsLoader.load()
        }

        return true
    }


    newMap(element, options) {
        if(this.google === null) {
            throw new Error('GoogleMapsService has not been initialized')
        }

        return new this.google.maps.Map(element, options)
    }


    newMarker(position, map, icon, animation, visible = true) {
        if(this.google === null) {
            throw new Error('GoogleMapsService has not been initialized')
        }

        return new this.google.maps.Marker({ position, map, icon, animation, visible })
    }


    // Smoothly zoom over 2 zoom levels
    // https://stackoverflow.com/a/4752617
    smoothZoom(map, max, cnt) {
        if(cnt >= max) {
            return
        } else {
            const z = this.google.maps.event.addListener(map, 'zoom_changed', (event) => {
                this.google.maps.event.removeListener(z)
                this.smoothZoom(map, max, cnt + 1)
            })
            setTimeout(() => { map.setZoom(cnt) }, 80)
        }
    }


    parseAddressComponents(placeResult) {
        const mappings = {
            'streetAddress': [
                { type: 'street_address', field: 'long_name' },
                { type: 'route', field: 'long_name' }
            ],
            'city': [
                // City can be in any of these...
                { type: 'locality', field: 'long_name' },
                { type: 'postal_town', field: 'long_name' },
                { type: 'sublocality', field: 'long_name' },
                { type: 'administrative_area_level_3', field: 'long_name' },
                { type: 'administrative_area_level_2', field: 'long_name' },
                { type: 'sublocality_level_1', field: 'long_name' },
                { type: 'sublocality_level_2', field: 'long_name' },
                { type: 'sublocality_level_3', field: 'long_name' },
                { type: 'sublocality_level_4', field: 'long_name' },
            ],
            'postalCode': [
                { type: 'postal_code', field: 'long_name' }
            ],
            'state': [
                { type: 'administrative_area_level_1', field: 'long_name' }
            ],
            'stateCode': [
                { type: 'administrative_area_level_1', field: 'short_name' }
            ],
            'country': [
                { type: 'country', field: 'long_name' }
            ],
            'countryCode': [
                { type: 'country', field: 'short_name' }
            ]
        }

        let result = {
            'formattedAddress':     placeResult.formatted_address,
            'streetAddress':        '',
            'city':                 '',
            'postalCode':           '',
            'state':                '',
            'stateCode':            '',
            'country':              '',
            'countryCode':          ''
        }

        if(placeResult.name && !placeResult.formatted_address.includes(placeResult.name)) {
            result.formattedAddress = `${placeResult.name}, ${placeResult.formatted_address}`
        }

        const addressComponents = placeResult.address_components || []

        let streetNumber = null

        for(const [field, mapping] of Object.entries(mappings)) {
            for(const addressSearch of mapping) {
                const addressComponent = addressComponents.find(a => a.types.includes(addressSearch.type))

                if(!addressComponent) continue

                result[field] = addressComponent[addressSearch.field]

                break
            }

        }

        const streetNumberComponent = addressComponents.find(a => a.types.includes('street_number'))

        // Fill street address the same way it's found in formattedAddress
        // i.e. street number first or street number after street name
        if(streetNumberComponent) {
            streetNumber = streetNumberComponent.long_name

            if(result.formattedAddress.startsWith(streetNumber)) {
                result.streetAddress = `${streetNumber} ${result.streetAddress}`
            } else {
                result.streetAddress = `${result.streetAddress} ${streetNumber}`
            }
        }

        // For some states the short_name equals the long name (maybe there is no short name)
        // empty out the short name in this case
        if(result.stateCode === result.state) result.stateCode = ''


        return result
    }
}


export default new GoogleMapsService()