export default [
    {
        "code": "AF",
        "finName": "Afganistan",
        "engName": "Afghanistan",
        "gerName": "Afghanistan"
    },
    {
        "code": "AL",
        "finName": "Albania",
        "engName": "Albania",
        "gerName": "Albanien"
    },
    {
        "code": "DZ",
        "finName": "Algeria",
        "engName": "Algeria",
        "gerName": "Algerien"
    },
    {
        "code": "AS",
        "finName": "Amerikan Samoa",
        "engName": "American Samoa",
        "gerName": "Amerikanisch-Samoa"
    },
    {
        "code": "AD",
        "finName": "Andorra",
        "engName": "Andorra",
        "gerName": "Andorra"
    },
    {
        "code": "AO",
        "finName": "Angola",
        "engName": "Angola",
        "gerName": "Angola"
    },
    {
        "code": "AI",
        "finName": "Anguilla",
        "engName": "Anguilla",
        "gerName": "Anguilla"
    },
    {
        "code": "AQ",
        "finName": "Antarktis",
        "engName": "Antarctica",
        "gerName": "Antarktis"
    },
    {
        "code": "AG",
        "finName": "Antigua ja Barbuda",
        "engName": "Antigua and Barbuda",
        "gerName": "Antigua und Barbuda"
    },
    {
        "code": "AR",
        "finName": "Argentiina",
        "engName": "Argentina",
        "gerName": "Argentinien"
    },
    {
        "code": "AM",
        "finName": "Armenia",
        "engName": "Armenia",
        "gerName": "Armenien"
    },
    {
        "code": "AW",
        "finName": "Aruba",
        "engName": "Aruba",
        "gerName": "Aruba"
    },
    {
        "code": "AU",
        "finName": "Australia",
        "engName": "Australia",
        "gerName": "Australien"
    },
    {
        "code": "AT",
        "finName": "Itävalta",
        "engName": "Austria",
        "gerName": "Österreich"
    },
    {
        "code": "AZ",
        "finName": "Azerbaidžan",
        "engName": "Azerbaijan",
        "gerName": "Aserbaidschan"
    },
    {
        "code": "BS",
        "finName": "Bahama",
        "engName": "Bahamas",
        "gerName": "Bahamas"
    },
    {
        "code": "BH",
        "finName": "Bahrain",
        "engName": "Bahrain",
        "gerName": "Bahrain"
    },
    {
        "code": "BD",
        "finName": "Bangladesh",
        "engName": "Bangladesh",
        "gerName": "Bangladesch"
    },
    {
        "code": "BB",
        "finName": "Barbados",
        "engName": "Barbados",
        "gerName": "Barbados"
    },
    {
        "code": "BY",
        "finName": "Valko-Venäjä",
        "engName": "Belarus",
        "gerName": "Belarus"
    },
    {
        "code": "BE",
        "finName": "Belgia",
        "engName": "Belgium",
        "gerName": "Belgien"
    },
    {
        "code": "BZ",
        "finName": "Belize",
        "engName": "Belize",
        "gerName": "Belize"
    },
    {
        "code": "BJ",
        "finName": "Benin",
        "engName": "Benin",
        "gerName": "Benin"
    },
    {
        "code": "BM",
        "finName": "Bermuda",
        "engName": "Bermuda",
        "gerName": "Bermuda"
    },
    {
        "code": "BT",
        "finName": "Bhutan",
        "engName": "Bhutan",
        "gerName": "Bhutan"
    },
    {
        "code": "BO",
        "finName": "Bolivia",
        "engName": "Bolivia, Plurinational State of",
        "gerName": "Bolivien"
    },
    {
        "code": "BQ",
        "finName": "Alankomaiden Karibia",
        "engName": "Bonaire, Sint Eustatius and Saba",
        "gerName": "Bonaire,  Saba,  Sint Eustatius"
    },
    {
        "code": "BA",
        "finName": "Bosnia ja Hertsegovina",
        "engName": "Bosnia and Herzegovina",
        "gerName": "Bosnien und Herzegowina"
    },
    {
        "code": "BW",
        "finName": "Botswana",
        "engName": "Botswana",
        "gerName": "Botswana"
    },
    {
        "code": "BV",
        "finName": "Bouvet’nsaari",
        "engName": "Bouvet Island",
        "gerName": "Bouvetinsel"
    },
    {
        "code": "BR",
        "finName": "Brasilia",
        "engName": "Brazil",
        "gerName": "Brasilien"
    },
    {
        "code": "IO",
        "finName": "Brittiläinen Intian valtameren alue",
        "engName": "British Indian Ocean Territory",
        "gerName": "Britisches Territorium im Indischen Ozean"
    },
    {
        "code": "BN",
        "finName": "Brunei",
        "engName": "Brunei Darussalam",
        "gerName": "Brunei"
    },
    {
        "code": "BG",
        "finName": "Bulgaria",
        "engName": "Bulgaria",
        "gerName": "Bulgarien"
    },
    {
        "code": "BF",
        "finName": "Burkina Faso",
        "engName": "Burkina Faso",
        "gerName": "Burkina Faso"
    },
    {
        "code": "BI",
        "finName": "Burundi",
        "engName": "Burundi",
        "gerName": "Burundi"
    },
    {
        "code": "CV",
        "finName": "Kap Verde",
        "engName": "Cape Verde",
        "gerName": "Kap Verde (Cabo Verde)"
    },
    {
        "code": "KH",
        "finName": "Kambodža",
        "engName": "Cambodia",
        "gerName": "Kambodscha"
    },
    {
        "code": "CM",
        "finName": "Kamerun",
        "engName": "Cameroon",
        "gerName": "Kamerun"
    },
    {
        "code": "CA",
        "finName": "Kanada",
        "engName": "Canada",
        "gerName": "Kanada"
    },
    {
        "code": "KY",
        "finName": "Caymansaaret",
        "engName": "Cayman Islands",
        "gerName": "Cayman Islands (Kaimaninseln)"
    },
    {
        "code": "CF",
        "finName": "Keski-Afrikan tasavalta",
        "engName": "Central African Republic",
        "gerName": "Zentralafrikanische Republik"
    },
    {
        "code": "TD",
        "finName": "Tšad",
        "engName": "Chad",
        "gerName": "Tschad"
    },
    {
        "code": "CL",
        "finName": "Chile",
        "engName": "Chile",
        "gerName": "Chile"
    },
    {
        "code": "CN",
        "finName": "Kiina",
        "engName": "China",
        "gerName": "Volksrepublik China"
    },
    {
        "code": "CX",
        "finName": "Joulusaari",
        "engName": "Christmas Island",
        "gerName": "Weihnachtsinsel"
    },
    {
        "code": "CC",
        "finName": "Kookossaaret",
        "engName": "Cocos (Keeling) Islands",
        "gerName": "Kokosinseln"
    },
    {
        "code": "CO",
        "finName": "Kolumbia",
        "engName": "Colombia",
        "gerName": "Kolumbien"
    },
    {
        "code": "KM",
        "finName": "Komorit",
        "engName": "Comoros",
        "gerName": "Komoren"
    },
    {
        "code": "CG",
        "finName": "Kongon tasavalta",
        "engName": "Congo",
        "gerName": "Republik Kongo (ehem. Kongo-Brazzaville)"
    },
    {
        "code": "CD",
        "finName": "Kongon demokraattinen tasavalta",
        "engName": "Congo, the Democratic Republic of the",
        "gerName": "Demokratische Republik Kongo (ehem. Zaire)"
    },
    {
        "code": "CK",
        "finName": "Cookinsaaret",
        "engName": "Cook Islands",
        "gerName": "Cookinseln"
    },
    {
        "code": "CR",
        "finName": "Costa Rica",
        "engName": "Costa Rica",
        "gerName": "Costa Rica"
    },
    {
        "code": "CI",
        "finName": "Norsunluurannikko",
        "engName": "Côte d'Ivoire",
        "gerName": "Elfenbeinküste (Côte d’Ivoire)"
    },
    {
        "code": "HR",
        "finName": "Kroatia",
        "engName": "Croatia",
        "gerName": "Kroatien"
    },
    {
        "code": "CU",
        "finName": "Kuuba",
        "engName": "Cuba",
        "gerName": "Kuba"
    },
    {
        "code": "CW",
        "finName": "Curaçao",
        "engName": "Curaçao",
        "gerName": "Curaçao"
    },
    {
        "code": "CY",
        "finName": "Kypros",
        "engName": "Cyprus",
        "gerName": "Zypern"
    },
    {
        "code": "CZ",
        "finName": "Tšekki",
        "engName": "Czech Republic",
        "gerName": "Tschechien"
    },
    {
        "code": "DK",
        "finName": "Tanska",
        "engName": "Denmark",
        "gerName": "Dänemark"
    },
    {
        "code": "DJ",
        "finName": "Djibouti",
        "engName": "Djibouti",
        "gerName": "Dschibuti"
    },
    {
        "code": "DM",
        "finName": "Dominica",
        "engName": "Dominica",
        "gerName": "Dominica"
    },
    {
        "code": "DO",
        "finName": "Dominikaaninen tasavalta",
        "engName": "Dominican Republic",
        "gerName": "Dominikanische Republik"
    },
    {
        "code": "EC",
        "finName": "Ecuador",
        "engName": "Ecuador",
        "gerName": "Ecuador"
    },
    {
        "code": "EG",
        "finName": "Egypti",
        "engName": "Egypt",
        "gerName": "Ägypten"
    },
    {
        "code": "SV",
        "finName": "El Salvador",
        "engName": "El Salvador",
        "gerName": "El Salvador"
    },
    {
        "code": "GQ",
        "finName": "Päiväntasaajan Guinea",
        "engName": "Equatorial Guinea",
        "gerName": "Äquatorialguinea"
    },
    {
        "code": "ER",
        "finName": "Eritrea",
        "engName": "Eritrea",
        "gerName": "Eritrea"
    },
    {
        "code": "EE",
        "finName": "Viro",
        "engName": "Estonia",
        "gerName": "Estland"
    },
    {
        "code": "SZ",
        "finName": "Swazimaa",
        "engName": "Swaziland",
        "gerName": "Eswatini[4]"
    },
    {
        "code": "ET",
        "finName": "Etiopia",
        "engName": "Ethiopia",
        "gerName": "Äthiopien"
    },
    {
        "code": "FK",
        "finName": "Falklandinsaaret",
        "engName": "Falkland Islands (Malvinas)",
        "gerName": "Falklandinseln"
    },
    {
        "code": "FO",
        "finName": "Färsaaret",
        "engName": "Faroe Islands",
        "gerName": "Färöer"
    },
    {
        "code": "FJ",
        "finName": "Fidži",
        "engName": "Fiji",
        "gerName": "Fidschi"
    },
    {
        "code": "FI",
        "finName": "Suomi",
        "engName": "Finland",
        "gerName": "Finnland"
    },
    {
        "code": "FR",
        "finName": "Ranska",
        "engName": "France",
        "gerName": "Frankreich"
    },
    {
        "code": "GF",
        "finName": "Ranskan Guayana",
        "engName": "French Guiana",
        "gerName": "Französisch-Guayana"
    },
    {
        "code": "PF",
        "finName": "Ranskan Polynesia",
        "engName": "French Polynesia",
        "gerName": "Französisch-Polynesien"
    },
    {
        "code": "TF",
        "finName": "Ranskan eteläiset alueet",
        "engName": "French Southern Territories",
        "gerName": "Französische Süd- und Antarktisgebiete"
    },
    {
        "code": "GA",
        "finName": "Gabon",
        "engName": "Gabon",
        "gerName": "Gabun"
    },
    {
        "code": "GM",
        "finName": "Gambia",
        "engName": "Gambia",
        "gerName": "Gambia"
    },
    {
        "code": "GE",
        "finName": "Georgia",
        "engName": "Georgia",
        "gerName": "Georgien"
    },
    {
        "code": "DE",
        "finName": "Saksa",
        "engName": "Germany",
        "gerName": "Deutschland"
    },
    {
        "code": "GH",
        "finName": "Ghana",
        "engName": "Ghana",
        "gerName": "Ghana"
    },
    {
        "code": "GI",
        "finName": "Gibraltar",
        "engName": "Gibraltar",
        "gerName": "Gibraltar"
    },
    {
        "code": "GR",
        "finName": "Kreikka",
        "engName": "Greece",
        "gerName": "Griechenland"
    },
    {
        "code": "GL",
        "finName": "Grönlanti",
        "engName": "Greenland",
        "gerName": "Grönland"
    },
    {
        "code": "GD",
        "finName": "Grenada",
        "engName": "Grenada",
        "gerName": "Grenada"
    },
    {
        "code": "GP",
        "finName": "Guadeloupe",
        "engName": "Guadeloupe",
        "gerName": "Guadeloupe"
    },
    {
        "code": "GU",
        "finName": "Guam",
        "engName": "Guam",
        "gerName": "Guam"
    },
    {
        "code": "GT",
        "finName": "Guatemala",
        "engName": "Guatemala",
        "gerName": "Guatemala"
    },
    {
        "code": "GG",
        "finName": "Guernsey",
        "engName": "Guernsey",
        "gerName": "Guernsey (Kanalinsel)"
    },
    {
        "code": "GN",
        "finName": "Guinea",
        "engName": "Guinea",
        "gerName": "Guinea"
    },
    {
        "code": "GW",
        "finName": "Guinea-Bissau",
        "engName": "Guinea-Bissau",
        "gerName": "Guinea-Bissau"
    },
    {
        "code": "GY",
        "finName": "Guyana",
        "engName": "Guyana",
        "gerName": "Guyana"
    },
    {
        "code": "HT",
        "finName": "Haiti",
        "engName": "Haiti",
        "gerName": "Haiti"
    },
    {
        "code": "HM",
        "finName": "Heard ja McDonaldinsaaret",
        "engName": "Heard Island and McDonald Islands",
        "gerName": "Heard und McDonaldinseln"
    },
    {
        "code": "VA",
        "finName": "Vatikaanivaltio (Pyhä istuin)",
        "engName": "Holy See (Vatican City State)",
        "gerName": "Vatikanstadt"
    },
    {
        "code": "HN",
        "finName": "Honduras",
        "engName": "Honduras",
        "gerName": "Honduras"
    },
    {
        "code": "HK",
        "finName": "Hongkong",
        "engName": "Hong Kong",
        "gerName": "Hongkong"
    },
    {
        "code": "HU",
        "finName": "Unkari",
        "engName": "Hungary",
        "gerName": "Ungarn"
    },
    {
        "code": "IS",
        "finName": "Islanti",
        "engName": "Iceland",
        "gerName": "Island"
    },
    {
        "code": "IN",
        "finName": "Intia",
        "engName": "India",
        "gerName": "Indien"
    },
    {
        "code": "ID",
        "finName": "Indonesia",
        "engName": "Indonesia",
        "gerName": "Indonesien"
    },
    {
        "code": "IR",
        "finName": "Iran",
        "engName": "Iran, Islamic Republic of",
        "gerName": "Iran"
    },
    {
        "code": "IQ",
        "finName": "Irak",
        "engName": "Iraq",
        "gerName": "Irak"
    },
    {
        "code": "IE",
        "finName": "Irlanti",
        "engName": "Ireland",
        "gerName": "Irland"
    },
    {
        "code": "IM",
        "finName": "Mansaari",
        "engName": "Isle of Man",
        "gerName": "Isle of Man"
    },
    {
        "code": "IL",
        "finName": "Israel",
        "engName": "Israel",
        "gerName": "Israel"
    },
    {
        "code": "IT",
        "finName": "Italia",
        "engName": "Italy",
        "gerName": "Italien"
    },
    {
        "code": "JM",
        "finName": "Jamaika",
        "engName": "Jamaica",
        "gerName": "Jamaika"
    },
    {
        "code": "JP",
        "finName": "Japani",
        "engName": "Japan",
        "gerName": "Japan"
    },
    {
        "code": "JE",
        "finName": "Jersey",
        "engName": "Jersey",
        "gerName": "Jersey (Kanalinsel)"
    },
    {
        "code": "JO",
        "finName": "Jordania",
        "engName": "Jordan",
        "gerName": "Jordanien"
    },
    {
        "code": "KZ",
        "finName": "Kazakstan",
        "engName": "Kazakhstan",
        "gerName": "Kasachstan"
    },
    {
        "code": "KE",
        "finName": "Kenia",
        "engName": "Kenya",
        "gerName": "Kenia"
    },
    {
        "code": "KI",
        "finName": "Kiribati",
        "engName": "Kiribati",
        "gerName": "Kiribati"
    },
    {
        "code": "KP",
        "finName": "Korean demokraattinen kansantasavalta",
        "engName": "Korea, Democratic People's Republic of",
        "gerName": "Nordkorea"
    },
    {
        "code": "KR",
        "finName": "Korean tasavalta",
        "engName": "Korea, Republic of",
        "gerName": "Südkorea"
    },
    {
        "code": "KW",
        "finName": "Kuwait",
        "engName": "Kuwait",
        "gerName": "Kuwait"
    },
    {
        "code": "KG",
        "finName": "Kirgisia",
        "engName": "Kyrgyzstan",
        "gerName": "Kirgisistan"
    },
    {
        "code": "LA",
        "finName": "Laos",
        "engName": "Lao People's Democratic Republic",
        "gerName": "Laos"
    },
    {
        "code": "LV",
        "finName": "Latvia",
        "engName": "Latvia",
        "gerName": "Lettland"
    },
    {
        "code": "LB",
        "finName": "Libanon",
        "engName": "Lebanon",
        "gerName": "Libanon"
    },
    {
        "code": "LS",
        "finName": "Lesotho",
        "engName": "Lesotho",
        "gerName": "Lesotho"
    },
    {
        "code": "LR",
        "finName": "Liberia",
        "engName": "Liberia",
        "gerName": "Liberia"
    },
    {
        "code": "LY",
        "finName": "Libya",
        "engName": "Libya",
        "gerName": "Libyen"
    },
    {
        "code": "LI",
        "finName": "Liechtenstein",
        "engName": "Liechtenstein",
        "gerName": "Liechtenstein"
    },
    {
        "code": "LT",
        "finName": "Liettua",
        "engName": "Lithuania",
        "gerName": "Litauen"
    },
    {
        "code": "LU",
        "finName": "Luxemburg",
        "engName": "Luxembourg",
        "gerName": "Luxemburg"
    },
    {
        "code": "MO",
        "finName": "Macao",
        "engName": "Macao",
        "gerName": "Macau"
    },
    {
        "code": "MG",
        "finName": "Madagaskar",
        "engName": "Madagascar",
        "gerName": "Madagaskar"
    },
    {
        "code": "MW",
        "finName": "Malawi",
        "engName": "Malawi",
        "gerName": "Malawi"
    },
    {
        "code": "MY",
        "finName": "Malesia",
        "engName": "Malaysia",
        "gerName": "Malaysia"
    },
    {
        "code": "MV",
        "finName": "Malediivit",
        "engName": "Maldives",
        "gerName": "Malediven"
    },
    {
        "code": "ML",
        "finName": "Mali",
        "engName": "Mali",
        "gerName": "Mali"
    },
    {
        "code": "MT",
        "finName": "Malta",
        "engName": "Malta",
        "gerName": "Malta"
    },
    {
        "code": "MH",
        "finName": "Marshallinsaaret",
        "engName": "Marshall Islands",
        "gerName": "Marshallinseln"
    },
    {
        "code": "MQ",
        "finName": "Martinique",
        "engName": "Martinique",
        "gerName": "Martinique"
    },
    {
        "code": "MR",
        "finName": "Mauritania",
        "engName": "Mauritania",
        "gerName": "Mauretanien"
    },
    {
        "code": "MU",
        "finName": "Mauritius",
        "engName": "Mauritius",
        "gerName": "Mauritius"
    },
    {
        "code": "YT",
        "finName": "Mayotte",
        "engName": "Mayotte",
        "gerName": "Mayotte"
    },
    {
        "code": "MX",
        "finName": "Meksiko",
        "engName": "Mexico",
        "gerName": "Mexiko"
    },
    {
        "code": "FM",
        "finName": "Mikronesian liittovaltio",
        "engName": "Micronesia, Federated States of",
        "gerName": "Föderierte Staaten von Mikronesien"
    },
    {
        "code": "MD",
        "finName": "Moldova",
        "engName": "Moldova, Republic of",
        "gerName": "Moldau (Moldawien)"
    },
    {
        "code": "MC",
        "finName": "Monaco",
        "engName": "Monaco",
        "gerName": "Monaco"
    },
    {
        "code": "MN",
        "finName": "Mongolia",
        "engName": "Mongolia",
        "gerName": "Mongolei"
    },
    {
        "code": "ME",
        "finName": "Montenegro",
        "engName": "Montenegro",
        "gerName": "Montenegro"
    },
    {
        "code": "MS",
        "finName": "Montserrat",
        "engName": "Montserrat",
        "gerName": "Montserrat"
    },
    {
        "code": "MA",
        "finName": "Marokko",
        "engName": "Morocco",
        "gerName": "Marokko"
    },
    {
        "code": "MZ",
        "finName": "Mosambik",
        "engName": "Mozambique",
        "gerName": "Mosambik"
    },
    {
        "code": "MM",
        "finName": "Myanmar",
        "engName": "Myanmar",
        "gerName": "Myanmar"
    },
    {
        "code": "NA",
        "finName": "Namibia",
        "engName": "Namibia",
        "gerName": "Namibia"
    },
    {
        "code": "NR",
        "finName": "Nauru",
        "engName": "Nauru",
        "gerName": "Nauru"
    },
    {
        "code": "NP",
        "finName": "Nepal",
        "engName": "Nepal",
        "gerName": "Nepal"
    },
    {
        "code": "NL",
        "finName": "Alankomaat",
        "engName": "Netherlands",
        "gerName": "Niederlande"
    },
    {
        "code": "NC",
        "finName": "Uusi-Kaledonia",
        "engName": "New Caledonia",
        "gerName": "Neukaledonien"
    },
    {
        "code": "NZ",
        "finName": "Uusi-Seelanti",
        "engName": "New Zealand",
        "gerName": "Neuseeland"
    },
    {
        "code": "NI",
        "finName": "Nicaragua",
        "engName": "Nicaragua",
        "gerName": "Nicaragua"
    },
    {
        "code": "NE",
        "finName": "Niger",
        "engName": "Niger",
        "gerName": "Niger"
    },
    {
        "code": "NG",
        "finName": "Nigeria",
        "engName": "Nigeria",
        "gerName": "Nigeria"
    },
    {
        "code": "NU",
        "finName": "Niue",
        "engName": "Niue",
        "gerName": "Niue"
    },
    {
        "code": "NF",
        "finName": "Norfolkinsaari",
        "engName": "Norfolk Island",
        "gerName": "Norfolkinsel"
    },
    {
        "code": "MK",
        "finName": "Pohjois-Makedonia",
        "engName": "Macedonia, the Former Yugoslav Republic of",
        "gerName": "Nordmazedonien"
    },
    {
        "code": "MP",
        "finName": "Pohjois-Mariaanit",
        "engName": "Northern Mariana Islands",
        "gerName": "Nördliche Marianen"
    },
    {
        "code": "NO",
        "finName": "Norja",
        "engName": "Norway",
        "gerName": "Norwegen"
    },
    {
        "code": "OM",
        "finName": "Oman",
        "engName": "Oman",
        "gerName": "Oman"
    },
    {
        "code": "PK",
        "finName": "Pakistan",
        "engName": "Pakistan",
        "gerName": "Pakistan"
    },
    {
        "code": "PW",
        "finName": "Palau",
        "engName": "Palau",
        "gerName": "Palau"
    },
    {
        "code": "PS",
        "finName": "Palestiina",
        "engName": "Palestine, State of",
        "gerName": "Palästina"
    },
    {
        "code": "PA",
        "finName": "Panama",
        "engName": "Panama",
        "gerName": "Panama"
    },
    {
        "code": "PG",
        "finName": "Papua-Uusi-Guinea",
        "engName": "Papua New Guinea",
        "gerName": "Papua-Neuguinea"
    },
    {
        "code": "PY",
        "finName": "Paraguay",
        "engName": "Paraguay",
        "gerName": "Paraguay"
    },
    {
        "code": "PE",
        "finName": "Peru",
        "engName": "Peru",
        "gerName": "Peru"
    },
    {
        "code": "PH",
        "finName": "Filippiinit",
        "engName": "Philippines",
        "gerName": "Philippinen"
    },
    {
        "code": "PN",
        "finName": "Pitcairn",
        "engName": "Pitcairn",
        "gerName": "Pitcairninseln"
    },
    {
        "code": "PL",
        "finName": "Puola",
        "engName": "Poland",
        "gerName": "Polen"
    },
    {
        "code": "PT",
        "finName": "Portugali",
        "engName": "Portugal",
        "gerName": "Portugal"
    },
    {
        "code": "PR",
        "finName": "Puerto Rico",
        "engName": "Puerto Rico",
        "gerName": "Puerto Rico"
    },
    {
        "code": "QA",
        "finName": "Qatar",
        "engName": "Qatar",
        "gerName": "Katar"
    },
    {
        "code": "RE",
        "finName": "Réunion",
        "engName": "Réunion",
        "gerName": "Réunion"
    },
    {
        "code": "RO",
        "finName": "Romania",
        "engName": "Romania",
        "gerName": "Rumänien"
    },
    {
        "code": "RU",
        "finName": "Venäjä",
        "engName": "Russian Federation",
        "gerName": "Russland"
    },
    {
        "code": "RW",
        "finName": "Ruanda",
        "engName": "Rwanda",
        "gerName": "Ruanda"
    },
    {
        "code": "BL",
        "finName": "Saint Barthélemy",
        "engName": "Saint Barthélemy",
        "gerName": "Saint-Barthélemy"
    },
    {
        "code": "SH",
        "finName": "Saint Helena, Ascension ja Tristan da Cunha",
        "engName": "Saint Helena, Ascension and Tristan da Cunha",
        "gerName": "St. Helena, Ascension und Tristan da Cunha"
    },
    {
        "code": "KN",
        "finName": "Saint Kitts ja Nevis",
        "engName": "Saint Kitts and Nevis",
        "gerName": "St. Kitts und Nevis"
    },
    {
        "code": "LC",
        "finName": "Saint Lucia",
        "engName": "Saint Lucia",
        "gerName": "St. Lucia"
    },
    {
        "code": "MF",
        "finName": "Saint Martin (Ranska)",
        "engName": "Saint Martin (French part)",
        "gerName": "Saint-Martin (französischer Teil)"
    },
    {
        "code": "PM",
        "finName": "Saint-Pierre ja Miquelon",
        "engName": "Saint Pierre and Miquelon",
        "gerName": "Saint-Pierre und Miquelon"
    },
    {
        "code": "VC",
        "finName": "Saint Vincent ja Grenadiinit",
        "engName": "Saint Vincent and the Grenadines",
        "gerName": "St. Vincent und die Grenadinen"
    },
    {
        "code": "WS",
        "finName": "Samoa",
        "engName": "Samoa",
        "gerName": "Samoa"
    },
    {
        "code": "SM",
        "finName": "San Marino",
        "engName": "San Marino",
        "gerName": "San Marino"
    },
    {
        "code": "ST",
        "finName": "São Tomé ja Príncipe",
        "engName": "Sao Tome and Principe",
        "gerName": "São Tomé und Príncipe"
    },
    {
        "code": "SA",
        "finName": "Saudi-Arabia",
        "engName": "Saudi Arabia",
        "gerName": "Saudi-Arabien"
    },
    {
        "code": "SN",
        "finName": "Senegal",
        "engName": "Senegal",
        "gerName": "Senegal"
    },
    {
        "code": "RS",
        "finName": "Serbia",
        "engName": "Serbia",
        "gerName": "Serbien"
    },
    {
        "code": "SC",
        "finName": "Seychellit",
        "engName": "Seychelles",
        "gerName": "Seychellen"
    },
    {
        "code": "SL",
        "finName": "Sierra Leone",
        "engName": "Sierra Leone",
        "gerName": "Sierra Leone"
    },
    {
        "code": "SG",
        "finName": "Singapore",
        "engName": "Singapore",
        "gerName": "Singapur"
    },
    {
        "code": "SX",
        "finName": "Sint Maarten (Alankomaat)",
        "engName": "Sint Maarten (Dutch part)",
        "gerName": "Sint Maarten"
    },
    {
        "code": "SK",
        "finName": "Slovakia",
        "engName": "Slovakia",
        "gerName": "Slowakei"
    },
    {
        "code": "SI",
        "finName": "Slovenia",
        "engName": "Slovenia",
        "gerName": "Slowenien"
    },
    {
        "code": "SB",
        "finName": "Salomonsaaret",
        "engName": "Solomon Islands",
        "gerName": "Salomonen"
    },
    {
        "code": "SO",
        "finName": "Somalia",
        "engName": "Somalia",
        "gerName": "Somalia"
    },
    {
        "code": "ZA",
        "finName": "Etelä-Afrikka",
        "engName": "South Africa",
        "gerName": "Südafrika"
    },
    {
        "code": "GS",
        "finName": "Etelä-Georgia ja Eteläiset Sandwichsaaret",
        "engName": "South Georgia and the South Sandwich Islands",
        "gerName": "Südgeorgien und die Südlichen Sandwichinseln"
    },
    {
        "code": "SS",
        "finName": "Etelä-Sudan",
        "engName": "South Sudan",
        "gerName": "Südsudan"
    },
    {
        "code": "ES",
        "finName": "Espanja",
        "engName": "Spain",
        "gerName": "Spanien"
    },
    {
        "code": "LK",
        "finName": "Sri Lanka",
        "engName": "Sri Lanka",
        "gerName": "Sri Lanka"
    },
    {
        "code": "SD",
        "finName": "Sudan",
        "engName": "Sudan",
        "gerName": "Sudan"
    },
    {
        "code": "SR",
        "finName": "Suriname",
        "engName": "Suriname",
        "gerName": "Suriname"
    },
    {
        "code": "SJ",
        "finName": "Svalbard ja Jan Mayen",
        "engName": "Svalbard and Jan Mayen",
        "gerName": "Spitzbergen und Jan Mayen"
    },
    {
        "code": "SE",
        "finName": "Ruotsi",
        "engName": "Sweden",
        "gerName": "Schweden"
    },
    {
        "code": "CH",
        "finName": "Sveitsi",
        "engName": "Switzerland",
        "gerName": "Schweiz (Confoederatio Helvetica)"
    },
    {
        "code": "SY",
        "finName": "Syyria",
        "engName": "Syrian Arab Republic",
        "gerName": "Syrien"
    },
    {
        "code": "TW",
        "finName": "Taiwan",
        "engName": "Taiwan",
        "gerName": "Taiwan"
    },
    {
        "code": "TJ",
        "finName": "Tadžikistan",
        "engName": "Tajikistan",
        "gerName": "Tadschikistan"
    },
    {
        "code": "TZ",
        "finName": "Tansania",
        "engName": "Tanzania, United Republic of",
        "gerName": "Tansania"
    },
    {
        "code": "TH",
        "finName": "Thaimaa",
        "engName": "Thailand",
        "gerName": "Thailand"
    },
    {
        "code": "TL",
        "finName": "Itä-Timor",
        "engName": "Timor-Leste",
        "gerName": "Osttimor (Timor-Leste)"
    },
    {
        "code": "TG",
        "finName": "Togo",
        "engName": "Togo",
        "gerName": "Togo"
    },
    {
        "code": "TK",
        "finName": "Tokelau",
        "engName": "Tokelau",
        "gerName": "Tokelau"
    },
    {
        "code": "TO",
        "finName": "Tonga",
        "engName": "Tonga",
        "gerName": "Tonga"
    },
    {
        "code": "TT",
        "finName": "Trinidad ja Tobago",
        "engName": "Trinidad and Tobago",
        "gerName": "Trinidad und Tobago"
    },
    {
        "code": "TN",
        "finName": "Tunisia",
        "engName": "Tunisia",
        "gerName": "Tunesien"
    },
    {
        "code": "TR",
        "finName": "Turkki",
        "engName": "Turkey",
        "gerName": "Türkei"
    },
    {
        "code": "TM",
        "finName": "Turkmenistan",
        "engName": "Turkmenistan",
        "gerName": "Turkmenistan"
    },
    {
        "code": "TC",
        "finName": "Turks- ja Caicossaaret",
        "engName": "Turks and Caicos Islands",
        "gerName": "Turks- und Caicosinseln"
    },
    {
        "code": "TV",
        "finName": "Tuvalu",
        "engName": "Tuvalu",
        "gerName": "Tuvalu"
    },
    {
        "code": "UG",
        "finName": "Uganda",
        "engName": "Uganda",
        "gerName": "Uganda"
    },
    {
        "code": "UA",
        "finName": "Ukraina",
        "engName": "Ukraine",
        "gerName": "Ukraine"
    },
    {
        "code": "AE",
        "finName": "Arabiemiirikunnat",
        "engName": "United Arab Emirates",
        "gerName": "Vereinigte Arabische Emirate"
    },
    {
        "code": "GB",
        "finName": "Yhdistynyt kuningaskunta",
        "engName": "United Kingdom",
        "gerName": "Vereinigtes Königreich (Großbritannien und Nordirland)"
    },
    {
        "code": "UM",
        "finName": "Yhdysvaltain pienet erillissaaret",
        "engName": "United States Minor Outlying Islands",
        "gerName": "United States Minor Outlying Islands"
    },
    {
        "code": "US",
        "finName": "Yhdysvallat",
        "engName": "United States",
        "gerName": "Vereinigte Staaten"
    },
    {
        "code": "UY",
        "finName": "Uruguay",
        "engName": "Uruguay",
        "gerName": "Uruguay"
    },
    {
        "code": "UZ",
        "finName": "Uzbekistan",
        "engName": "Uzbekistan",
        "gerName": "Usbekistan"
    },
    {
        "code": "VU",
        "finName": "Vanuatu",
        "engName": "Vanuatu",
        "gerName": "Vanuatu"
    },
    {
        "code": "VE",
        "finName": "Venezuela",
        "engName": "Venezuela, Bolivarian Republic of",
        "gerName": "Venezuela"
    },
    {
        "code": "VN",
        "finName": "Vietnam",
        "engName": "Viet Nam",
        "gerName": "Vietnam"
    },
    {
        "code": "VG",
        "finName": "Brittiläiset Neitsytsaaret",
        "engName": "Virgin Islands, British",
        "gerName": "Britische Jungferninseln"
    },
    {
        "code": "VI",
        "finName": "Yhdysvaltain Neitsytsaaret",
        "engName": "Virgin Islands, U.S.",
        "gerName": "Amerikanische Jungferninseln"
    },
    {
        "code": "WF",
        "finName": "Wallis ja Futunasaaret",
        "engName": "Wallis and Futuna",
        "gerName": "Wallis und Futuna"
    },
    {
        "code": "EH",
        "finName": "Länsi-Sahara",
        "engName": "Western Sahara",
        "gerName": "Westsahara"
    },
    {
        "code": "YE",
        "finName": "Jemen",
        "engName": "Yemen",
        "gerName": "Jemen"
    },
    {
        "code": "ZM",
        "finName": "Sambia",
        "engName": "Zambia",
        "gerName": "Sambia"
    },
    {
        "code": "ZW",
        "finName": "Zimbabwe",
        "engName": "Zimbabwe",
        "gerName": "Simbabwe"
    },
    {
        "code": "AX",
        "finName": "Ahvenanmaa",
        "engName": "Åland Islands",
        "gerName": "Åland"
    }
]
