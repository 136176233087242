//
//
// RUDOLF frontend
//
// Nurminen Development Oy Ltd - https://nurminen.dev
//
// For customer: The Rudolf Oy
//
// ALL RIGHTS RESERVED BY CUSTOMER
//
//

//
// File author(s):
//   - Riku Nurminen <riku@nurminen.dev>
//


import { defineStore }              from 'pinia'

import { apiRequest }               from '../api_request.js'

import * as crudFactory             from './_crud.js'


const api = {
    'index':                    [ 'GET', '/assignments' ],
}


const assignments               = []
const assignmentsMap            = new Map()


export const useAssignmentsStore = defineStore('assignments', {
    state: () => {
        return {
            ...crudFactory.states(),
        }
    },


    getters: {
        ...crudFactory.getters(assignments, assignmentsMap),


        find: (state) => (taskId, productId) => {
            const item = assignments.find(a => a.task === taskId && a.product === productId)

            return item ? item : {}
        },
    },


    actions: {
        ...crudFactory.actions('assignments', {}, assignments, assignmentsMap)
    },

})
