import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"
import _imports_0 from 'FRONTEND/images/linkedin-icon.svg'
import _imports_1 from 'FRONTEND/images/file-type-icon-csv.png'
import _imports_2 from 'FRONTEND/images/file-type-icon-docx.png'
import _imports_3 from 'FRONTEND/images/file-type-icon-image.png'
import _imports_4 from 'FRONTEND/images/file-type-icon-pdf.png'
import _imports_5 from 'FRONTEND/images/file-type-icon-ppt.png'
import _imports_6 from 'FRONTEND/images/file-type-icon-xls.png'
import _imports_7 from 'FRONTEND/images/file-type-icon-zip.png'
import _imports_8 from 'FRONTEND/images/file-type-icon-generic.png'


const _hoisted_1 = { class: "lead-page-active-fields-wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "lead-name",
  style: {"grid-area":"leadname"}
}
const _hoisted_3 = {
  key: 1,
  class: "main-details-column bold",
  style: {"grid-area":"mdtitle"}
}
const _hoisted_4 = {
  key: 2,
  class: "additional-details-column bold",
  style: {"grid-area":"adtitle"}
}
const _hoisted_5 = {
  class: "leadfield",
  style: {"grid-area":"address-field"}
}
const _hoisted_6 = {
  class: "leadvalue",
  style: {"grid-area":"address-value"}
}
const _hoisted_7 = {
  class: "leadfield",
  style: {"grid-area":"creationDate-field"}
}
const _hoisted_8 = {
  class: "leadvalue",
  style: {"grid-area":"creationDate-value"}
}
const _hoisted_9 = {
  class: "leadfield",
  style: {"grid-area":"projectType-field"}
}
const _hoisted_10 = {
  class: "leadvalue",
  style: {"grid-area":"projectType-value"}
}
const _hoisted_11 = {
  class: "leadfield",
  style: {"grid-area":"address_misc-field"}
}
const _hoisted_12 = {
  class: "leadvalue",
  style: {"grid-area":"address_misc-value"}
}
const _hoisted_13 = {
  class: "leadfield",
  style: {"grid-area":"customerType-field"}
}
const _hoisted_14 = {
  class: "leadvalue",
  style: {"grid-area":"customerType-value"}
}
const _hoisted_15 = {
  class: "leadfield",
  style: {"grid-area":"project-field"}
}
const _hoisted_16 = {
  class: "leadvalue",
  style: {"grid-area":"project-value"}
}
const _hoisted_17 = {
  key: 0,
  class: "bold"
}
const _hoisted_18 = {
  class: "leadfield",
  style: {"grid-area":"region-field"}
}
const _hoisted_19 = {
  class: "leadvalue",
  style: {"grid-area":"region-value"}
}
const _hoisted_20 = {
  class: "leadfield",
  style: {"grid-area":"phoneNumbers-field"}
}
const _hoisted_21 = {
  class: "leadvalue",
  style: {"grid-area":"phoneNumbers-value"}
}
const _hoisted_22 = {
  class: "leadfield",
  style: {"grid-area":"zone1-field"}
}
const _hoisted_23 = {
  class: "leadvalue",
  style: {"grid-area":"zone1-value"}
}
const _hoisted_24 = {
  class: "leadfield",
  style: {"grid-area":"zone2-field"}
}
const _hoisted_25 = {
  class: "leadvalue",
  style: {"grid-area":"zone2-value"}
}
const _hoisted_26 = {
  class: "leadfield",
  style: {"grid-area":"zone3-field"}
}
const _hoisted_27 = {
  class: "leadvalue",
  style: {"grid-area":"zone3-value"}
}
const _hoisted_28 = {
  class: "leadfield",
  style: {"grid-area":"zone4-field"}
}
const _hoisted_29 = {
  class: "leadvalue",
  style: {"grid-area":"zone4-value"}
}
const _hoisted_30 = {
  class: "leadfield",
  style: {"grid-area":"website-field"}
}
const _hoisted_31 = {
  class: "leadvalue",
  style: {"grid-area":"website-value"}
}
const _hoisted_32 = ["href"]
const _hoisted_33 = {
  class: "leadfield",
  style: {"grid-area":"email-field"}
}
const _hoisted_34 = {
  class: "leadvalue",
  style: {"grid-area":"email-value"}
}
const _hoisted_35 = ["href"]
const _hoisted_36 = {
  class: "leadfield linkedin",
  style: {"grid-area":"linkedin-field"}
}
const _hoisted_37 = {
  class: "leadvalue",
  style: {"grid-area":"linkedin-value"}
}
const _hoisted_38 = ["href"]
const _hoisted_39 = {
  key: 18,
  class: "lead-freetext-comment mt-3"
}
const _hoisted_40 = { class: "smalltext semibold mb-1" }
const _hoisted_41 = {
  key: 0,
  class: "lead-freetext-comment-area"
}
const _hoisted_42 = {
  key: 19,
  class: "lead-tags mt-3",
  style: {"grid-area":"tags"}
}
const _hoisted_43 = {
  key: 1,
  class: "rtitle normalcase semibold mb-3"
}
const _hoisted_44 = { class: "contact-card primary-contact" }
const _hoisted_45 = { class: "contact-card-icon" }
const _hoisted_46 = { class: "contact-card-content" }
const _hoisted_47 = {
  class: "is-flex",
  style: {"height":"20px","margin-right":"10px"}
}
const _hoisted_48 = { class: "contact-card-name" }
const _hoisted_49 = { key: 0 }
const _hoisted_50 = ["href"]
const _hoisted_51 = {
  key: 0,
  class: "contact-card-position-title"
}
const _hoisted_52 = { class: "has-text-black smalltext" }
const _hoisted_53 = { class: "has-text-black smalltext ml-2" }
const _hoisted_54 = {
  key: 3,
  class: "has-text-black smalltext"
}
const _hoisted_55 = ["href"]
const _hoisted_56 = {
  key: 3,
  class: "rtitle normalcase semibold mb-3"
}
const _hoisted_57 = { class: "contact-card-icon" }
const _hoisted_58 = { class: "contact-card-content" }
const _hoisted_59 = {
  class: "is-flex",
  style: {"height":"20px","margin-right":"10px"}
}
const _hoisted_60 = { class: "contact-card-name" }
const _hoisted_61 = { key: 0 }
const _hoisted_62 = ["href"]
const _hoisted_63 = {
  key: 0,
  class: "contact-card-position-title"
}
const _hoisted_64 = { class: "has-text-black smalltext" }
const _hoisted_65 = { class: "has-text-black smalltext ml-2" }
const _hoisted_66 = {
  key: 3,
  class: "has-text-black smalltext"
}
const _hoisted_67 = ["href"]
const _hoisted_68 = {
  key: 5,
  class: "rtitle normalcase semibold mb-3"
}
const _hoisted_69 = {
  key: 6,
  class: "document-link-cards"
}
const _hoisted_70 = { class: "document-link-card-icon" }
const _hoisted_71 = {
  key: 0,
  src: _imports_1,
  style: {"height":"40px"}
}
const _hoisted_72 = {
  key: 1,
  src: _imports_2,
  style: {"height":"40px"}
}
const _hoisted_73 = {
  key: 2,
  src: _imports_3,
  style: {"height":"40px"}
}
const _hoisted_74 = {
  key: 3,
  src: _imports_4,
  style: {"height":"40px"}
}
const _hoisted_75 = {
  key: 4,
  src: _imports_5,
  style: {"height":"40px"}
}
const _hoisted_76 = {
  key: 5,
  src: _imports_6,
  style: {"height":"40px"}
}
const _hoisted_77 = {
  key: 6,
  src: _imports_7,
  style: {"height":"40px"}
}
const _hoisted_78 = {
  key: 7,
  src: _imports_8,
  style: {"height":"40px"}
}
const _hoisted_79 = ["onClick"]
const _hoisted_80 = { class: "document-link-card-text" }
const _hoisted_81 = { class: "document-link-card-url" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_icon = _resolveComponent("b-icon")
  const _component_b_tag = _resolveComponent("b-tag")
  const _component_b_taglist = _resolveComponent("b-taglist")
  const _component_b_tooltip = _resolveComponent("b-tooltip")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (Array.isArray($props.activeFields) && $props.activeFields.filter(f => f !== "linkedContacts").length > 0)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["active-fields-grid", { "mb-4": true }]),
          style: _normalizeStyle({ "gridTemplateAreas": $options.computedGridAreas })
        }, [
          ($props.activeFields.includes("leadname"))
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString($props.lead.name), 1 /* TEXT */))
            : _createCommentVNode("v-if", true),
          ($options.mainDetailsFieldsOrdered.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.l('add-edit-lead/main-details-column-header')), 1 /* TEXT */))
            : _createCommentVNode("v-if", true),
          ($options.additionalDetailsFieldsOrdered.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.l('add-edit-lead/additional-details-column-header')), 1 /* TEXT */))
            : _createCommentVNode("v-if", true),
          _createCommentVNode(" -----"),
          ($props.activeFields.includes("address"))
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.settingsStore.label("address")) + ":", 1 /* TEXT */),
                _createElementVNode("div", _hoisted_6, _toDisplayString($props.lead.address), 1 /* TEXT */)
              ], 64 /* STABLE_FRAGMENT */))
            : _createCommentVNode("v-if", true),
          _createCommentVNode(" -----"),
          ($props.activeFields.includes("creationDate"))
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.l('add-edit-lead/field-created')) + ":", 1 /* TEXT */),
                _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$dayjs($props.lead.creation_timestamp).format('llll')), 1 /* TEXT */)
              ], 64 /* STABLE_FRAGMENT */))
            : _createCommentVNode("v-if", true),
          _createCommentVNode(" -----"),
          ($props.activeFields.includes("projectType"))
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 5 }, [
                _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.settingsStore.label("projecttype")) + ":", 1 /* TEXT */),
                _createElementVNode("div", _hoisted_10, _toDisplayString($props.lead.projecttype), 1 /* TEXT */)
              ], 64 /* STABLE_FRAGMENT */))
            : _createCommentVNode("v-if", true),
          _createCommentVNode(" -----"),
          ($props.activeFields.includes("address_misc"))
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 6 }, [
                _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.settingsStore.label("addressmisc")) + ":", 1 /* TEXT */),
                _createElementVNode("div", _hoisted_12, _toDisplayString($props.lead.addressmisc), 1 /* TEXT */)
              ], 64 /* STABLE_FRAGMENT */))
            : _createCommentVNode("v-if", true),
          _createCommentVNode(" -----"),
          ($props.activeFields.includes("customerType"))
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 7 }, [
                _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.settingsStore.label("customertype")) + ":", 1 /* TEXT */),
                _createElementVNode("div", _hoisted_14, _toDisplayString($props.lead.customertype), 1 /* TEXT */)
              ], 64 /* STABLE_FRAGMENT */))
            : _createCommentVNode("v-if", true),
          _createCommentVNode(" -----"),
          ($props.activeFields.includes("project"))
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 8 }, [
                _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.settingsStore.label("project")) + ":", 1 /* TEXT */),
                _createElementVNode("div", _hoisted_16, [
                  _createTextVNode(_toDisplayString($props.lead.project), 1 /* TEXT */),
                  ($props.lead.project && $props.lead.project_isparent === true)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_17, "(" + _toDisplayString(_ctx.l('add-edit-lead/hq-parent')) + ")", 1 /* TEXT */))
                    : _createCommentVNode("v-if", true)
                ])
              ], 64 /* STABLE_FRAGMENT */))
            : _createCommentVNode("v-if", true),
          _createCommentVNode(" -----"),
          ($props.activeFields.includes("region"))
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 9 }, [
                _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.settingsStore.label("region")) + ":", 1 /* TEXT */),
                _createElementVNode("div", _hoisted_19, _toDisplayString($props.lead.region), 1 /* TEXT */)
              ], 64 /* STABLE_FRAGMENT */))
            : _createCommentVNode("v-if", true),
          _createCommentVNode(" -----"),
          ($props.activeFields.includes("phoneNumbers"))
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 10 }, [
                _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.l('add-edit-lead/field-phonenumbers')) + ":", 1 /* TEXT */),
                _createElementVNode("div", _hoisted_21, _toDisplayString(Array.isArray($props.lead.phoneNumbers) ? $props.lead.phoneNumbers.join(', ') : ''), 1 /* TEXT */)
              ], 64 /* STABLE_FRAGMENT */))
            : _createCommentVNode("v-if", true),
          _createCommentVNode(" -----"),
          ($props.activeFields.includes("zone1"))
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 11 }, [
                _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.settingsStore.label("zone1")) + ":", 1 /* TEXT */),
                _createElementVNode("div", _hoisted_23, _toDisplayString($props.lead.zone1), 1 /* TEXT */)
              ], 64 /* STABLE_FRAGMENT */))
            : _createCommentVNode("v-if", true),
          _createCommentVNode(" -----"),
          ($props.activeFields.includes("zone2"))
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 12 }, [
                _createElementVNode("div", _hoisted_24, _toDisplayString(_ctx.settingsStore.label("zone2")) + ":", 1 /* TEXT */),
                _createElementVNode("div", _hoisted_25, _toDisplayString($props.lead.zone2), 1 /* TEXT */)
              ], 64 /* STABLE_FRAGMENT */))
            : _createCommentVNode("v-if", true),
          _createCommentVNode(" -----"),
          ($props.activeFields.includes("zone3"))
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 13 }, [
                _createElementVNode("div", _hoisted_26, _toDisplayString(_ctx.settingsStore.label("zone3")) + ":", 1 /* TEXT */),
                _createElementVNode("div", _hoisted_27, _toDisplayString($props.lead.zone3), 1 /* TEXT */)
              ], 64 /* STABLE_FRAGMENT */))
            : _createCommentVNode("v-if", true),
          _createCommentVNode(" -----"),
          ($props.activeFields.includes("zone4"))
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 14 }, [
                _createElementVNode("div", _hoisted_28, _toDisplayString(_ctx.settingsStore.label("zone4")) + ":", 1 /* TEXT */),
                _createElementVNode("div", _hoisted_29, _toDisplayString($props.lead.zone4), 1 /* TEXT */)
              ], 64 /* STABLE_FRAGMENT */))
            : _createCommentVNode("v-if", true),
          _createCommentVNode(" -----"),
          ($props.activeFields.includes("website"))
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 15 }, [
                _createElementVNode("div", _hoisted_30, _toDisplayString(_ctx.settingsStore.label("website")) + ":", 1 /* TEXT */),
                _createElementVNode("div", _hoisted_31, [
                  ($options.websiteComputed)
                    ? (_openBlock(), _createElementBlock("a", {
                        key: 0,
                        href: $options.websiteComputed,
                        target: "_blank"
                      }, _toDisplayString($props.lead.website), 9 /* TEXT, PROPS */, _hoisted_32))
                    : _createCommentVNode("v-if", true)
                ])
              ], 64 /* STABLE_FRAGMENT */))
            : _createCommentVNode("v-if", true),
          _createCommentVNode(" -----"),
          ($props.activeFields.includes("email"))
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 16 }, [
                _createElementVNode("div", _hoisted_33, _toDisplayString(_ctx.settingsStore.label("email")) + ":", 1 /* TEXT */),
                _createElementVNode("div", _hoisted_34, [
                  ($props.lead.email)
                    ? (_openBlock(), _createElementBlock("a", {
                        key: 0,
                        href: "mailto:" + $props.lead.email
                      }, _toDisplayString($props.lead.email), 9 /* TEXT, PROPS */, _hoisted_35))
                    : _createCommentVNode("v-if", true)
                ])
              ], 64 /* STABLE_FRAGMENT */))
            : _createCommentVNode("v-if", true),
          _createCommentVNode(" -----"),
          ($props.activeFields.includes("linkedin"))
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 17 }, [
                _createElementVNode("div", _hoisted_36, [
                  _createElementVNode("div", null, [
                    _createVNode(_component_b_icon, {
                      icon: "linkedin",
                      "custom-size": "mdi-18px",
                      type: "is-light"
                    })
                  ]),
                  _cache[3] || (_cache[3] = _createElementVNode("div", null, "LinkedIn:", -1 /* HOISTED */))
                ]),
                _createElementVNode("div", _hoisted_37, [
                  ($props.lead.linkedin)
                    ? (_openBlock(), _createElementBlock("a", {
                        key: 0,
                        href: $props.lead.linkedin,
                        title: "LinkedIn",
                        target: "_blank"
                      }, _toDisplayString($props.lead.linkedin), 9 /* TEXT, PROPS */, _hoisted_38))
                    : _createCommentVNode("v-if", true)
                ])
              ], 64 /* STABLE_FRAGMENT */))
            : _createCommentVNode("v-if", true),
          _createCommentVNode(" -----"),
          ($props.activeFields.includes("freeTextComment"))
            ? (_openBlock(), _createElementBlock("div", _hoisted_39, [
                _createElementVNode("div", _hoisted_40, _toDisplayString(_ctx.l('add-edit-lead/field-comment')) + ":", 1 /* TEXT */),
                ($props.lead.freeTextComment)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_41, _toDisplayString($props.lead.freeTextComment), 1 /* TEXT */))
                  : _createCommentVNode("v-if", true)
              ]))
            : _createCommentVNode("v-if", true),
          _createCommentVNode(" -----"),
          ($props.activeFields.includes("tags") && Array.isArray($props.lead?.tags) && $props.lead.tags.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_42, [
                _createVNode(_component_b_taglist, null, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.lead.tags, (tag, idx) => {
                      return (_openBlock(), _createBlock(_component_b_tag, {
                        key: "lead_tag_" + idx
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(tag), 1 /* TEXT */)
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1024 /* DYNAMIC_SLOTS */))
                    }), 128 /* KEYED_FRAGMENT */))
                  ]),
                  _: 1 /* STABLE */
                })
              ]))
            : _createCommentVNode("v-if", true)
        ], 4 /* STYLE */))
      : _createCommentVNode("v-if", true),
    ($props.activeFields.includes("mainContact"))
      ? (_openBlock(), _createElementBlock("h5", _hoisted_43, _toDisplayString(_ctx.l('add-edit-lead/primary-contact')) + ":", 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    ($props.activeFields.includes("mainContact") && $options.mainContact)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: _normalizeClass(["contact-cards", { "mb-4": $props.activeFields.includes("linkedContacts") || $props.activeFields.includes("documentLinks") }])
        }, [
          _createElementVNode("div", _hoisted_44, [
            _createElementVNode("div", _hoisted_45, [
              _createVNode(_component_b_tooltip, {
                class: "is-clickable",
                type: "is-white",
                position: "is-right",
                label: _ctx.l("add-edit-lead/primary-contact")
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_icon, { icon: "account-star" })
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["label"])
            ]),
            _createElementVNode("div", _hoisted_46, [
              _createElementVNode("div", _hoisted_47, [
                _createElementVNode("div", _hoisted_48, _toDisplayString($options.mainContact.firstname) + " " + _toDisplayString($options.mainContact.lastname), 1 /* TEXT */),
                ($options.mainContact.linkedin)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_49, [
                      _createElementVNode("a", {
                        href: $options.mainContact.linkedin,
                        target: "_blank"
                      }, _cache[4] || (_cache[4] = [
                        _createElementVNode("img", {
                          src: _imports_0,
                          width: "16",
                          style: {"margin":"0 0 1px 7px"}
                        }, null, -1 /* HOISTED */)
                      ]), 8 /* PROPS */, _hoisted_50)
                    ]))
                  : _createCommentVNode("v-if", true)
              ]),
              ($options.mainContact.title)
                ? (_openBlock(), _createElementBlock("div", _hoisted_51, _toDisplayString($options.mainContact.title), 1 /* TEXT */))
                : _createCommentVNode("v-if", true),
              _createElementVNode("span", _hoisted_52, _toDisplayString($options.mainContact.telephone), 1 /* TEXT */),
              ($options.mainContact.telephone)
                ? (_openBlock(), _createBlock(_component_b_icon, {
                    key: 1,
                    icon: "content-copy",
                    "custom-class": "clipboard-copy-icon is-clickable",
                    onClick: _cache[0] || (_cache[0] = $event => ($options.copyToClipboard($options.mainContact.telephone)))
                  }))
                : _createCommentVNode("v-if", true),
              ($options.mainContact.telephone2)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                    _createElementVNode("span", _hoisted_53, _toDisplayString($options.mainContact.telephone2), 1 /* TEXT */),
                    ($options.mainContact.telephone2)
                      ? (_openBlock(), _createBlock(_component_b_icon, {
                          key: 0,
                          icon: "content-copy",
                          "custom-class": "clipboard-copy-icon is-clickable",
                          onClick: _cache[1] || (_cache[1] = $event => ($options.copyToClipboard($options.mainContact.telephone2)))
                        }))
                      : _createCommentVNode("v-if", true)
                  ], 64 /* STABLE_FRAGMENT */))
                : _createCommentVNode("v-if", true),
              _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
              ($options.mainContact.email)
                ? (_openBlock(), _createElementBlock("span", _hoisted_54, [
                    _createElementVNode("a", {
                      href: "mailto:" + $options.mainContact.email
                    }, _toDisplayString($options.mainContact.email), 9 /* TEXT, PROPS */, _hoisted_55)
                  ]))
                : _createCommentVNode("v-if", true),
              ($options.mainContact.email)
                ? (_openBlock(), _createBlock(_component_b_icon, {
                    key: 4,
                    icon: "content-copy",
                    "custom-class": "clipboard-copy-icon is-clickable",
                    onClick: _cache[2] || (_cache[2] = $event => ($options.copyToClipboard($options.mainContact.email)))
                  }))
                : _createCommentVNode("v-if", true)
            ])
          ])
        ], 2 /* CLASS */))
      : _createCommentVNode("v-if", true),
    ($props.activeFields.includes("linkedContacts"))
      ? (_openBlock(), _createElementBlock("h5", _hoisted_56, _toDisplayString(_ctx.l('add-edit-lead/contacts-header')) + ":", 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    ($props.activeFields.includes("linkedContacts") && Array.isArray($props.lead?.contacts) && $props.lead.contacts.length > 0)
      ? (_openBlock(), _createElementBlock("div", {
          key: 4,
          class: _normalizeClass(["contact-cards", { "mb-4": $props.activeFields.includes("documentLinks") }])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.leadContacts, (contact) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(["contact-card", { "primary-contact": $props.lead.primaryContact === contact._id }]),
              key: "preview_contact_card_" + contact._id
            }, [
              _createElementVNode("div", _hoisted_57, [
                ($props.lead.primaryContact === contact._id)
                  ? (_openBlock(), _createBlock(_component_b_tooltip, {
                      key: 0,
                      class: "is-clickable",
                      type: "is-white",
                      position: "is-right",
                      label: _ctx.l("add-edit-lead/primary-contact")
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_b_icon, { icon: "account-star" })
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["label"]))
                  : (_openBlock(), _createBlock(_component_b_icon, {
                      key: 1,
                      icon: "account"
                    }))
              ]),
              _createElementVNode("div", _hoisted_58, [
                _createElementVNode("div", _hoisted_59, [
                  _createElementVNode("div", _hoisted_60, _toDisplayString(contact.firstname) + " " + _toDisplayString(contact.lastname), 1 /* TEXT */),
                  (contact.linkedin)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_61, [
                        _createElementVNode("a", {
                          href: contact.linkedin,
                          target: "_blank"
                        }, _cache[6] || (_cache[6] = [
                          _createElementVNode("img", {
                            src: _imports_0,
                            width: "16",
                            style: {"margin":"0 0 1px 7px"}
                          }, null, -1 /* HOISTED */)
                        ]), 8 /* PROPS */, _hoisted_62)
                      ]))
                    : _createCommentVNode("v-if", true)
                ]),
                (contact.title)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_63, _toDisplayString(contact.title), 1 /* TEXT */))
                  : _createCommentVNode("v-if", true),
                _createElementVNode("span", _hoisted_64, _toDisplayString(contact.telephone), 1 /* TEXT */),
                (contact.telephone)
                  ? (_openBlock(), _createBlock(_component_b_icon, {
                      key: 1,
                      icon: "content-copy",
                      "custom-class": "clipboard-copy-icon is-clickable",
                      onClick: $event => ($options.copyToClipboard(contact.telephone))
                    }, null, 8 /* PROPS */, ["onClick"]))
                  : _createCommentVNode("v-if", true),
                (contact.telephone2)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                      _createElementVNode("span", _hoisted_65, _toDisplayString(contact.telephone2), 1 /* TEXT */),
                      (contact.telephone2)
                        ? (_openBlock(), _createBlock(_component_b_icon, {
                            key: 0,
                            icon: "content-copy",
                            "custom-class": "clipboard-copy-icon is-clickable",
                            onClick: $event => ($options.copyToClipboard(contact.telephone2))
                          }, null, 8 /* PROPS */, ["onClick"]))
                        : _createCommentVNode("v-if", true)
                    ], 64 /* STABLE_FRAGMENT */))
                  : _createCommentVNode("v-if", true),
                _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
                (contact.email)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_66, [
                      _createElementVNode("a", {
                        href: "mailto:" + contact.email
                      }, _toDisplayString(contact.email), 9 /* TEXT, PROPS */, _hoisted_67)
                    ]))
                  : _createCommentVNode("v-if", true),
                (contact.email)
                  ? (_openBlock(), _createBlock(_component_b_icon, {
                      key: 4,
                      icon: "content-copy",
                      "custom-class": "clipboard-copy-icon is-clickable",
                      onClick: $event => ($options.copyToClipboard(contact.email))
                    }, null, 8 /* PROPS */, ["onClick"]))
                  : _createCommentVNode("v-if", true)
              ])
            ], 2 /* CLASS */))
          }), 128 /* KEYED_FRAGMENT */))
        ], 2 /* CLASS */))
      : _createCommentVNode("v-if", true),
    ($props.activeFields.includes("documentLinks"))
      ? (_openBlock(), _createElementBlock("h5", _hoisted_68, _toDisplayString(_ctx.l('add-edit-lead/document-links-title')) + ":", 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    ($props.activeFields.includes("documentLinks") && $options.leadDocumentLinks.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_69, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.leadDocumentLinks, (documentLink) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "document-link-card",
              key: "preview_document_link_card_" + documentLink._id
            }, [
              _createElementVNode("div", _hoisted_70, [
                (documentLink.type === "csv")
                  ? (_openBlock(), _createElementBlock("img", _hoisted_71))
                  : (documentLink.type === "doc")
                    ? (_openBlock(), _createElementBlock("img", _hoisted_72))
                    : (documentLink.type === "image")
                      ? (_openBlock(), _createElementBlock("img", _hoisted_73))
                      : (documentLink.type === "pdf")
                        ? (_openBlock(), _createElementBlock("img", _hoisted_74))
                        : (documentLink.type === "ppt")
                          ? (_openBlock(), _createElementBlock("img", _hoisted_75))
                          : (documentLink.type === "xls")
                            ? (_openBlock(), _createElementBlock("img", _hoisted_76))
                            : (documentLink.type === "zip")
                              ? (_openBlock(), _createElementBlock("img", _hoisted_77))
                              : (_openBlock(), _createElementBlock("img", _hoisted_78))
              ]),
              _createCommentVNode("///////////////////////////////////////////////////////////////////////"),
              _createCommentVNode(""),
              _createCommentVNode(" Document link card, VIEW MODE"),
              _createCommentVNode(""),
              _createCommentVNode("///////////////////////////////////////////////////////////////////////"),
              _createElementVNode("div", {
                class: "document-link-card-content is-clickable",
                onClick: $event => ($options.openLinkNewTab(documentLink.url))
              }, [
                _createElementVNode("div", _hoisted_80, _toDisplayString(documentLink.text), 1 /* TEXT */),
                _createElementVNode("div", _hoisted_81, _toDisplayString(documentLink.url), 1 /* TEXT */)
              ], 8 /* PROPS */, _hoisted_79)
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}