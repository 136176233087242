//
//
// RUDOLF frontend
//
// Nurminen Development Oy Ltd - https://nurminen.dev
//
// For customer: The Rudolf Oy
//
// ALL RIGHTS RESERVED BY CUSTOMER
//
//

//
// File author(s):
//   - Riku Nurminen <riku@nurminen.dev>
//


import { defineStore }  from 'pinia'


export const useSortingStore = defineStore('sorting', {
    state: () => {
        return {
            column: 'creation_timestamp',
            order: 'desc',
        }
    }
})
