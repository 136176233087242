import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "page-content-inner rudolfbox is-relative" }
const _hoisted_2 = { class: "localization-grid" }
const _hoisted_3 = { class: "localization-left is-relative" }
const _hoisted_4 = { class: "top-area" }
const _hoisted_5 = { class: "control" }
const _hoisted_6 = { class: "localizations-list" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "localization-name" }
const _hoisted_9 = { class: "rtitle sm" }
const _hoisted_10 = {
  key: 0,
  class: "has-text-primary"
}
const _hoisted_11 = { class: "ml-4 has-text-italic" }
const _hoisted_12 = { class: "localization-controls" }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = ["onClick"]
const _hoisted_15 = ["onClick"]
const _hoisted_16 = { class: "localization-right" }
const _hoisted_17 = { class: "rudolfbox" }
const _hoisted_18 = { class: "modal-inner has-text-centered" }
const _hoisted_19 = { class: "modal-text" }
const _hoisted_20 = { class: "buttons mb-0" }
const _hoisted_21 = { class: "rudolfbox" }
const _hoisted_22 = { class: "modal-inner has-text-centered is-relative" }
const _hoisted_23 = { class: "import-file" }
const _hoisted_24 = { class: "file-cta" }
const _hoisted_25 = {
  key: 0,
  class: "modal-text is-italic"
}
const _hoisted_26 = {
  key: 1,
  class: "import-to"
}
const _hoisted_27 = {
  key: 2,
  class: "is-flex mb-4 ml-4",
  style: {"column-gap":"25px"}
}
const _hoisted_28 = { style: {"align-self":"flex-end"} }
const _hoisted_29 = { class: "bold has-text-left" }
const _hoisted_30 = { class: "buttons mb-0" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_autocomplete = _resolveComponent("b-autocomplete")
  const _component_b_icon = _resolveComponent("b-icon")
  const _component_b_tooltip = _resolveComponent("b-tooltip")
  const _component_b_field = _resolveComponent("b-field")
  const _component_b_loading = _resolveComponent("b-loading")
  const _component_localization_edit = _resolveComponent("localization-edit")
  const _component_b_button = _resolveComponent("b-button")
  const _component_b_modal = _resolveComponent("b-modal")
  const _component_b_upload = _resolveComponent("b-upload")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[17] || (_cache[17] = _createElementVNode("h2", { class: "rtitle mb-2" }, "Localization", -1 /* HOISTED */)),
          _createVNode(_component_b_field, {
            class: "add-localization-field",
            grouped: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_b_autocomplete, {
                modelValue: $data.localizationSearchStr,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.localizationSearchStr) = $event)),
                placeholder: "Add localization...",
                icon: "plus",
                expanded: "",
                "custom-class": "square add-localization-input",
                "open-on-focus": true,
                "clear-on-select": true,
                data: $options.filteredLocalizations,
                field: "fullName",
                onSelect: _cache[1] || (_cache[1] = localization => $options.addLocalization(localization)),
                loading: $data.loading
              }, null, 8 /* PROPS */, ["modelValue", "data", "loading"]),
              _createElementVNode("p", _hoisted_5, [
                _createVNode(_component_b_tooltip, {
                  label: "Import from file",
                  type: "is-white"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_icon, {
                      icon: "file-import-outline",
                      "custom-size": "mdi-36px",
                      "custom-class": "import-localization-icon is-clickable",
                      onClick: _cache[2] || (_cache[2] = $event => ($options.openImportLocalizationModal()))
                    })
                  ]),
                  _: 1 /* STABLE */
                })
              ])
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _createElementVNode("div", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.computedLocalizations, (localization, idx) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(["localization", { "selected": this.selectedLocalizationId === localization._id }]),
              key: "localization_list_" + localization._id,
              onClick: $event => ($options.selectLocalization(localization))
            }, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  (localization.active)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_10, "[ ACTIVE ] "))
                    : _createCommentVNode("v-if", true),
                  _createTextVNode(_toDisplayString(localization.name), 1 /* TEXT */)
                ]),
                _createElementVNode("div", _hoisted_11, _toDisplayString(localization.nativeName), 1 /* TEXT */)
              ]),
              _createElementVNode("div", _hoisted_12, [
                (localization.active !== true)
                  ? (_openBlock(), _createBlock(_component_b_tooltip, {
                      key: 0,
                      label: "Set as active localization",
                      type: "is-white",
                      "append-to-body": ""
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", {
                          class: "activate-localization",
                          onClick: _withModifiers($event => ($options.setAsActive(localization)), ["stop"])
                        }, [
                          _createVNode(_component_b_icon, {
                            icon: "octagram",
                            "custom-size": "mdi-18px"
                          })
                        ], 8 /* PROPS */, _hoisted_13)
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1024 /* DYNAMIC_SLOTS */))
                  : _createCommentVNode("v-if", true),
                _createVNode(_component_b_tooltip, {
                  label: "Export localization",
                  type: "is-white",
                  "append-to-body": ""
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      class: "export-localization",
                      onClick: _withModifiers($event => ($options.exportLocalization(localization._id)), ["stop"])
                    }, [
                      _createVNode(_component_b_icon, {
                        icon: "file-export-outline",
                        "custom-size": "mdi-18px"
                      })
                    ], 8 /* PROPS */, _hoisted_14)
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1024 /* DYNAMIC_SLOTS */),
                _createVNode(_component_b_tooltip, {
                  label: $options.deleteLocalizationTooltipLabel(localization),
                  type: "is-white",
                  "append-to-body": "",
                  multilined: localization.active === true
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      class: "delete-localization",
                      onClick: _withModifiers($event => ($options.openDeleteLocalizationConfirmModal(localization)), ["stop"])
                    }, [
                      _createVNode(_component_b_icon, {
                        icon: "delete-outline",
                        "custom-size": "mdi-18px",
                        "custom-class": localization.code === "en" || localization.active === true ? "disabled" : ""
                      }, null, 8 /* PROPS */, ["custom-class"])
                    ], 8 /* PROPS */, _hoisted_15)
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label", "multilined"])
              ])
            ], 10 /* CLASS, PROPS */, _hoisted_7))
          }), 128 /* KEYED_FRAGMENT */))
        ]),
        _createVNode(_component_b_loading, {
          "is-full-page": false,
          modelValue: $data.loading,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($data.loading) = $event)),
          animation: "none"
        }, null, 8 /* PROPS */, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_16, [
        _createVNode(_component_localization_edit, {
          localization: $options.selectedLocalization,
          onEnglishFirstSaved: _cache[4] || (_cache[4] = (englishLocalization) => $data.selectedLocalizationId = englishLocalization._id)
        }, null, 8 /* PROPS */, ["localization"])
      ])
    ]),
    _createVNode(_component_b_loading, {
      "is-full-page": false,
      modelValue: _ctx.appLoading,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => ((_ctx.appLoading) = $event))
    }, null, 8 /* PROPS */, ["modelValue"]),
    _createCommentVNode(""),
    _createCommentVNode(""),
    _createCommentVNode(" ███╗   ███╗ ██████╗ ██████╗  █████╗ ██╗     ███████╗"),
    _createCommentVNode(" ████╗ ████║██╔═══██╗██╔══██╗██╔══██╗██║     ██╔════╝"),
    _createCommentVNode(" ██╔████╔██║██║   ██║██║  ██║███████║██║     ███████╗"),
    _createCommentVNode(" ██║╚██╔╝██║██║   ██║██║  ██║██╔══██║██║     ╚════██║"),
    _createCommentVNode(" ██║ ╚═╝ ██║╚██████╔╝██████╔╝██║  ██║███████╗███████║"),
    _createCommentVNode(" ╚═╝     ╚═╝ ╚═════╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝╚══════╝"),
    _createCommentVNode(""),
    _createCommentVNode(""),
    _createVNode(_component_b_modal, {
      class: "fit-content-height",
      modelValue: $data.deleteLocalizationConfirmModalActive,
      "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => (($data.deleteLocalizationConfirmModalActive) = $event)),
      width: 640,
      scroll: "keep",
      "can-cancel": [ 'escape', 'outside' ],
      "on-cancel": $options.closeDeleteLocalizationConfirmModal
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("div", _hoisted_18, [
            _createVNode(_component_b_icon, {
              icon: "alert-circle-outline",
              "custom-class": "modal-alert"
            }),
            _createElementVNode("p", _hoisted_19, [
              _cache[18] || (_cache[18] = _createTextVNode("Are you sure you want to delete the localization for \"")),
              _createElementVNode("strong", null, _toDisplayString($data.localizationToBeDeleted.fullName), 1 /* TEXT */),
              _cache[19] || (_cache[19] = _createTextVNode("\"?"))
            ]),
            _cache[22] || (_cache[22] = _createElementVNode("p", { class: "modal-text" }, "All saved translations for this language will be permanently lost.", -1 /* HOISTED */)),
            _cache[23] || (_cache[23] = _createElementVNode("p", { class: "modal-text has-text-weight-bold" }, "This action cannot be undone!", -1 /* HOISTED */)),
            _createElementVNode("div", _hoisted_20, [
              _createVNode(_component_b_button, {
                type: "is-light",
                rounded: "",
                onClick: _cache[6] || (_cache[6] = $event => ($options.closeDeleteLocalizationConfirmModal()))
              }, {
                default: _withCtx(() => _cache[20] || (_cache[20] = [
                  _createTextVNode("Cancel")
                ])),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_b_button, {
                type: "is-primary",
                rounded: "",
                onClick: _cache[7] || (_cache[7] = $event => ($options.deleteLocalization()))
              }, {
                default: _withCtx(() => _cache[21] || (_cache[21] = [
                  _createTextVNode("Delete")
                ])),
                _: 1 /* STABLE */
              })
            ])
          ])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "on-cancel"]),
    _createVNode(_component_b_modal, {
      class: "fit-content-height import-localization",
      modelValue: $data.importLocalizationModalActive,
      "onUpdate:modelValue": _cache[16] || (_cache[16] = $event => (($data.importLocalizationModalActive) = $event)),
      width: 640,
      scroll: "keep",
      "can-cancel": [ 'escape', 'outside' ],
      "on-cancel": $options.closeImportLocalizationModal
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_21, [
          _createElementVNode("div", _hoisted_22, [
            _cache[28] || (_cache[28] = _createElementVNode("h2", { class: "rtitle lg" }, "Import localization", -1 /* HOISTED */)),
            _createElementVNode("div", _hoisted_23, [
              _createVNode(_component_b_field, {
                class: "file is-primary",
                "custom-class": "smallfield"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_upload, {
                    class: "file-label",
                    modelValue: $data.rawImportedLocalizationFile,
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => (($data.rawImportedLocalizationFile) = $event)),
                    accept: ".xlsx",
                    disabled: $data.loading
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_24, [
                        _createVNode(_component_b_icon, {
                          class: "file-icon",
                          icon: "paperclip"
                        }),
                        _cache[24] || (_cache[24] = _createElementVNode("span", { class: "file-label rtitle sm" }, "Attach localization file", -1 /* HOISTED */))
                      ])
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["modelValue", "disabled"])
                ]),
                _: 1 /* STABLE */
              })
            ]),
            ($data.rawImportedLocalizationFile)
              ? (_openBlock(), _createElementBlock("p", _hoisted_25, _toDisplayString($data.rawImportedLocalizationFile.name), 1 /* TEXT */))
              : _createCommentVNode("v-if", true),
            ($data.rawImportedLocalizationFile)
              ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                  _cache[25] || (_cache[25] = _createElementVNode("div", { class: "import-to-text" }, "Import to:", -1 /* HOISTED */)),
                  _createElementVNode("div", null, [
                    _createVNode(_component_b_field, { class: "add-localization-field" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_b_autocomplete, {
                          modelValue: $data.importLocalizationSearchStr,
                          "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => (($data.importLocalizationSearchStr) = $event)),
                          placeholder: "Search languages...",
                          icon: "file-import-outline",
                          expanded: "",
                          "custom-class": "square",
                          size: "is-small",
                          "open-on-focus": true,
                          "append-to-body": false,
                          "icon-right": $data.importLocalizationSearchStr ? "close-circle" : null,
                          "icon-right-clickable": true,
                          onIconRightClick: _cache[11] || (_cache[11] = $event => ($options.clearImportTo())),
                          data: $options.filteredImportLocalizations,
                          field: "fullName",
                          onSelect: _cache[12] || (_cache[12] = localization => $options.selectImportTo(localization)),
                          loading: $data.loading
                        }, null, 8 /* PROPS */, ["modelValue", "icon-right", "data", "loading"])
                      ]),
                      _: 1 /* STABLE */
                    })
                  ])
                ]))
              : _createCommentVNode("v-if", true),
            ($options.importToExists)
              ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                  _createElementVNode("div", _hoisted_28, [
                    _createVNode(_component_b_icon, {
                      icon: "alert-outline",
                      type: "is-warning",
                      "custom-size": "mdi-48px"
                    })
                  ]),
                  _createElementVNode("div", _hoisted_29, "Localization for \"" + _toDisplayString($options.importToName) + "\" already exists. Imported localization will overwrite any values currently set.", 1 /* TEXT */)
                ]))
              : _createCommentVNode("v-if", true),
            _createElementVNode("div", _hoisted_30, [
              _createVNode(_component_b_button, {
                type: "is-light",
                rounded: "",
                onClick: _cache[13] || (_cache[13] = $event => ($options.closeImportLocalizationModal()))
              }, {
                default: _withCtx(() => _cache[26] || (_cache[26] = [
                  _createTextVNode("Cancel")
                ])),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_b_button, {
                type: "is-primary",
                rounded: "",
                onClick: _cache[14] || (_cache[14] = $event => ($options.importLocalization())),
                disabled: $data.rawImportedLocalizationFile && $data.importTo ? false : true,
                loading: $data.loading
              }, {
                default: _withCtx(() => _cache[27] || (_cache[27] = [
                  _createTextVNode("Import")
                ])),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["disabled", "loading"])
            ]),
            _createVNode(_component_b_loading, {
              "is-full-page": false,
              modelValue: $data.loading,
              "onUpdate:modelValue": _cache[15] || (_cache[15] = $event => (($data.loading) = $event)),
              animation: "none"
            }, null, 8 /* PROPS */, ["modelValue"])
          ])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "on-cancel"])
  ]))
}