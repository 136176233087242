//
//
// RUDOLF frontend
//
// Nurminen Development Oy Ltd - https://nurminen.dev
//
// For customer: The Rudolf Oy
//
// ALL RIGHTS RESERVED BY CUSTOMER
//
//

//
// File author(s):
//   - Riku Nurminen <riku@nurminen.dev>
//


import { defineStore }              from 'pinia'

import router                       from '../router/index.js'

import * as crudFactory             from './_crud.js'


const notifications     = []
const notificationsMap  = new Map()


export const useNotificationsStore = defineStore('notifications', {
    state: () => {
        return {
            ...crudFactory.states(),
        }
    },


    getters: {
        ...crudFactory.getters(notifications, notificationsMap),

        latest() {
            return this.all.sort((a, b) => b.created_at.localeCompare(a.created_at))
        },

        unread() {
            return this.all.filter(n => n.read !== true)
        },

        numUnread() {
            return this.all.filter(n => n.read !== true).length
        },

        numRead() {
            return this.all.filter(n => n.read === true).length
        },

    },


    actions: {
        ...crudFactory.actions('notifications', {}, notifications, notificationsMap),

        notificationClick(notification) {
            if(notification.read !== true) this.markAsRead(notification)

            if(notification.lead) {
              router.push({ name: 'lead-details', params: { 'leadId': notification.lead } })
            } else if(notification.leadId) {
              router.push({ name: 'lead-details', params: { 'leadId': notification.leadId } })
            }
        },

        async markAsRead(notification) {
            try {
                this.UPDATE({ _id: notification._id, read: true })
      
                const payload = { read: true }
      
                await this.patch({ id: notification._id, payload })
              } catch(error) {
                console.log(error)
              }
        },

        async deleteNotification(notification) {
            if(notification.read !== true) return

            try {
              this.DESTROY_BY({ field: '_id', values: notification._id })
    
              await this.destroy(notification._id, true)
            } catch(error) {
              console.log(error)
            }
        },

        markAllAsRead() {
            for(const notification of notifications) {
              if(notification.read === true) continue
    
              this.markAsRead(notification)
            }
        },
    
    
        async deleteAllRead() {
            const readNotifications = notifications.filter(n => n.read === true)
    
            const readNotificationIds = readNotifications.map(n => n._id)
    
            this.DESTROY_BY({ field: '_id', values: readNotificationIds })
    
            for(const readNotificationId of readNotificationIds) {
              this.destroy(readNotificationId, true)
            }
        },

    }

})
